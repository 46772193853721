import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CPagination, CPaginationItem, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { toast } from 'react-toastify';
import ReactDatePicker from "react-datepicker";

const KioskUserReport = () => {

    const accessToken = localStorage.getItem('authToken');
    const [kioskUserData, setKioskUserData] = useState([]);
    const [allKioskUsersData, setAllKioskUsersData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchBtnLoading, setSearchBtnLoading] = useState(false);
    const [printBtnLoading, setPrintBtnLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(50);
    const [totalPages, setTotalPages] = useState('');
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [outlets, setOutlets] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [clicked, setClicked] = useState(false);
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        // const fetchCustomerData = () => {
          if (searchText === "" && touched) {
            const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
            console.log("adjustedStartDate :", adjustedStartDate);
            
            const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
            getKioskUsers(currentPage, recordsPerPage, searchText, selectedOutlet, adjustedStartDate, adjustedEndDate);
          }
        // };
        // fetchCustomerData();
    }, [recordsPerPage, searchText]);

    useEffect(() => {
        getOutlets();
    }, [])

    const handlePageChange = (page) => {
        console.log(" page : ", page);

        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }

        getKioskUsers(page, recordsPerPage, searchText, selectedOutlet, startDate, endDate);
    }

    const handleRecordsPerPageChange = (e) => {
        const limit = e.target.value;
        setRecordsPerPage(limit);
        getKioskUsers(currentPage, limit, searchText, selectedOutlet, startDate, endDate);
    };    

    const getKioskUsers = async(page, limit, search, outlet, start, end) => {
        try {
            const headers = {
                Authorization : `Bearer ${accessToken}`
            }
            // let url =  `kiosk-user?limit=${recordsPerPage}&page=${page}`;
            // if(search !== "" && search !== undefined) {
            //     url += `&search=${search}`
            // }
            console.log("outlet id :", outlet);
            
            let url = `kiosk-user`;

            // Add outletId to the URL only if it exists
            if (outlet && outlet !== '') {
                url += `?outletId=${outlet}`;
            }

            // Check if there's a search query
            if (search && search !== "") {
                // Append search query with the correct delimiter
                url += `${url.includes('?') ? '&' : '?'}search=${search}`;
            } else {
                // Append pagination parameters only if there's no search query
                url += `${url.includes('?') ? '&' : '?'}limit=${limit}&page=${page}&startDate=${start?.toISOString().split("T")[0]}&endDate=${end?.toISOString().split("T")[0]}`;
            }
            const response = await axios.get(url, { headers });
            console.log("Kisok response : ", response?.data?.data);
            setKioskUserData(response?.data?.data?.kioskUsers);
            setTotalPages(response?.data?.data?.pagination?.pages);
            setFilteredData(response?.data?.data?.kioskUsers); 
        } catch (error) {
            console.log("Error :", error);
        }
    }

    const getAllKioskUsers = async() => {
        try {
            const headers = {
                Authorization : `Bearer ${accessToken}`
            }
            const response = await axios.get("kiosk-user", { headers });
            console.log("Kisok response : ", response?.data?.data?.kioskUsers);
            setAllKioskUsersData(response?.data?.data?.kioskUsers);
        } catch (error) {
            console.log("Error :", error);            
        }
    }

    const getOutlets = async () => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("outlet", { headers });
    
          if (response?.data?.status === true) {
            console.log("outlets :", response.data.data);
            const activeOutlets = response?.data?.data?.outlets.filter((active) => active?.status === "ACTIVE");
            setOutlets(activeOutlets);
            // setOutlets(response?.data?.data?.outlets);
          }
        } catch (error) {
          if (error?.response) {
            let errorMessage = "";
            if (error.response?.status === 406) {
              errorMessage =
                error?.response?.data?.error?.message ||
                error?.response?.data?.error;
            } else if (error.response?.status === 412) {
              errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
              errorMessage = error?.response?.data?.error;
            }
    
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        }
    };

    const handleSearchKioskUsers = () => {
        const trimmedSearchText = searchText.trim().toLowerCase();
        if (trimmedSearchText) {
            const filtered = kioskUserData.filter(order => {
                const userName = order?.name?.toLowerCase() || '';  // Ensure name is a string and convert to lowercase
                const userEmail = order?.email?.toLowerCase() || '';  // Ensure email is a string and convert to lowercase
                const userMobile = order?.phone || '';  // Mobile doesn't need lowercase as it's usually numeric
        
                // Combine name, email, and mobile into a single string for easy searching
                const combinedSearchString = `${userName} ${userEmail} ${userMobile}`;
        
                // Check if the combined string includes the trimmed search text
                return combinedSearchString.includes(trimmedSearchText);
            });
    
            setFilteredData(filtered);
        } else {
            setFilteredData(kioskUserData); // Reset to all data if search text is empty
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setClicked(true);
    
        // Reset currentPage to 1 when the date or outlet changes
        setCurrentPage(1);
    
        if (searchText === "") {
            setBtnLoading(true);            
        } else {
            setSubmitBtnLoading(true);
        }
    
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // end of day
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
    
        // Call the API with the reset page number (1)
        await getKioskUsers(1, recordsPerPage, searchText, selectedOutlet, adjustedStartDate, adjustedEndDate);
    
        toast.success("Data Fetched Successfully", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
        });
    
        setBtnLoading(false);
        setSubmitBtnLoading(false);
    }    

    const handlePrintReport = async() => {

        setPrintBtnLoading(true);
        // Fetch all order data for the report
        // const allOrdersData = await getAllKioskUsers();
        setPrintBtnLoading(false);
        const formatDate = (date) => {
            return new Date(date).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            });
        };
        
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        const tableContent = `
          <table style="border-collapse: collapse; width: 100%;">
            <thead>
              <tr style="font-size: 12px">
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Date of First Order</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Date of Last Order</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Customer Name</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Mobile</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Email</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Outlet</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Total Orders Placed</th>
              </tr>
            </thead>
            <tbody>
              ${filteredData?.length > 0 ? 
                filteredData?.map((customerInfo, index) => `
                  <tr style="font-size: 11px">
                    <td style="border: 1px solid black; padding: 8px; ">${index + 1}</td>
                    <td style="border: 1px solid black; padding: 8px; width: 120px">${customerInfo?.firstCreatedAt}</td>
                    <td style="border: 1px solid black; padding: 8px; width: 120px">${customerInfo?.lastCreatedAt}</td>
                    <td style="border: 1px solid black; padding: 8px; word-break: break-word; width: 140px">${customerInfo?.name}</td>
                    <td style="border: 1px solid black; padding: 8px; word-break: break-word; width: 180px"><span>${customerInfo?.phone}</span></td>
                    <td style="border: 1px solid black; padding: 8px; word-break: break-word; width: 180px"><span>${customerInfo?.email ? customerInfo?.email : "No Email"}</span></td>
                    <td style="border: 1px solid black; padding: 8px; word-break: break-word; width: 120px">${customerInfo?.outletDetails?.map((out) => out?.name).filter(Boolean).join(', ') + '.'}</td>
                    <td style="border: 1px solid black; padding: 8px;">${customerInfo?.repeatCount}</td>
                  </tr>
                `).join("") :
                `<tr>
                  <td colspan="9" style="border: 1px solid black; padding: 8px; text-align: center;">No Kiosk Users Found</td>
                </tr>`
              }
            </tbody>
          </table>
        `;
    
        const win = window.open("", "", "width=900,height=600");
        win.document.write(`
          <html>
            <head>
              <title>Kiosk Users Report</title>
              <style>
                table { border-collapse: collapse; width: 100%; }
                th, td { border: 1px solid black; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body onload="window.print();window.close()">
                <div style="display: flex; justify-content: space-between; align-items: center">
                    <h2>Kiosk Users Report</h2>
                    <span>From [${formattedStartDate} to ${formattedEndDate}]</span>
                </div>
                <div>${tableContent}</div>
            </body>
          </html>
        `);
        win.document.close();
    }

    const handleEndDateChange = (date) => {
        if (date && startDate && date < startDate) {
          setError('End date cannot be earlier than start date');
        } else {
          setError('');
          setEndDate(date);
        }
    };

    useEffect(() => {
            const now = new Date();
            const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            const currentDate = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate()
            );
        
            const start = new Date(firstOfMonth.setHours(23, 59, 59, 999)); // start of day
            const end = new Date(currentDate.setHours(23, 59, 59, 999)); // end of day
            // Set the start date and end date
            setStartDate(start);
            setEndDate(end);
            // getProductData(start, end, selectedOutlet, selectedCategory);
    
    }, []);

    return (
        <div className='container'>
            <h5>KIOSK USERS REPORTS</h5>

            <CRow>
                <CCol md={6}>
                    <CFormLabel className="fw-semibold">Select Outlet <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                        id="selectField"
                        name="selectField"
                        value={selectedOutlet}
                        onChange={(e) => setSelectedOutlet(e.target.value)}
                    >
                        <option value="">All Outlets</option>
    
                        {outlets.map((outName, index) => {
                        return (
                            <option key={index} value={outName?._id}>
                            {outName?.name}
                            </option>
                        );
                        })}
                    </CFormSelect>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>Start Date <span className="text-danger">*</span></CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                        selected={startDate}
                        dateFormat="dd-MM-yyyy"  // Set the date format here
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Select Start Date"
                        className="form-control"
                        />
                    </div>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>End Date <span className="text-danger">*</span></CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={endDate}
                            // onChange={(date) => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            onChange={handleEndDateChange}
                            placeholderText="Select End Date"
                            className="form-control"
                        />
                        {/* { error && <div className="text-danger" style={{ fontSize: '14px' }}>{error}</div> } */}
                    </div>
                </CCol>
                <CCol xs={12} md={2}>
                    <CRow><CFormLabel className='text-white'>.</CFormLabel></CRow>
                    <CButton color="primary" className="w-100" onClick={handleSubmit} 
                        disabled={btnLoading || selectedOutlet === ""}
                    >
                        {btnLoading ? <CSpinner size="sm" /> : 'Submit'} 
                        {/* Submit */}
                    </CButton>
                </CCol>
            </CRow>

            <CRow className="mt-3">
                <CCol md="5" lg="6">
                    <CFormInput
                    type="text"
                    placeholder="Search By Name or Mobile or Email" 
                    onChange={(e) => (setSearchText(e.target.value), setTouched(true))}
                    />
                </CCol>
                <CCol md="3" lg="3" className="text-center">
                    <CButton className="btn rounded-pill w-100" onClick={handleSubmit} disabled={ searchText === "" } >
                      {submitBtnLoading ? <CSpinner size="sm" /> : "Search"}
                    </CButton>
                </CCol>
                <CCol md="4" lg="3" className="text-center">
                    <CButton className="btn rounded-pill w-100 bg-success border-success" onClick={handlePrintReport}>
                        { printBtnLoading ? <CSpinner size='sm' /> : "Print Report" }  
                    </CButton>
                </CCol>
            </CRow>

            <CRow className="mt-3">
                <CCol>
                    { clicked === false ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                            <h5 className="text-danger">Please Select Outlet and Required Date</h5>
                        </div>
                        ) : (
                        <>
                            {/* Dropdown for selecting records per page */}
                            <CRow>
                                <CPagination className="mt-1 justify-content-end" style={{ cursor: 'pointer' }}>
                                    <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                                        <CFormSelect
                                        className="ml-3"
                                        style={{ width: 'auto' }}
                                        value={recordsPerPage}
                                        onChange={handleRecordsPerPageChange}
                                        >
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={200}>200</option>
                                            <option value={300}>300</option>
                                            <option value={400}>400</option>
                                            <option value={500}>500</option>
                                        </CFormSelect>
                                    </div>
                                    <CPaginationItem
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Previous
                                    </CPaginationItem>
                                    {totalPages > 15 ? (
                                        <>
                                            {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                            {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                            const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;

                                            // Avoid rendering out-of-range page numbers
                                            if (pageNumber <= totalPages) {
                                                return (
                                                <CPaginationItem
                                                    key={pageNumber}
                                                    active={pageNumber === currentPage}
                                                    onClick={() => handlePageChange(pageNumber)}
                                                >
                                                    {pageNumber}
                                                </CPaginationItem>
                                                );
                                            }
                                            return null;
                                            })}

                                            {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                            {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                                        </>
                                        ) : (
                                        // If totalPages is 15 or less, just display all page numbers
                                        Array.from({ length: totalPages }, (_, i) => (
                                            <CPaginationItem
                                            key={i + 1}
                                            active={i + 1 === currentPage}
                                            onClick={() => handlePageChange(i + 1)}
                                            >
                                            {i + 1}
                                            </CPaginationItem>
                                        ))
                                        )
                                    }
                                    <CPaginationItem
                                        disabled={currentPage === totalPages}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next
                                    </CPaginationItem>
                                </CPagination>
                            </CRow>
                        
                            <CTable striped bordered hover responsive>
                                <CTableHead>
                                    <CTableRow style={{ fontSize: '15px' }}>
                                    <CTableHeaderCell>#</CTableHeaderCell>
                                    <CTableHeaderCell>Date of First Order</CTableHeaderCell>
                                    <CTableHeaderCell>Date of Last Order</CTableHeaderCell>
                                    <CTableHeaderCell>Customer Name</CTableHeaderCell>
                                    <CTableHeaderCell>Mobile & Email</CTableHeaderCell>
                                    <CTableHeaderCell>Outlet</CTableHeaderCell>
                                    <CTableHeaderCell>Total Orders Placed</CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                    {loading ? (
                                        <CTableRow>
                                            <CTableDataCell colSpan="10" className="text-center">
                                            <CSpinner color="primary" size="lg" /> {/* Display spinner */}
                                            </CTableDataCell>
                                        </CTableRow>
                                    ) :
                                    filteredData?.length > 0 ? (
                                        <>
                                            {filteredData?.map((customerData, index) => (
                                                <CTableRow key={index} style={{ fontSize: '15px' }}>
                                                    <CTableDataCell>{(currentPage - 1) * recordsPerPage + index + 1}</CTableDataCell>
                                                    <CTableDataCell style={{ width: '160px' }}>{customerData?.firstCreatedAt}</CTableDataCell>
                                                    <CTableDataCell style={{ width: '160px' }}>{customerData?.lastCreatedAt}</CTableDataCell>
                                                    <CTableDataCell style={{ width: '200px' }}>{customerData?.name}</CTableDataCell>
                                                    <CTableDataCell>
                                                        <span className='text-primary'>{customerData?.phone}</span> <br />
                                                        <span className='text-success'>{customerData?.email}</span>
                                                    </CTableDataCell>
                                                    <CTableDataCell style={{ width: '210px' }}>
                                                        {customerData?.outletDetails?.map((out) => out?.name)
                                                            .filter(Boolean) // Remove any undefined or null names
                                                            .join(', ') + '.'
                                                        }
                                                    </CTableDataCell>
                                                    <CTableDataCell className="text-center" style={{ width: '170px' }}>{customerData?.repeatCount}</CTableDataCell>
                                                </CTableRow>
                                            ))}
                                        </>
                                    ) : (
                                        <CTableRow>
                                            <CTableDataCell colSpan="9" className="text-center text-danger fw-semibold">
                                            No Customers Found
                                            </CTableDataCell>
                                        </CTableRow>
                                    )}
                                </CTableBody>
                            </CTable>

                            <CRow>
                                <CPagination className="mt-1 justify-content-end" style={{ cursor: 'pointer' }}>
                                <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                                    <CFormSelect
                                    className="ml-3"
                                    style={{ width: 'auto' }}
                                    value={recordsPerPage}
                                    onChange={(e) => setRecordsPerPage(e.target.value)}
                                    >
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                    <option value={300}>300</option>
                                    <option value={400}>400</option>
                                    <option value={500}>500</option>
                                    </CFormSelect>
                                </div>
                                <CPaginationItem
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                    Previous
                                </CPaginationItem>
                                {totalPages > 15 ? (
                                    <>
                                        {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                        {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                                        {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                        const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;

                                        // Avoid rendering out-of-range page numbers
                                        if (pageNumber <= totalPages) {
                                            return (
                                            <CPaginationItem
                                                key={pageNumber}
                                                active={pageNumber === currentPage}
                                                onClick={() => handlePageChange(pageNumber)}
                                            >
                                                {pageNumber}
                                            </CPaginationItem>
                                            );
                                        }
                                        return null;
                                        })}

                                        {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                        {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                                    </>
                                    ) : (
                                    // If totalPages is 15 or less, just display all page numbers
                                    Array.from({ length: totalPages }, (_, i) => (
                                        <CPaginationItem
                                        key={i + 1}
                                        active={i + 1 === currentPage}
                                        onClick={() => handlePageChange(i + 1)}
                                        >
                                        {i + 1}
                                        </CPaginationItem>
                                    ))
                                    )
                                }
                                <CPaginationItem
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                    Next
                                </CPaginationItem>
                                </CPagination>
                            </CRow>
                        </>
                        )
                    }
                </CCol>
            </CRow>
        </div>
    )
}

export default KioskUserReport