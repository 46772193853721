import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { DataTableComponent, DeleteModal, PrimaryButton } from "../../components";
import { FaTrash, FaPencilAlt } from "react-icons/fa";

const Roles = () => {
  const accessToken = localStorage.getItem("authToken");
  const [name, setName] = useState("");
  const [roles, setRoles] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [categoryId, setCategoryId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };

  const handleRowsPerPageChange = (rowsPerPage) => {
    setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };

  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  const columns = [
    {
      name: "Sr.No",
      // cell: (row, index) => (pagination.page - 1) * pagination.rowsPerPage + index + 1,
      cell: (row, index) => <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>,
      center: true,
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="fs-7">{row.name ? row.name : "--------"}</div>
      ),
    },
    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center">
          <a
            href="#"
            onClick={() => handleEditModal(row)}
            className="text-primary me-2 d-flex align-items-center gap-1"
            style={{ textDecoration: "none" }}
          >
            <FaPencilAlt /> EDIT
          </a>{" "}
          |
          <a
            href="#"
            className="text-danger ms-2 d-flex align-items-center gap-1"
            onClick={() => handleDeleteModal(row)}
            style={{ textDecoration: "none" }}
          >
            <FaTrash /> DELETE
          </a>
        </div>
      ),
      minWidth: "165px",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setName(value);
  };

  const addRole = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const inputData = {
        name: name,
      };

      const response = await axios.post("role", inputData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getRoles();
        setName("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("role", { headers });

      if (response?.data?.status === true) {
        response?.data?.data?.roles.forEach((item, index) => {
          item.serialNumber = index + 1;
        });

        setRoles(response?.data?.data?.roles);
        setFilteredData(response?.data?.data?.roles);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    setEditedData({
      _id: data?._id,
      name: data.name,
    });
    setOpenEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevState) => ({ ...prevState, [name]: value }));
  };

  const editRoleSubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put("role", editedData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getRoles();
        setOpenEditModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setCategoryId(data?._id);
  };

  const handleDeleteRole = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: categoryId,
      };
      const response = await axios.delete(`role`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getRoles();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }

      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const result = roles.filter((item) => {
      return item.name.toLowerCase().match(searchText.toLocaleLowerCase());
    });
    setFilteredData(result);
  }, [searchText]);

  return (
    <CContainer>
      <CRow>
        <CCol className="col-md-8 col-lg-5 mx-md-auto mb-md-3">
          <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
            <CCardHeader>
              <CCardTitle className="fs-6 fw-semibold pt-1">
                ADD ROLE
              </CCardTitle>
            </CCardHeader>
            <CForm onSubmit={addRole}>
              <CCardBody>
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Name <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="Name"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={handleChange}
                  />
                </div>
              </CCardBody>
              <CCardFooter>
                <PrimaryButton title="ADD ROLE" isLoading={isLoading} />
              </CCardFooter>
            </CForm>
          </CCard>
        </CCol>
        <CCol className="col-md-12 col-lg-7">
          <div>
            <DataTableComponent
              columns={updatedColumns}
              // data={filteredData}
              data={getPaginatedData()} // Use the paginated data here
              title="ROLES LIST"
              searchText={searchText}
              onChange={handleSearchChange}
              pagination
              totalRows={filteredData?.length}
              paginationTotalRows={filteredData.length} // Total number of items
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              paginationServer={false} // Set to false for client-side pagination
            />
          </div>
        </CCol>
      </CRow>

      <CModal
        alignment="center"
        visible={openEditModal}
        onClose={() => setOpenEditModal(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle id="VerticallyCenteredExample" className="fs-6">
            EDIT ROLE
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={editRoleSubmit}>
          <CModalBody>
            <CFormLabel className="fw-semibold">
              Name <span className="text-danger">*</span>
            </CFormLabel>
            <div className="mb-3">
              <CFormInput
                type="text"
                placeholder="Name"
                className="form-control"
                name="name"
                value={editedData.name}
                onChange={handleEditChange}
              />
            </div>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              isLoading={isEditBtnLoading}
            />
          </CModalFooter>
        </CForm>
      </CModal>

      <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteRole} isLoading={isDeleteBtnLoading} title="DELETE ROLE" />
    </CContainer>
  );
};

export default Roles;
