import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { CButton, CCard, CCardBody, CCardHeader, CCardText, CCol, CContainer, CRow, CForm, CInputGroup, CFormLabel, CFormInput, CInputGroupText, CFormTextarea, CFormSelect, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CNav } from '@coreui/react';
import { CardText } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { PrimaryButton } from '../../components';

const Settings = () => {
    const accessToken = localStorage.getItem("authToken");
    const [settingsData, setSettingsData] = useState({});
    const [currentImageKey, setCurrentImageKey] = useState('');
    const [openUpdateImgModal, setOpenUpdateImgModal] = useState(false);
    const [arrivalTime, setArrivalTime] = useState("");
    const [chargesForm, setChargesForm] = useState({
        deliveryCharges: '',
        packagingCharges: '',
        packagingTaxPercent: '',
        platformFees: '',
        platformTaxPercent: '',
    });
    const [notificationImages, setNotificationImages] = useState({
        notificationImages: {
            accountCreation: null,
            inviteeAccountCreation: null,
            referralReward: null,
            orderPlaced: null,
            orderCancelled: null,
            orderDelivered: null,
            orderReady: null,
            orderStatusMismatchScan: null,
            outletOrderMismatchScan: null
        },
        _id: ''
    });
    const [imageError, setImageError] = useState('');
    const [couponsData, setCouponsData] = useState([]);
    const [bithdayCouponList, setBithdayCouponList] = useState([]);
    const [referralCouponList, setReferralCouponList] = useState([]);
    const [whatsAppCouponList, setWhatsAppCouponList] = useState([]);

    const [chargesBtnLoading, setChargesBtnLoading] = useState(false);
    const [ATBtnLoading, setATBtnLoading] = useState(false);
    const [CouponsBtnLoading, setCouponsBtnLoading] = useState(false);
    const [imageBtnLoading, setImageBtnLoading] = useState(false);

    const getSettings = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get('settings', { headers });
            console.log("Response :", response);
            
            setSettingsData(response.data?.data?.settings);
            let charges = {
                deliveryCharges: response.data?.data?.settings?.deliveryCharges,
                packagingCharges: response.data?.data?.settings?.packagingCharges,
                packagingTaxPercent: response.data?.data?.settings?.packagingTaxPercent,
                platformFees: response.data?.data?.settings?.platformFees,
                platformTaxPercent: response.data?.data?.settings?.platformTaxPercent,
                _id: response.data?.data?.settings?._id,
            }
            let arrivalT = {
                _id: response.data?.data?.settings?._id,
                arrivalTimeSettings: response.data?.data?.settings?.arrivalTimeSettings
            }
            setChargesForm(charges);
            setArrivalTime(arrivalT);
        } catch (error) {
            console.error("Error fetching settings", error);
        }
    }

    const handleChangeCharges = (e) => {
        const { name, value } = e.target;
        setChargesForm({
            ...chargesForm,
            [name]: value
        })
    }

    const handleChargesSubmit = async (e) => {
        e.preventDefault();
        setChargesBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            };
            const response = await axios.put('settings', chargesForm, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                getSettings();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setChargesBtnLoading(false);
        }
    }

    const handleChangeArrivalTime = (e) => {
        const { name, value } = e.target;
        setArrivalTime(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleArrivalTimeSubmit = async (e) => {
        e.preventDefault();
        setATBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            }
            const response = await axios.put("settings", arrivalTime, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                getSettings();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setATBtnLoading(false);
        }
    }

    const getCoupons = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("coupons/getBirthdayReferralCoupon", { headers });
            setCouponsData(response?.data?.data?.coupons);
            const responseData = response?.data?.data?.coupons;
            console.log("All Coupons List :", responseData);
            
            const refCoupons = responseData.filter(c => c?.isBirthDayCoupon === false && c?.usageType === "MULTIPLE");
            console.log("Refferral COupon List :", refCoupons);
            
            const bdayCoupons = responseData.filter(c => c?.isReferralCoupon === false && c?.usageType === "MULTIPLE");
            console.log("Bday Coupon :", bdayCoupons);  
            
            const whatsappCoupon = responseData.filter(c => c?.isReferralCoupon === false && c?.usageType !== "MULTIPLE");
            console.log(" whatsApp Coupon list :", whatsappCoupon);
            
            setBithdayCouponList(bdayCoupons);
            setWhatsAppCouponList(whatsappCoupon);
            setReferralCouponList(refCoupons);
        } catch (error) {
            console.error("Error fetching settings", error);
        }
    }

    const imageLabels = {
        accountCreation: 'Account Creation',
        inviteeAccountCreation: 'Invitee Account Creation',
        referralReward: 'Referral Reward',
        orderPlaced: 'Order Placed',
        orderCancelled: 'Order Cancelled',
        orderDelivered: 'Order Delivered',
        orderReady: 'Order Ready',
        orderStatusMismatchScan: 'Order Status Mismatch On Scan',
        outletOrderMismatchScan: 'Outlet Order Mismatch On Scan'
    }

    const handleInputChangeReff = (e) => {
        const { value } = e.target;
        setSettingsData((prevData) => ({
            ...prevData,
            referralCoupon: {
                ...prevData.referralCoupon,
                _id: value
            }
        }))
    }

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;

    //     setSettingsData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //         couponInfo: {
    //             ...prevData.couponInfo,
    //             coupon: {
    //                 ...prevData.couponInfo?.coupon,
    //                 _id: value
    //             }
    //         }
    //     }));
    // };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        setSettingsData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    
    const handleWhatsAppCouponChange = (selectedCoupon) => {
        console.log("Selcetred :coupon :",selectedCoupon);
        
        setSettingsData((prevData) => ({
            ...prevData,
            whatsAppCoupon: selectedCoupon, // Always store the _id
        }));
    };
    

    const handleBirthCouponChange = (e) => {
        const { value } = e.target;
    
        setSettingsData((prevData) => ({
            ...prevData,
            couponInfo: {
                ...prevData.couponInfo,
                coupon: {
                    ...prevData.couponInfo?.coupon,
                    _id: value,
                },
            },
        }));
    };
    
    const handleSubmitCoupon = async (e) => {
        e.preventDefault();
        setCouponsBtnLoading(true);
        try {
            console.log("Debugging settingsData:", settingsData); // Add this line for debugging
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            }
            console.log("After header");
            console.log("After header 1", settingsData);
        
             // Ensure `whatsAppCoupon` is a string (not an object)
            const whatsAppCouponId = typeof settingsData?.whatsAppCoupon === "object" ? settingsData?.whatsAppCoupon._id : settingsData?.whatsAppCoupon;
            // const updatedWhatsAppCouponExpiryDays = settingsData.whatsAppCouponExpiryDays
            console.log("After whatsAppCouponId");

            const couponId = settingsData?.couponInfo?.coupon?._id;
            const formdata = new FormData();
            console.log("After formdata");

            formdata.append('_id', settingsData?._id);
            formdata.append('referralCoupon', settingsData?.referralCoupon?._id);
            formdata.append('whatsAppCoupon', whatsAppCouponId);
            // formdata.append('whatsAppCouponExpiryDays', updatedWhatsAppCouponExpiryDays);
            // formdata.append('couponInfo', JSON.stringify({
            //     coupon: settingsData?.couponInfo?.coupon?._id
            // }));
            formdata.append('couponInfo[coupon]', settingsData?.couponInfo?.coupon?._id)
            console.log("After formdata append");

            const response = await axios.put("settings", formdata, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                getSettings();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if ( error.response?.status === 406 ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if ( error.response?.status === 404 ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if ( error.response?.status === 412 ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setCouponsBtnLoading(false);
        }
    }

    const openImageModal = (key) => {
        setCurrentImageKey(key);
        setOpenUpdateImgModal(true);
    }

    const handleImageChange = (e, key) => {
        const file = e.target.files[0];
        if (file) {
            const validFormats = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!validFormats.includes(file.type)) {
                setImageError('Invalid file format. Only JPEG, PNG, and JPG are allowed.');
                return;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    if (img.width === 500) {
                        setNotificationImages((prevImages) => ({
                            ...prevImages,
                            [key]: file
                        }));
                        setImageError(''); // Clear the error message if the width is correct Clear 
                    } else {
                        setImageError('Image width must be 500px');
                    }
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpdateImages = async (e) => {
        e.preventDefault();
        setImageBtnLoading(true);
        const updatedImages = {
            accountCreation: notificationImages.accountCreation || settingsData?.notificationImages?.accountCreation || null,
            inviteeAccountCreation: notificationImages.inviteeAccountCreation || settingsData?.notificationImages?.inviteeAccountCreation || null,
            referralReward: notificationImages.referralReward || settingsData?.notificationImages?.referralReward || null,
            orderPlaced: notificationImages.orderPlaced || settingsData?.notificationImages?.orderPlaced || null,
            orderCancelled: notificationImages.orderCancelled || settingsData?.notificationImages?.orderCancelled || null,
            orderDelivered: notificationImages.orderDelivered || settingsData?.notificationImages?.orderDelivered || null,
            orderReady: notificationImages.orderReady || settingsData?.notificationImages?.orderReady || null,
            orderStatusMismatchScan: notificationImages.orderStatusMismatchScan || settingsData?.notificationImages?.orderStatusMismatchScan || null,
            outletOrderMismatchScan: notificationImages.outletOrderMismatchScan || settingsData?.notificationImages?.outletOrderMismatchScan || null
        };
        const _id = settingsData?._id
        setNotificationImages(updatedImages);
        try {
            const headers = {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${accessToken}`,
            };
            const formData = new FormData();
            formData.append("_id", _id);
            for (const key in updatedImages) {
                if (updatedImages[key]) {
                    formData.append(`notificationImages.${key}`, updatedImages[key]);
                }
            }
            const response = await axios.put("settings", formData, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                getSettings();
                setOpenUpdateImgModal(false);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
                } else if (error.response?.status === 400) {
                    errorMessage = error?.response?.data?.message;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setImageBtnLoading(false);
        }
    }

    useEffect(() => {
        getSettings();
        getCoupons();
    }, []);

    if (!settingsData) {
        return <div>Loading...</div>;
    }

    return (
        <CContainer>
            
            {/* <--------- Notification Images --------> */}
            <CRow>
                <CCol>
                    <h5>NOTIFICATION IMAGES</h5>
                    <CRow>
                        {settingsData?.notificationImages && Object.entries(settingsData?.notificationImages).map(([key, url]) => (
                            <CCol sm="6" md="4" lg="3" key={key} className="mb-4">
                                <CCard className='p-1' style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <label style={{ fontWeight: '600', color: 'GrayText' }}>{imageLabels[key] || key}</label>
                                        <img src={url} alt={key} style={{ width: '100%', marginBottom: '10px', height: "260px" }} />
                                        <CButton color="primary" style={{ fontSize: '15px', margin: '5px', borderRadius: '20px' }} onClick={() => openImageModal(key)}>Change Image</CButton>
                                    </div>
                                </CCard>
                            </CCol>
                        ))}
                    </CRow>
                </CCol>
            </CRow>
            <hr />

            {/* <-------- Charges , Arrival Time And Coupons Info --------> */}
            <CRow>
                <CCol md="4">
                    <CForm onSubmit={handleChargesSubmit}>
                        <h5>CHARGES</h5>
                        <CCard style={{ border: '2px solid #007bff', backgroundColor: '#e9ecef', borderRadius: '15px', padding: '1px', marginBottom: '20px' }}>
                            <CCardBody>
                                <CInputGroup className="mb-3">
                                    <CInputGroup>
                                        <CInputGroupText className='w-50 fw-semibold'>Delivery Charges </CInputGroupText>
                                        <CFormInput type="number" name="deliveryCharges" value={chargesForm?.deliveryCharges} onChange={handleChangeCharges}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            }}
                                            pattern="^\d+(\.\d+){0,2}$"
                                            title="Purchase price must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                                        />
                                    </CInputGroup>
                                </CInputGroup>
                                <CInputGroup className="mb-3">
                                    <CInputGroup>
                                        <CInputGroupText className='w-50 fw-semibold'>Packaging Charges </CInputGroupText>
                                        <CFormInput type="number" name="packagingCharges" value={chargesForm?.packagingCharges} onChange={handleChangeCharges}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            }}
                                            pattern="^\d+(\.\d+){0,2}$"
                                            title="Purchase price must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                                        />
                                    </CInputGroup>
                                </CInputGroup>
                                <CInputGroup className="mb-3">
                                    <CInputGroup>
                                        <CInputGroupText className='w-50 fw-semibold'>Packaging Tax % </CInputGroupText>
                                        <CFormInput type="number" name="packagingTaxPercent" value={chargesForm?.packagingTaxPercent} onChange={handleChangeCharges}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            }}
                                            pattern="^\d+(\.\d+){0,2}$"
                                            title="Purchase price must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                                        />
                                    </CInputGroup>
                                </CInputGroup>
                                <CInputGroup className="mb-3">
                                    <CInputGroup>
                                        <CInputGroupText className='w-50 fw-semibold'>Platform Fees </CInputGroupText>
                                        <CFormInput type="number" name="platformFees" value={chargesForm?.platformFees} onChange={handleChangeCharges}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            }}
                                            pattern="^\d+(\.\d+){0,2}$"
                                            title="Purchase price must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                                        />
                                    </CInputGroup>
                                </CInputGroup>
                                <CInputGroup className="mb-3">
                                    <CInputGroup>
                                        <CInputGroupText className='w-50 fw-semibold'>Platform Tax % </CInputGroupText>
                                        <CFormInput type="number" name="platformTaxPercent" value={chargesForm?.platformTaxPercent} onChange={handleChangeCharges}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            }}
                                            pattern="^\d+(\.\d+){0,2}$"
                                            title="Purchase price must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                                        />
                                    </CInputGroup>
                                </CInputGroup>
                                <div className='d-flex justify-content-center'>
                                    {/* <CButton color="primary" onClick={handleChargesSubmit} style={{ fontSize: '15px' }}>Update Charges</CButton> */}
                                    <PrimaryButton title="Update Charges" isLoading={chargesBtnLoading} />
                                </div>
                            </CCardBody>
                        </CCard>
                    </CForm>
                </CCol>
                <CCol md="4">
                    <CForm onSubmit={handleSubmitCoupon}>
                        <h5>COUPONS INFO</h5>
                        <CCard style={{ border: '2px solid #17a2b8', backgroundColor: '#e9ecef', borderRadius: '15px', padding: '1px', marginBottom: '20px' }} >
                            <CCardBody>
                                <CInputGroup className="mb-3">
                                    <CInputGroupText className='fw-semibold' style={{ width: '45%' }}>Birthday Coupon</CInputGroupText>
                                    <CFormSelect id='birthdayCoupon' name="birthdayCoupon" onChange={handleBirthCouponChange} value={settingsData?.couponInfo?.coupon?._id || ''}>
                                        <option value="">Select Birthday Coupon</option>
                                        {
                                            bithdayCouponList?.map((coup) => {
                                                return (
                                                    <option key={coup?._id} value={coup?._id}>{coup?.title}</option>
                                                );
                                            })
                                        }
                                    </CFormSelect>
                                </CInputGroup>

                                <CInputGroup className="mb-3">
                                    <CInputGroupText className='fw-semibold' style={{ width: '45%' }}>Referral Coupon</CInputGroupText>
                                    <CFormSelect name="title" value={settingsData?.referralCoupon?._id} onChange={handleInputChangeReff}>
                                        <option value="">Select Referral Coupon</option>
                                        {
                                            referralCouponList?.map((coup) => {
                                                return (
                                                    <option key={coup?._id} value={coup?._id}>{coup?.title}</option>
                                                )
                                            })
                                        }
                                    </CFormSelect>
                                </CInputGroup>

                                <CInputGroup className="mb-3">
                                    <CInputGroupText className='fw-semibold' style={{ width: '45%' }}>WhatsApp Coupon</CInputGroupText>
                                    <CFormSelect id='whatsAppCoupon' name="whatsAppCoupon" value={settingsData?.whatsAppCoupon?._id} onChange={(e) => handleWhatsAppCouponChange(e.target.value)}>
                                        <option value="">Select WhatsApp Coupon</option>
                                        {
                                            whatsAppCouponList?.map((coup) => {
                                                return (
                                                    <option key={coup?._id} value={coup?._id}>{coup?.title}</option>
                                                );
                                            })
                                        }
                                    </CFormSelect>
                                </CInputGroup>

                                {/* <CInputGroup className='mb-3'>
                                    <CInputGroupText className='fw-semibold' style={{ width: '45%' }}>WC Valid For Days </CInputGroupText>
                                    <CFormInput type="number" name="whatsAppCouponExpiryDays" value={settingsData?.whatsAppCouponExpiryDays} onChange={handleInputChange}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                            );
                                        }}
                                        pattern="^\d+(\.\d+){0,2}$"
                                        title="Purchase price must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                                    />
                                </CInputGroup> */}
                                
                                <div className='d-flex justify-content-center'>
                                    <PrimaryButton title="Update Coupons" isLoading={CouponsBtnLoading} />
                                </div>
                            </CCardBody>
                        </CCard>
                    </CForm>
                </CCol>

                <CCol md="4">
                    <CForm onSubmit={handleArrivalTimeSubmit}>
                        <h5>ARRIVAL TIME SETTINGS</h5>
                        <CCard style={{ border: '2px solid #17a2b8', backgroundColor: '#e9ecef', borderRadius: '15px', padding: '1px', marginBottom: '20px' }}>
                            <CCardBody>
                                <CInputGroup className='mb-3'>
                                    <CInputGroupText className='w-60 fw-semibold'>Estimated Arrival Time </CInputGroupText>
                                    <CFormInput type="number" name="arrivalTimeSettings" value={arrivalTime?.arrivalTimeSettings} onChange={handleChangeArrivalTime}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                            );
                                        }}
                                        pattern="^\d+(\.\d+){0,2}$"
                                        title="Purchase price must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                                    />
                                </CInputGroup>
                                <div className='d-flex justify-content-center'>
                                    <PrimaryButton title="Update Arrival Time" isLoading={ATBtnLoading} />
                                </div>
                            </CCardBody>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow>



            {/* <--------------- Image update Modal ----------------> */}
            <CModal alignment="center" aria-labelledby="VerticallyCenteredExample" visible={openUpdateImgModal} onClose={() => setOpenUpdateImgModal(false)}>
                <CModalHeader>
                    <CModalTitle>Change Image</CModalTitle>
                </CModalHeader>
                <CForm onSubmit={handleUpdateImages}>
                    <CModalBody>
                        <CInputGroup>
                            <CFormInput type="file" className='w-100' accept="image/*" onChange={(e) => handleImageChange(e, currentImageKey)} />
                            {imageError &&
                                <span style={{ color: 'red', fontSize: '13px' }}>{imageError}</span>
                            }
                        </CInputGroup>
                    </CModalBody>
                    <CModalFooter>
                        <PrimaryButton title="Save" isLoading={imageBtnLoading} isDisable={!!imageError || imageBtnLoading} />
                        <CButton color="danger" className='btn rounded-pill' onClick={() => setOpenUpdateImgModal(false)}>Cancel</CButton>
                    </CModalFooter>
                </CForm>
            </CModal>

        </CContainer>
    );
}

export default Settings;
