import React, { useEffect, useState } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCardTitle,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CRow,
} from "@coreui/react";
import { PrimaryButton } from "../../components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import { format } from "date-fns";

const CartCoupon = ({ outlets, selectedOptions, setSelectedOptions, setSelectedCouponOption }) => {
    const accessToken = localStorage.getItem("authToken");
    const animatedComponents = makeAnimated();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const outletData = outlets.map((outlet) => ({
        value: outlet._id,
        label: `${outlet.name} (${outlet.city})`,
    }));
    const [generalCouponInputData, setGeneralCouponInputData] = useState({
        isHidden: "",
        title: "",
        couponCode: "",
        description: "",
        usageType: "",
        discountPercent: "",
        discountAmount: "",
        maxDiscountAmount: "",
        minOrderAmount: "",
        validityStartDate: "",
        validityEndDate: "",
        // startTime: "",
        // endTime: "",
        // dayOfWeek: "",
        termsAndConditions: [], // Array to store terms
    });
    const [selectedLevelOptions, setSelectedLevelOptions] = useState([]);
    const [generalBtnLoading, setGeneralBtnLoading] = useState(false);
    const [currentTerm, setCurrentTerm] = useState('');
    const [levels, setLevels] = useState([]);
    const [selectedIncludedOptions, setSelectedIncludedOptions] = useState([]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%", // Adjust width based on your requirement
            borderRadius: "6px",
            minWidth: "200px", // Add a minimum width to avoid layout issues
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d",
        }),
    };

    const addTerm = () => {
        if (!currentTerm.trim()) return;
        setGeneralCouponInputData((prevData) => ({
            ...prevData,
            termsAndConditions: [...prevData.termsAndConditions, currentTerm],
        }));
        setCurrentTerm(''); // Clear input after adding
    };

    const removeTerm = (index) => {
        setGeneralCouponInputData((prevData) => ({
            ...prevData,
            termsAndConditions: prevData.termsAndConditions.filter(
                (_, i) => i !== index
            ),
        }));
    };

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handleSelectIncChange = (selected) => {
        setSelectedIncludedOptions(selected);
    };

    const handleSelectLevelChange = (selected) => {
        setSelectedLevelOptions(selected);
    };

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handlegeneralInputChange = (e) => {
        const { name, value } = e.target;

        const numericFields = [
            "discountPercent",
            "discountAmount",
            "maxDiscountAmount",
            "minOrderAmount",
        ];
        if (numericFields.includes(name)) {
            const numberValue = Number(value);
            if (numberValue >= 0 || value === "") {
                setGeneralCouponInputData((prev) => {
                    if (name === "discountPercent" && value === "") {
                        return {
                            ...prev,
                            [name]: value,
                            maxDiscountAmount: "",
                        };
                    }
                    return { ...prev, [name]: value };
                });
            }
        } else {
            setGeneralCouponInputData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const submitGeneralCouponDetails = async (e) => {
        e.preventDefault();
        setGeneralBtnLoading(true);

        if (generalCouponInputData?.discountPercent) {
            delete generalCouponInputData?.discountAmount;
        } else if (generalCouponInputData?.discountAmount) {
            delete generalCouponInputData?.discountPercent;
            delete generalCouponInputData?.maxDiscountAmount;
        }

        let selectedOutlet = selectedOptions.map((option) => option.value);
        // let selectedIncOutlet = selectedIncludedOptions.map((option) => option.value); New Change
        // let selectedLevels = selectedLevelOptions?.map((options) => options?.value); New Change
        let generalCouponData = {
            ...generalCouponInputData,
            excludedOutlets: selectedOutlet,
            // includedOutlets: selectedIncOutlet, New Change
            // applicableLevels: selectedLevels New Change
        };

        if (generalCouponData?.excludedOutlets?.length === 0) {
            delete generalCouponData?.excludedOutlets
        }

        if (generalCouponData?.validityStartDate === "") {
            delete generalCouponData.validityStartDate
        }

        if (generalCouponData?.validityEndDate === "") {
            delete generalCouponData.validityEndDate
        }

        if (generalCouponData?.maxDiscountAmount === "") {
            delete generalCouponData.maxDiscountAmount
        }

        // if (generalCouponData?.applicableLevels === "") {
        //     delete generalCouponData.applicableLevels
        // } New Change

        // if(generalCouponData?.includedOutlets?.length === 0) {
        //     delete generalCouponData?.includedOutlets
        // } New Change

        // if(generalCouponData?.startTime === "") {
        //     delete generalCouponData?.startTime
        // } New Change

        // if(generalCouponData?.endTime === "") {
        //     delete generalCouponData?.endTime
        // } New Change

        // if(generalCouponData?.applicableLevels === "") {
        //     delete generalCouponData?.applicableLevels
        // } New Change

        // if(generalCouponData?.dayOfWeek === "") {
        //     delete generalCouponData?.dayOfWeek
        // } New Change

        if (Array.isArray(generalCouponData?.termsAndConditions) && generalCouponData.termsAndConditions.length === 0) {
            delete generalCouponData.termsAndConditions;
        }

        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const response = await axios.post("coupons", generalCouponData, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                e.target.reset();
                setSelectedOptions([]);
                setSelectedIncludedOptions([]);
                setSelectedCouponOption("");
                setGeneralCouponInputData({
                    title: "",
                    isHidden: "",
                    couponCode: "",
                    description: "",
                    usageType: "",
                    discountPercent: "",
                    discountAmount: "",
                    maxDiscountAmount: "",
                    minOrderAmount: "",
                    validityStartDate: "",
                    validityEndDate: "",
                    // applicableLevels: "", New Change
                    // dayOfWeek: "", New Change
                    // endTime: "", New Change
                    // startTime: "", New Change
                });
                setFromDate(null);
                setToDate(null);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage =
                        error?.response?.data?.data?.message ||
                        error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setGeneralBtnLoading(false);
        }
    };

    const generalBtnDisable = () => {
        let isDisable = false;

        if (
            !generalCouponInputData?.title?.trim() ||
            !generalCouponInputData?.couponCode?.trim() ||
            !generalCouponInputData?.description?.trim() ||
            !generalCouponInputData?.usageType
        ) {
            isDisable = true;
        }

        return isDisable;
    };

    const fetchLevelsData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("level", { headers });
            if (response?.data?.status === true) {
                const levels = response?.data?.data?.levels;
                console.log("Levels :", levels);
                
                setLevels(levels)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        fetchLevelsData();
    }, [])

    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setGeneralCouponInputData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);

    return <CCard>
        <CCardHeader>
            <CCardTitle className="fs-6">ADD CART COUPON</CCardTitle>
        </CCardHeader>
        <CForm onSubmit={submitGeneralCouponDetails}>
            <CCardBody>
                <CRow className="mb-3">
                    <CCol>
                        <div>
                            <CFormLabel className="fw-semibold">
                                Title <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                placeholder="Title"
                                name="title"
                                value={generalCouponInputData.title}
                                onChange={handlegeneralInputChange}
                            />
                        </div>
                    </CCol>
                    <CCol>
                        <div>
                            <CFormLabel className="fw-semibold">
                                Coupon Code <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                placeholder="Coupon Code"
                                name="couponCode"
                                value={generalCouponInputData.couponCode}
                                onChange={handlegeneralInputChange}
                            />
                        </div>
                    </CCol>
                    <CCol>
                        <div>
                            <CFormLabel className="fw-semibold">
                                Usage Type <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormSelect
                                name="usageType"
                                value={generalCouponInputData.usageType}
                                onChange={handlegeneralInputChange}
                            >
                                <option value="">Select Usage</option>
                                <option value="MULTIPLE">MULTIPLE</option>
                                <option value="ONE-TIME">ONE-TIME</option>
                            </CFormSelect>
                        </div>
                    </CCol>
                </CRow>

                <CRow className="mb-3">
                    <CCol md="8">
                        <CFormLabel className="fw-semibold">
                            Select Excluded Outlets
                        </CFormLabel>
                        <Select
                            isMulti
                            components={animatedComponents}
                            value={selectedOptions}
                            onChange={handleSelectChange}
                            options={outletData}
                            styles={customStyles}
                            placeholder="Select Excluded Outlets"
                            closeMenuOnSelect={false}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: "#e9ecef",
                                    primary: "#007bff",
                                },
                            })}
                        />
                    </CCol>
                    <CCol md="4">
                        <CFormLabel className="fw-semibold">
                            Hide Coupon <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                            name="isHidden"
                            value={generalCouponInputData.isHidden}
                            onChange={handlegeneralInputChange}
                        >
                            <option value="">Select Option</option>
                            <option value="true">YES</option>
                            <option value="false">NO</option>
                        </CFormSelect>
                    </CCol>
                </CRow>

                {/* <CRow className="mb-3">
                    <CCol md="8" lg="8">
                        <CFormLabel className="fw-semibold">
                            Select Included Outlets
                        </CFormLabel>
                        <Select
                            isMulti
                            components={animatedComponents}
                            value={selectedIncludedOptions}
                            onChange={handleSelectIncChange}
                            options={outletData}
                            styles={customStyles}
                            placeholder="Select Included Outlets"
                            closeMenuOnSelect={false}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: "#e9ecef",
                                    primary: "#007bff",
                                },
                            })}
                        />
                    </CCol>
                    <CCol md="4" lg="4">
                        <CFormLabel className="fw-semibold">
                            Select Level
                        </CFormLabel>
                        <Select
                            isMulti
                            components={animatedComponents}
                            value={selectedLevelOptions}
                            onChange={handleSelectLevelChange}
                            options={levels?.map((le) => ({
                                value: le._id,
                                label: le.title,
                            }))}
                            styles={customStyles}
                            placeholder="Select Level"
                            closeMenuOnSelect={false}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                ...theme.colors,
                                primary25: "#e9ecef",
                                primary: "#007bff",
                                },
                            })}
                        />
                    </CCol>
                </CRow> New Change */}

                <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                        Description <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormTextarea
                        rows={2}
                        placeholder="Description"
                        name="description"
                        value={generalCouponInputData.description}
                        onChange={handlegeneralInputChange}
                    />
                </div>

                <CRow className="mb-3">
                    <CCol>
                        <div>
                            <CFormLabel className="fw-semibold">
                                Validity Start Date
                            </CFormLabel>
                            <DatePicker
                                selected={fromDate}
                                onChange={handleFromDateChange}
                                placeholderText="Start Date"
                                className="form-control"
                            />
                        </div>
                    </CCol>
                    <CCol>
                        <div>
                            <CFormLabel className="fw-semibold">
                                Validity End Date
                            </CFormLabel>
                            <DatePicker
                                selected={toDate}
                                onChange={handleToDateChange}
                                placeholderText="End Date"
                                className="form-control"
                            />
                        </div>
                    </CCol>
                    <CCol>
                        <CFormLabel className="fw-semibold">
                            Discount Percent <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                            min={0}
                            max={100}
                            disabled={
                                generalCouponInputData?.discountAmount
                            }
                            placeholder="Discount Percent"
                            className="no-arrows"
                            type="number"
                            name="discountPercent"
                            value={generalCouponInputData.discountPercent}
                            onChange={handlegeneralInputChange}
                        />
                    </CCol>
                </CRow>

                {/* <CRow>
                    <CCol md="4" lg="4">
                        <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                                Set Start Time
                            </CFormLabel>
                            <CFormInput
                                type="time"
                                size="sm"
                                onChange={handlegeneralInputChange}
                                name="startTime"
                                className="mb-3"
                                value={generalCouponInputData?.startTime}
                            />
                        </div>
                    </CCol>

                    <CCol md="4" lg="4">
                        <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                                Set End Time
                            </CFormLabel>
                            <CFormInput
                                type="time"
                                size="sm"
                                onChange={handlegeneralInputChange}
                                name="endTime"
                                className="mb-3"
                                value={generalCouponInputData?.endTime}
                            />
                        </div>
                    </CCol>

                    <CCol md="4" lg="4">
                        <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                                Select Day
                            </CFormLabel>
                            <CFormSelect
                                size="sm"
                                onChange={handlegeneralInputChange}
                                name="dayOfWeek"
                                className="mb-3"
                                aria-label="Small select example"
                                value={generalCouponInputData?.dayOfWeek}
                            >
                                <option value="">Select Day</option>
                                <option value="0">SUNDAY</option>
                                <option value="1">MONDAY</option>
                                <option value="2">TUESDAY</option>
                                <option value="3">WEDNESDAY</option>
                                <option value="4">THURSDAY</option>
                                <option value="5">FRIDAY</option>
                                <option value="6">SATURDAY</option>
                            </CFormSelect>
                        </div>
                    </CCol>
                </CRow> */}

                <CRow>
                    <CCol>
                        <CFormLabel className="fw-semibold">
                            Discount Amount <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                            min={0}
                            disabled={
                                generalCouponInputData?.discountPercent
                            }
                            placeholder="Discount Amount"
                            type="number"
                            className="no-arrows"
                            name="discountAmount"
                            value={generalCouponInputData.discountAmount}
                            onChange={handlegeneralInputChange}
                        />
                    </CCol>
                    <CCol>
                        <CFormLabel className="fw-semibold">
                            Max. Dis. Amount
                        </CFormLabel>
                        <CFormInput
                            min={0}
                            disabled={
                                generalCouponInputData?.discountAmount
                            }
                            placeholder="Max. Dis. Amount"
                            type="number"
                            className="no-arrows"
                            name="maxDiscountAmount"
                            value={generalCouponInputData.maxDiscountAmount}
                            onChange={handlegeneralInputChange}
                        />
                    </CCol>
                    <CCol>
                        <CFormLabel className="fw-semibold">
                            Min. Order Amount <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                            min={0}
                            placeholder="Min. Order Amount"
                            type="number"
                            className="no-arrows"
                            name="minOrderAmount"
                            value={generalCouponInputData.minOrderAmount}
                            onChange={handlegeneralInputChange}
                        />
                    </CCol>
                </CRow>
                <CRow className="mt-3">
                        {/* Other fields */}
                        <CCol md="12" className="mb-3">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Terms and Conditions <span className="text-danger">*</span>
                                </CFormLabel>
                                <div className="d-flex mb-2">
                                    <CFormInput
                                        placeholder="Add a term"
                                        value={currentTerm}
                                        onChange={(e) => setCurrentTerm(e.target.value)}
                                        className="me-2"
                                    />
                                    <CButton disabled={!currentTerm.trim()} onClick={addTerm}>ADD</CButton>
                                </div>
                                {generalCouponInputData?.termsAndConditions?.length > 0 && (
                                    <ul className="list-group">
                                        {generalCouponInputData?.termsAndConditions?.map((term, index) => (
                                            <li
                                                key={index}
                                                className="list-group-item d-flex justify-content-between align-items-center"
                                            >
                                                {term}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-danger rounded-pill"
                                                    onClick={() => removeTerm(index)}
                                                >
                                                    Remove
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </CCol>
                    </CRow>
            </CCardBody>
            <CCardFooter>
                <PrimaryButton
                    isDisable={generalBtnDisable()}
                    isLoading={generalBtnLoading}
                    title="ADD CART COUPON"
                />
            </CCardFooter>
        </CForm>
    </CCard>;
};

export default CartCoupon;
