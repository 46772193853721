import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import {
  CCol,
  CContainer,
  CRow,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CForm,
  CModalBody,
  CModalFooter,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CFormTextarea,
} from "@coreui/react";
import { format } from "date-fns";
import { DeleteModal, PrimaryButton } from "../../components";
import DatePicker from "react-datepicker";
import "./CouponCard.css"

const CouponCard = () => {
  const accessToken = localStorage.getItem("authToken");
  const animatedComponents = makeAnimated();
  const [couponsData, setCouponsData] = useState([]);
  const [activeTab, setActiveTab] = useState("ACTIVE");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [couponId, setCouponId] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [singleCouponDetails, setSingleCouponDetails] = useState({});
  const [outlets, setOutlets] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectExcludedProducts, setSelectExcludedProducts] = useState([]);
  const [isEditSizeUpgradeCouponModalOpen, setIsEditSizeUpgradeCouponModalOpen] = useState(false)
  const [editSizeUpgradeCouponData, setEditSizeUpgradeCouponData] = useState({})
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [searchText, setSearchText] = useState('');
  const [addonList, setAddonList] = useState([]);
  const [selectedIncludedOptions, setSelectedIncludedOptions] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedLevelOptions, setSelectedLevelOptions] = useState([]);
  const [searchActiveData, setSearchActiveData] = useState([]);
  const [searchInactiveData, setSearchInactiveData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [currentTerm, setCurrentTerm] = useState('');
  const outletData = outlets.map((out) => ({
    value: out._id,
    label: `${out.name} (${out.city})`
  }))
  const productsData = productsList.map((p) => ({
    value: p._id,
    label: `${p.name}`,
    catId: p?.category?._id
  }))
  const filteredProductsData = productsData.filter(product =>
    selectedCategories.some(category => product.catId === category.value)
  )
  const categoriesData = categoryList.map((c) => ({
    value: c._id,
    label: `${c.name}`
  }))
  const [isEditBogoCouponModalOpen, setIsEditBogoCouponModalOpen] = useState(false);
  const [editedBogoCouponData, setEditedBogoCouponData] = useState({
    isBogoCoupon: true,
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    validityStartDate: "",
    validityEndDate: "",
    startTime: "",
    endTime: "",
    dayOfWeek: "",
    minOrderAmount: "",
    termsAndConditions: [], // Array to store terms
  });

  const [isEditCategoryCouponModalOpen, setIsEditCategoryCouponModalOpen] = useState(false);
  const [editedCategoryCouponData, setEditedCategoryCouponData] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPercent: "",
    discountAmount: "",
    maxDiscountAmount: "",
    validityStartDate: "",
    validityEndDate: "",
    startTime: "",
    endTime: "",
    dayOfWeek: "",
    minOrderAmount: "",
    termsAndConditions: [], // Array to store terms
  });

  const [isEditSpecialCategoryCouponModalOpen, setIsEditSpecialCategoryCouponModalOpen] = useState(false);
  const [editedSpecialCategoryCouponData, setEditedSpecialCategoryCouponData] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPrice: "",
    applicableQuantityType: "",
    validityStartDate: "",
    validityEndDate: "",
    startTime: "",
    endTime: "",
    dayOfWeek: "",
    minOrderAmount: "",
    termsAndConditions: [], // Array to store terms
  });

  const [isEditProductCouponModalOpen, setIsEditProductCouponModalOpen] = useState(false);
  const [editedProductCouponData, setEditedProductCouponData] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPercent: "",
    discountAmount: "",
    maxDiscountAmount: "",
    validityStartDate: "",
    validityEndDate: "",
    startTime: "",
    endTime: "",
    dayOfWeek: "",
    minOrderAmount: "",
    termsAndConditions: [], // Array to store terms
  });

  const [isEditSpecialProductCouponModalOpen, setIsEditSpecialProductCouponModalOpen] = useState(false);
  const [editedSpecialProductCouponData, setEditedSpecialProductCouponData] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPrice: "",
    applicableQuantityType: "",
    validityStartDate: "",
    validityEndDate: "",
    startTime: "",
    endTime: "",
    dayOfWeek: "",
    minOrderAmount: "",
    termsAndConditions: [], // Array to store terms
  })

  const [isEditCartCouponModalOpen, setIsEditCartCouponModalOpen] =
    useState(false);

  const [editedCartCouponDetails, setEditedCartCouponDetails] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPercent: "",
    discountAmount: "",
    maxDiscountAmount: "",
    minOrderAmount: "",
    validityStartDate: "",
    validityEndDate: "",
    dayOfWeek: "",
    startTime: "",
    endTime: "",
    termsAndConditions: [], // Array to store terms
  });

  const [isEditUniversalCouponModalOpen, setIsEditUniversalCouponModalOpen] = useState(false);
  const [editedUniversalCouponDetails, setEditedUniversalCouponDetails] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPercent: "",
    validityStartDate: "",
    validityEndDate: "",
    startTime: "",
    endTime: "",
    dayOfWeek: "",
    minOrderAmount: "",
    termsAndConditions: [], // Array to store terms
  })

  const activeCoupons = couponsData
    .filter((coupon) => {
      if (!coupon.validityEndDate && !coupon.validityStartDate) {
        return true;
      }
      const validityEndDate = new Date(coupon.validityEndDate);
      return validityEndDate >= new Date();
    })
    .sort((a, b) => {
      if (!a.validityEndDate && !b.validityEndDate) return 0;
      if (!a.validityEndDate) return -1;
      if (!b.validityEndDate) return 1;
      return new Date(a.validityEndDate) - new Date(b.validityEndDate);
    });

  const inactiveCoupons = couponsData
    .filter((coupon) => {
      if (!coupon.validityEndDate && !coupon.validityStartDate) {
        return false;
      }
      const validityEndDate = new Date(coupon.validityEndDate);
      return validityEndDate < new Date();
    })
    .sort((a, b) => {
      if (!a.validityEndDate && !b.validityEndDate) return 0;
      if (!a.validityEndDate) return -1;
      if (!b.validityEndDate) return 1;
      return new Date(a.validityEndDate) - new Date(b.validityEndDate);
    });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%", // Adjust width based on your requirement
      borderRadius: '6px',
      minWidth: "200px", // Add a minimum width to avoid layout issues
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
      "&:hover": {
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d"
    }),
  };

  const getCategoryList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/category/get", { headers });

      if (response?.data?.status === true) {
        setCategoryList(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const fetchLevelsData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("level", { headers });
      if (response?.data?.status === true) {
        const levels = response?.data?.data?.levels;
        setLevels(levels)
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getProductsList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("product", { headers });
      if (response?.data?.status === true) {
        setProductsList(response?.data?.data?.products);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getAllCouponsData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("coupons", { headers });

      if (response?.data?.status === true) {
        setCouponsData(response?.data?.data?.coupons);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const formatDate = (date) => {
    const validityEndDate = new Date(date);
    const formattedDate = format(validityEndDate, "yyyy-MM-dd");

    return formattedDate;
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setCouponId(data._id);
  };

  const handleDeleteCoupon = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: couponId,
      };

      const response = await axios.delete(`coupons`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAllCouponsData();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(error?.response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }

      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleCartValidStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
    }
  };

  const handleCartValidEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  const handleUniversalValidStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
    }
  };

  const handleUniversalValidEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  const handleBogoValidityStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedBogoCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditedBogoCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
    }
  };

  const handleBogoValidityEndDateChange = (date) => {
    if (date) {
      // Ensure the date is stripped of any time information
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

      // Format the date in "yyyy-MM-dd" without time zone influence
      const formattedDate = utcDate.toISOString().split("T")[0];

      setEditedBogoCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditedBogoCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });
      if (response?.data?.status === true) {
        setOutlets(response?.data?.data?.outlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  }

  const handleProductValidityStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedSpecialProductCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
      setEditedProductCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditedProductCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
      setEditedSpecialProductCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }))
    }
  };

  const handleProductValidityEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedProductCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
      setEditedSpecialProductCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditedProductCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
      setEditedSpecialProductCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  const handleCategoryValidityStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedSpecialCategoryCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
      setEditedCategoryCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditedSpecialCategoryCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
      setEditedCategoryCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
    }
  };

  const handleCategoryValidityEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedCategoryCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
      setEditedSpecialCategoryCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditedCategoryCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
      setEditedSpecialCategoryCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  // VERY OLD ONE
  // const filteredAddons = editSizeUpgradeCouponData?.applicableProducts ? addonList?.filter(rm => rm.product._id === editSizeUpgradeCouponData?.applicableProducts) : [];

  // BEFORE ONE 
  // const filteredAddons = selectedProduct ? addonList?.filter(rm => rm?.product?._id === selectedProduct) : [];

  const filteredAddons = selectedProduct
    ? addonList?.filter(rm => {
      return String(rm?.product?._id) === String(selectedProduct);
    })
    : [];

  const getAddonProductsList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("product-addon", { headers });
      if (response?.data?.status === true) {
        const addOns = response?.data?.data?.productAddons;
        const productsWithSizeAddon = addOns.filter((item) =>
          item.addons.some((addon) => addon.addonTitle === 'Size')
        );

        // const sizeAddons = addOns.filter((item) => item?.addonTitle === "Size");
        setAddonList(productsWithSizeAddon)
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    // const formattedStartDate = data?.validityStartDate ? format(data?.validityStartDate, "yyyy-MM-dd") : null;
    // const formattedEndDate = data?.validityEndDate ? format(data?.validityEndDate, "yyyy-MM-dd") : null;

    const formattedStartDate = data?.validityStartDate
      ? new Date(data.validityStartDate).toISOString().split("T")[0]
      : null;
    const formattedEndDate = data?.validityEndDate
      ? new Date(data.validityEndDate).toISOString().split("T")[0]
      : null;

    if (data?.sizeUpgradeAddon) {
      if (data?.sizeUpgradeAddon !== null || data?.sizeUpgradeAddon !== undefined) {
        setIsEditSizeUpgradeCouponModalOpen(true)
        setEditSizeUpgradeCouponData({
          _id: data?._id,
          isHidden: data?.isHidden,
          title: data?.title,
          couponCode: data?.couponCode,
          sizeUpgradeAddon: data?.sizeUpgradeAddon?._id,
          description: data?.description,
          usageType: data?.usageType,
          validityStartDate: formattedStartDate,
          validityEndDate: formattedEndDate,
          termsAndConditions: data?.termsAndConditions,
          minOrderAmount: data?.minOrderAmount,
          startTime: data?.startTime,
          endTime: data?.endTime,
          dayOfWeek: data?.dayOfWeek,
          applicableProducts: data?.applicableProducts[0]?._id,
        })
        let selecteData = data?.excludedOutlets;
        let selectedIncData = data?.includedOutlets;
        let selectedLevels = data?.applicableLevels;
        let mappedSelectedData = selecteData.map((m) =>
        ({
          value: m?._id,
          label: m?.name
        })
        )
        let mappedSelectedIncData = selectedIncData?.map((inc) => ({
          value: inc?._id,
          label: inc?.name
        }))
        let mappedSelectedLevels = selectedLevels?.map((le) => ({
          value: le?._id,
          label: le?.title
        }))
        setSelectedProduct(data?.applicableProducts[0]?._id);
        setSelectedOptions(mappedSelectedData);
        setSelectedIncludedOptions(mappedSelectedIncData);
        setSelectedLevelOptions(mappedSelectedLevels);
      }
    }

    if (data?.isBogoCoupon === true) {
      setIsEditBogoCouponModalOpen(true);
      setEditedBogoCouponData({
        _id: data?._id,
        isHidden: data?.isHidden,
        isBogoCoupon: true,
        couponCode: data.couponCode,
        title: data?.title,
        description: data?.description,
        usageType: data?.usageType,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
        excludedOutlets: data?.excludedOutlets,
        termsAndConditions: data?.termsAndConditions,
        startTime: data?.startTime,
        endTime: data?.endTime,
        dayOfWeek: data?.dayOfWeek,
        includedOutlets: data?.includedOutlets,
        minOrderAmount: data?.minOrderAmount
      });

      let selecteData = data?.excludedOutlets;
      let selectedIncludedOutlets = data?.includedOutlets;
      let selectedLevels = data?.applicableLevels

      let mappedSelectedData = selecteData.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      })
      )
      setSelectedOptions(mappedSelectedData);

      let mappedSelectedIncOutlets = selectedIncludedOutlets?.map((i) => ({
        value: i?._id,
        label: i?.name
      }))
      setSelectedIncludedOptions(mappedSelectedIncOutlets);

      let mappedSelectedLevels = selectedLevels?.map((le) => ({
        value: le?._id,
        label: le?.title
      }))
      setSelectedLevelOptions(mappedSelectedLevels);
    }

    if (data?.applicableCategories !== null && data?.applicableCategories.length > 0 && data?.applicableQuantityType === null) {
      setIsEditCategoryCouponModalOpen(true);
      setEditedCategoryCouponData({
        _id: data?._id,
        isHidden: data?.isHidden,
        title: data?.title,
        couponCode: data?.couponCode,
        description: data?.description,
        usageType: data?.usageType,
        discountPercent: data?.discountPercent,
        discountAmount: data?.discountAmount,
        maxDiscountAmount: data?.maxDiscountAmount,
        // applicableCategory: data?.applicableCategory?._id,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
        termsAndConditions: data?.termsAndConditions,
        startTime: data?.startTime,
        endTime: data?.endTime,
        dayOfWeek: data?.dayOfWeek,
        minOrderAmount: data?.minOrderAmount
      });

      let selectedExcludedOutlets = data?.excludedOutlets;
      let selectedUpdatedCategories = data?.applicableCategories;
      let selectEditedExProducts = data?.excludedProducts;
      let selectedIncOutlets = data?.includedOutlets;
      let selectedLevels = data?.applicableLevels;

      let mappedSelectedExcludedOutlets = selectedExcludedOutlets?.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      }))
      let mappedSelectedUpdatedCategories = selectedUpdatedCategories?.map((uc) => ({
        value: uc?._id,
        label: uc?.name
      }))
      let mappedSelectEditedExProducts = selectEditedExProducts?.map((e) => ({
        value: e?._id,
        label: e?.name
      }))
      let mappedIncludedOutlets = selectedIncOutlets?.map((inc) => ({
        value: inc?._id,
        label: inc?.name
      }))
      let mappedSelectedLevels = selectedLevels?.map((le) => ({
        value: le?._id,
        label: le?.title
      }))
      setSelectedOptions(mappedSelectedExcludedOutlets);
      setSelectedCategories(mappedSelectedUpdatedCategories);
      setSelectExcludedProducts(mappedSelectEditedExProducts);
      setSelectedIncludedOptions(mappedIncludedOutlets);
      setSelectedLevelOptions(mappedSelectedLevels);
    }

    if (data?.applicableCategories?.length > 0 && data?.applicableQuantityType !== null) {
      setIsEditSpecialCategoryCouponModalOpen(true);
      setEditedSpecialCategoryCouponData({
        _id: data?._id,
        isHidden: data?.isHidden,
        title: data?.title,
        couponCode: data?.couponCode,
        description: data?.description,
        usageType: data?.usageType,
        discountPrice: data?.discountPrice,
        applicableQuantityType: data?.applicableQuantityType,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
        termsAndConditions: data?.termsAndConditions,
        startTime: data?.startTime,
        endTime: data?.endTime,
        dayOfWeek: data?.dayOfWeek,
        minOrderAmount: data?.minOrderAmount
      })
      let selectedEditedExcOutlet = data?.excludedOutlets;
      let selectedEditedIncOutlet = data?.includedOutlets;
      let selectedUpdatedCategories = data?.applicableCategories;
      let selectEditedExProducts = data?.excludedProducts;
      let selectedLevels = data?.applicableLevels;

      let mappedSelectedEditedExcOutletData = selectedEditedExcOutlet?.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      }))
      let mappedSelectedEditedIncOutletData = selectedEditedIncOutlet?.map((inc) => ({
        value: inc?._id,
        label: inc?.name
      }))
      let mappedSelectedUpdatedProducts = selectedUpdatedCategories?.map((uc) => ({
        value: uc?._id,
        label: uc?.name
      }))
      let mappedSelectEditedExProducts = selectEditedExProducts?.map((e) => ({
        value: e?._id,
        label: e?.name
      }))
      let mappedSelectedLevels = selectedLevels?.map((le) => ({
        value: le?._id,
        name: le?.title
      }))
      setSelectedOptions(mappedSelectedEditedExcOutletData);
      setSelectedIncludedOptions(mappedSelectedEditedIncOutletData);
      setSelectedCategories(mappedSelectedUpdatedProducts);
      setSelectExcludedProducts(mappedSelectEditedExProducts)
      setSelectedLevelOptions(mappedSelectedLevels);
    }

    if (data?.applicableProducts?.length > 0 && data?.applicableQuantityType !== null) {
      setIsEditSpecialProductCouponModalOpen(true);
      setEditedSpecialProductCouponData({
        _id: data?._id,
        isHidden: data?.isHidden,
        title: data?.title,
        couponCode: data?.couponCode,
        description: data?.description,
        usageType: data?.usageType,
        discountPrice: data?.discountPrice,
        applicableQuantityType: data?.applicableQuantityType,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
        termsAndConditions: data?.termsAndConditions,
        startTime: data?.startTime,
        endTime: data?.endTime,
        dayOfWeek: data?.dayOfWeek,
        minOrderAmount: data?.minOrderAmount
      })
      let selectedExOutlet = data?.excludedOutlets;
      let selectedIncOutlet = data?.includedOutlets;
      let selectedUpdatedProducts = data?.applicableProducts;
      let selectedLevels = data?.applicableLevels;

      let mappedSelectedExOutlet = selectedExOutlet?.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      }))
      let mappedIncludedOutlets = selectedIncOutlet?.map((inc) => ({
        value: inc?._id,
        label: inc?.name
      }))
      let mappedSelectedUpdatedProducts = selectedUpdatedProducts?.map((up) => ({
        value: up?._id,
        label: up?.name
      }))
      let mappedSelectedLevels = selectedLevels?.map((le) => ({
        value: le?._id,
        label: le?.title
      }))
      setSelectedOptions(mappedSelectedExOutlet);
      setSelectedProducts(mappedSelectedUpdatedProducts);
      setSelectedIncludedOptions(mappedIncludedOutlets);
      setSelectedLevelOptions(mappedSelectedLevels);
    }

    if (!data?.sizeUpgradeAddon) {
      if (data?.applicableProducts !== null && data?.applicableProducts.length > 0 && data?.applicableQuantityType === null) {
        setIsEditProductCouponModalOpen(true);

        setEditedProductCouponData({
          _id: data?._id,
          isHidden: data?.isHidden,
          title: data?.title,
          couponCode: data?.couponCode,
          description: data?.description,
          usageType: data?.usageType,
          discountPercent: data?.discountPercent,
          discountAmount: data?.discountAmount,
          maxDiscountAmount: data?.maxDiscountAmount,
          // applicableProduct: data?.applicableProduct?._id,
          validityStartDate: formattedStartDate,
          validityEndDate: formattedEndDate,
          termsAndConditions: data?.termsAndConditions,
          startTime: data?.startTime,
          endTime: data?.endTime,
          dayOfWeek: data?.dayOfWeek,
          minOrderAmount: data?.minOrderAmount
        });

        let selectedEditedProduct = data?.excludedOutlets;
        let selectedUpdatedProducts = data?.applicableProducts;
        let selectedLevels = data?.applicableLevels;
        let selectedIncOutlets = data?.includedOutlets;
        let mappedSelectedEditedProductData = selectedEditedProduct.map((m) =>
        ({
          value: m?._id,
          label: m?.name
        }))
        let mappedSelectedUpdatedProducts = selectedUpdatedProducts.map((up) => ({
          value: up?._id,
          label: up?.name
        }))
        let mappedSelectedLevels = selectedLevels?.map((le) => ({
          value: le?._id,
          label: le?.title
        }))
        let mappedIncludedOutlets = selectedIncOutlets?.map((inc) => ({
          value: inc?._id,
          name: inc?.name
        }))
        setSelectedOptions(mappedSelectedEditedProductData);
        setSelectedProducts(mappedSelectedUpdatedProducts);
        setSelectedLevelOptions(mappedSelectedLevels);
        setSelectedIncludedOptions(mappedIncludedOutlets);
      }
    }

    if (
      data?.applicableProducts.length === 0 &&
      data?.applicableQuantityType === null &&
      data?.applicableCategories.length === 0 &&
      data?.isBogoCoupon === false && data?.minOrderAmount !== null
    ) {
      setIsEditCartCouponModalOpen(true);
      setEditedCartCouponDetails({
        _id: data?._id,
        isHidden: data?.isHidden,
        title: data?.title,
        couponCode: data?.couponCode,
        description: data?.description,
        usageType: data?.usageType,
        discountPercent: data?.discountPercent,
        discountAmount: data?.discountAmount,
        maxDiscountAmount: data?.maxDiscountAmount,
        minOrderAmount: data?.minOrderAmount,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
        termsAndConditions: data?.termsAndConditions,
        startTime: data?.startTime,
        endTime: data?.endTime,
        dayOfWeek: data?.dayOfWeek,
      });

      let selectedEditGeneral = data?.excludedOutlets;
      let selectedEditIncOutlet = data?.includedOutlets;
      let selectedLevels = data?.applicableLevels;
      let mappedSelectedEditGeneralData = selectedEditGeneral.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      })
      )
      let mappedSelectedEditIncOutlet = selectedEditIncOutlet?.map((m) => ({
        value: m?._id,
        label: m?.name
      }))
      let mappedSelectedLevels = selectedLevels?.map((le) => ({
        value: le?._id,
        label: le?.title
      }))
      setSelectedOptions(mappedSelectedEditGeneralData);
      setSelectedIncludedOptions(mappedSelectedEditIncOutlet);
      setSelectedLevelOptions(mappedSelectedLevels);
    }

    if (
      data?.applicableProducts.length === 0 &&
      data?.applicableQuantityType === null &&
      data?.applicableCategories.length === 0 &&
      data?.minOrderAmount === null &&
      data?.isBogoCoupon === false
    ) {
      setIsEditUniversalCouponModalOpen(true);
      setEditedUniversalCouponDetails({
        _id: data?._id,
        isHidden: data?.isHidden,
        title: data?.title,
        couponCode: data?.couponCode,
        description: data?.description,
        usageType: data?.usageType,
        discountPercent: data?.discountPercent,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
        termsAndConditions: data?.termsAndConditions
      });

      let selectedEditGeneral = data?.excludedOutlets;
      let mappedSelectedEditGeneralData = selectedEditGeneral.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      })
      )
      setSelectedOptions(mappedSelectedEditGeneralData);
    }
  };

  const handleSizeUpgradeInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "applicableProducts") {
      setSelectedProduct([value])
    }

    // if(name==="applicableProducts"){
    //   setEditSizeUpgradeCouponData((prev) => ({ ...prev, [name]: [value] }));
    // }
    else {
      setEditSizeUpgradeCouponData((prev) => ({ ...prev, [name]: value }));
    }

    // setEditSizeUpgradeCouponData((prev) => ({ ...prev, [name]: value }));
  }

  const handleSizeUpgradeValidityStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditSizeUpgradeCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditSizeUpgradeCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
    }
  };

  const handleSizeUpgradeValidityEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditSizeUpgradeCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditSizeUpgradeCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  const submitSizeUpgradeCouponDetails = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      let selectedOutlet = selectedOptions.map((options) => options.value);
      let selectedIncOutlet = selectedIncludedOptions?.map((options) => options?.value);
      let selectedLevels = selectedLevelOptions?.map((level) => level?.value);

      let selectedAppProd = Array.isArray(selectedProduct)
        ? selectedProduct  // If it's already an array, use it as-is
        : [selectedProduct];  // If it's a single value, wrap it in an array
      let editedSizeUpgradeData = {
        ...editSizeUpgradeCouponData,
        excludedOutlets: selectedOutlet,
        includedOutlets: selectedIncOutlet,
        applicableLevels: selectedLevels,
        applicableProducts: selectedAppProd
      }

      if (editedSizeUpgradeData?.excludedOutlets?.length === 0) {
        delete editedSizeUpgradeData?.excludedOutlets
      }

      if (editedSizeUpgradeData?.includedOutlets?.length === 0) {
        delete editedSizeUpgradeData?.includedOutlets
      }

      if (editedSizeUpgradeData?.applicableLevels?.length === 0) {
        delete editedSizeUpgradeData?.applicableLevels
      }

      if (editedSizeUpgradeData?.minOrderAmount === "") {
        delete editedSizeUpgradeData?.minOrderAmount
      }

      const response = await axios.put("coupons", editedSizeUpgradeData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setEditSizeUpgradeCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          validityStartDate: "",
          validityEndDate: "",
        });
        getAllCouponsData();
        setIsEditSizeUpgradeCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleBogoInputChange = (e) => {
    const { name, value } = e.target;
    setEditedBogoCouponData((prev) => ({ ...prev, [name]: value }));
  };

  const submitBogoCouponDetails = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let selectedOutlet = selectedOptions?.map((options) => options.value);
      let selectedIncOutlet = selectedIncludedOptions?.map((options) => options.value);
      let selectedLevels = selectedLevelOptions?.map((options) => options?.value);

      let editedBogoData = {
        ...editedBogoCouponData,
        excludedOutlets: selectedOutlet,
        includedOutlets: selectedIncOutlet,
        applicableLevels: selectedLevels
      }

      if (editedBogoCouponData?.includedOutlets?.length === 0) {
        delete editedBogoData?.includedOutlets
      }

      if (editedBogoCouponData?.startTime === "") {
        delete editedBogoData?.startTime
      }

      if (editedBogoCouponData?.endTime === "") {
        delete editedBogoData?.endTime
      }

      if (editedBogoCouponData?.applicableLevels?.length === 0) {
        delete editedBogoData?.applicableLevels
      }

      if (editedBogoCouponData?.dayOfWeek === "") {
        delete editedBogoData?.dayOfWeek
      }

      if (editedBogoCouponData?.minOrderAmount === "" || editedBogoCouponData?.minOrderAmount === null) {
        delete editedBogoData?.minOrderAmount
      }

      console.log("editedBogoCouponData", editedBogoCouponData);


      const response = await axios.put("coupons", editedBogoData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setEditedBogoCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          validityStartDate: "",
          validityEndDate: "",
        });
        getAllCouponsData();
        setIsEditBogoCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleCategoryCouponChange = (e) => {
    const { name, value } = e.target;
    setEditedCategoryCouponData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSpecialCategoryCouponChange = (e) => {
    const { name, value } = e.target;
    setEditedSpecialCategoryCouponData((prev) => ({ ...prev, [name]: value }));
  }

  const submitCategoryCouponDetails = async (e) => {
    e.preventDefault();

    if (editedCategoryCouponData?.discountAmount !== null) {
      delete editedCategoryCouponData?.discountPercent;
      delete editedCategoryCouponData?.maxDiscountAmount
    } else if (editedCategoryCouponData?.discountPercent !== null) {
      delete editedCategoryCouponData?.discountAmount;
    }

    if (editedCategoryCouponData?.maxDiscountAmount === null) {
      delete editedCategoryCouponData.maxDiscountAmount;
    }

    let selectedOutlet = selectedOptions.map((option) => option.value);
    let selectedIncOutlet = selectedIncludedOptions?.map((option) => option?.value);
    let selectedCategoriesInfo = selectedCategories?.map((cat) => cat.value);
    let selectedExProdcutsInfo = selectExcludedProducts?.map((ep) => ep.value);
    let selectedLevels = selectedLevelOptions?.map((le) => le?.value);
    let editedCategoryData = {
      ...editedCategoryCouponData,
      excludedOutlets: selectedOutlet,
      includedOutlets: selectedIncOutlet,
      applicableCategories: selectedCategoriesInfo,
      excludedProductList: selectedExProdcutsInfo,
      applicableLevels: selectedLevels
    }

    if (editedCategoryData?.excludedOutlets?.length === 0) {
      delete editedCategoryData?.excludedOutlets
    }

    if (editedCategoryData?.includedOutlets?.length === 0) {
      delete editedCategoryData?.includedOutlets
    }

    if (editedCategoryData?.applicableLevels?.length === 0) {
      delete editedCategoryData?.applicableLevels
    }

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("coupons", editedCategoryData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setEditedCategoryCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPercent: "",
          discountAmount: "",
          applicableCategory: "",
          validityStartDate: "",
          validityEndDate: "",
        });
        setSelectedLevelOptions([]);
        setSelectedIncludedOptions([]);
        setSelectExcludedProducts([]);
        getAllCouponsData();
        setIsEditCategoryCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = '';
        if (error.response?.status === 406) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        } else if (error.response?.status === 404) {
          errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const submitSpecialCategoryCouponDetails = async (e) => {
    e.preventDefault();
    let selectedOutlet = selectedOptions.map((option) => option.value);
    let selectedIncludedOutlet = selectedIncludedOptions?.map((option) => option?.value);
    let selectedLevels = selectedLevelOptions?.map((level) => level?.value);
    let selectedCategoriesInfo = selectedCategories.map((cat) => cat.value);
    let editedSpecialCategoryData = {
      ...editedSpecialCategoryCouponData,
      excludedOutlets: selectedOutlet,
      includedOutlets: selectedIncludedOutlet,
      applicableCategories: selectedCategoriesInfo,
      applicableLevels: selectedLevels
    }

    if (editedSpecialCategoryData?.excludedOutlets?.length === 0) {
      delete editedSpecialCategoryData?.excludedOutlets
    }

    if (editedSpecialCategoryData?.includedOutlets?.length === 0) {
      delete editedSpecialCategoryData?.includedOutlets
    }

    if (editedSpecialCategoryData?.applicableLevels?.length === 0) {
      delete editedSpecialCategoryData?.applicableLevels
    }

    if (editedSpecialCategoryData?.minOrderAmount === "") {
      delete editedSpecialCategoryData?.minOrderAmount
    }

    if (editedSpecialCategoryData?.startTime === "") {
      delete editedSpecialCategoryData?.startTime
    }

    if (editedSpecialCategoryData?.endTime === "") {
      delete editedSpecialCategoryData?.endTime
    }

    if (editedSpecialCategoryData?.dayOfWeek === "") {
      delete editedSpecialCategoryData?.dayOfWeek
    }

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("coupons", editedSpecialCategoryData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setEditedSpecialCategoryCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPrice: "",
          applicableQuantityType: "",
          validityStartDate: "",
          validityEndDate: "",
        });

        getAllCouponsData();
        setIsEditSpecialCategoryCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = '';
        if (error.response?.status === 406) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        } else if (error.response?.status === 404) {
          errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  }

  const handleProductCouponChange = (e) => {
    const { name, value } = e.target;
    setEditedProductCouponData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSpecialProductCouponChange = (e) => {
    const { name, value } = e.target;
    setEditedSpecialProductCouponData((prev) => ({ ...prev, [name]: value }));
  }

  const submitProductCouponDetails = async (e) => {
    e.preventDefault();

    if (editedProductCouponData?.discountAmount !== null) {
      delete editedProductCouponData?.discountPercent;
      delete editedProductCouponData?.maxDiscountAmount;
    } else if (editedProductCouponData?.discountPercent !== null) {
      delete editedProductCouponData?.discountAmount;
    }

    if (editedProductCouponData?.maxDiscountAmount === null) {
      delete editedProductCouponData?.maxDiscountAmount;
    }

    let selectedOutlet = selectedOptions.map((option) => option.value);
    let selectedIncOutlet = selectedIncludedOptions?.map((option) => option?.value);
    let selectedLevel = selectedLevelOptions?.map((le) => le?.value);
    let selectedProductsInfo = selectedProducts.map((prods) => prods.value);
    let editedProductData = {
      ...editedProductCouponData,
      excludedOutlets: selectedOutlet,
      includedOutlets: selectedIncOutlet,
      applicableLevels: selectedLevel,
      applicableProducts: selectedProductsInfo
    }

    if (editedProductData?.excludedOutlets?.length === 0) {
      delete editedProductData?.excludedOutlets
    }

    if (editedProductData?.includedOutlets?.length === 0) {
      delete editedProductData?.includedOutlets
    }

    if (editedProductData?.applicableLevels?.length === 0) {
      delete editedProductData?.applicableLevels
    }

    if (editedProductData?.minOrderAmount === "") {
      delete editedProductData?.minOrderAmount
    }

    if (editedProductData?.dayOfWeek === "") {
      delete editedProductData?.dayOfWeek
    }

    if (editedProductData?.startTime === "") {
      delete editedProductData?.startTime
    }

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("coupons", editedProductData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setEditedProductCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPercent: "",
          discountAmount: "",
          applicableProduct: "",
          validityStartDate: "",
          validityEndDate: "",
        });
        setSelectedLevelOptions([]);
        setSelectedIncludedOptions([]);
        setSelectExcludedProducts([]);
        getAllCouponsData();
        setIsEditProductCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = '';
        if (error.response?.status === 406) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        } else if (error.response?.status === 404) {
          errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const submitSpecialProductCouponDetails = async (e) => {
    e.preventDefault();
    let selectedOutlet = selectedOptions.map((option) => option.value);
    let selectedIncOutlet = selectedIncludedOptions?.map((option) => option?.value);
    let selectedLevels = selectedLevelOptions?.map((level) => level?.value);
    let selectedProductsInfo = selectedProducts.map((prods) => prods.value);
    let editedSpecialProductData = {
      ...editedSpecialProductCouponData,
      excludedOutlets: selectedOutlet,
      includedOutlets: selectedIncOutlet,
      applicableLevels: selectedLevels,
      applicableProducts: selectedProductsInfo
    }

    if (editedSpecialProductData?.includedOutlets?.length === 0) {
      delete editedSpecialProductData?.includedOutlets
    }

    if (editedSpecialProductData?.excludedOutlets?.length === 0) {
      delete editedSpecialProductData?.excludedOutlets
    }

    if (editedSpecialProductData?.applicableLevels?.length === 0) {
      delete editedSpecialProductData?.applicableLevels
    }

    if (editedSpecialProductData?.minOrderAmount === "") {
      delete editedSpecialProductData?.minOrderAmount
    }

    if (editedSpecialProductData?.dayOfWeek === "") {
      delete editedSpecialProductData?.dayOfWeek
    }

    if (editedSpecialProductData?.startTime === "") {
      delete editedSpecialProductData?.startTime
    }

    if (editedSpecialProductData?.endTime === "") {
      delete editedSpecialProductData?.endTime
    }

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("coupons", editedSpecialProductData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setEditedSpecialProductCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPrice: "",
          applicableQuantityType: "",
          validityStartDate: "",
          validityEndDate: "",
        });

        getAllCouponsData();
        setIsEditSpecialProductCouponModalOpen(false);
      }
    } catch (error) {
      let errorMessage = '';
      if (error.response?.status === 406) {
        errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
      } else if (error.response?.status === 404) {
        errorMessage = error?.response?.data?.data?.title;
      } else if (error.response?.status === 412) {
        errorMessage = error?.response?.data?.data?.title;
      } else if (error.response?.status === 500) {
        errorMessage = error?.response?.data?.error
      }
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  }

  const handleCartInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCartCouponDetails((prev) => ({ ...prev, [name]: value }));
  };

  const submitCartCouponDetails = async (e) => {
    e.preventDefault();

    if (editedCartCouponDetails?.discountPercent) {
      delete editedCartCouponDetails?.discountAmount;
      delete editedCartCouponDetails?.maxDiscountAmount;
    } else if (editedCartCouponDetails?.discountAmount) {
      delete editedCartCouponDetails?.discountPercent;
    }

    if (editedCartCouponDetails?.maxDiscountAmount === null) {
      delete editedCartCouponDetails?.maxDiscountAmount
    }

    let selectedOutlet = selectedOptions.map((option) => option.value);
    let selectedIncOutlet = selectedIncludedOptions?.map((option) => option?.value);
    let selectedLevels = selectedLevelOptions?.map((level) => level?.value);
    let editedGeneralData = {
      ...editedCartCouponDetails,
      excludedOutlets: selectedOutlet,
      includedOutlets: selectedIncOutlet,
      applicableLevels: selectedLevels
    }

    if (editedGeneralData?.excludedOutlets?.length === 0) {
      delete editedGeneralData?.excludedOutlets
    }

    if (editedGeneralData?.includedOutlets?.length === 0) {
      delete editedGeneralData?.includedOutlets
    }

    if (editedGeneralData?.applicableLevels?.length === 0) {
      delete editedGeneralData?.applicableLevels
    }

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put("coupons", editedGeneralData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        getAllCouponsData();
        setEditedCartCouponDetails({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPercent: "",
          discountAmount: "",
          maxDiscountAmount: "",
          minOrderAmount: "",
          validityStartDate: "",
          validityEndDate: "",
        });
        setIsEditCartCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleUniversalInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUniversalCouponDetails((prev) => ({ ...prev, [name]: value }));
  };

  const submitUniversalCouponDetails = async (e) => {
    e.preventDefault();

    let selectedOutlet = selectedOptions.map((option) => option.value);
    let editedGeneralData = {
      ...editedUniversalCouponDetails,
      excludedOutlets: selectedOutlet
    }
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put("coupons", editedGeneralData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        getAllCouponsData();
        setEditedCartCouponDetails({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPercent: "",
          validityStartDate: "",
          validityEndDate: "",
        });
        setIsEditUniversalCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = '';
        if (error.response?.status === 406) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        } else if (error.response?.status === 404) {
          errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleOpenViewModal = (data) => {
    getSingleCouponDetails(data?._id);
    setOpenViewModal(true);
  };

  const getSingleCouponDetails = async (couponId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`coupons?_id=${couponId}`, { headers });

      if (response?.data?.status === true) {
        setSingleCouponDetails(response?.data?.data?.coupons);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const updatedDate = (date) => {
    const formattedDate = date?.split("T")[0];
    return formattedDate;
  };

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  }

  const handleSelectIncChange = (selected) => {
    setSelectedIncludedOptions(selected)
  }

  const handleSelectLevelChange = (selected) => {
    setSelectedLevelOptions(selected)
  }

  const handleSelectProductChange = (prods) => {
    setSelectedProducts(prods);
  }

  const handleSelectCategoriesChange = (cats) => {
    setSelectedCategories(cats);
  }

  const handleSelectExProductChannge = (ex) => {
    setSelectExcludedProducts(ex);
  }

  const convertDate = (dateString) => {
    if (!dateString) {
      return "Invalid Date";
    }

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const dateParts = dateString.split("-");
    if (dateParts.length !== 3) {
      return "Invalid Date";
    }

    const year = dateParts[0];
    const month = parseInt(dateParts[1], 10) - 1;
    const day = dateParts[2];

    if (isNaN(month) || isNaN(day) || isNaN(year)) {
      return "Invalid Date";
    }

    const formattedDate = `${day} ${months[month]} ${year}`;

    return formattedDate;
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const filterCoupons = (coupons) => {
    if (selectedFilter === "ALL") {
      return coupons;
    }
    return coupons.filter((coupon) => {
      if (selectedFilter === "BOGO" && coupon?.isBogoCoupon) return true;
      if (selectedFilter === "UNIVERSAL" && coupon?.applicableProducts.length === 0 && coupon?.applicableQuantityType === null && coupon?.applicableCategories.length === 0 && coupon?.minOrderAmount === null && coupon?.isBogoCoupon === false) return true;
      if (selectedFilter === "CATEGORY" && coupon?.applicableCategories !== null && coupon?.applicableCategories.length > 0 && coupon?.applicableQuantityType === null) return true;
      if (selectedFilter === "PRODUCT" && coupon?.applicableProducts !== null && coupon?.applicableProducts.length > 0 && coupon?.applicableQuantityType === null && !coupon?.sizeUpgradeAddon) return true;
      if (selectedFilter === "SIZEUPGRADE" && coupon?.sizeUpgradeAddon) return true;
      if (selectedFilter === "CARTCOUPON" && coupon?.applicableProducts.length === 0 && coupon?.applicableQuantityType === null && coupon?.applicableCategories.length === 0 && coupon?.isBogoCoupon === false && coupon?.minOrderAmount !== null) return true;
      if (selectedFilter === "SPECIALPRODUCT" && coupon?.applicableProducts?.length > 0 && coupon?.applicableQuantityType !== null) return true;
      if (selectedFilter === "SPECIALCATEGORY" && coupon?.applicableCategories?.length > 0 && coupon?.applicableQuantityType !== null) return true;
    });
  };

  const handleSearchProducts = (e) => {
    e.preventDefault();
    setClicked(true);

    // Convert searchText to lowercase for case-insensitive search
    const lowerCaseSearchText = searchText.toLowerCase();

    // Filter the coupons based on title or couponCode
    const searchedActiveCoupons = filteredActiveCoupons?.filter((coupon) =>
      coupon?.title?.toLowerCase().includes(lowerCaseSearchText) ||
      coupon?.couponCode?.toLowerCase().includes(lowerCaseSearchText)
    );

    const searchedInActiceCoupons = filteredInactiveCoupons?.filter((coupon) =>
      coupon?.title?.toLowerCase().includes(lowerCaseSearchText) ||
      coupon?.couponCode?.toLowerCase().includes(lowerCaseSearchText)
    );

    setSearchActiveData(searchedActiveCoupons);
    setSearchInactiveData(searchedInActiceCoupons);
  };

  useEffect(() => {
    if (searchText === "") {
      setClicked(false);
    }
  }, [searchText]);

  const addBogoTerm = () => {
    if (!currentTerm.trim()) return;
    setEditedBogoCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: [...prevData.termsAndConditions, currentTerm],
    }));
    setCurrentTerm(''); // Clear input after adding
  };

  const removeBogoTerm = (index) => {
    setEditedBogoCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: prevData.termsAndConditions.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const addCartTerm = () => {
    if (!currentTerm.trim()) return;
    setEditedCartCouponDetails((prevData) => ({
      ...prevData,
      termsAndConditions: [...prevData.termsAndConditions, currentTerm],
    }));
    setCurrentTerm(''); // Clear input after adding
  };

  const removeCartTerm = (index) => {
    setEditedCartCouponDetails((prevData) => ({
      ...prevData,
      termsAndConditions: prevData.termsAndConditions.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const addProductTerm = () => {
    if (!currentTerm.trim()) return;
    setEditedProductCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: [...prevData.termsAndConditions, currentTerm],
    }));
    setCurrentTerm(''); // Clear input after adding
  };

  const removeProductTerm = (index) => {
    setEditedProductCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: prevData.termsAndConditions.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const addCategoryTerm = () => {
    if (!currentTerm.trim()) return;
    setEditedCategoryCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: [...prevData.termsAndConditions, currentTerm],
    }));
    setCurrentTerm(''); // Clear input after adding
  };

  const removeCategoryTerm = (index) => {
    setEditedCategoryCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: prevData.termsAndConditions.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const addUniversalTerm = () => {
    if (!currentTerm.trim()) return;
    setEditedUniversalCouponDetails((prevData) => ({
      ...prevData,
      termsAndConditions: [...prevData.termsAndConditions, currentTerm],
    }));
    setCurrentTerm(''); // Clear input after adding
  };

  const removeUniversalTerm = (index) => {
    setEditedUniversalCouponDetails((prevData) => ({
      ...prevData,
      termsAndConditions: prevData.termsAndConditions.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const addSizeTerm = () => {
    if (!currentTerm.trim()) return;
    setEditSizeUpgradeCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: [...prevData.termsAndConditions, currentTerm],
    }));
    setCurrentTerm(''); // Clear input after adding
  };

  const removeSizeTerm = (index) => {
    setEditSizeUpgradeCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: prevData.termsAndConditions.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const addSpProductTerm = () => {
    if (!currentTerm.trim()) return;
    setEditedSpecialProductCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: [...prevData.termsAndConditions, currentTerm],
    }));
    setCurrentTerm(''); // Clear input after adding
  };

  const removeSpProductTerm = (index) => {
    setEditedSpecialProductCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: prevData.termsAndConditions.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const addSpCatTerm = () => {
    if (!currentTerm.trim()) return;
    setEditedSpecialCategoryCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: [...prevData.termsAndConditions, currentTerm],
    }));
    setCurrentTerm(''); // Clear input after adding
  };

  const removeSpCatTerm = (index) => {
    setEditedSpecialCategoryCouponData((prevData) => ({
      ...prevData,
      termsAndConditions: prevData.termsAndConditions.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const [fidata, setFidata] = useState([])

  // const filteredActiveCoupons = filterCoupons(activeCoupons);
  const filteredActiveCoupons = clicked ? searchActiveData : filterCoupons(activeCoupons);

  // const filteredInactiveCoupons = filterCoupons(inactiveCoupons);
  const filteredInactiveCoupons = clicked ? searchInactiveData : filterCoupons(inactiveCoupons);

  useEffect(() => {
    getAllCouponsData();
    getProductsList();
    getCategoryList();
    getOutlets();
    getAddonProductsList();
    fetchLevelsData();
  }, []);

  return (
    <CContainer className="mb-3">
      <div>

        {/* Dropdown for Filtering */}
        <CRow className="mb-3">
          <CCol md="5" lg="6" >
            <CFormLabel className="fw-semibold">Filter By Coupon Type</CFormLabel>
            <CFormSelect
              aria-label="Filter coupons"
              onChange={handleFilterChange}
              value={selectedFilter}
              className="ms-2 text-center"
              style={{ textAlignLast: 'center' }}
            >
              <option value="ALL">ALL COUPONS</option>
              <option value="CARTCOUPON">CART COUPON</option>
              <option value="BOGO">BOGO COUPON</option>
              <option value="PRODUCT">PRODUCT COUPON</option>
              <option value="CATEGORY">CATEGORY COUPON</option>
              <option value="UNIVERSAL">UNIVERSAL COUPON</option>
              <option value="SIZEUPGRADE">SIZE UPGRADE COUPON</option>
              <option value="SPECIALPRODUCT">SPECIAL PRODUCT COUPON</option>
              <option value="SPECIALCATEGORY">SPECIAL CATEGORY COUPON</option>
            </CFormSelect>
          </CCol>
          <CCol md="5" lg="4">
            <CFormLabel className="fw-semibold text-white">.</CFormLabel>
            <CFormInput
              type="text"
              placeholder="Search By Coupon Name Or Coupon Code"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </CCol>
          <CCol md="2" lg="2" className="text-center">
            <CFormLabel className="fw-semibold text-white">.</CFormLabel>
            <CButton className="btn rounded-pill w-100" disabled={!searchText} onClick={handleSearchProducts}>
              Search
            </CButton>
          </CCol>
        </CRow>

        <div
          className="d-flex justify-content-center gap-2 mb-2"
          style={{ cursor: "pointer" }}
        >
          <div
            color="none"
            className={`w-50 ${activeTab === "ACTIVE" ? "text-secondary" : "text-dark"
              } text-center p-2 fw-semibold`}
            onClick={() => handleTabChange("ACTIVE")}
            style={{
              borderBottom:
                activeTab === "ACTIVE"
                  ? "2px solid #bcbcbd"
                  : "1px solid #bcbcbd",
              transition: "border-bottom 0.3s ease-in-out",
              background: activeTab === "ACTIVE" ? "#e7e9eb" : "none",
              border: "1px solid #bcbcbd",
              borderRadius: "5px",
            }}
          >
            ACTIVE
          </div>
          <div
            color="none"
            className={`w-50 ${activeTab === "INACTIVE" ? "text-secondary" : "text-dark"
              } text-center p-2 fw-semibold`}
            onClick={() => handleTabChange("INACTIVE")}
            style={{
              borderBottom:
                activeTab === "INACTIVE"
                  ? "2px solid #bcbcbd"
                  : "1px solid #bcbcbd",
              transition: "border-bottom 0.3s ease-in-out",
              background: activeTab === "INACTIVE" ? "#e7e9eb" : "none",
              border: "1px solid #bcbcbd",
              borderRadius: "5px",
            }}
          >
            INACTIVE
          </div>
        </div>

        {activeTab === "ACTIVE" && (
          <CRow>
            {filteredActiveCoupons?.map((item, index) => (
              <CCol key={index} sm="12" md="6" lg="4" xl="3" className="mb-4">
                <div className="coupon-card shadow-sm rounded border">
                  <div className="coupon-header py-3 text-center border-bottom">
                    <h6 className="coupon-title ">{item?.title}</h6>
                  </div>
                  <div className="coupon-body p-3 mb-4">
                    <h6 className="coupon-discount text-center text-success fw-bold mb-3">
                      {item?.discountAmount !== null && `₹ ${item?.discountAmount} OFF`}
                      {item?.discountPrice !== null && `₹ ${item?.discountPrice}`}
                      {item?.discountPercent !== null && `${item?.discountPercent}% OFF`}
                      {item?.sizeUpgradeAddon && "Size Upgrade Available"}
                      {item?.isBogoCoupon && (
                        <span className="ms-2">BOGO</span>
                      )}
                    </h6>
                    <div className="coupon-details text-muted" style={{ fontSize: "14px" }}>
                      {item?.applicableCategories?.length === 0 &&
                        item?.applicableProducts?.length === 0 &&
                        !item?.isBogoCoupon ? (
                        <p>Applies to all items in our store.</p>
                      ) : null}
                      {item?.applicableCategories?.length > 0 && (
                        <p>
                          <strong>Applicable Categories:</strong>{" "}
                          {item?.applicableCategories.map((cat, index) => (
                            <span key={index}>
                              {cat?.name}
                              {index < item.applicableCategories.length - 1 ? ", " : "."}
                            </span>
                          ))}
                        </p>
                      )}
                      {item?.applicableProducts?.length > 0 && (
                        <p>
                          <strong>Applicable Products:</strong>{" "}
                          {item?.applicableProducts.map((prod, index) => (
                            <span key={index}>
                              {prod?.name}
                              {index < item.applicableProducts.length - 1 ? ", " : "."}
                            </span>
                          ))}
                        </p>
                      )}
                    </div>
                    <p className="validity text-center mt-3" style={{ fontSize: "13px" }}>
                      {item?.validityEndDate ? (
                        <>
                          <strong>Valid until:</strong>{" "}
                          <span className="text-danger">
                            {new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              timeZone: "UTC",
                            }).format(new Date(item.validityEndDate))}
                          </span>
                        </>
                      ) : (
                        <span className="text-warning">Valid for unlimited duration</span>
                      )}
                    </p>
                    <div className="text-center mt-2" style={{ border: '2px dashed #dfeef5' }}>
                      <p>
                        <strong>Coupon Code:</strong>{" "}
                        <span className="fw-bold" style={{ color: '#fc006b' }}>{item?.couponCode}</span>
                      </p>
                    </div>
                    <div className="coupon-hidden-status text-center mt-3">
                      <p className="fw-bold" style={{ fontSize: "14px" }}>
                        Coupon Hidden:{" "}
                        <span
                          className={`${item?.isHidden ? "text-danger" : "text-success"
                            }`}
                        >
                          {item?.isHidden ? "YES" : "NO"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="coupon-footer bg-light py-2 d-flex justify-content-around">
                    <button
                      className="btn btn-primary btn-sm px-3"
                      onClick={() => handleOpenViewModal(item)}
                    >
                      Details
                    </button>
                    <button
                      className="btn btn-warning btn-sm px-3"
                      onClick={() => handleEditModal(item)}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-danger btn-sm px-3"
                      onClick={() => handleDeleteModal(item)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </CCol>
            ))}
          </CRow>
        )}

        {activeTab === "INACTIVE" && (
          <CRow>
            {/* filteredInactiveCoupons, inactiveCoupons */}
            {filteredInactiveCoupons?.map((item, index) => (
              <CCol key={index} sm="12" md="6" lg="4" xl="3" className="mb-4">
                <div className="coupon-card shadow-sm rounded border">
                  <div className="coupon-header py-3 text-center border-bottom">
                    <h6 className="coupon-title ">{item?.title}</h6>
                  </div>
                  <div className="coupon-body p-3 mb-4">
                    <h6 className="coupon-discount text-center text-success fw-bold mb-3">
                      {item?.discountAmount !== null && `₹ ${item?.discountAmount} OFF`}
                      {item?.discountPrice !== null && `₹ ${item?.discountPrice}`}
                      {item?.discountPercent !== null && `${item?.discountPercent}% OFF`}
                      {item?.sizeUpgradeAddon && "Size Upgrade Available"}
                      {item?.isBogoCoupon && (
                        <span className="ms-2">BOGO</span>
                      )}
                    </h6>
                    <div className="coupon-details text-muted" style={{ fontSize: "14px" }}>
                      {item?.applicableCategories?.length === 0 &&
                        item?.applicableProducts?.length === 0 &&
                        !item?.isBogoCoupon ? (
                        <p>Applies to all items in our store.</p>
                      ) : null}
                      {item?.applicableCategories?.length > 0 && (
                        <p>
                          <strong>Applicable Categories:</strong>{" "}
                          {item?.applicableCategories.map((cat, index) => (
                            <span key={index}>
                              {cat?.name}
                              {index < item.applicableCategories.length - 1 ? ", " : "."}
                            </span>
                          ))}
                        </p>
                      )}
                      {item?.applicableProducts?.length > 0 && (
                        <p>
                          <strong>Applicable Products:</strong>{" "}
                          {item?.applicableProducts.map((prod, index) => (
                            <span key={index}>
                              {prod?.name}
                              {index < item.applicableProducts.length - 1 ? ", " : "."}
                            </span>
                          ))}
                        </p>
                      )}
                    </div>
                    <p className="validity text-center mt-3" style={{ fontSize: "13px" }}>
                      {item?.validityEndDate ? (
                        <>
                          <strong>Valid until:</strong>{" "}
                          <span className="text-danger">
                            {new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              timeZone: "UTC",
                            }).format(new Date(item.validityEndDate))}
                          </span>
                        </>
                      ) : (
                        <span className="text-warning">Valid for unlimited duration</span>
                      )}
                    </p>
                    <div className="text-center mt-2" style={{ border: '2px dashed #dfeef5' }}>
                      <p>
                        <strong>Coupon Code:</strong>{" "}
                        <span className="fw-bold" style={{ color: '#fc006b' }}>{item?.couponCode}</span>
                      </p>
                    </div>
                    <div className="coupon-hidden-status text-center mt-3">
                      <p className="fw-bold" style={{ fontSize: "14px" }}>
                        Coupon Hidden:{" "}
                        <span
                          className={`${item?.isHidden ? "text-danger" : "text-success"
                            }`}
                        >
                          {item?.isHidden ? "YES" : "NO"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="coupon-footer bg-light py-2 d-flex justify-content-around">
                    <button
                      className="btn btn-primary btn-sm px-3"
                      onClick={() => handleOpenViewModal(item)}
                    >
                      Details
                    </button>
                    <button
                      className="btn btn-warning btn-sm px-3"
                      onClick={() => handleEditModal(item)}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-danger btn-sm px-3"
                      onClick={() => handleDeleteModal(item)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </CCol>
            ))}
          </CRow>
        )}

      </div>

      {/* DELETE MODAL  */}
      <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteCoupon} isLoading={isDeleteBtnLoading} title="DELETE COUPON" />

      {/* VIEW COUPON DETAILS MODAL  */}
      <CModal
        alignment="center"
        visible={openViewModal}
        onClose={() => setOpenViewModal(false)}
        aria-labelledby="VerticallyCenteredExample"
        size="lg"
      >
        <div
          style={{
            padding: "10px",
            // backgroundColor: "#ffffff",
            borderRadius: "8px",
            // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            // maxWidth: "600px",
            margin: "10px"
          }}
        >
          <h4 style={{ textAlign: "center", color: "purple", marginBottom: "15px" }}>
            {singleCouponDetails?.title}
          </h4>

          {singleCouponDetails?.discountAmount !== null && singleCouponDetails?.discountPercent !== null && singleCouponDetails?.discountPrice !== null ? (
            <div style={{ marginBottom: "10px", color: "#28a745", fontWeight: "bold", fontSize: "1.2em", textAlign: "center" }}>
              {singleCouponDetails?.discountAmount ? (
                `${singleCouponDetails.discountAmount} Rs OFF`
              ) : (
                `${singleCouponDetails.discountPercent}% OFF`
              )}
            </div>
          ) : null}

          {singleCouponDetails?.discountAmount === null && singleCouponDetails?.discountPercent !== null && singleCouponDetails?.discountPrice === null ? (
            <div style={{ marginBottom: "10px", color: "#28a745", fontWeight: "bold", fontSize: "1.2em", textAlign: "center" }}>
              {singleCouponDetails?.discountAmount ? (
                `${singleCouponDetails.discountAmount} Rs OFF`
              ) : (
                `${singleCouponDetails.discountPercent}% OFF`
              )}
            </div>
          ) : null}

          {singleCouponDetails?.discountPrice !== null ? (
            <div style={{ marginBottom: "10px", color: "#28a745", fontWeight: "bold", fontSize: "1.2em", textAlign: "center" }}>
              {singleCouponDetails?.discountPrice ? (
                `₹. ${singleCouponDetails.discountPrice}`
              ) : (
                `${singleCouponDetails.discountPrice}% OFF`
              )}
            </div>
          ) : null}

          {singleCouponDetails?.applicableProducts?.length === 0 ? (
            <p style={{ fontSize: "0.95em", color: "#666", textAlign: "center" }}>
              {singleCouponDetails?.applicableCategories?.length > 0 ? (
                <span>
                  Applicable Categories: {singleCouponDetails.applicableCategories.map((cat, index) => (
                    <span key={index}>{cat.name}{index < singleCouponDetails.applicableCategories.length - 1 ? ", " : "."}</span>
                  ))}
                </span>
              ) : (
                <span className="text-success">"On All Categories In Our Store"</span>
              )}
            </p>
          ) : null}

          {singleCouponDetails?.applicableCategories?.length === 0 ? (
            <p style={{ fontSize: "0.95em", color: "#666", textAlign: "center" }}>
              {singleCouponDetails?.applicableProducts?.length > 0 ? (
                <span>
                  Applicable Products: {singleCouponDetails?.applicableProducts?.map((cat, index) => (
                    <span key={index}>{cat?.name}{index < singleCouponDetails?.applicableProducts?.length - 1 ? ", " : "."}</span>
                  ))}
                </span>
              ) : (
                <span className="text-success">"On All Products In Our Store"</span>
              )}
            </p>
          ) : null}

          {singleCouponDetails?.excludedOutlets?.length !== 0 ? (
            <p style={{ fontSize: "0.95em", color: "#666", textAlign: "center" }}>
              {singleCouponDetails?.applicableProducts?.length ? (
                <span>
                  Excluded Outlets: {singleCouponDetails?.excludedOutlets?.map((cat, index) => (
                    <span key={index}>{cat?.name}{index < singleCouponDetails?.excludedOutlets?.length - 1 ? ", " : "."}</span>
                  ))}
                </span>
              ) : (
                <span className="text-primary">"Available In All The Stores"</span>
              )}
            </p>
          ) : null}

          <div style={{ borderTop: "1px solid #ddd", paddingTop: "15px", marginTop: "10px" }}>
            <CRow className="mb-3">
              <CCol md="6">
                <h6 className="text-muted">Coupon Code : <span className="text-info" style={{ fontSize: '14px' }}>{singleCouponDetails?.couponCode}</span></h6>
              </CCol>
              <CCol md="6">
                <h6 className="text-muted">Usage Type : <span className="text-secondary">{singleCouponDetails?.usageType}</span></h6>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <h6 className="text-muted">Is Coupon Hidden : <span className="text-secondary" >{singleCouponDetails?.isHidden ? "Yes" : "No"}</span></h6>
              </CCol>
              <CCol>
                <h6 className="text-muted">Addon Name : <span className="text-secondary">{singleCouponDetails?.sizeUpgradeAddon?.addonTitle || "N/A"}</span></h6>
              </CCol>
            </CRow>

            {singleCouponDetails?.includedOutlets?.length > 0 && singleCouponDetails?.excludedOutlets?.length > 0 ? (
              <CRow>
                <CCol>
                  <h6 className="text-muted">Included Outlets : <span className="text-secondary">{singleCouponDetails?.includedOutlets?.length !== 0 ? `${singleCouponDetails?.includedOutlets?.map((cat, index) => (
                    <span key={index}>{cat?.name}{index < singleCouponDetails?.includedOutlets?.length - 1 ? ", " : "."}</span>
                  ))}`
                    : "No Included Outlets"}</span>
                  </h6>
                </CCol>
                <CCol>
                  <h6 className="text-muted">Excluded Outlets : <span className="text-secondary">{singleCouponDetails?.excludedOutlets?.length !== 0 ? `${singleCouponDetails?.excludedOutlets?.map((cat, index) => (
                    <span key={index}>{cat?.name}{index < singleCouponDetails?.excludedOutlets?.length - 1 ? ", " : "."}</span>
                  ))}`
                    : "No Excluded Outlets"}</span>
                  </h6>
                </CCol>
              </CRow>
            ) : null}

            {singleCouponDetails?.dependantCategories?.length > 0 ? (
              <CRow>
                <CCol>
                  <h6>Dependant Categories : <span>{singleCouponDetails?.dependantCategories?.length !== 0 ? singleCouponDetails?.dependantCategories : "No Dependant Categories Have Been Added For This Coupon"}</span></h6>
                </CCol>
              </CRow>
            ) : null}

            {singleCouponDetails?.dependantProducts?.length > 0 ? (
              <CRow>
                <CCol>
                  <h6>Dependant Products : <span>{singleCouponDetails?.dependantProducts?.length !== 0 ? singleCouponDetails?.dependantProducts : "No Dependant Products Have Been Added For This Coupon"}</span></h6>
                </CCol>
              </CRow>
            ) : null}

            <CRow className="mb-3">
              <CCol>
                <h6 className="text-muted">Valid From : <span className="text-secondary">{singleCouponDetails?.validityStartDate ? convertDate(updatedDate(singleCouponDetails?.validityStartDate)) : "No Validity"}</span></h6>
              </CCol>
              <CCol>
                <h6 className="text-muted">Valid Until : <span className="text-secondary">{singleCouponDetails?.validityEndDate ? convertDate(updatedDate(singleCouponDetails?.validityEndDate)) : "No Validity"}</span></h6>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <h6 className="text-muted">Start Time : <span className="text-secondary">{singleCouponDetails?.startTime ? singleCouponDetails?.startTime : "No Validity"}</span></h6>
              </CCol>
              <CCol>
                <h6 className="text-muted">End Time : <span className="text-secondary">{singleCouponDetails?.endTime ? singleCouponDetails?.endTime : "No Validity"}</span></h6>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <h6 className="text-muted">Specific Day : <span className="text-secondary">{singleCouponDetails?.dayOfWeek !== undefined && singleCouponDetails?.dayOfWeek !== null ? ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][singleCouponDetails?.dayOfWeek] : "No Specific Day"}</span></h6>
              </CCol>
              <CCol>
                <h6 className="text-muted">Discount Percent : <span className="text-secondary">{singleCouponDetails?.discountPercent ? `${singleCouponDetails?.discountPercent}%` : "No Discount"}</span></h6>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <h6 className="text-muted">Max Discount Amount : <span className="text-secondary">{singleCouponDetails?.maxDiscountAmount ? `₹. ${singleCouponDetails?.maxDiscountAmount}` : "No Max Discount"}</span></h6>
              </CCol>
              <CCol>
                <h6 className="text-muted">Min Order Amount : <span className="text-secondary">{singleCouponDetails?.minOrderAmount ? `₹. ${singleCouponDetails?.minOrderAmount}` : "No Min Order"}</span></h6>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <h6 className="text-muted">Description : <span className="text-secondary">{singleCouponDetails?.description}</span></h6>
            </CRow>

            {singleCouponDetails?.termsAndConditions?.length !== 0 && singleCouponDetails?.termsAndConditions ? (
              <CRow className="mb-3">
                <h6 className="text-muted">Terms And Conditions : </h6>
                <div className="ms-2 text-secondary">
                  <ul style={{ fontSize: '14px', paddingLeft: '20px', margin: 0 }}>
                    {singleCouponDetails?.termsAndConditions?.map((term, index) => (
                      <li key={index} style={{ marginBottom: '5px' }}>
                        {term}
                      </li>
                    ))}
                  </ul>
                </div>
              </CRow>
            ) : null}

          </div>
        </div>

        <CModalFooter style={{ justifyContent: "center", paddingTop: "10px" }}>
          <CButton
            className="w-25"
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              padding: "8px 16px",
              borderRadius: "8px",
              fontSize: "0.9em",
              fontWeight: "bold"
            }}
            onClick={() => setOpenViewModal(false)}
          >
            CLOSE
          </CButton>
        </CModalFooter>
      </CModal>

      {/* EDIT BOGO COUPON MODAL  */}
      <CModal
        size="xl"
        alignment="center"
        visible={isEditBogoCouponModalOpen}
        onClose={() => setIsEditBogoCouponModalOpen(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle>EDIT BOGO COUPON</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={submitBogoCouponDetails}>
          <CModalBody>
            <CRow className="mb-3">
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Title"
                    name="title"
                    value={editedBogoCouponData.title}
                    onChange={handleBogoInputChange}
                  />
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Coupon Code <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Coupon Code"
                    name="couponCode"
                    value={editedBogoCouponData.couponCode}
                    onChange={handleBogoInputChange}
                  />
                </div>
              </CCol>
              <CCol md="4" lg="4" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Usage Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="usageType"
                    value={editedBogoCouponData.usageType}
                    onChange={handleBogoInputChange}
                  >
                    <option value="">Select Usage</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                    <option value="ONE-TIME">ONE-TIME</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="6" lg="6" className="mb-3">
                <CFormLabel className="fw-semibold">
                  Select Included Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedIncludedOptions}
                  onChange={handleSelectIncChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select Included Outlets"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              <CCol md="6" lg="6">
                <CFormLabel className="fw-semibold">
                  Select Excluded Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select options" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              <CCol md="3" lg="3" className="mb-3">
                <CFormLabel className="fw-semibold">
                  Select Level
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents} // Ensure you import `animatedComponents` from `react-select/animated`
                  value={selectedLevelOptions} // Your selected options state
                  onChange={handleSelectLevelChange} // Handler for when options are selected/deselected
                  options={levels?.map((le) => ({
                    value: le._id,
                    label: le.title,
                  }))} // Map `levels` to the required format for `react-select`
                  styles={customStyles} // Custom styles object
                  placeholder="Select Level" // Updated placeholder
                  closeMenuOnSelect={false} // Keep menu open for multiple selections
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Hide Coupon
                  </CFormLabel>
                  <CFormSelect
                    name="isHidden"
                    value={editedBogoCouponData?.isHidden}
                    onChange={handleBogoInputChange}
                  >
                    <option value="">Select Option</option>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity Start Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleBogoValidityStartDateChange}
                    placeholderText="Validity Start Date"
                    className="form-control"
                    selected={editedBogoCouponData?.validityStartDate ? new Date(editedBogoCouponData?.validityStartDate) : null}
                  />
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity End Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleBogoValidityEndDateChange}
                    placeholderText="Validity End Date"
                    className="form-control"
                    value={editedBogoCouponData?.validityEndDate}
                    selected={editedBogoCouponData?.validityEndDate ? new Date(editedBogoCouponData?.validityEndDate) : null}
                  />
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Select Day
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    onChange={handleBogoInputChange}
                    name="dayOfWeek"
                    className="mb-3"
                    aria-label="Small select example"
                    value={editedBogoCouponData?.dayOfWeek}
                  >
                    <option value="">Select Day</option>
                    <option value="0">SUNDAY</option>
                    <option value="1">MONDAY</option>
                    <option value="2">TUESDAY</option>
                    <option value="3">WEDNESDAY</option>
                    <option value="4">THURSDAY</option>
                    <option value="5">FRIDAY</option>
                    <option value="6">SATURDAY</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set Start Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleBogoInputChange}
                    name="startTime"
                    value={editedBogoCouponData?.startTime}
                  />
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set End Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleBogoInputChange}
                    name="endTime"
                    value={editedBogoCouponData?.endTime}
                  />
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <CFormLabel className="fw-semibold">
                  Min. Order Amount
                </CFormLabel>
                <CFormInput
                  min={0}
                  // disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSpecialProductCoupon"}
                  placeholder="Min. Order Amount"
                  type="number"
                  className="no-arrows"
                  name="minOrderAmount"
                  value={editedBogoCouponData.minOrderAmount}
                  onChange={handleBogoInputChange}
                />
              </CCol>
              <CCol md="12" lg="12" className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={3}
                  placeholder="Description"
                  name="description"
                  value={editedBogoCouponData.description}
                  onChange={handleBogoInputChange}
                />
              </CCol>
            </CRow>
            <CRow>
              {/* Other fields */}
              <CCol md="12" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Terms and Conditions
                  </CFormLabel>
                  <div className="d-flex mb-2">
                    <CFormInput
                      placeholder="Add a term"
                      value={currentTerm}
                      onChange={(e) => setCurrentTerm(e.target.value)}
                      className="me-2"
                    />
                    <CButton disabled={!currentTerm.trim()}
                      onClick={addBogoTerm}
                    >
                      ADD
                    </CButton>
                  </div>
                  {editedBogoCouponData?.termsAndConditions?.length > 0 && (
                    <ul className="list-group">
                      {editedBogoCouponData?.termsAndConditions?.map((term, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {term}
                          <button
                            type="button"
                            className="btn btn-sm btn-danger rounded-pill"
                            onClick={() => removeBogoTerm(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              style={{ borderRadius: "18px", width: '20%' }}
            />
          </CModalFooter>
        </CForm>
      </CModal>

      {/* EDIT CATEGORY COUPON MODAL  */}
      <CModal
        size="lg"
        alignment="center"
        visible={isEditCategoryCouponModalOpen}
        onClose={() => setIsEditCategoryCouponModalOpen(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle>EDIT CATEGORY COUPON</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={submitCategoryCouponDetails}>
          <CModalBody>
            <CRow>
              <CCol className="col-md-4 col-lg-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Title"
                    name="title"
                    value={editedCategoryCouponData.title}
                    onChange={handleCategoryCouponChange}
                  />
                </div>
              </CCol>
              <CCol className="col-md-4 col-lg-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Coupon Code <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Coupon Code"
                    name="couponCode"
                    value={editedCategoryCouponData.couponCode}
                    onChange={handleCategoryCouponChange}
                  />
                </div>
              </CCol>
              <CCol className="col-md-4 col-lg-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Usage Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="usageType"
                    value={editedCategoryCouponData.usageType}
                    onChange={handleCategoryCouponChange}
                  >
                    <option value="">Select Usage</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                    <option value="ONE-TIME">ONE-TIME</option>
                  </CFormSelect>
                </div>
              </CCol>

            </CRow>

            <CRow className="mb-3 mt-2">
              <CCol className="col-md-6">
                <CFormLabel className="fw-semibold">
                  Select Categories <span className="text-danger">*</span>
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedCategories}
                  options={categoriesData}
                  onChange={handleSelectCategoriesChange}
                  styles={customStyles}
                  placeholder="Select Categories" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>

              <CCol className="col-md-6">
                <CFormLabel className="fw-semibold">
                  Select Excluded Products
                </CFormLabel>
                <Select
                  isMulti
                  options={filteredProductsData}
                  value={selectExcludedProducts}
                  onChange={handleSelectExProductChannge}
                  components={animatedComponents}
                  styles={customStyles}
                  placeholder="Select Excluded Products" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol md="12" lg="12">
                <CFormLabel className="fw-semibold">
                  Select Excluded Outlets
                </CFormLabel>

                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select options" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>

              {/* New Changes */}
              <CCol md="6" lg="6">
                <CFormLabel className="fw-semibold">
                  Select Included Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedIncludedOptions}
                  onChange={handleSelectIncChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select Included Outlets"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol md="12" lg="12">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={3}
                  placeholder="Description"
                  name="description"
                  value={editedCategoryCouponData.description}
                  onChange={handleCategoryCouponChange}
                />
              </CCol>

            </CRow>

            <CRow className="mb-3">
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Hide Coupon
                  </CFormLabel>
                  <CFormSelect
                    name="isHidden"
                    value={editedCategoryCouponData?.isHidden}
                    onChange={handleCategoryCouponChange}
                  >
                    <option value="">Select Option</option>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity Start Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleCategoryValidityStartDateChange}
                    placeholderText="Validity Start Date"
                    className="form-control"
                    selected={editedCategoryCouponData?.validityStartDate ? new Date(editedCategoryCouponData?.validityStartDate) : null}
                  />
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity End Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleCategoryValidityEndDateChange}
                    placeholderText="Validity End Date"
                    className="form-control"
                    selected={editedCategoryCouponData?.validityEndDate ? new Date(editedCategoryCouponData?.validityEndDate) : null}
                  />
                </div>
              </CCol>
            </CRow>

            {/* New Changes */}

            <CRow className="mb-3">
              <CCol className="col-md-3 col-lg-3">
                <CFormLabel className="fw-semibold">
                  Select Level
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents} // Ensure you import `animatedComponents` from `react-select/animated`
                  value={selectedLevelOptions} // Your selected options state
                  onChange={handleSelectLevelChange} // Handler for when options are selected/deselected
                  options={levels?.map((le) => ({
                    value: le._id,
                    label: le.title,
                  }))} // Map `levels` to the required format for `react-select`
                  styles={customStyles} // Custom styles object
                  placeholder="Select Level" // Updated placeholder
                  closeMenuOnSelect={false} // Keep menu open for multiple selections
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>

              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Select Day
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    onChange={handleCategoryCouponChange}
                    name="dayOfWeek"
                    aria-label="Small select example"
                    value={editedCategoryCouponData?.dayOfWeek}
                  >
                    <option value="">Select Day</option>
                    <option value="0">SUNDAY</option>
                    <option value="1">MONDAY</option>
                    <option value="2">TUESDAY</option>
                    <option value="3">WEDNESDAY</option>
                    <option value="4">THURSDAY</option>
                    <option value="5">FRIDAY</option>
                    <option value="6">SATURDAY</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set Start Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleCategoryCouponChange}
                    name="startTime"
                    value={editedCategoryCouponData?.startTime}
                  />
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set End Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleCategoryCouponChange}
                    name="endTime"
                    value={editedProductCouponData?.endTime}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <CFormLabel className="fw-semibold">
                  Discount Percent <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  disabled={
                    editedCategoryCouponData?.discountAmount
                  }
                  placeholder="Discount Percent"
                  type="number"
                  name="discountPercent"
                  value={editedCategoryCouponData?.discountPercent}
                  onChange={handleCategoryCouponChange}
                  className="no-arrows"
                />
              </CCol>

              <CCol>
                <CFormLabel className="fw-semibold">
                  Discount Amount <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  disabled={
                    editedCategoryCouponData?.discountPercent
                  }
                  placeholder="Discount Amount"
                  type="number"
                  name="discountAmount"
                  value={editedCategoryCouponData?.discountAmount}
                  onChange={handleCategoryCouponChange}
                  className="no-arrows"
                />
              </CCol>

              <CCol>
                <CFormLabel className="fw-semibold">
                  Max Discount Amount
                </CFormLabel>
                <CFormInput
                  disabled={
                    editedCategoryCouponData?.discountAmount || editedCategoryCouponData?.maxDiscountAmount === null
                  }
                  placeholder="Max. Discount Amount"
                  type="number"
                  name="maxDiscountAmount"
                  value={editedCategoryCouponData?.maxDiscountAmount}
                  onChange={handleCategoryCouponChange}
                  className="no-arrows"
                />
              </CCol>
              {/* New Changes */}
              <CCol md="3" lg="3">
                <CFormLabel className="fw-semibold">
                  Min. Order Amount
                </CFormLabel>
                <CFormInput
                  min={0}
                  // disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSpecialProductCoupon"}
                  placeholder="Min. Order Amount"
                  type="number"
                  className="no-arrows"
                  name="minOrderAmount"
                  value={editedCategoryCouponData?.minOrderAmount}
                  onChange={handleCategoryCouponChange}
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              {/* Other fields */}
              <CCol md="12" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Terms and Conditions
                  </CFormLabel>
                  <div className="d-flex mb-2">
                    <CFormInput
                      placeholder="Add a term"
                      value={currentTerm}
                      onChange={(e) => setCurrentTerm(e.target.value)}
                      className="me-2"
                    />
                    <CButton disabled={!currentTerm.trim()}
                      onClick={addCategoryTerm}
                    >
                      ADD
                    </CButton>
                  </div>
                  {editedCategoryCouponData?.termsAndConditions?.length > 0 && (
                    <ul className="list-group">
                      {editedCategoryCouponData?.termsAndConditions?.map((term, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {term}
                          <button
                            type="button"
                            className="btn btn-sm btn-danger rounded-pill"
                            onClick={() => removeCategoryTerm(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              style={{ borderRadius: "18px" }}
            />
          </CModalFooter>
        </CForm>
      </CModal>


      {/* EDIT SPECIAL CATEGORY COUPON MODAL  */}
      <CModal
        size="lg"
        alignment="center"
        visible={isEditSpecialCategoryCouponModalOpen}
        onClose={() => setIsEditSpecialCategoryCouponModalOpen(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle>EDIT SPECIAL CATEGORY COUPON</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={submitSpecialCategoryCouponDetails}>
          <CModalBody>
            <CRow>
              <CCol className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Title"
                    name="title"
                    value={editedSpecialCategoryCouponData?.title}
                    onChange={handleSpecialCategoryCouponChange}
                  />
                </div>
              </CCol>
              <CCol className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Coupon Code <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Coupon Code"
                    name="couponCode"
                    value={editedSpecialCategoryCouponData?.couponCode}
                    onChange={handleSpecialCategoryCouponChange}
                  />
                </div>
              </CCol>
              <CCol className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Usage Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="usageType"
                    value={editedSpecialCategoryCouponData?.usageType}
                    onChange={handleSpecialCategoryCouponChange}
                  >
                    <option value="">Select Usage</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                    <option value="ONE-TIME">ONE-TIME</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>

            <CRow className="mb-3 mt-2">
              <CCol className="col-md-6">
                <CFormLabel className="fw-semibold">
                  Select Categories <span className="text-danger">*</span>
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedCategories}
                  options={categoriesData}
                  onChange={handleSelectCategoriesChange}
                  styles={customStyles}
                  placeholder="Select Categories" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              <CCol className="col-md-6 col-lg-6">
                <CFormLabel className="fw-semibold">
                  Select Excluded Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select options" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            {/* New Changes  */}

            <CRow className="mb-3">
              <CCol md="6" lg="6">
                <CFormLabel className="fw-semibold">
                  Select Included Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedIncludedOptions}
                  onChange={handleSelectIncChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select Included Outlets"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              <CCol className="col-md-6 col-lg-6">
                <CFormLabel className="fw-semibold">
                  Select Level
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents} // Ensure you import `animatedComponents` from `react-select/animated`
                  value={selectedLevelOptions} // Your selected options state
                  onChange={handleSelectLevelChange} // Handler for when options are selected/deselected
                  options={levels?.map((le) => ({
                    value: le._id,
                    label: le.title,
                  }))} // Map `levels` to the required format for `react-select`
                  styles={customStyles} // Custom styles object
                  placeholder="Select Level" // Updated placeholder
                  closeMenuOnSelect={false} // Keep menu open for multiple selections
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol md="8" lg="8">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={3}
                  placeholder="Description"
                  name="description"
                  value={editedSpecialCategoryCouponData.description}
                  onChange={handleSpecialCategoryCouponChange}
                />
              </CCol>

            </CRow>

            {/* New Changes */}

            <CRow className="mb-3">
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Select Day
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    onChange={handleSpecialCategoryCouponChange}
                    name="dayOfWeek"
                    aria-label="Small select example"
                    value={editedSpecialCategoryCouponData?.dayOfWeek}
                  >
                    <option value="">Select Day</option>
                    <option value="0">SUNDAY</option>
                    <option value="1">MONDAY</option>
                    <option value="2">TUESDAY</option>
                    <option value="3">WEDNESDAY</option>
                    <option value="4">THURSDAY</option>
                    <option value="5">FRIDAY</option>
                    <option value="6">SATURDAY</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set Start Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleSpecialCategoryCouponChange}
                    name="startTime"
                    value={editedSpecialCategoryCouponData?.startTime}
                  />
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set End Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleSpecialCategoryCouponChange}
                    name="endTime"
                    value={editedSpecialCategoryCouponData?.endTime}
                  />
                </div>
              </CCol>
              <CCol md="3" lg="3">
                <CFormLabel className="fw-semibold">
                  Min. Order Amount
                </CFormLabel>
                <CFormInput
                  min={0}
                  // disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSpecialProductCoupon"}
                  placeholder="Min. Order Amount"
                  type="number"
                  className="no-arrows"
                  name="minOrderAmount"
                  value={editedSpecialCategoryCouponData?.minOrderAmount}
                  onChange={handleSpecialCategoryCouponChange}
                />
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Hide Coupon
                  </CFormLabel>
                  <CFormSelect
                    name="isHidden"
                    value={editedSpecialCategoryCouponData?.isHidden}
                    onChange={handleSpecialCategoryCouponChange}
                  >
                    <option value="">Select Option</option>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity Start Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleCategoryValidityStartDateChange}
                    placeholderText="Validity Start Date"
                    className="form-control"
                    selected={editedSpecialCategoryCouponData?.validityStartDate ? new Date(editedSpecialCategoryCouponData?.validityStartDate) : null}
                  />
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity End Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleCategoryValidityEndDateChange}
                    placeholderText="Validity End Date"
                    className="form-control"
                    selected={editedSpecialCategoryCouponData?.validityEndDate ? new Date(editedSpecialCategoryCouponData?.validityEndDate) : null}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              {
                editedSpecialCategoryCouponData?.applicableQuantityType &&
                <CCol>
                  <CFormLabel className="fw-semibold">
                    Applicable Quantity Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="applicableQuantityType"
                    value={editedSpecialCategoryCouponData?.applicableQuantityType}
                    onChange={handleSpecialCategoryCouponChange}
                  >
                    <option value="">Select Applicable Quantity Type</option>
                    <option value="SINGLE">SINGLE</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                  </CFormSelect>
                </CCol>
              }

              <CCol>
                <CFormLabel className="fw-semibold">
                  Discount Price <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  placeholder="Discount Price"
                  type="number"
                  name="discountPrice"
                  value={editedSpecialCategoryCouponData?.discountPrice}
                  onChange={handleSpecialCategoryCouponChange}
                  className="no-arrows"
                />
              </CCol>
            </CRow>

            <CRow className="mt-3">
              {/* Other fields */}
              <CCol md="12" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Terms and Conditions
                  </CFormLabel>
                  <div className="d-flex mb-2">
                    <CFormInput
                      placeholder="Add a term"
                      value={currentTerm}
                      onChange={(e) => setCurrentTerm(e.target.value)}
                      className="me-2"
                    />
                    <CButton disabled={!currentTerm.trim()}
                      onClick={addSpCatTerm}
                    >
                      ADD
                    </CButton>
                  </div>
                  {editedSpecialCategoryCouponData?.termsAndConditions?.length > 0 && (
                    <ul className="list-group">
                      {editedSpecialCategoryCouponData?.termsAndConditions?.map((term, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {term}
                          <button
                            type="button"
                            className="btn btn-sm btn-danger rounded-pill"
                            onClick={() => removeSpCatTerm(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              style={{ borderRadius: "18px" }}
            />
          </CModalFooter>
        </CForm>
      </CModal>

      {/* EDIT PRODUCT COUPON MODAL  */}
      <CModal
        size="lg"
        alignment="center"
        visible={isEditProductCouponModalOpen}
        onClose={() => setIsEditProductCouponModalOpen(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle>EDIT PRODUCT COUPON</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={submitProductCouponDetails}>
          <CModalBody>
            <CRow className="mb-3">
              <CCol className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Title"
                    name="title"
                    value={editedProductCouponData?.title}
                    onChange={handleProductCouponChange}
                  />
                </div>
              </CCol>
              <CCol className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Coupon Code <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Coupon Code"
                    name="couponCode"
                    value={editedProductCouponData?.couponCode}
                    onChange={handleProductCouponChange}
                  />
                </div>
              </CCol>
              <CCol className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Usage Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="usageType"
                    value={editedProductCouponData?.usageType}
                    onChange={handleProductCouponChange}
                  >
                    <option value="">Select Usage</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                    <option value="ONE-TIME">ONE-TIME</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>

            <CRow className="mb-3">

              {/* New Changes */}

              <CCol md="6" lg="6">
                <CFormLabel className="fw-semibold">
                  Select Included Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedIncludedOptions}
                  onChange={handleSelectIncChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select Included Outlets"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>

              <CCol md="12" lg="12">
                <CFormLabel className="fw-semibold">
                  Select Excluded Outlets
                </CFormLabel>

                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select options" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            <CRow className="mt-2 mb-2">
              <CCol className="col-md-12">
                <CFormLabel className="fw-semibold">
                  Select Products <span className="text-danger">*</span>
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedProducts}
                  options={productsData}
                  onChange={handleSelectProductChange}
                  styles={customStyles}
                  placeholder="Select Products"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              {/* New Changes */}
              <CCol className="col-md-6">
                <CFormLabel className="fw-semibold">
                  Select Level
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents} // Ensure you import `animatedComponents` from `react-select/animated`
                  value={selectedLevelOptions} // Your selected options state
                  onChange={handleSelectLevelChange} // Handler for when options are selected/deselected
                  options={levels?.map((le) => ({
                    value: le._id,
                    label: le.title,
                  }))} // Map `levels` to the required format for `react-select`
                  styles={customStyles} // Custom styles object
                  placeholder="Select Level" // Updated placeholder
                  closeMenuOnSelect={false} // Keep menu open for multiple selections
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol lg={12} md={12} >
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={3}
                  placeholder="Description"
                  name="description"
                  value={editedProductCouponData.description}
                  onChange={handleProductCouponChange}
                />
              </CCol>
            </CRow>


            <CRow className="mb-3">
              {/* New Changes */}
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Select Day
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    onChange={handleProductCouponChange}
                    name="dayOfWeek"
                    aria-label="Small select example"
                    value={editedProductCouponData?.dayOfWeek}
                  >
                    <option value="">Select Day</option>
                    <option value="0">SUNDAY</option>
                    <option value="1">MONDAY</option>
                    <option value="2">TUESDAY</option>
                    <option value="3">WEDNESDAY</option>
                    <option value="4">THURSDAY</option>
                    <option value="5">FRIDAY</option>
                    <option value="6">SATURDAY</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="2" lg="2">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set Start Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleProductCouponChange}
                    name="startTime"
                    value={editedProductCouponData?.startTime}
                  />
                </div>
              </CCol>
              <CCol md="2" lg="2">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set End Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleProductCouponChange}
                    name="endTime"
                    value={editedProductCouponData?.endTime}
                  />
                </div>
              </CCol>
              <CCol md="4" lg="4" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Hide Coupon <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="isHidden"
                    value={editedProductCouponData?.isHidden}
                    onChange={handleProductCouponChange}
                  >
                    <option value="">Select Option</option>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity Start Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleProductValidityStartDateChange}
                    placeholderText="Validity Start Date"
                    className="form-control"
                    selected={editedProductCouponData?.validityStartDate ? new Date(editedProductCouponData?.validityStartDate) : null}
                  />
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity End Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleProductValidityEndDateChange}
                    placeholderText="Validity End Date"
                    className="form-control"
                    selected={editedProductCouponData?.validityEndDate ? new Date(editedProductCouponData?.validityEndDate) : null}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              {/* New Changes */}
              <CCol md="3" lg="3">
                <CFormLabel className="fw-semibold">
                  Min. Order Amount
                </CFormLabel>
                <CFormInput
                  min={0}
                  // disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSpecialProductCoupon"}
                  placeholder="Min. Order Amount"
                  type="number"
                  className="no-arrows"
                  name="minOrderAmount"
                  value={editedProductCouponData.minOrderAmount}
                  onChange={handleProductCouponChange}
                />
              </CCol>
              <CCol md="4" lg="4">
                <CFormLabel className="fw-semibold">
                  Discount Percent <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  disabled={
                    editedProductCouponData?.discountAmount
                  }
                  placeholder="Discount Percent"
                  type="number"
                  name="discountPercent"
                  value={editedProductCouponData.discountPercent}
                  onChange={handleProductCouponChange}
                  className="no-arrows"
                />
              </CCol>
              <CCol md="4" lg="4">
                <CFormLabel className="fw-semibold">
                  Discount Amount <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  disabled={
                    editedProductCouponData?.discountPercent
                  }
                  placeholder="Discount Amount"
                  type="number"
                  name="discountAmount"
                  value={editedProductCouponData.discountAmount}
                  onChange={handleProductCouponChange}
                  className="no-arrows"
                />
              </CCol>
              <CCol md="4" lg="4">
                <CFormLabel className="fw-semibold">
                  Max Discount Amount
                </CFormLabel>
                <CFormInput
                  placeholder="Max. Discount Amount"
                  disabled={editedProductCouponData.maxDiscountAmount === null}
                  type="number"
                  name="maxDiscountAmount"
                  value={editedProductCouponData.maxDiscountAmount}
                  onChange={handleProductCouponChange}
                  className="no-arrows"
                />
              </CCol>
            </CRow>

            <CRow className="mb-3">
              {/* Other fields */}
              <CCol md="12" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Terms and Conditions
                  </CFormLabel>
                  <div className="d-flex mb-2">
                    <CFormInput
                      placeholder="Add a term"
                      value={currentTerm}
                      onChange={(e) => setCurrentTerm(e.target.value)}
                      className="me-2"
                    />
                    <CButton disabled={!currentTerm.trim()}
                      onClick={addProductTerm}
                    >
                      ADD
                    </CButton>
                  </div>
                  {editedProductCouponData?.termsAndConditions?.length > 0 && (
                    <ul className="list-group">
                      {editedProductCouponData?.termsAndConditions?.map((term, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {term}
                          <button
                            type="button"
                            className="btn btn-sm btn-danger rounded-pill"
                            onClick={() => removeProductTerm(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              style={{ borderRadius: "18px" }}
            />
          </CModalFooter>
        </CForm>
      </CModal>

      {/* EDIT SPECIAL PRODUCT COUPON MODAL  */}
      <CModal
        size="lg"
        alignment="center"
        visible={isEditSpecialProductCouponModalOpen}
        onClose={() => setIsEditSpecialProductCouponModalOpen(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle>EDIT SPECIAL PRODUCT COUPON</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={submitSpecialProductCouponDetails}>
          <CModalBody>
            <CRow>
              <CCol md="4" lg="4" className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Title"
                    name="title"
                    value={editedSpecialProductCouponData?.title}
                    onChange={handleSpecialProductCouponChange}
                  />
                </div>
              </CCol>
              <CCol md="4" lg="4" className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Coupon Code <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Coupon Code"
                    name="couponCode"
                    value={editedSpecialProductCouponData?.couponCode}
                    onChange={handleSpecialProductCouponChange}
                  />
                </div>
              </CCol>
              <CCol md="4" lg="4" className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Usage Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="usageType"
                    value={editedSpecialProductCouponData?.usageType}
                    onChange={handleSpecialProductCouponChange}
                  >
                    <option value="">Select Usage</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                    <option value="ONE-TIME">ONE-TIME</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>

            <CRow className="mt-2 mb-2">
              <CCol className="col-md-6">
                <CFormLabel className="fw-semibold">
                  Select Products <span className="text-danger">*</span>
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedProducts}
                  options={productsData}
                  onChange={handleSelectProductChange}
                  styles={customStyles}
                  placeholder="Select Products"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              <CCol className="col-md-6">
                <CFormLabel className="fw-semibold">
                  Select Excluded Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select options" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            <CRow className="mb-3">
              {/* New Changes */}
              <CCol md="6" lg="6">
                <CFormLabel className="fw-semibold">
                  Select Included Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedIncludedOptions}
                  onChange={handleSelectIncChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select Included Outlets"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              <CCol className="col-md-6">
                <CFormLabel className="fw-semibold">
                  Select Level
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents} // Ensure you import `animatedComponents` from `react-select/animated`
                  value={selectedLevelOptions} // Your selected options state
                  onChange={handleSelectLevelChange} // Handler for when options are selected/deselected
                  options={levels?.map((le) => ({
                    value: le._id,
                    label: le.title,
                  }))} // Map `levels` to the required format for `react-select`
                  styles={customStyles} // Custom styles object
                  placeholder="Select Level" // Updated placeholder
                  closeMenuOnSelect={false} // Keep menu open for multiple selections
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="12" md="12" className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={3}
                  placeholder="Description"
                  name="description"
                  value={editedSpecialProductCouponData?.description}
                  onChange={handleSpecialProductCouponChange}
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4" lg="4" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Hide Coupon <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="isHidden"
                    value={editedSpecialProductCouponData?.isHidden}
                    onChange={handleSpecialProductCouponChange}
                  >
                    <option value="">Select Option</option>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity Start Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleProductValidityStartDateChange}
                    placeholderText="Validity Start Date"
                    className="form-control"
                    selected={editedSpecialProductCouponData?.validityStartDate ? new Date(editedSpecialProductCouponData?.validityStartDate) : null}
                  />
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity End Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleProductValidityEndDateChange}
                    placeholderText="Validity End Date"
                    className="form-control"
                    selected={editedSpecialProductCouponData?.validityEndDate ? new Date(editedSpecialProductCouponData?.validityEndDate) : null}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Select Day
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    onChange={handleSpecialProductCouponChange}
                    name="dayOfWeek"
                    aria-label="Small select example"
                    value={editedSpecialProductCouponData?.dayOfWeek}
                  >
                    <option value="">Select Day</option>
                    <option value="0">SUNDAY</option>
                    <option value="1">MONDAY</option>
                    <option value="2">TUESDAY</option>
                    <option value="3">WEDNESDAY</option>
                    <option value="4">THURSDAY</option>
                    <option value="5">FRIDAY</option>
                    <option value="6">SATURDAY</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set Start Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleSpecialProductCouponChange}
                    name="startTime"
                    value={editedSpecialProductCouponData?.startTime}
                  />
                </div>
              </CCol>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set End Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleSpecialProductCouponChange}
                    name="endTime"
                    value={editedSpecialProductCouponData?.endTime}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol md="4" lg="4">
                <CFormLabel className="fw-semibold">
                  Min. Order Amount
                </CFormLabel>
                <CFormInput
                  min={0}
                  // disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSpecialProductCoupon"}
                  placeholder="Min. Order Amount"
                  type="number"
                  className="no-arrows"
                  name="minOrderAmount"
                  value={editedProductCouponData.minOrderAmount}
                  onChange={handleProductCouponChange}
                />
              </CCol>

              <CCol md="6" lg="6">
                <CFormLabel className="fw-semibold">
                  Discount Price <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  placeholder="Discount Price"
                  type="number"
                  name="discountPrice"
                  value={editedSpecialProductCouponData?.discountPrice}
                  onChange={handleSpecialProductCouponChange}
                  className="no-arrows"
                />
              </CCol>

              {editedSpecialProductCouponData?.applicableQuantityType ? (
                <CCol md="6" lg="6">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Applicable Quantity Type <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      name="applicableQuantityType"
                      value={editedSpecialProductCouponData?.applicableQuantityType}
                      onChange={handleSpecialProductCouponChange}
                    >
                      <option value="">Select Applicable Quantity Type</option>
                      <option value="SINGLE">SINGLE</option>
                      <option value="MULTIPLE">MULTIPLE</option>
                    </CFormSelect>
                  </div>
                </CCol>
              ) : null}
            </CRow>

            <CRow className="mt-3">
              {/* Other fields */}
              <CCol md="12" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Terms and Conditions
                  </CFormLabel>
                  <div className="d-flex mb-2">
                    <CFormInput
                      placeholder="Add a term"
                      value={currentTerm}
                      onChange={(e) => setCurrentTerm(e.target.value)}
                      className="me-2"
                    />
                    <CButton disabled={!currentTerm.trim()}
                      onClick={addSpProductTerm}
                    >
                      ADD
                    </CButton>
                  </div>
                  {editedSpecialProductCouponData?.termsAndConditions?.length > 0 && (
                    <ul className="list-group">
                      {editedSpecialProductCouponData?.termsAndConditions?.map((term, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {term}
                          <button
                            type="button"
                            className="btn btn-sm btn-danger rounded-pill"
                            onClick={() => removeSpProductTerm(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              style={{ borderRadius: "18px" }}
            />
          </CModalFooter>
        </CForm>
      </CModal>

      {/* EDIT CART COUPON MODAL  */}
      <CModal
        size="lg"
        alignment="center"
        visible={isEditCartCouponModalOpen}
        onClose={() => setIsEditCartCouponModalOpen(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle>EDIT CART COUPON</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={submitCartCouponDetails}>
          <CModalBody>
            <CRow className="mb-2">
              <CCol>
                <div>
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Title"
                    name="title"
                    value={editedCartCouponDetails.title}
                    onChange={handleCartInputChange}
                  />
                </div>
              </CCol>
              <CCol>
                <div>
                  <CFormLabel className="fw-semibold">
                    Coupon Code <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Coupon Code"
                    name="couponCode"
                    value={editedCartCouponDetails.couponCode}
                    onChange={handleCartInputChange}
                  />
                </div>
              </CCol>
              <CCol>
                <div>
                  <CFormLabel className="fw-semibold">
                    Usage Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="usageType"
                    value={editedCartCouponDetails.usageType}
                    onChange={handleCartInputChange}
                  >
                    <option value="">Select Usage</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                    <option value="ONE-TIME">ONE-TIME</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>

            <CRow className="mb-2">
              <CCol md="8" lg="8">
                <CFormLabel className="fw-semibold">
                  Select Included Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedIncludedOptions}
                  onChange={handleSelectIncChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select Included Outlets"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              <CCol md="4" lg="4">
                <CFormLabel className="fw-semibold">
                  Select Level
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedLevelOptions}
                  onChange={handleSelectLevelChange}
                  options={levels?.map((le) => ({
                    value: le._id,
                    label: le.title,
                  }))}
                  styles={customStyles}
                  placeholder="Select Level"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            <CRow className="mb-2">
              <CCol md="8">
                <div>
                  <CFormLabel className="fw-semibold">
                    Select Excluded Outlets
                  </CFormLabel>
                  <Select
                    isMulti
                    components={animatedComponents}
                    value={selectedOptions}
                    onChange={handleSelectChange}
                    options={outletData}
                    styles={customStyles}
                    placeholder="Select options"
                    closeMenuOnSelect={false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#e9ecef",
                        primary: "#007bff",
                      },
                    })}
                  />
                </div>
              </CCol>
              <CCol md="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Hide Coupon <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="isHidden"
                    value={editedCartCouponDetails?.isHidden}
                    onChange={handleCartInputChange}
                  >
                    <option value="">Select Option</option>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>


            <div className="mb-3">
              <CFormLabel className="fw-semibold">
                Description <span className="text-danger">*</span>
              </CFormLabel>
              <CFormTextarea
                rows={4}
                placeholder="Description"
                name="description"
                value={editedCartCouponDetails.description}
                onChange={handleCartInputChange}
              />
            </div>

            <CRow className="mb-3">
              <CCol md='4'>
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity Start Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleCartValidStartDateChange}
                    placeholderText="Validity Start Date"
                    className="form-control"
                    selected={editedCartCouponDetails?.validityStartDate ? new Date(editedCartCouponDetails?.validityStartDate) : null}
                  />
                </div>
              </CCol>

              <CCol md='4' className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity End Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleCartValidEndDateChange}
                    placeholderText="Validity End Date"
                    className="form-control"
                    selected={editedCartCouponDetails?.validityEndDate ? new Date(editedCartCouponDetails?.validityEndDate) : null}
                  />
                </div>
              </CCol>

              {
                // editedCartCouponDetails.hasOwnProperty('discountPercent') &&
                <CCol md='4'>
                  <CFormLabel className="fw-semibold">
                    Discount Percent <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    disabled={
                      editedCartCouponDetails?.discountAmount
                    }
                    placeholder="Discount Percent"
                    type="number"
                    name="discountPercent"
                    value={editedCartCouponDetails.discountPercent}
                    onChange={handleCartInputChange}
                    className="no-arrows"
                  />
                </CCol>
              }
              {
                // editedCartCouponDetails.hasOwnProperty('discountAmount') &&
                <CCol md='4'>
                  <CFormLabel className="fw-semibold">
                    Discount Amount <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    disabled={
                      editedCartCouponDetails?.discountPercent
                    }
                    placeholder="Discount Amount"
                    type="number"
                    name="discountAmount"
                    value={editedCartCouponDetails.discountAmount}
                    onChange={handleCartInputChange}
                    className="no-arrows"
                  />
                </CCol>
              }

              {
                // editedCartCouponDetails?.maxDiscountAmount &&
                <CCol md='4'>
                  <CFormLabel className="fw-semibold">
                    Max. Dis. Amount
                  </CFormLabel>
                  <CFormInput
                    disabled={
                      editedCartCouponDetails?.discountAmount || editedCartCouponDetails?.maxDiscountAmount === null
                    }
                    placeholder="Max. Dis. Amount"
                    type="number"
                    name="maxDiscountAmount"
                    value={editedCartCouponDetails.maxDiscountAmount}
                    onChange={handleCartInputChange}
                    className="no-arrows"
                  />
                </CCol>
              }

              {
                // editedCartCouponDetails.minOrderAmount &&
                <CCol md='4'>
                  <CFormLabel className="fw-semibold">
                    Min. Order Amount <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Min. Order Amount"
                    type="number"
                    name="minOrderAmount"
                    value={editedCartCouponDetails.minOrderAmount}
                    onChange={handleCartInputChange}
                    className="no-arrows"
                  />
                </CCol>
              }
            </CRow>

            <CRow>
              <CCol md="4" lg="4">
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Select Day
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    onChange={handleCartInputChange}
                    name="dayOfWeek"
                    className="mb-3"
                    aria-label="Small select example"
                    value={editedCartCouponDetails?.dayOfWeek}
                  >
                    <option value="">Select Day</option>
                    <option value="0">SUNDAY</option>
                    <option value="1">MONDAY</option>
                    <option value="2">TUESDAY</option>
                    <option value="3">WEDNESDAY</option>
                    <option value="4">THURSDAY</option>
                    <option value="5">FRIDAY</option>
                    <option value="6">SATURDAY</option>
                  </CFormSelect>
                </div>
              </CCol>

              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set Start Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleCartInputChange}
                    name="startTime"
                    value={editedCartCouponDetails?.startTime}
                  />
                </div>
              </CCol>

              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set End Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleCartInputChange}
                    name="endTime"
                    value={editedCartCouponDetails?.endTime}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow className="mt-3">
              {/* Other fields */}
              <CCol md="12" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Terms and Conditions
                  </CFormLabel>
                  <div className="d-flex mb-2">
                    <CFormInput
                      placeholder="Add a term"
                      value={currentTerm}
                      onChange={(e) => setCurrentTerm(e.target.value)}
                      className="me-2"
                    />
                    <CButton disabled={!currentTerm.trim()}
                      onClick={addCartTerm}
                    >
                      ADD
                    </CButton>
                  </div>
                  {editedCartCouponDetails?.termsAndConditions?.length > 0 && (
                    <ul className="list-group">
                      {editedCartCouponDetails?.termsAndConditions?.map((term, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {term}
                          <button
                            type="button"
                            className="btn btn-sm btn-danger rounded-pill"
                            onClick={() => removeCartTerm(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              style={{ borderRadius: "18px" }}
            />
          </CModalFooter>
        </CForm>
      </CModal>

      {/* EDIT SIZE UPGRADE COUPON MODAL  */}
      <CModal
        size="lg"
        alignment="center"
        visible={isEditSizeUpgradeCouponModalOpen}
        onClose={() => setIsEditSizeUpgradeCouponModalOpen(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle>EDIT SIZE UPGRADE COUPON</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={submitSizeUpgradeCouponDetails}>
          <CModalBody>
            <CRow className="mb-3">
              <CCol md='4'>
                <div>
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Title"
                    name="title"
                    value={editSizeUpgradeCouponData?.title}
                    onChange={handleSizeUpgradeInputChange}
                  />
                </div>
              </CCol>
              <CCol md='4' className='mb-3'>
                <div>
                  <CFormLabel className="fw-semibold">
                    Coupon Code <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Coupon Code"
                    name="couponCode"
                    value={editSizeUpgradeCouponData?.couponCode}
                    onChange={handleSizeUpgradeInputChange}
                  />
                </div>
              </CCol>
              <CCol md='4'>
                <div>
                  <CFormLabel className="fw-semibold">
                    Usage Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="usageType"
                    value={editSizeUpgradeCouponData?.usageType}
                    onChange={handleSizeUpgradeInputChange}
                  >
                    <option value="">Select Usage</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                    <option value="ONE-TIME">ONE-TIME</option>
                  </CFormSelect>
                </div>
              </CCol>
              {selectedProduct && (
                <CCol md="6">
                  <CFormLabel className="fw-semibold">
                    Select Product <span className="text-danger">*</span>
                  </CFormLabel>
                  <Select
                    options={addonList.map((rm) => ({
                      value: rm?.product?._id,
                      label: rm?.product?.name,
                    }))}
                    // options= {productsData}
                    value={
                      selectedProduct
                        ? addonList
                          .map((rm) => ({ value: rm?.product?._id, label: rm?.product?.name }))
                          .find((option) => option.value === selectedProduct)
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (typeof selectedOption === 'object' && selectedOption !== null) {
                        handleSizeUpgradeInputChange({ target: { name: 'applicableProducts', value: selectedOption.value } });
                      } else {
                        handleSizeUpgradeInputChange({ target: { name: 'applicableProducts', value: '' } });
                      }
                    }}
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Select Product"
                  />
                </CCol>
              )}

              <CCol md="6">
                <CFormLabel className="fw-semibold">
                  Select Size Upgrade Addon
                </CFormLabel>
                <CFormSelect
                  name="sizeUpgradeAddon"
                  value={editSizeUpgradeCouponData?.sizeUpgradeAddon}
                  onChange={handleSizeUpgradeInputChange}
                >
                  <option value="">Select Size Upgrade Addon</option>
                  {filteredAddons?.map((fa, index) => {
                    return (
                      fa.addons.map((ufa, ufaIndex) => {
                        return (
                          <option key={ufa?.addonId} value={ufa?.addonId}>{ufa?.addonTitle}</option>
                        );
                      })
                    );
                  })}
                  {/* <option key={filteredAddons[0]?._id} value={filteredAddons[0]?._id}>
                          <option key={ufa?._id} value={ufa?._id}>{ufa?.addonTitle}</option> => IMP PUT TOP

                      {filteredAddons[0]?.addonTitle}
                    </option> */}
                </CFormSelect>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol md="12" lg="12">
                <CFormLabel className="fw-semibold">
                  Select Included Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedIncludedOptions}
                  onChange={handleSelectIncChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select Included Outlets"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            <div className="mb-3">
              <CFormLabel className="fw-semibold">
                Select Excluded Outlets <span className="text-danger">*</span>
              </CFormLabel>
              <Select
                isMulti
                components={animatedComponents}
                value={selectedOptions}
                onChange={handleSelectChange}
                options={outletData}
                styles={customStyles}
                placeholder="Select Excluded Outlets"
                closeMenuOnSelect={false}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#e9ecef",
                    primary: "#007bff",
                  },
                })}
              />
            </div>

            <CRow>
              <CCol md={8} lg={8} className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={3}
                  placeholder="Description"
                  name="description"
                  value={editSizeUpgradeCouponData.description}
                  onChange={handleSizeUpgradeInputChange}
                />
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol md="6" lg="6">
                <CFormLabel className="fw-semibold">
                  Select Level
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedLevelOptions}
                  onChange={handleSelectLevelChange}
                  options={levels?.map((le) => ({
                    value: le._id,
                    label: le.title,
                  }))}
                  styles={customStyles}
                  placeholder="Select Level"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              <CCol md={4} lg={4}>
                <div>
                  <CFormLabel className="fw-semibold">
                    Hide Coupon <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="isHidden"
                    value={editSizeUpgradeCouponData?.isHidden}
                    onChange={handleSizeUpgradeInputChange}
                  >
                    <option value="">Select Option</option>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md='4' lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity Start Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleSizeUpgradeValidityStartDateChange}
                    placeholderText="Validity Start Date"
                    className="form-control"
                    selected={editSizeUpgradeCouponData?.validityStartDate ? new Date(editSizeUpgradeCouponData?.validityStartDate) : null}
                  />
                </div>
              </CCol>
              <CCol md='4' lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity End Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleSizeUpgradeValidityEndDateChange}
                    placeholderText="Validity End Date"
                    className="form-control"
                    selected={editSizeUpgradeCouponData?.validityEndDate ? new Date(editSizeUpgradeCouponData?.validityEndDate) : null}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Select Day
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    onChange={handleSizeUpgradeInputChange}
                    name="dayOfWeek"
                    aria-label="Small select example"
                    value={editSizeUpgradeCouponData?.dayOfWeek}
                  >
                    <option value="">Select Day</option>
                    <option value="0">SUNDAY</option>
                    <option value="1">MONDAY</option>
                    <option value="2">TUESDAY</option>
                    <option value="3">WEDNESDAY</option>
                    <option value="4">THURSDAY</option>
                    <option value="5">FRIDAY</option>
                    <option value="6">SATURDAY</option>
                  </CFormSelect>
                </div>
              </CCol>

              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set Start Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleSizeUpgradeInputChange}
                    name="startTime"
                    value={editSizeUpgradeCouponData?.startTime}
                  />
                </div>
              </CCol>

              <CCol md="3" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set End Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleSizeUpgradeInputChange}
                    name="endTime"
                    value={editSizeUpgradeCouponData?.endTime}
                  />
                </div>
              </CCol>

              <CCol md="3" lg="3">
                <CFormLabel className="fw-semibold">
                  Min. Order Amount
                </CFormLabel>
                <CFormInput
                  min={0}
                  // disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSpecialProductCoupon"}
                  placeholder="Min. Order Amount"
                  type="number"
                  className="no-arrows"
                  name="minOrderAmount"
                  value={editSizeUpgradeCouponData?.minOrderAmount}
                  onChange={handleSizeUpgradeInputChange}
                />
              </CCol>
            </CRow>

            <CRow>
              {/* Other fields */}
              <CCol md="12" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Terms and Conditions
                  </CFormLabel>
                  <div className="d-flex mb-2">
                    <CFormInput
                      placeholder="Add a term"
                      value={currentTerm}
                      onChange={(e) => setCurrentTerm(e.target.value)}
                      className="me-2"
                    />
                    <CButton disabled={!currentTerm.trim()}
                      onClick={addSizeTerm}
                    >
                      ADD
                    </CButton>
                  </div>
                  {editSizeUpgradeCouponData?.termsAndConditions?.length > 0 && (
                    <ul className="list-group">
                      {editSizeUpgradeCouponData?.termsAndConditions?.map((term, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {term}
                          <button
                            type="button"
                            className="btn btn-sm btn-danger rounded-pill"
                            onClick={() => removeSizeTerm(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              style={{ borderRadius: "18px" }}
            />
          </CModalFooter>
        </CForm>
      </CModal>

      {/* EDIT UNIVERSAL COUPON MODAL  */}
      <CModal
        size="lg"
        alignment="center"
        visible={isEditUniversalCouponModalOpen}
        onClose={() => setIsEditUniversalCouponModalOpen(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle>EDIT UNIVERSAL COUPON</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={submitUniversalCouponDetails}>
          <CModalBody>
            <CRow className="mb-2">
              <CCol>
                <div>
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Title"
                    name="title"
                    value={editedUniversalCouponDetails.title}
                    onChange={handleUniversalInputChange}
                  />
                </div>
              </CCol>
              <CCol>
                <div>
                  <CFormLabel className="fw-semibold">
                    Coupon Code <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Coupon Code"
                    name="couponCode"
                    value={editedUniversalCouponDetails.couponCode}
                    onChange={handleUniversalInputChange}
                  />
                </div>
              </CCol>
              <CCol>
                <div>
                  <CFormLabel className="fw-semibold">
                    Usage Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="usageType"
                    value={editedUniversalCouponDetails.usageType}
                    onChange={handleUniversalInputChange}
                  >
                    <option value="">Select Usage</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                    <option value="ONE-TIME">ONE-TIME</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>

            <CRow className="mb-2">
              <CCol md="8" lg="8">
                <CFormLabel className="fw-semibold">
                  Select Included Outlets
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedIncludedOptions}
                  onChange={handleSelectIncChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select Included Outlets"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
              <CCol md="4" lg="4">
                <CFormLabel className="fw-semibold">
                  Select Level
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedLevelOptions}
                  onChange={handleSelectLevelChange}
                  options={levels?.map((le) => ({
                    value: le._id,
                    label: le.title,
                  }))}
                  styles={customStyles}
                  placeholder="Select Level"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>
            </CRow>

            <CRow className="mb-2">
              <CCol md="8">
                <div>
                  <CFormLabel className="fw-semibold">
                    Select Excluded Outlets
                  </CFormLabel>
                  <Select
                    isMulti
                    components={animatedComponents}
                    value={selectedOptions}
                    onChange={handleSelectChange}
                    options={outletData}
                    styles={customStyles}
                    placeholder="Select options"
                    closeMenuOnSelect={false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#e9ecef",
                        primary: "#007bff",
                      },
                    })}
                  />
                </div>
              </CCol>
              <CCol md="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Hide Coupon <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="isHidden"
                    value={editedUniversalCouponDetails?.isHidden}
                    onChange={handleUniversalInputChange}
                  >
                    <option value="">Select Option</option>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>

            <div className="mb-3">
              <CFormLabel className="fw-semibold">
                Description <span className="text-danger">*</span>
              </CFormLabel>
              <CFormTextarea
                rows={4}
                placeholder="Description"
                name="description"
                value={editedUniversalCouponDetails.description}
                onChange={handleUniversalInputChange}
              />
            </div>

            <CRow className="mb-3">
              <CCol md='4'>
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity Start Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleUniversalValidStartDateChange}
                    placeholderText="Validity Start Date"
                    className="form-control"
                    selected={editedUniversalCouponDetails?.validityStartDate ? new Date(editedUniversalCouponDetails?.validityStartDate) : null}
                  />
                </div>
              </CCol>

              <CCol md='4'>
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity End Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleUniversalValidEndDateChange}
                    placeholderText="Validity End Date"
                    className="form-control"
                    selected={editedUniversalCouponDetails?.validityEndDate ? new Date(editedUniversalCouponDetails?.validityEndDate) : null}
                  />
                </div>
              </CCol>

              <CCol md='4'>
                <CFormLabel className="fw-semibold">
                  Discount Percent <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  placeholder="Discount Percent"
                  type="number"
                  name="discountPercent"
                  value={editedUniversalCouponDetails.discountPercent}
                  onChange={handleUniversalInputChange}
                  className="no-arrows"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4" lg="4">
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Select Day
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    onChange={handleUniversalInputChange}
                    name="dayOfWeek"
                    className="mb-3"
                    aria-label="Small select example"
                    value={editedUniversalCouponDetails?.dayOfWeek}
                  >
                    <option value="">Select Day</option>
                    <option value="0">SUNDAY</option>
                    <option value="1">MONDAY</option>
                    <option value="2">TUESDAY</option>
                    <option value="3">WEDNESDAY</option>
                    <option value="4">THURSDAY</option>
                    <option value="5">FRIDAY</option>
                    <option value="6">SATURDAY</option>
                  </CFormSelect>
                </div>
              </CCol>

              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set Start Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleUniversalInputChange}
                    name="startTime"
                    value={editedUniversalCouponDetails?.startTime}
                  />
                </div>
              </CCol>

              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Set End Time
                  </CFormLabel>
                  <CFormInput
                    type="time"
                    size="sm"
                    onChange={handleUniversalInputChange}
                    name="endTime"
                    value={editedUniversalCouponDetails?.endTime}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow className="mt-3">
              {/* Other fields */}
              <CCol md="12" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Terms and Conditions
                  </CFormLabel>
                  <div className="d-flex mb-2">
                    <CFormInput
                      placeholder="Add a term"
                      value={currentTerm}
                      onChange={(e) => setCurrentTerm(e.target.value)}
                      className="me-2"
                    />
                    <CButton disabled={!currentTerm.trim()}
                      onClick={addUniversalTerm}
                    >
                      ADD
                    </CButton>
                  </div>
                  {editedUniversalCouponDetails?.termsAndConditions?.length > 0 && (
                    <ul className="list-group">
                      {editedUniversalCouponDetails?.termsAndConditions?.map((term, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {term}
                          <button
                            type="button"
                            className="btn btn-sm btn-danger rounded-pill"
                            onClick={() => removeUniversalTerm(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              style={{ borderRadius: "18px" }}
            />
          </CModalFooter>
        </CForm>
      </CModal>
    </CContainer>
  );
};

export default CouponCard;
