import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow } from '@coreui/react'
import { PrimaryButton } from '../../components'
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import makeAnimated from "react-select/animated";
import { format } from 'date-fns';

const BOGOCoupon = ({ outlets, selectedOptions, setSelectedOptions, setSelectedCouponOption }) => {
    const accessToken = localStorage.getItem("authToken");
    const [selectedIncludedOptions, setSelectedIncludedOptions] = useState([]);
    const [bogoCouponInputData, setBogoCouponInputData] = useState({
        isBogoCoupon: true,
        isHidden: "",
        title: "",
        couponCode: "",
        description: "",
        usageType: "",
        // minOrderAmount: "", New Change
        validityStartDate: "",
        validityEndDate: "",
        // startTime: "", New Change
        // endTime: "", New Change
        // dayOfWeek: "", New Change
        termsAndConditions: [], // Array to store terms
    });
    const [selectedLevelOptions, setSelectedLevelOptions] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [bogoBtnLoading, setBogoBtnLoading] = useState(false);
    const [currentTerm, setCurrentTerm] = useState('');
    const animatedComponents = makeAnimated();
    const outletData = outlets.map((outlet) => ({
        value: outlet._id,
        label: `${outlet.name} (${outlet.city})`
    }));
    const [levels, setLevels] = useState([]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%", // Adjust width based on your requirement
            borderRadius: '6px',
            minWidth: "200px", // Add a minimum width to avoid layout issues
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d"
        }),
    };

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handleSelectIncChange = (selected) => {        
        setSelectedIncludedOptions(selected);
    };

    const handleSelectLevelChange = (selected) => {
        setSelectedLevelOptions(selected);
    }

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleBogoInputChange = (e) => {
        const { name, value } = e.target;
        setBogoCouponInputData((prev) => ({ ...prev, [name]: value }));
        console.log("Name :", name , "Value :", value);
        
    };

    const addTerm = () => {
        if (!currentTerm.trim()) return;
        setBogoCouponInputData((prevData) => ({
            ...prevData,
            termsAndConditions: [...prevData.termsAndConditions, currentTerm],
        }));
        setCurrentTerm(''); // Clear input after adding
    };

    const removeTerm = (index) => {
        setBogoCouponInputData((prevData) => ({
            ...prevData,
            termsAndConditions: prevData.termsAndConditions.filter(
                (_, i) => i !== index
            ),
        }));
    };

    const submitBogoCouponDetails = async (e) => {
        e.preventDefault();
        setBogoBtnLoading(true);
        let selectedOutlet = selectedOptions.map((option) => option.value);
        // let selectedIncOutlet = selectedIncludedOptions.map((option) => option.value); New Change
        // let selectedLevels = selectedLevelOptions?.map((options) => options?.value); New Change
        let bogoCouponData = {
            ...bogoCouponInputData,
            excludedOutlets: selectedOutlet,
            // includedOutlets: selectedIncOutlet, New Change
            // applicableLevels: selectedLevels New Change
        }

        if(bogoCouponData?.excludedOutlets?.length === 0) {
            delete bogoCouponData?.excludedOutlets
        }

        if(bogoCouponData?.applicableLevels?.length === 0) {
            delete bogoCouponData?.applicableLevels
        }

        // if(bogoCouponData?.includedOutlets?.length === 0) {
        //     delete bogoCouponData?.includedOutlets
        // } New Change

        // if(bogoCouponData?.startTime === "") {
        //     delete bogoCouponData?.startTime
        // } New Change

        // if(bogoCouponData?.endTime === "") {
        //     delete bogoCouponData?.endTime
        // } New Change

        // if(bogoCouponData?.applicableLevels?.length === 0) {
        //     delete bogoCouponData?.applicableLevels
        // } New Change

        // if(bogoCouponData?.dayOfWeek === "") {
        //     delete bogoCouponData?.dayOfWeek
        // } New Change

        // if(bogoCouponData?.minOrderAmount === "") {
        //     delete bogoCouponData?.minOrderAmount
        // } New Change

        if (bogoCouponData?.validityStartDate === "") {
            delete bogoCouponData.validityStartDate
        }

        if (bogoCouponData?.validityEndDate === "") {
            delete bogoCouponData.validityEndDate
        }

        if (Array.isArray(bogoCouponData?.termsAndConditions) && bogoCouponData?.termsAndConditions?.length === 0) {
            delete bogoCouponData.termsAndConditions;
        }

        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const response = await axios.post("coupons", bogoCouponData, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                e.target.reset();
                setSelectedOptions([]);
                setSelectedIncludedOptions([]);
                setSelectedLevelOptions([]);
                setBogoCouponInputData({
                    title: "",
                    isHidden: "",
                    couponCode: "",
                    description: "",
                    usageType: "",
                    // dayOfWeek: "", New Change
                    // minOrderAmount: "", New Change
                    // endTime: "", New Change
                    // startTime: "", New Change
                });
                setFromDate(null);
                setToDate(null);
                setSelectedCouponOption("");
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setBogoBtnLoading(false);
        }
    };

    const fetchLevelsData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("level", { headers });
            if (response?.data?.status === true) {
                const levels = response?.data?.data?.levels;
                console.log("Levels :", levels);
                
                setLevels(levels)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const bogoBtnDisable = () => {
        let isDisable = false;

        if (
            !bogoCouponInputData?.title?.trim() ||
            !bogoCouponInputData?.couponCode?.trim() ||
            !bogoCouponInputData?.description?.trim() ||
            !bogoCouponInputData?.usageType
            // !bogoCouponInputData?.validityEndDate ||
            // !bogoCouponInputData?.validityStartDate
        ) {
            isDisable = true;
        }

        return isDisable;
    };

    useEffect(() => {
        fetchLevelsData();
    }, [])

    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setBogoCouponInputData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);

    return (
        <CCard>
            <CCardHeader>
                <CCardTitle className="fs-6">ADD BOGO COUPON</CCardTitle>
            </CCardHeader>
            <CForm onSubmit={submitBogoCouponDetails}>
                <CCardBody>
                    <CRow>
                        <CCol md='4'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Title <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Title"
                                    name="title"
                                    value={bogoCouponInputData.title}
                                    onChange={handleBogoInputChange}
                                />
                            </div>
                        </CCol>
                        <CCol md='4'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Coupon Code <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Coupon Code"
                                    name="couponCode"
                                    value={bogoCouponInputData.couponCode}
                                    onChange={handleBogoInputChange}
                                />
                            </div>
                        </CCol>
                        <CCol md='4' className='mb-3'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Usage Type <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="usageType"
                                    value={bogoCouponInputData.usageType}
                                    onChange={handleBogoInputChange}
                                >
                                    <option value="">Select Usage</option>
                                    <option value="MULTIPLE">MULTIPLE</option>
                                    <option value="ONE-TIME">ONE-TIME</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol md='4'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Select Start Date
                                </CFormLabel>
                                <DatePicker
                                    selected={fromDate ? new Date(fromDate) : null}
                                    onChange={handleFromDateChange}
                                    placeholderText="Start Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                        <CCol md='4'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Select End Date
                                </CFormLabel>
                                <DatePicker
                                    selected={toDate ? new Date(toDate) : null}
                                    onChange={handleToDateChange}
                                    placeholderText="End Date"
                                    className="form-control w-100"
                                />
                            </div>
                        </CCol>
                        <CCol md='4' className='mb-3'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Hide Coupon <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="isHidden"
                                    value={bogoCouponInputData?.isHidden}
                                    onChange={handleBogoInputChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>

                    {/* <CRow>
                        <CCol md="4" lg="4">
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Set Start Time
                                </CFormLabel>
                                <CFormInput
                                    type="time"
                                    size="sm"
                                    onChange={handleBogoInputChange}
                                    name="startTime"
                                    className="mb-3"
                                    value={bogoCouponInputData?.startTime}
                                />
                            </div>
                        </CCol>

                        <CCol md="4" lg="4">
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Set End Time
                                </CFormLabel>
                                <CFormInput
                                    type="time"
                                    size="sm"
                                    onChange={handleBogoInputChange}
                                    name="endTime"
                                    className="mb-3"
                                    value={bogoCouponInputData?.endTime}
                                />
                            </div>
                        </CCol>

                        <CCol md="4" lg="4">
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Day
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleBogoInputChange}
                                    name="dayOfWeek"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={bogoCouponInputData?.dayOfWeek}
                                >
                                    <option value="">Select Day</option>
                                    <option value="0">SUNDAY</option>
                                    <option value="1">MONDAY</option>
                                    <option value="2">TUESDAY</option>
                                    <option value="3">WEDNESDAY</option>
                                    <option value="4">THURSDAY</option>
                                    <option value="5">FRIDAY</option>
                                    <option value="6">SATURDAY</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow> New Change */} 

                    {/* <CRow className='mb-3'>
                        <CCol md="8" lg="8">
                            <CFormLabel className="fw-semibold">
                                Select Included Outlets
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedIncludedOptions}
                                onChange={handleSelectIncChange}
                                options={outletData}
                                styles={customStyles}
                                placeholder="Select Included Outlets"
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                        <CCol md="4" lg="4">
                            <CFormLabel className="fw-semibold">
                                Select Level
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedLevelOptions}
                                onChange={handleSelectLevelChange}
                                options={levels?.map((le) => ({
                                    value: le._id,
                                    label: le.title,
                                }))}
                                styles={customStyles}
                                placeholder="Select Level"
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                    ...theme.colors,
                                    primary25: "#e9ecef",
                                    primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                    </CRow> New Change */}

                    <CRow className='mb-3'>
                        <CCol md={12} lg={12}>
                            <CFormLabel className="fw-semibold">
                                Select Excluded Outlets
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedOptions}
                                onChange={handleSelectChange}
                                options={outletData}
                                styles={customStyles}
                                placeholder="Select Excluded Outlets"
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                        {/* <CCol md={4} lg={4}>
                            <CFormLabel className="fw-semibold">
                                Min. Order Amount
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                placeholder="Min. Order Amount"
                                type="number"
                                className="no-arrows"
                                name="minOrderAmount"
                                value={bogoCouponInputData.minOrderAmount}
                                onChange={bogoCouponInputData}
                            />
                        </CCol> New Change */}
                    </CRow>

                    <div className="mb-3">
                        <CFormLabel className="fw-semibold">
                            Description <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormTextarea
                            rows={2}
                            placeholder="Description"
                            name="description"
                            value={bogoCouponInputData.description}
                            onChange={handleBogoInputChange}
                        />
                    </div>
                    <CRow>
                        {/* Other fields */}
                        <CCol md="12" className="mb-3">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Terms and Conditions <span className="text-danger">*</span>
                                </CFormLabel>
                                <div className="d-flex mb-2">
                                    <CFormInput
                                        placeholder="Add a term"
                                        value={currentTerm}
                                        onChange={(e) => setCurrentTerm(e.target.value)}
                                        className="me-2"
                                    />
                                    <CButton disabled={!currentTerm.trim()} onClick={addTerm}>ADD</CButton>
                                </div>
                                {bogoCouponInputData?.termsAndConditions?.length > 0 && (
                                    <ul className="list-group">
                                        {bogoCouponInputData?.termsAndConditions?.map((term, index) => (
                                            <li
                                                key={index}
                                                className="list-group-item d-flex justify-content-between align-items-center"
                                            >
                                                {term}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-danger rounded-pill"
                                                    onClick={() => removeTerm(index)}
                                                >
                                                    Remove
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton
                        isDisable={bogoBtnDisable()}
                        isLoading={bogoBtnLoading}
                        title="ADD BOGO COUPON"
                    />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default BOGOCoupon
