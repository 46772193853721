import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { CBadge, CButton, CCard, CCardHeader, CCardTitle, CCol, CContainer, CFormLabel, CFormSelect, CModal, CModalBody, CModalHeader, CModalTitle, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { DataTableComponent } from '../../components';
import { CardBody } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import ReactDatePicker from 'react-datepicker';

const AssetStockTransactionReport = () => {

    const accessToken = localStorage.getItem("authToken");
    const [stockReportByStorageLocation, setStockReportByStorageLocation] = useState([]);
    const [assetStockTransactions, setAssetStockTransactions] = useState([]); 
    const [assetStockReport, setAssetStockReport] = useState([]);
    const [assetStockReportOutlet, setAssetStockReportOutlet] = useState([]);
    const [stockReportByOutlet, setStockReportByOutlet] = useState([]);
    const [openAssetStockTransactionModal, setOpenAssetStockTransactionModal] = useState(false);
    const [activeTab, setActiveTab] = useState("ACTIVE");
    const [storageLocationList, setStorageLocationList] = useState([]);
    const [assetsList, setAssetsList] = useState([]);
    const [outletsList, setOutletsList] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [startDateOutlet, setStartDateOutlet] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endDateOutlet, setEndDateOutlet] = useState(null);
    const [selectedStorageLocation, setSelectedStorageLocation] = useState('');
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [filterLoading, setFilterLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const getStockReportByStorageLocation = async() => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("assets-stock-transaction/asset-stock-by-storage-location", { headers });
            setStockReportByStorageLocation(response?.data?.data?.assetsStockTransactionsReport);
            setAssetStockTransactions(response?.data?.data?.assetsStockTransactionsReport[0]?.assetStockTransactions)
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if ( error.response?.status === 406 ) {
                  errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if ( error.response?.status === 412 ) {
                  errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                  errorMessage = error?.response?.data?.error
                }
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        }
    }

    const getAssetStockReportByStorageLocation = async(selectedStorageLocation, startDate, endDate) => {
      try {
        const headers = {
          Authorization : `Bearer ${accessToken}`
        }
        const params = {
          storage_location: selectedStorageLocation,
          startDate: startDate,
          endDate: endDate
        }
        const response = await axios.get('assets-stock-transaction/asset-stock-by-storage-location', { headers, params })
        const combinedData = response?.data?.data?.assetsStockTransactionsReport;     
        const reportData = combinedData.map((data) => data?.assetStockTransactions);        
        setAssetStockReport(combinedData);
      } catch (error) {
        if (error?.response) {
          let errorMessage = '';
          if ( error.response?.status === 406 ) {
            errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
          }
          else if ( error.response?.status === 412 ) {
            errorMessage = error?.response?.data?.data?.message;
          } else if (error.response?.status === 500) {
            errorMessage = error?.response?.data?.error
          }
  
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      }
    }

    const getstockReportByOutlet = async() => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("assets-stock-transaction/asset-stock-by-outlet-wise", { headers });
            setStockReportByOutlet(response?.data?.data?.assetsStockTransactionsReport);
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if ( error.response?.status === 406 ) {
                  errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
                }
                else if ( error.response?.status === 412 ) {
                  errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                  errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            } 
        }
    }

    const getAssetStockReportByOutlet = async(selectedOutlet, startDateOutlet, endDateOutlet) => {
      try {
        const headers = {
          Authorization : `Bearer ${accessToken}`
        }
        const params = {
          storage_location: selectedOutlet,
          startDate: startDateOutlet,
          endDate: endDateOutlet
        }
        const response = await axios.get('assets-stock-transaction/asset-stock-by-outlet-wise', { headers, params })
        const combinedData = response?.data?.data?.assetsStockTransactionsReport;
        const reportData = combinedData.map((data) => data?.assetStockTransactions);        
        setAssetStockReportOutlet(combinedData);
      } catch (error) {
        if (error?.response) {
          let errorMessage = '';
          if ( error.response?.status === 406 ) {
            errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
          }
          else if ( error.response?.status === 412 ) {
            errorMessage = error?.response?.data?.data?.message;
          } else if (error.response?.status === 500) {
            errorMessage = error?.response?.data?.error
          }

          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      }
    }

    const getStorageLocation = async() => {
      try {
          const headers = {
              Authorization : `Bearer ${accessToken}`
          }
          const response = await axios.get('storage-location', { headers })
          setStorageLocationList(response?.data?.data?.storageLocations);
      } catch (error) {
          if (error.response && error.response?.data?.status === false) {
              let errorMessage = error.response.data.error;
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
              });
          }
      }
    }

    const getAssetsData = async() => {
      try {
          const headers = {
              Authorization: `Bearer ${accessToken}`
          }
          const response = await axios.get("asset", { headers });            
          setAssetsList(response?.data?.data?.assets);
      } catch (error) {
          if (error.response && error.response?.data?.status === false) {
              let errorMessage = error.response.data.error;
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
              });
          }
      }
    }

    const getOutletsData = async() => {
      try {
          const headers = {
              Authorization: `Bearer ${accessToken}`
          }
          const response = await axios.get("outlet", { headers });
          setOutletsList(response?.data?.data?.outlets);
      } catch (error) {
          if (error.response && error.response?.data?.status === false) {
              let errorMessage = error.response.data.error;
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
              });
          }
      }
    }

    const handleOpenStorageWiseViewModal = (data) => {
      setOpenAssetStockTransactionModal(true);
    }
    
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    }

    const handleSubmitFilters = async(e) => {
      e.preventDefault();
      setFilterLoading(true);
      // Ensure the time component is removed for start date and end date
      const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
      const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day

      // Log the formatted dates
      const formatDate = (date) => {
        return date.toISOString().split('T')[0]; // Format: '2024-07-31'
      };
      await getAssetStockReportByStorageLocation(selectedStorageLocation, startDate, endDate);
      setFilterLoading(false);
    }

    const handleSubmitOutletFilters = async(e) => {
      e.preventDefault();
      setLoading(true);
      // Ensure the time component is removed for start date and end date
      const adjustedStartDate = new Date(startDateOutlet.setHours(23, 59, 59, 999)); // start of day
      const adjustedEndDate = new Date(endDateOutlet.setHours(23, 59, 59, 999)); // end of day

      // Log the formatted dates
      const formatDate = (date) => {
        return date.toISOString().split('T')[0]; // Format: '2024-07-31'
      };
      await getAssetStockReportByOutlet(selectedOutlet, startDateOutlet, endDateOutlet);
      setLoading(false);
    }
    
    useEffect(() => {
        getStockReportByStorageLocation();
        getstockReportByOutlet();
        getStorageLocation();
        getAssetsData();
        getOutletsData();
    }, []);

    useEffect(() => {
      const now = new Date();
      const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const currentDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      // Set the start date and end date
      setStartDate(firstOfMonth);
      setEndDate(currentDate);
    }, []);

    useEffect(() => {
      const now = new Date();
      const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const currentDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      // Set the start date and end date
      setStartDateOutlet(firstOfMonth);
      setEndDateOutlet(currentDate);
    }, []);

    return (
        <CContainer>    

          {/* <----------- Storage Location and Outlet Tabs ------------>  */}
          <div className="d-flex justify-content-center gap-2 mb-2" style={{ cursor: "pointer" }}>
            <div
              color="none"
              className={`w-50 ${activeTab === "ACTIVE" ? "text-secondary" : "text-dark"
                } text-center p-2 fw-semibold`}
              onClick={() => handleTabChange("ACTIVE")}
              style={{
                borderBottom:
                  activeTab === "ACTIVE"
                    ? "2px solid #bcbcbd"
                    : "1px solid #bcbcbd",
                transition: "border-bottom 0.3s ease-in-out",
                background: activeTab === "ACTIVE" ? "#e7e9eb" : "none",
                border: "1px solid #bcbcbd",
                borderRadius: "5px",
              }}
            >
              STORAGE LOCATION WISE
            </div>
            <div
              color="none"
              className={`w-50 ${activeTab === "INACTIVE" ? "text-secondary" : "text-dark"
                } text-center p-2 fw-semibold`}
              onClick={() => handleTabChange("INACTIVE")}
              style={{
                borderBottom:
                  activeTab === "INACTIVE"
                    ? "2px solid #bcbcbd"
                    : "1px solid #bcbcbd",
                transition: "border-bottom 0.3s ease-in-out",
                background: activeTab === "INACTIVE" ? "#e7e9eb" : "none",
                border: "1px solid #bcbcbd",
                borderRadius: "5px",
              }}
            >
              OUTLET WISE
            </div>
          </div>    
          
          {/* <---------- Storage Location Wise Report Section ---------> */}
          { activeTab === 'ACTIVE' && (
            <>
              {/* <----- Storage Location Wise Asset Stock Report List -----> */}
              <CRow>
                <h5 className="mt-4">Asset Stock Report</h5>
              </CRow>
              <CRow className="mt-2">
                <CCol>
                  <CTable striped bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>Sr.No</CTableHeaderCell>
                        <CTableHeaderCell>Storage Location</CTableHeaderCell>
                        <CTableHeaderCell>Asset Name</CTableHeaderCell>
                        <CTableHeaderCell>Asset Type</CTableHeaderCell>
                        <CTableHeaderCell>Model</CTableHeaderCell>
                        <CTableHeaderCell>Current Stock </CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {stockReportByStorageLocation?.length > 0 ? (
                        stockReportByStorageLocation?.map((assetStock, index) => (
                          <CTableRow key={assetStock.id}>
                            <CTableDataCell>{index + 1}</CTableDataCell>
                            <CTableDataCell>{assetStock?.storageLocation}</CTableDataCell>
                            <CTableDataCell>{assetStock?.asset?.name}</CTableDataCell>
                            <CTableDataCell>{assetStock?.assetType}</CTableDataCell>
                            <CTableDataCell>{assetStock?.asset?.model}</CTableDataCell>
                            <CTableDataCell className='text-end'>{assetStock?.currentStock}</CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CBadge className="w-100" color={assetStock?.asset?.status === 'ACTIVE' ? 'success' : 'danger'}>{assetStock?.asset?.status}</CBadge>
                            </CTableDataCell>
                          </CTableRow>
                        ))) : (
                        <CTableRow>
                          <CTableDataCell colSpan="8" className="text-center text-danger fw-5">
                            No Products Found
                          </CTableDataCell>
                        </CTableRow>
                      )
                      }
                    </CTableBody>
                  </CTable>
                </CCol>
              </CRow>

              <hr />

              {/* <----- Storage Location Asset Stock Transaction Report List -----> */}
              <CRow>
                <h5 className="mt-1">Asset Stock Transaction Report</h5>
              </CRow>

              {/* <---------- Storage Location Filters ---------> */}
              <CRow className="mt-1">
                <CCol md={3}>
                  <CFormLabel className='fw-semibold'>Select Storage Location</CFormLabel>
                  <CFormSelect
                    id="selectField"
                    name="storage_location"
                    value={selectedStorageLocation}
                    onChange={(e) => setSelectedStorageLocation(e.target.value)}
                  >
                    <option value="">All Storage Locations</option>
                    { storageLocationList.map((storage, index) => {
                      return (
                        <option key={index} value={storage?._id}>
                          {storage?.name}
                        </option>
                      );
                    })}
                  </CFormSelect>
                </CCol>
                <CCol className="col-md-3" md={3}>
                  <CFormLabel className='fw-semibold'>Select Start Date</CFormLabel>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Select Start Date"
                      className="form-control"
                    />
                  </div>
                </CCol>
                <CCol className="col-md-3" md={3}>
                  <CFormLabel className='fw-semibold'>Select End Date</CFormLabel>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      // onChange={handleEndDateChange}
                      placeholderText="Select End Date"
                      className="form-control"
                    />
                    {/* {error && <div className="text-danger" style={{ fontSize: '14px' }}>{error}</div>} */}
                  </div>
                </CCol>

                <CCol xs={12} md={3}>
                  <CFormLabel className='text-white'>.</CFormLabel>
                  <CButton color="primary" className="w-100" onClick={handleSubmitFilters}> 
                    {filterLoading ? <CSpinner size="sm" /> : 'Submit'}
                  </CButton>
                </CCol>
              </CRow>

              
              <CRow className="mt-2">
                <CCol>
                  <CTable striped bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>Sr.No</CTableHeaderCell>
                        <CTableHeaderCell>Asset Name</CTableHeaderCell>
                        <CTableHeaderCell>Financial Year</CTableHeaderCell>
                        <CTableHeaderCell>Credited Stock</CTableHeaderCell>
                        <CTableHeaderCell>Debited Stock</CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Storage Location</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {assetStockReport?.length > 0 ? (
                        assetStockReport?.map((report, index) => {
                          // Calculate credited and debited stock
                          let creditedStock = 0;
                          let debitedStock = 0;

                          report?.assetStockTransactions?.forEach((transaction) => {
                            if (transaction.transaction_type === "CREDIT") {
                              creditedStock += transaction.quantity;
                            } else if (transaction.transaction_type === "DEBIT") {
                              debitedStock += transaction.quantity;
                            }
                          });
                          return(
                            <CTableRow key={report.id}>
                              <CTableDataCell>{index + 1}</CTableDataCell>
                              <CTableDataCell>{report?.asset?.name}</CTableDataCell>
                              <CTableDataCell>{report?.assetStockTransactions[0]?.fin_year}</CTableDataCell>
                              <CTableDataCell>{creditedStock}</CTableDataCell>
                              <CTableDataCell>{debitedStock}</CTableDataCell>
                              <CTableDataCell className="text-center">
                                <CBadge className="w-100" color={report?.asset?.status === 'ACTIVE' ? 'success' : 'danger'}>{report?.asset?.status}</CBadge>
                              </CTableDataCell>
                              <CTableDataCell>{report?.storageLocation}</CTableDataCell>
                            </CTableRow>
                          ) 
                        }
                      )) : (
                        <CTableRow>
                          <CTableDataCell colSpan="8" className="text-center text-danger fw-5">
                            Select Filters To Check Reports
                          </CTableDataCell>
                        </CTableRow>
                      )
                      }
                    </CTableBody>
                  </CTable>
                </CCol>
              </CRow>
            </>
          )}

          {/* <---------- Outlet Wise Report Section ---------> */}
          { activeTab === 'INACTIVE' && (
            <>
              {/* <----- Outlet Wise Asset Stock Report List -----> */}
              <CRow>
                <h5 className="mt-4">Asset Stock Report</h5>
              </CRow>
              <CRow className="mt-2">
                <CCol>
                  <CTable striped bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>Sr.No</CTableHeaderCell>
                        <CTableHeaderCell>Outlet</CTableHeaderCell>
                        <CTableHeaderCell>Asset Name</CTableHeaderCell>
                        <CTableHeaderCell>Asset Type</CTableHeaderCell>
                        <CTableHeaderCell>Model</CTableHeaderCell>
                        <CTableHeaderCell>Current Stock </CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {stockReportByOutlet?.length > 0 ? (
                        stockReportByOutlet?.map((assetStock, index) => (
                          <CTableRow key={assetStock.id}>
                            <CTableDataCell>{index + 1}</CTableDataCell>
                            <CTableDataCell>{assetStock?.outletDetails}</CTableDataCell>
                            <CTableDataCell>{assetStock?.asset?.name}</CTableDataCell>
                            <CTableDataCell>{assetStock?.assetType}</CTableDataCell>
                            <CTableDataCell>{assetStock?.asset?.model}</CTableDataCell>
                            <CTableDataCell className='text-end'>{assetStock?.currentStock}</CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CBadge className="w-100" color={assetStock?.asset?.status === 'ACTIVE' ? 'success' : 'danger'}>{assetStock?.asset?.status}</CBadge>
                            </CTableDataCell>
                          </CTableRow>
                        ))) : (
                        <CTableRow>
                          <CTableDataCell colSpan="8" className="text-center text-danger fw-5">
                            No Products Found
                          </CTableDataCell>
                        </CTableRow>
                      )
                      }
                    </CTableBody>
                  </CTable>
                </CCol>
              </CRow>

              <hr />

              {/* <----- Outlet Wise Asset Stock Transaction Report List -----> */}
              <CRow>
                <h5 className="mt-1">Asset Stock Transaction Report</h5>
              </CRow>

              {/* <---------- Outlet Filters ---------> */}
              <CRow className="mt-2">
                <CCol md={3}>
                  <CFormSelect
                    id="selectField"
                    name="outlet"
                    value={selectedOutlet}
                    onChange={(e) => setSelectedOutlet(e.target.value)}
                  >
                    <option value="">Select Outlet</option>
                    {outletsList.map((outName, index) => {
                      return (
                        <option key={index} value={outName?._id}>
                          {outName?.name}
                        </option>
                      );
                    })}
                  </CFormSelect>
                </CCol>
                <CCol className="col-md-3" md={3}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ReactDatePicker
                      selected={startDateOutlet}
                      onChange={(date) => setStartDateOutlet(date)}
                      placeholderText="Select Start Date"
                      className="form-control"
                    />
                  </div>
                </CCol>
                <CCol className="col-md-3" md={3}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ReactDatePicker
                      selected={endDateOutlet}
                      onChange={(date) => setEndDateOutlet(date)}
                      // onChange={handleEndDateChange}
                      placeholderText="Select End Date"
                      className="form-control"
                    />
                  </div>
                </CCol>
                <CCol xs={12} md={3}>
                  <CButton color="primary" className="w-100" onClick={handleSubmitOutletFilters}>
                    {loading ? <CSpinner size="sm" /> : 'Submit'}
                  </CButton>
                </CCol>
              </CRow>

              
              <CRow className="mt-2">
                <CCol>
                  <CTable striped bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>Sr.No</CTableHeaderCell>
                        <CTableHeaderCell>Asset Name</CTableHeaderCell>
                        <CTableHeaderCell>Financial Year</CTableHeaderCell>
                        <CTableHeaderCell>Credited Stock</CTableHeaderCell>
                        <CTableHeaderCell>Debited Stock</CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Outlet</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {assetStockReportOutlet?.length > 0 ? (
                        assetStockReportOutlet?.map((report, index) => {
                          // Calculate credited and debited stock
                          let creditedStock = 0;
                          let debitedStock = 0;

                          report?.assetStockTransactions?.forEach((transaction) => {
                            if (transaction.transaction_type === "CREDIT") {
                              creditedStock += transaction.quantity;
                            } else if (transaction.transaction_type === "DEBIT") {
                              debitedStock += transaction.quantity;
                            }
                          });
                          return(
                          <CTableRow key={report.id}>
                            <CTableDataCell>{index + 1}</CTableDataCell>
                            <CTableDataCell>{report?.asset?.name}</CTableDataCell>
                            <CTableDataCell>{report?.assetStockTransactions[0]?.fin_year}</CTableDataCell>
                            <CTableDataCell>{creditedStock}</CTableDataCell>
                            <CTableDataCell>{debitedStock}</CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CBadge className="w-100" color={report?.asset?.status === 'ACTIVE' ? 'success' : 'danger'}>{report?.asset?.status}</CBadge>
                            </CTableDataCell>
                            <CTableDataCell>{report?.outletDetails}</CTableDataCell>
                          </CTableRow>
                        ) }
                      )) : (
                        <CTableRow>
                          <CTableDataCell colSpan="8" className="text-center text-danger fw-5">
                            Select Filters To Check Reports
                          </CTableDataCell>
                        </CTableRow>
                      )
                      }
                    </CTableBody>
                  </CTable>
                </CCol>
              </CRow>
            </>
          )}

          {openAssetStockTransactionModal ? (
            <CModal 
              alignment="center"
              size='xl'
              visible={openAssetStockTransactionModal}
              onClose={() => setOpenAssetStockTransactionModal(false)}
              aria-labelledby="VerticallyCenteredExample"
            >
              <CModalHeader>
                <CModalTitle>View Detailed Information</CModalTitle>
              </CModalHeader>
              <CModalBody>
                <CRow className="mt-3">
                  <CCol>
                    <CTable striped bordered hover responsive>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Sr.No</CTableHeaderCell>
                          <CTableHeaderCell>Asset Name</CTableHeaderCell>
                          <CTableHeaderCell>Financial Year</CTableHeaderCell>
                          <CTableHeaderCell>Quantity</CTableHeaderCell>
                          <CTableHeaderCell>Transaction Type</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Storage Location</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {assetStockTransactions?.length > 0 ? (
                          assetStockTransactions?.map((report, index) => (
                            <CTableRow key={report.id}>
                              <CTableDataCell>{index + 1}</CTableDataCell>
                              <CTableDataCell>{report?.assetDetails?.name}</CTableDataCell>
                              <CTableDataCell>{report?.fin_year}</CTableDataCell>
                              <CTableDataCell>{report?.quantity}</CTableDataCell>
                              <CTableDataCell>{report?.transaction_type}</CTableDataCell>
                              <CTableDataCell className="text-center">
                                <CBadge className="w-100" color={report?.assetDetails?.status === 'ACTIVE' ? 'success' : 'danger'}>{report?.assetDetails?.status}</CBadge>
                              </CTableDataCell>
                              <CTableDataCell>{report?.storageLocationDetails?.name}</CTableDataCell>
                            </CTableRow>
                          ))) : (
                          <CTableRow>
                            <CTableDataCell colSpan="8" className="text-center text-danger fw-5">
                              No Products Found
                            </CTableDataCell>
                          </CTableRow>
                        )
                        }
                      </CTableBody>
                    </CTable>
                  </CCol>
                </CRow>
              </CModalBody>
            </CModal>
          ) : null }

        </CContainer> 
    )
}

export default AssetStockTransactionReport