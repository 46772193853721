import { CBadge, CButton, CCol, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CPagination, CPaginationItem, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import ReactDatePicker from "react-datepicker";
import React, { useEffect, useState, useRef } from 'react';
import axios from '../../api/axios';
import { toast } from 'react-toastify';

const CustomerReport = () => {

  const accessToken = localStorage.getItem('authToken');
  const [customerData, setCustomerData] = useState([]);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [FilteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState('50');
  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState('Recent Users');
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [levels, setLevels] = useState([]);
  const [levelId, setLevelId] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [emptyData, setEmptyData] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState('');
  const [outlets, setOutlets] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState('');

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        console.log("outlets :", response.data.data);
        const activeOutlets = response?.data?.data?.outlets.filter((active) => active?.status === "ACTIVE");
        setOutlets(activeOutlets);
        // setOutlets(response?.data?.data?.outlets);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const fetchLevelsData = async () => {
    try {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.get("level", { headers });
        if (response?.data?.status === true) {
            const levels = response?.data?.data?.levels;
            console.log("Levels :", levels);
            
            setLevels(levels);
        }
    } catch (error) {
        if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response.data.error;
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        }
    }
  };

  const handlePageChange = (page) => {
    console.log(" page : ", page);
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
    getCustomerData(selectedAccount, page, searchText, levelId, sortOrder, recordsPerPage, startDate, endDate, selectedOutlet);
  };

  const handleRecordsPerPageChange = (value) => {
    
    const newRecordsPerPage = parseInt(value, 10);    
    setRecordsPerPage(value);
    // setCurrentPage(1); // Reset to the first page
    getCustomerData(selectedAccount, currentPage, searchText, levelId, sortOrder, value, startDate, endDate, selectedOutlet);
  };

  const getCustomerData = async(account, page, search, level, sort, limit, start, end, outlet) => {
    setLoading(true);
    
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`
      }      

      let url = `user/getUsersWithOrders`;

      // Check if the 'recent users' filter is selected
      const isRecentUsersSelected = account === "Recent Users";

      // Check if search filter is applied
      if (!search) {
        // If 'Recent Users' is selected, apply date filters
        if (isRecentUsersSelected) {
          url += `?page=${page}&limit=${limit}&fromDate=${start?.toISOString().split("T")[0]}&toDate=${end?.toISOString().split("T")[0]}`;
        } else {
          // If not 'Recent Users', no date filters
          url += `?page=${page}&limit=${limit}`;
        }
      } else {
        // Apply search query
        url += `?search=${search}`;
      }

      // Add account filter based on selection
      if (account !== "" && account !== "Recent Users") {
        url += search ? `&filter=${account}` : `&filter=${account}`;
      }

      // Add level filter if provided
      if (level !== "") {
        url += `&levelId=${level}`;
      }

      // Add sort order filter if provided
      if (sort !== "") {
        url += `&sortOrder=${sort}`;
      }

      // Add outlet filter if provided
      if (outlet) {
        url += `&outletId=${outlet}`;
      }

      setEmptyData(false);
      const response = await axios.get(url, { headers });
      console.log("response :", response?.data?.data);
      setCustomerData(response?.data?.data?.usersWithOrders);
      setFilteredData(response?.data?.data?.usersWithOrders);
      setTotalPages(response?.data?.data?.pagination?.total_pages);
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if ( error.response?.status === 406 ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        } else if ( error.response?.status === 404  ) {
          errorMessage = error?.response?.data?.data?.message;
        }
        else if ( error.response?.status === 412 ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        setEmptyData(true);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setFilteredData([]);
      }
    } finally {
      setLoading(false);
    }
  }

  const viewOrderDetails = (data) => {
    console.log("Customer Deatils :", data);
    
    setViewDetailsModal(true);
    setSelectedCustomer(data);
  }

  const closeModal = () => {
    setViewDetailsModal(false);
    setSelectedCustomer(null);
  }

  // const handleSearchCustomers = () => {
  //   const trimmedSearchText = searchText.trim().toLowerCase(); 
  //   if (trimmedSearchText) {
  //     const filtered = customerData.filter(order => {
  //       const userName = order?.name?.toLowerCase() || '';  // Ensure name is a string and convert to lowercase
  //       const userEmail = order?.email?.toLowerCase() || '';  // Ensure email is a string and convert to lowercase
  //       const userMobile = order?.mobile || '';  // Mobile doesn't need lowercase as it's usually numeric
  
  //       // Combine name, email, and mobile into a single string for easy searching
  //       const combinedSearchString = `${userName} ${userEmail} ${userMobile}`;
  
  //       // Check if the combined string includes the trimmed search text
  //       return combinedSearchString.includes(trimmedSearchText);
  //     });
  
  //     setFilteredData(filtered);
  //   } else {
  //     setFilteredData(customerData); // Reset to all data if search text is empty
  //   }
  // };

  const handlePrintReport = () => {
    const tableContent = `
      <table style="border-collapse: collapse; width: 100%;">
        <thead>
          <tr>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Joined On</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Customer Name</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Email & Mob.no</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Outlet</th>
            <th style="border: 1px solid black; padding: 8px; text-align: center;">Total Orders Placed</th>
            <th style="border: 1px solid black; padding: 8px; text-align: center;">Total Orders Cancelled</th>
            <th style="border: 1px solid black; padding: 8px; text-align: center;">Total Vists</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left; width: 70px">Total Spendings</th>
          </tr>
        </thead>
        <tbody>
          ${customerData?.length > 0 ? 
            customerData?.map((customerInfo, index) => `
              <tr>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px">${(currentPage - 1) * recordsPerPage + index + 1}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; word-break: break-word; width: 140px">${customerInfo?.createdAt}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; word-break: break-word; width: 140px">${customerInfo?.name}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; word-break: break-word; width: 190px"><span>${customerInfo?.email}</span><br /><span>${customerInfo?.mobile}</span></td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; word-break: break-word; width: 170px">${customerInfo?.firstOrder?.outletName}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; text-align: center">${customerInfo?.orderCount}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; text-align: center">${customerInfo?.cancelledOrderCount}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; text-align: center">${customerInfo?.numberOfVisits}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; text-align: right">₹. ${customerInfo?.totalGrandTotal?.toFixed(2)}</td>
              </tr>
            `).join("") :
            `<tr>
              <td colspan="10" style="border: 1px solid black; padding: 8px; text-align: center;">No Customers Found</td>
            </tr>`
          }
        </tbody>
      </table>
    `;

    const win = window.open("", "", "width=900,height=600");
    win.document.write(`
      <html>
        <head>
          <title>Customers Report</title>
          <style>
            table { border-collapse: collapse; width: 100%; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body onload="window.print();window.close()">
          <h2>Customers Report</h2>
          <div>${tableContent}</div>
        </body>
      </html>
    `);
    win.document.close();
  }

  const handlePrintReportWithId = () => {
    const tableContent = `
      <table style="border-collapse: collapse; width: 100%;">
        <thead>
          <tr>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Joined On</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Customer Name</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Email</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Mobile</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">ID</th>
            <th style="border: 1px solid black; padding: 8px; text-align: center;">Total Orders Placed</th>
          </tr>
        </thead>
        <tbody>
          ${customerData?.length > 0 ? 
            customerData?.map((customerInfo, index) => `
              <tr>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px">${(currentPage - 1) * recordsPerPage + index + 1}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; word-break: break-word; width: 140px">${customerInfo?.createdAt}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; word-break: break-word; width: 140px">${customerInfo?.name}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; word-break: break-word; width: 180px">${customerInfo?.email}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; word-break: break-word; width: 180px">${customerInfo?.mobile}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px;">${customerInfo?._id}</td>
                <td style="border: 1px solid black; padding: 8px; font-size: 13px; text-align: center">${customerInfo?.orderCount}</td>
              </tr>
            `).join("") :
            `<tr>
              <td colspan="7" style="border: 1px solid black; padding: 8px; text-align: center;">No Customers Found</td>
            </tr>`
          }
        </tbody>
      </table>
    `;

    const win = window.open("", "", "width=900,height=600");
    win.document.write(`
      <html>
        <head>
          <title>Customers Report</title>
          <style>
            table { border-collapse: collapse; width: 100%; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body onload="window.print();window.close()">
          <h2>Customers Report</h2>
          <div>${tableContent}</div>
        </body>
      </html>
    `);
    win.document.close();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitBtnLoading(true);

    // Reset currentPage to 1 when the date or outlet changes
    setCurrentPage(1);

    const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // end of day
    const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day

    await getCustomerData(selectedAccount, 1, searchText, levelId, sortOrder, recordsPerPage, adjustedStartDate, adjustedEndDate, selectedOutlet);

    if (emptyData === false) {
      toast.success("Data Fetched Successfully", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
    
    setSubmitBtnLoading(false);
  }

  const handleEndDateChange = (date) => {
    if (date && startDate && date < startDate) {
      setError('End date cannot be earlier than start date');
    } else {
      setError('');
      setEndDate(date);
    }
  };

  useEffect(() => {
    const now = new Date();
    const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const currentDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );

    const start = new Date(firstOfMonth.setHours(23, 59, 59, 999)); // start of day
    const end = new Date(currentDate.setHours(23, 59, 59, 999)); // end of day
    // Set the start date and end date
    setStartDate(start);
    setEndDate(end);
    // getProductData(start, end, selectedOutlet, selectedCategory);    
  }, []);

  useEffect(() => {
    fetchLevelsData();
    getOutlets();
  }, []);  

  return (
    <div className="container">
        <h5>CUSTOMER REPORTS</h5>

        <CRow className="mt-3">
          <CCol md="4" lg="4">
            <CFormLabel className="fw-semibold">Select Outlet <span className="text-danger">*</span></CFormLabel>
            <CFormSelect
                id="selectField"
                name="selectField"
                value={selectedOutlet}
                onChange={(e) => setSelectedOutlet(e.target.value)}
            >
                <option value="">All Outlets</option>

                {outlets.map((outName, index) => {
                return (
                    <option key={index} value={outName?._id}>
                    {outName?.name}
                    </option>
                );
                })}
            </CFormSelect>
          </CCol>
          <CCol md={3}>
            <CFormLabel className='fw-semibold'>Start Date <span className="text-danger">*</span></CFormLabel>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <ReactDatePicker
                  selected={startDate}
                  dateFormat="dd-MM-yyyy"  // Set the date format here
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Select Start Date"
                  className="form-control"
                  disabled={levelId !== "" || selectedAccount !== "Recent Users"}
                />
            </div>
          </CCol>
          <CCol md={3}>
              <CFormLabel className='fw-semibold'>End Date <span className="text-danger">*</span></CFormLabel>
              <div style={{ display: "flex", flexDirection: "column" }}>
                  <ReactDatePicker
                    selected={endDate}
                    // onChange={(date) => setEndDate(date)}
                    dateFormat="dd-MM-yyyy"  // Set the date format here
                    onChange={handleEndDateChange}
                    placeholderText="Select End Date"
                    className="form-control"
                    disabled={levelId !== "" || selectedAccount !== "Recent Users"}
                  />
                  {/* { error && <div className="text-danger" style={{ fontSize: '14px' }}>{error}</div> } */}
              </div>
          </CCol>

          <CCol md="2" lg="2">
            <CFormLabel className='fw-semibold'>Filter By Level:</CFormLabel>
            <CFormSelect onChange={(e) => setLevelId(e.target.value)} value={levelId}>
                <option value="">ALL</option>
                {levels.map((level) => <option value={level?._id}>{level.title}</option>)}
            </CFormSelect>
          </CCol>

          
          </CRow>

          <CRow className='mt-2'>
            <CCol md="4" lg="4">
              <CFormLabel className='fw-semibold'>Select Sort By</CFormLabel>
              <CFormSelect id='selectField' value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                <option value="">Descending Order</option>
                <option value="asc">Ascending Order</option>
              </CFormSelect>
            </CCol>
          
            <CCol md="4" lg="4">
              <CFormLabel className='fw-semibold'>Select Accounts</CFormLabel>
              <CFormSelect id='selectField' value={selectedAccount} onChange={(e) => setSelectedAccount(e.target.value)}>
                <option value="Recent Users">Recent Users</option>
                <option value="DELETED">Deleted Accounts</option> 
                <option value="ONE-TIME">One Timers</option>
                <option value="LOYAL">Loyal Customers</option>
                <option value="NO_ORDER_USER">Not Ordered</option>
              </CFormSelect>
            </CCol>
            <CCol md="4" lg="4">
              <div className='d-fex' style={{ flexDirection: 'column' }}>
                <CFormLabel color="primary" className="text-white" >.</CFormLabel>
                <CButton className='w-100 rounded-pill' onClick={handleSubmit} disabled={submitBtnLoading}>
                  { submitBtnLoading ? <CSpinner size='sm' /> : "Submit" }
                </CButton>
              </div>
            </CCol>
        </CRow>

        <CRow className='mt-2'>
          <CCol md="4" lg="4">
            <CFormLabel className='fw-semibold'>Search</CFormLabel>
            <CFormInput
              type="text"
              placeholder="Search By Name or Mobile"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </CCol>
          <CCol md="2" lg="2">
            <div className='d-fex' style={{ flexDirection: 'column' }}>
              <CFormLabel color="primary" className="text-white" >.</CFormLabel>
              <CButton className='w-100 rounded-pill' onClick={handleSubmit} disabled={submitBtnLoading}>
                { submitBtnLoading ? <CSpinner size='sm' /> : "Search" }
              </CButton>
            </div>
          </CCol>
          <CCol md="3" lg="3" className="text-center">
            <CFormLabel color="primary" className="text-white" >.</CFormLabel>
            <CButton className="btn rounded-pill w-100 bg-success border-success" onClick={handlePrintReport}>
              Print Report For Page no. {currentPage}
            </CButton>
          </CCol>
          <CCol md="3" lg="3" className="text-center">
            <CFormLabel color="primary" className="text-white" >.</CFormLabel>
            <CButton className="btn rounded-pill w-100 bg-warning border-warning" onClick={handlePrintReportWithId}>
              Print Report With ID For Pg no. {currentPage}
            </CButton>
          </CCol>
        </CRow>

        <CRow className="mt-1">
          { FilteredData?.length === 0 ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <h5 className="text-danger">
                Please Select An Outlet And Click On Submit To View Customer Data
              </h5>
            </div>
          ) : (

          <CCol>
            <CRow className='mt-2'>
                {/* Pagination */}
                <CPagination className="mt-1 justify-content-end" style={{ cursor: 'pointer' }}>
                  <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                    <CFormSelect
                      className="ml-3"
                      style={{ width: 'auto' }}
                      value={recordsPerPage}
                      onChange={(e) => handleRecordsPerPageChange(e.target.value)}
                      // onChange={(e) => setRecordsPerPage(e.target.value)}
                    >
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={300}>300</option>
                      <option value={400}>400</option>
                      <option value={500}>500</option>
                      <option value={1000}>1200</option>
                    </CFormSelect>
                  </div>
                  <CPaginationItem
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </CPaginationItem>
                  {totalPages > 15 ? (
                      <>
                        {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                        {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                        {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                          const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;

                          // Avoid rendering out-of-range page numbers
                          if (pageNumber <= totalPages) {
                            return (
                              <CPaginationItem
                                key={pageNumber}
                                active={pageNumber === currentPage}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber}
                              </CPaginationItem>
                            );
                          }
                          return null;
                        })}

                        {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                        {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                      </>
                    ) : (
                      // If totalPages is 15 or less, just display all page numbers
                      Array.from({ length: totalPages }, (_, i) => (
                        <CPaginationItem
                          key={i + 1}
                          active={i + 1 === currentPage}
                          onClick={() => handlePageChange(i + 1)}
                        >
                          {i + 1}
                        </CPaginationItem>
                      ))
                    )
                  }
                  <CPaginationItem
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </CPaginationItem>
                </CPagination>
            </CRow>

            <CTable striped bordered hover responsive>
              <CTableHead>
                <CTableRow style={{ fontSize: '15px' }}>
                  <CTableHeaderCell>#</CTableHeaderCell>
                  <CTableHeaderCell>Joined on</CTableHeaderCell>
                  <CTableHeaderCell>Customer Name</CTableHeaderCell>
                  <CTableHeaderCell>Email & Mob.no</CTableHeaderCell>
                  <CTableHeaderCell>Outlet</CTableHeaderCell>
                  <CTableHeaderCell>Tot Orders Placed</CTableHeaderCell>
                  <CTableHeaderCell>Tot Orders Cancelled</CTableHeaderCell>
                  <CTableHeaderCell>Tot Visits</CTableHeaderCell>
                  <CTableHeaderCell>Tot Spendings</CTableHeaderCell>
                  <CTableHeaderCell>Actions</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {loading ? (
                  <CTableRow>
                    <CTableDataCell colSpan="10" className="text-center">
                      <CSpinner color="primary" size="lg" /> {/* Display spinner */}
                    </CTableDataCell>
                  </CTableRow>
                ) :
                FilteredData?.length > 0 ? (
                  <>
                    {FilteredData?.map((customerData, index) => (
                      <CTableRow key={index} style={{ fontSize: '15px' }}>
                        <CTableDataCell>{(currentPage - 1) * recordsPerPage + index + 1}</CTableDataCell>
                        <CTableDataCell>{customerData?.createdAt}</CTableDataCell>
                        <CTableDataCell style={{width: '150px', wordBreak: "break-word" }}>{customerData?.name}</CTableDataCell>
                        <CTableDataCell style={{width: '19%', wordBreak: "break-word" }}>
                          <span className='text-primary'>{customerData?.email}</span> <br />
                          <span className='text-muted'>{customerData?.mobile}</span>
                        </CTableDataCell>
                        <CTableDataCell >{customerData?.firstOrder ? customerData?.firstOrder?.outletName : '---------'}</CTableDataCell>
                        <CTableDataCell className="text-center">{customerData?.orderCount}</CTableDataCell>
                        <CTableDataCell className="text-center">{customerData?.cancelledOrderCount}</CTableDataCell>
                        <CTableDataCell className='text-center'>{customerData?.numberOfVisits}</CTableDataCell>
                        <CTableDataCell className='text-end'>
                          ₹ {customerData?.totalGrandTotal?.toFixed(2)}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <CButton
                            color="info"
                            size="sm"
                            onClick={() => viewOrderDetails(customerData)}
                          >
                            View Details
                          </CButton>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </>
                ) : (
                  <CTableRow>
                    <CTableDataCell colSpan="10" className="text-center text-danger fw-5">
                      No Customers Found
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>

            <CRow>
                {/* Pagination */}
                <CPagination className="mt-1 justify-content-end" style={{ cursor: 'pointer' }}>
                  <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                    <CFormSelect
                      className="ml-3"
                      style={{ width: 'auto' }}
                      value={recordsPerPage}
                      onChange={(e) => handleRecordsPerPageChange(e.target.value)}
                      // onChange={(e) => setRecordsPerPage(e.target.value)}
                    >
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={300}>300</option>
                      <option value={400}>400</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </CFormSelect>
                  </div>
                  <CPaginationItem
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </CPaginationItem>
                  {totalPages > 15 ? (
                      <>
                        {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                        {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                        {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                          const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;

                          // Avoid rendering out-of-range page numbers
                          if (pageNumber <= totalPages) {
                            return (
                              <CPaginationItem
                                key={pageNumber}
                                active={pageNumber === currentPage}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber}
                              </CPaginationItem>
                            );
                          }
                          return null;
                        })}

                        {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                        {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                      </>
                    ) : (
                      // If totalPages is 15 or less, just display all page numbers
                      Array.from({ length: totalPages }, (_, i) => (
                        <CPaginationItem
                          key={i + 1}
                          active={i + 1 === currentPage}
                          onClick={() => handlePageChange(i + 1)}
                        >
                          {i + 1}
                        </CPaginationItem>
                      ))
                    )
                  }
                  <CPaginationItem
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </CPaginationItem>
                </CPagination>
            </CRow>
          </CCol>
          )  }
        </CRow>

        {/* <---------- Modal for viewing order details ----------> */}
        <CModal
          visible={viewDetailsModal}
          onClose={closeModal}
          className="modal-xl"
        >
          <CModalHeader onClose={closeModal}>
            <CModalTitle className="text-center w-100">CUSTOMER ORDER DETAILS</CModalTitle>
          </CModalHeader>
          <CModalBody>
            {selectedCustomer ? (
              <div className="p-3">
                <CRow className="mb-2">
                  <CCol xs={12}>
                    <h6 className='fw-semibold'>Ordered Items:</h6>
                    <CTable striped bordered hover responsive>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>#</CTableHeaderCell>
                          <CTableHeaderCell>Date</CTableHeaderCell>
                          <CTableHeaderCell>Outlet</CTableHeaderCell>
                          <CTableHeaderCell>Order No</CTableHeaderCell>
                          <CTableHeaderCell>Order Status</CTableHeaderCell>
                          <CTableHeaderCell>Coupon Applied</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Total Payment</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                          {selectedCustomer?.orders?.map((item, index) => (
                              <CTableRow key={item._id}>
                                <CTableDataCell>{index + 1}</CTableDataCell>
                                <CTableDataCell>{item?.order_date}</CTableDataCell>
                                <CTableDataCell>{item?.outletName}</CTableDataCell>
                                <CTableDataCell>{item?.order_sequence}{item?.order_no}</CTableDataCell>
                                {/* <CTableDataCell>
                                  {new Date(item?.createdAt).toLocaleDateString('en-US', {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                  })}
                                </CTableDataCell> */}
                                {/* <CTableDataCell>
                                  {new Date(item?.createdAt).toLocaleTimeString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                    hour12: true,
                                  })}
                                </CTableDataCell> */}
                                <CTableDataCell className="text-center">
                                  <CBadge
                                    className="w-75"
                                    size="sm"
                                    color={
                                      item?.orderStatus === "COMPLETED"
                                        ? "success"
                                        : item?.orderStatus === "DELIVERED"
                                        ? "primary"
                                        : item?.orderStatus === "CANCELLED"
                                        ? "danger"
                                        : "warning"
                                    }
                                  >
                                    {item?.orderStatus}
                                  </CBadge>
                                </CTableDataCell>
                                <CTableDataCell className='fw-semibold' style={{ color: item?.couponDetails ? 'green' : 'brown' }}>{item?.couponDetails ? item?.couponDetails : "No"}</CTableDataCell>
                                <CTableDataCell className="text-end">₹ {item?.grandTotal?.toFixed(2)}</CTableDataCell>
                              </CTableRow>
                          ))}
                          {/* Grand Total Row */}
                          { selectedCustomer?.totalGrandTotal !== 0 ? (
                              <CTableRow>
                                <CTableDataCell colSpan="6" className="text-end fw-bold">
                                  Grand Total:
                                </CTableDataCell>
                                <CTableDataCell className="text-end fw-bold">
                                  ₹ {selectedCustomer?.totalGrandTotal?.toFixed(2)}
                                </CTableDataCell>
                              </CTableRow>
                            ) : (
                              <CTableRow>
                                <CTableDataCell colSpan="8" className="text-center fw-semibold text-danger">
                                  No Orders Found
                                </CTableDataCell>
                              </CTableRow>
                            )
                          }
                      </CTableBody>
                    </CTable>
                  </CCol>
                </CRow>
              </div>
            ) : (
              <p>No Customer Details Available.</p>
            )}
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={closeModal}>
              Close
            </CButton>
          </CModalFooter>
        </CModal>
    </div>
  )
}

export default CustomerReport;