import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";
import { PrimaryButton } from "../../components";
import Select from 'react-select';
import makeAnimated from "react-select/animated";

const StockTransfer = () => {
  const accessToken = localStorage.getItem("authToken");
  const animatedComponents = makeAnimated();
  const [outlets, setOutlets] = useState([]);
  const [rawMaterialList, setRawMaterialList] = useState([]);
  const [inputData, setInputData] = useState({
    outletId: "",
    outletStockTransferData: [
      {
        rawMaterialId: "",
        currentStockQuantity: "",
        transferQuantity: "",
      },
    ],
  });
  const [storageLocationList, setStorageLocationList] = useState([]);
  const [selectedStorageLocation, setSelectedStorageLocation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [prevArr, setPrevArr] = useState([]);
  const [filteredStorageLocations, setFilteredStorageLocations] = useState([]);
  const [newCurrentStock, setNewCurrentStock] = useState('');
  const [rawMatId, setRawMatId] = useState('');
  const [storageLocationId, setStorageLocationId] = useState('');
  const [validationErrors, setValidationErrors] = useState({
    outletId: "",
    storageLocation: "",
    outletStockTransferData: [
      {
        rawMaterialId: "",
        currentStockQuantity: "",
        transferQuantity: "",
      },
    ],
  });

  const handleAddItem = () => {
    const stockValuesErrors = inputData.outletStockTransferData.map(
      validateOutletStockTransferDataItem
    );
    if (stockValuesErrors.some((errors) => Object.keys(errors).length > 0)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        outletStockTransferData: stockValuesErrors,
      }));
      return;
    }

    setInputData((prevData) => ({
      ...prevData,
      outletStockTransferData: [
        ...prevData.outletStockTransferData,
        {
          rawMaterialId: "",
          currentStockQuantity: "",
          transferQuantity: "",
        },
      ],
    }));
  };

  const validateOutletStockTransferDataItem = (item) => {
    const errors = {};

    if (!item.rawMaterialId.trim()) {
      errors.rawMaterialId = "Raw Material is required.";
    }

    if (item.currentStockQuantity === "") {
      errors.currentStockQuantity = "Current Stock is required.";
    }

    if (item.transferQuantity === "") {
      errors.transferQuantity = "Transferable Qty is required.";
    }

    return errors;
  };

  const handleRemoveItem = (index) => {
    setInputData((prevData) => {
      const updatedItems = [...prevData.outletStockTransferData];
      updatedItems.splice(index, 1);
      return {
        ...prevData,
        outletStockTransferData: updatedItems,
      };
    });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      outletStockTransferData: [],
    }));
    if (
      name === "rawMaterialId" ||
      name === "currentStockQuantity" ||
      name === "transferQuantity"
    ) {
      setInputData((prevData) => {
        const updatedOutletStockTransferData = [
          ...prevData.outletStockTransferData,
        ];
        updatedOutletStockTransferData[index] = {
          ...updatedOutletStockTransferData[index],
          [name]: name === "transferQuantity" ? parseFloat(value, 10) : value,
        };
        return {
          ...prevData,
          outletStockTransferData: updatedOutletStockTransferData,
        };
      });
    } else {
      setInputData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const getSingleStockTransferDetails = async (rmId, index) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(
        `stock-transactions/getCurrentStock?rawMaterialId=${rmId}`,
        { headers }
      );
      if (response?.data?.status === true) {
        const currentStockQuantity = response?.data?.data?.currentStock;
        const unit = response?.data?.data?.rawMaterial?.unit

        setInputData((prevData) => {
          const newData = { ...prevData };
          newData.outletStockTransferData = [
            ...newData.outletStockTransferData,
          ];
          newData.outletStockTransferData[index] = {
            ...newData.outletStockTransferData[index],
            rawMaterialId: rmId,
            // currentStockQuantity,
            // unit
          };
          return newData;
        });
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        // toast.error(errorMessage, {
        //   position: "top-right",
        //   autoClose: 3000,
        //   theme: "colored",
        // });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      // let newInputData = {
      //   ...inputData,
      //     storageLocation: selectedStorageLocation
      // }

      const response = await axios.post(
        "stock-transactions/addStockToOutlet",
        inputData,
        {
          headers,
        }
      );

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setInputData({
          outletId: "",
          outletStockTransferData: [
            {
              rawMaterialId: "",
              currentStockQuantity: "",
              transferQuantity: "",
            },
          ],
        });
      }
    } catch (error) {
      
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.error;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        setOutlets(response?.data?.data?.outlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getRawMaterials = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("raw-material", { headers });

      if (response?.data?.status === true) {
        setRawMaterialList(response?.data?.data?.rawMaterials);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getStorageLocation = async(rawId, storageId, index) => {
    console.log("Raw Id :", rawId);
    
    try {
        const headers = {
            Authorization : `Bearer ${accessToken}`
        }
        const response = await axios.get(`storage-location/getCurrentStock?rawMaterialId=${rawId}`, { headers })
        const storageLocations = response?.data?.data?.storageLocations;

        setFilteredStorageLocations(storageLocations); // Update the filtered state
        if (storageId) {
          console.log("Hii HGello", storageId);
          
          const storageLoc = response?.data?.data?.storageLocations?.find((st) => st?._id === storageId);
          const filteredStock = storageLoc?.stockInfo?.find((cs) => cs?.rawMaterial?._id === rawId);
          
          setNewCurrentStock(filteredStock?.currentStock);

          // Extract the current stock and unit
          const currentStock = filteredStock?.currentStock || 0;
          const currentUnit = filteredStock?.rawMaterial?.unit || "";

          // Update the inputData state
          setInputData((prevData) => {
            const newData = { ...prevData };
            newData.outletStockTransferData = [...newData.outletStockTransferData];
            newData.outletStockTransferData[index] = {
              ...newData.outletStockTransferData[index],
              currentStockQuantity: currentStock, // Set current stock
              unit: currentUnit, // Set unit
            };
            return newData;
          });
        }
    } catch (error) {
        if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response.data.error;
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
        }
    }
  };

  const btnDisable = () => {
    let isDisable = false;

    if (!inputData.outletId) {
      isDisable = true;
    }

    for (let i = 0; i < inputData.outletStockTransferData.length; i++) {
      const item = inputData.outletStockTransferData[i];

      if (
        !item.rawMaterialId ||
        !item.currentStockQuantity ||
        !item.transferQuantity
      ) {
        isDisable = true;
        break;
      }
    }

    return isDisable;
  };

  useEffect(() => {
    getOutlets();
    getRawMaterials();
    // getStorageLocation();
  }, []);

  return (
    <CContainer>
      <CCard
        className="p-4"
        style={{
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <CCardHeader
          className="d-flex align-items-center justify-content-between"
          style={{
              backgroundColor: "#4caf50",
              color: "white",
              borderRadius: "8px",
              padding: "1rem",
          }}
        >
          <h5 className="fw-semibold mb-0">ADD STOCK TRANSFER TO OUTLET</h5>
        </CCardHeader>

        <CForm onSubmit={handleSubmit}>
          <CCardBody>
            <div className="mb-4">
              <CFormLabel className="fw-semibold">
                Select Outlet <span className="text-danger">*</span>
              </CFormLabel>
              <CFormSelect
                size="sm"
                name="outletId"
                className="mb-3"
                value={inputData?.outletId}
                onChange={handleChange}
                style={{ padding: "0.5rem", borderRadius: "8px" }}
              >
                <option value="">Select Outlet</option>
                {outlets &&
                  outlets.map((rm) => (
                    <option key={rm._id} value={rm._id}>
                      {rm.name}
                    </option>
                  ))}
              </CFormSelect>
            </div>

            {inputData.outletId !== "" && (
              <>
                {inputData.outletStockTransferData.map((transferData, index) => (
                  <div
                    key={index}
                    className="mb-4 p-3"
                    style={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "8px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <CRow className="align-items-center">
                      <CCol md="6">
                        <CFormLabel className="fw-semibold">
                          Select Raw Material <span className="text-danger">*</span>
                        </CFormLabel>
                        <Select
                          components={animatedComponents}
                          options={rawMaterialList.map((rm) => ({
                            value: rm._id,
                            label: rm.name,
                            isDisabled: inputData.outletStockTransferData.some(
                              (data) => data.rawMaterialId.toString() === rm._id.toString()
                            ),
                          }))}
                          value={
                            transferData.rawMaterialId
                              ? {
                                value: transferData.rawMaterialId,
                                label: rawMaterialList.find(
                                  (rm) => rm._id.toString() === transferData.rawMaterialId.toString()
                                )?.name,
                              }
                              : null
                          }
                          // onChange={(selectedOption) => {
                          //   if (selectedOption) {
                          //     // Update state when a new raw material is selected
                          //     const rawMaterialId = selectedOption.value;
                          //     setRawMatId(rawMaterialId);
                          //     getSingleStockTransferDetails(rawMaterialId, index);
                          //     getStorageLocation(rawMaterialId, storageLocationId, index)
                          //   } else {
                          //     // Clear all related fields when raw material is cleared
                          //     setInputData((prevData) => {
                          //       const updatedData = { ...prevData };
                          //       updatedData.outletStockTransferData[index] = {
                          //         rawMaterialId: "", 
                          //         currentStockQuantity: 0, 
                          //         unit: "", 
                          //         transferQuantity: "",
                          //       };
                          //       return updatedData;
                          //     });
                          //   }
                          //   setValidationErrors((prevErrors) => ({
                          //     ...prevErrors,
                          //     outletStockTransferData: [], // Clear validation errors
                          //   }));
                          // }}

                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              const rawMaterialId = selectedOption.value;
                          
                              // Update the raw material ID and trigger storage location logic
                              setInputData((prevData) => {
                                const updatedData = { ...prevData };
                                updatedData.outletStockTransferData[index] = {
                                  ...updatedData.outletStockTransferData[index],
                                  rawMaterialId, // Update the selected raw material ID
                                  transferQuantity: 0, // Reset transfer quantity for safety
                                };
                                return updatedData;
                              });
                          
                              // Fetch and update storage location, unit, and current stock
                              getStorageLocation(rawMaterialId, transferData?.storageLocation, index);
                            } else {
                              // Clear all fields if the raw material selection is cleared
                              setInputData((prevData) => {
                                const updatedData = { ...prevData };
                                updatedData.outletStockTransferData[index] = {
                                  rawMaterialId: "",
                                  currentStockQuantity: 0,
                                  unit: "",
                                  transferQuantity: 0,
                                };
                                return updatedData;
                              });
                            }
                          
                            // Clear validation errors
                            setValidationErrors((prevErrors) => ({
                              ...prevErrors,
                              outletStockTransferData: [],
                            }));
                          }}
                          classNamePrefix="react-select"
                          isClearable
                          placeholder="Select Raw Material"
                        />

                        <div className="text-danger" style={{ fontSize: '12px' }}>
                          {validationErrors.outletStockTransferData[index]
                            ?.rawMaterialId}
                        </div>
                      </CCol>

                      <CCol md={6}>
                        <CFormLabel className="fw-semibold">Select Storage Location <span className="text-danger">*</span></CFormLabel>
                        <Select
                          components={animatedComponents}
                          id="selectField"
                          name={`storage_location_${index}`} // Unique name for each row
                          value={transferData.storageLocation // Get the storage location for the current row
                            ? {
                                value: transferData.storageLocation,
                                label: filteredStorageLocations.find(
                                  (storage) => storage._id === transferData.storageLocation
                                )?.name,
                              }
                            : null}
                          onChange={(selectedOption) => {
                            const storageId = selectedOption?.value || "";

                            // Update the storage location for the current row
                            setInputData((prevData) => {
                              const updatedData = { ...prevData };
                              updatedData.outletStockTransferData[index] = {
                                ...updatedData.outletStockTransferData[index],
                                storageLocation: storageId,
                              };
                              return updatedData;
                            });

                            if (storageId) {
                              setStorageLocationId(storageId);
                              // Fetch data or perform any action based on the selected storage location
                              getStorageLocation(transferData.rawMaterialId, storageId, index);
                            }
                          }}
                          options={filteredStorageLocations.map((storage) => ({
                            value: storage._id,
                            label: `${storage.name} -> ${storage.city}, ${storage.state}`,
                          }))}
                          placeholder="All Storage Locations"
                          isClearable
                          isDisabled={!transferData.rawMaterialId}
                        />
                      </CCol>

                      <CCol md="3" className="mt-3">
                        <CFormLabel className="fw-semibold">
                          Current Qty <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          min={0}
                          disabled
                          type="number"
                          placeholder="Current Qty"
                          className="form-control no-arrows"
                          name="currentStockQuantity"
                          value={transferData.currentStockQuantity || 0}
                        />
                      </CCol>

                      <CCol md="3">
                        <CFormLabel className="fw-semibold">Unit</CFormLabel>
                        <CFormInput
                          disabled
                          type="text"
                          placeholder="Unit"
                          className="form-control no-arrows"
                          name="Unit"
                          value={transferData.unit || ""}
                        />
                      </CCol>

                      <CCol md="3">
                        <CFormLabel className="fw-semibold">
                          Transferable Qty <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          min={0}
                          step="any"
                          type="number"
                          placeholder="Transferable Qty"
                          className="form-control no-arrows"
                          name="transferQuantity"
                          value={transferData?.transferQuantity}
                          onChange={(e) => handleChange(e, index)}
                        />
                        <div className="text-danger" style={{ fontSize: '12px' }}>
                          {validationErrors.outletStockTransferData[index]
                            ?.transferQuantity}
                        </div>
                      </CCol>

                      <CCol md="3" className="text-end">
                        {index ===
                          inputData.outletStockTransferData?.length - 1 && (
                              <CButton
                                size="sm"
                                onClick={handleAddItem}
                                className="btn btn-circle me-2 w-50"
                                color="dark" variant="outline"
                              >
                                + ADD MORE
                              </CButton>
                          )}
                        {inputData.outletStockTransferData?.length > 1 && (
                          <CButton
                            size="sm"
                            onClick={() => handleRemoveItem(index)}
                            className="btn btn-danger btn-circle"
                          >
                            <FaMinus />
                          </CButton>
                        )}
                      </CCol>
                    </CRow>
                  </div>
                ))}
              </>
            )}
          </CCardBody>

          <CCardFooter className="text-end">
            <PrimaryButton
              isDisable={btnDisable()}
              title="Process Stock Transfer"
              isLoading={isLoading}
              style={{ padding: "0.5rem 2rem" }}
            />
          </CCardFooter>
        </CForm>
      </CCard>
    </CContainer>


  );
};

export default StockTransfer;
