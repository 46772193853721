import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CContainer, CForm, CFormInput, CFormLabel, CFormTextarea, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react'
import React, { useState, useEffect } from 'react'
import { DataTableComponent, DeleteModal, PrimaryButton } from '../../components'
import axios from '../../api/axios';
import { FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

const StorageLocation = () => {

    const accessToken = localStorage.getItem('authToken');
    const [storageLocationList, setStorageLocationList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [storageLocatioId, setStorageLocatioId] = useState('');
    const [addBtnLoading, setAddBtnLoading] = useState(false);
    const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [storageLocationInputData, setStorageLocationInputData] = useState({
        name: '',
        address: '',
        city: '',
        state: ''
    });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [openEditModal, setopenEditModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [errors, setErrors] = useState({});
    const [editErrors, setEditErrors] = useState({});
    const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
    const [filterLoading, setFilterLoading] = useState(false);
    const [filterState, setFilterState] = useState("");
    const [filterCity, setFilterCity] = useState("");

    const columns = [
        {
          name: "Sr.No",
          // cell: (row, index) => (pagination.page - 1) * pagination.rowsPerPage + index + 1,
          cell: (row, index) => <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>,
          center: true,
          width: "90px"
        },
        {
          name: "NAME",
          selector: (row) => row.name,
          cell: (row) => (
            <div className="fs-7">{row.name ? row.name : "--------"}</div>
          ),
          width: "220px"
        },
        {
            name: "ADDRESS",
            selector: (row) => row.address,
            cell: (row) => (
              <div className="fs-7">{row.address ? row.address : "--------"}</div>
            ),
            width: "290px"
        },
        {
            name: "CITY",
            selector: (row) => row?.city,
            cell: (row) => (
              <div className="fs-7">{row?.city ? row?.city : "--------"}</div>
            ),
        },
        {
            name: "STATE",
            selector: (row) => row?.state,
            cell: (row) => (
              <div className="fs-7">{row?.state ? row?.state : "--------"}</div>
            ),
        },
        {
          name: "ACTIONS",
          right: "true",
          cell: (row) => (
            <div className="d-flex justify-content-around align-items-center">
              <a
                href="#"
                onClick={() => handleEditModal(row)}
                className="text-primary me-2 d-flex align-items-center gap-1"
                style={{ textDecoration: "none" }}
              >
                <FaPencilAlt /> EDIT
              </a>{" "}
              |
              <a
                href="#"
                className="text-danger ms-2 d-flex align-items-center gap-1"
                onClick={() => handleDeleteModal(row)}
                style={{ textDecoration: "none" }}
              >
                <FaTrash /> DELETE
              </a>
            </div>
          ),
          minWidth: "165px",
        },
    ]

    const updatedColumns = columns.map((column) => ({
            ...column,
            name : (
            <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
            )
        }) 
    )

    // const getStorageLocation = async() => {
    //     try {
    //         const headers = {
    //             Authorization : `Bearer ${accessToken}`
    //         }
    //         const response = await axios.get('storage-location', { headers })
    //         response.data?.data?.storageLocations.forEach((item, index) => {
    //             item.serialNumber = index + 1;
    //         });
    //         setStorageLocationList(response?.data?.data?.storageLocations);
    //         setFilteredData(response?.data?.data?.storageLocations);
    //     } catch (error) {
    //         if (error.response && error.response?.data?.status === false) {
    //             let errorMessage = error.response.data.error;
    //             toast.error(errorMessage, {
    //               position: "top-right",
    //               autoClose: 3000,
    //               theme: "colored",
    //             });
    //         }
    //     }
    // }

    const handleFilter = async () => {
        setFilterLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const params = {};
        
            if (filterState) params.state = filterState;
            if (filterCity) params.city = filterCity;
        
            const response = await axios.get("storage-location", {
                headers,
                params, // Include filters as query parameters
            });
            response.data?.data?.storageLocations.forEach((item, index) => {
                item.serialNumber = index + 1;
                });
            console.log("Response :", response);
          
            setStorageLocationList(response?.data?.data?.storageLocations);
            setFilteredData(response?.data?.data?.storageLocations);
            if(filterState !== "" || filterCity !== "") {
                toast.success(response?.data?.data?.message, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                const errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                });
            }
        } finally {
          setFilterLoading(false);
        }
    };

    const handleAddNewOpenModal = () => {
        setOpenAddModal(true);
    }

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setStorageLocationInputData({
            name: '',
            address: '',
            city: '',
            state: ''
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStorageLocationInputData((prevState) => ({ ...prevState, [name]: value }));
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        if(validateForm()) {
            setAddBtnLoading(true);
            try {
                const headers = {
                    Authorization: `Bearer ${accessToken}`
                }
                const response = await axios.post('storage-location', storageLocationInputData, { headers });
                if (response?.data?.status === true) {
                    toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                    });
                    // getStorageLocation();
                    handleFilter();
                    setOpenAddModal(false);
                    setStorageLocationInputData({
                        name: "",
                        address: "",
                        city: "",
                        state: ""
                    });
                }
            } catch (error) {
                if (error?.response) {
                    let errorMessage = '';
                    if ( error.response?.status === 406 ) {
                        errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                    }
                    else if ( error.response?.status === 412 ) {
                        errorMessage = error?.response?.data?.data?.message;
                    } else if (error.response?.status === 500) {
                        errorMessage = error?.response?.data?.error
                    }
        
                    toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                    });
                }
            } finally {
                setAddBtnLoading(false);
            }
        }
    }

    const handleEditModal = (data) => {        
        setopenEditModal(true);        
        setEditedData({
            _id: data?._id,
            name: data?.name,
            address: data?.address,
            city: data?.city,
            state: data?.state
        })
    }

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevState) => ({ ...prevState, [name]: value} ))
    }

    const handleEditSubmit = async(e) => {
        e.preventDefault();
        if( validateEditForm() ) {
            setIsEditBtnLoading(true);
            try {
                const headers = {
                    Authorization: `Bearer ${accessToken}`
                }
                const response = await axios.put('storage-location', editedData, { headers });
                if (response?.data?.status === true) {
                    toast.success(response?.data?.data?.message, {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "colored",
                    });
                    // getStorageLocation();
                    handleFilter();
                    setopenEditModal(false);
                }
            } catch (error) {
                if (error?.response) {
                    let errorMessage = '';
                    if ( error.response?.status === 406 ) {
                        errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                    }
                    else if ( error.response?.status === 412 ) {
                        errorMessage = error?.response?.data?.data?.message;
                    } else if (error.response?.status === 500) {
                        errorMessage = error?.response?.data?.error
                    }
                    toast.error(errorMessage, {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "colored",
                    });
                }
            } finally {
                setIsEditBtnLoading(false);
            }
        }
    }

    const handleSearchChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleDeleteModal = (data) => {
        setOpenDeleteModal(true);
        setStorageLocatioId(data._id)
    }

    const handleDeleteStorageLocation = async(e) => {
        e.preventDefault();
        setIsDeleteBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const data = {
                _id: storageLocatioId
            }
            const response = await axios.delete('storage-location', { data, headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
                // getStorageLocation();
                handleFilter();
                setOpenDeleteModal(false);
            }
        } catch (error) {
            if (error.response.status === 403) {
                toast.error(error?.response?.data?.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        } finally {
            setIsDeleteBtnLoading(false);
        }
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    }

    const validateForm = () => {
        const newErrors = {};
        const trimmedTitle = storageLocationInputData?.name.trim();
        const wordCount = trimmedTitle.split(/\s+/).length;
        const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;

        if(!storageLocationInputData?.name) {
            newErrors.name = "Name is required";
        } 
        // else if (!isNaN(storageLocationInputData?.name) || /^-\d+$/.test(storageLocationInputData?.name)) {
        //     newErrors.name = "Name should not contain numeric or a negative integer";
        // }
        else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.name = "Name must contain at least 2 letters";
        } 
        // else if (specialCharacterPattern.test(storageLocationInputData?.name)) {
        //     newErrors.name = "Name should not contain special characters";
        // }
        // if(!versionForm.is_current_version) {
        if (storageLocationInputData.address === "" || storageLocationInputData.address === null || storageLocationInputData.address === undefined) {
            newErrors.address = "Address is required"
        } 
        // else if (!isNaN(storageLocationInputData?.address) || /^-\d+$/.test(storageLocationInputData?.address)) {
        //     newErrors.address = "Address should not contain numeric or a negative integer";
        // } 
        else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.address = "Address must contain at least 2 letters";
        }

        if (storageLocationInputData.city === "" || storageLocationInputData.city === null || storageLocationInputData.city === undefined) {
            newErrors.city = "City is required"
        } else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.city = "City must contain at least 2 letters";
        }

        if (storageLocationInputData.state === "" || storageLocationInputData.state === null || storageLocationInputData.state === undefined) {
            newErrors.state = "State is required"
        } else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.state = "State must contain at least 2 letters";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const validateEditForm = () => {
        const newErrors = {};
        const trimmedTitle = editedData?.name.trim();
        const wordCount = trimmedTitle.split(/\s+/).length;
        const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;

        if(!editedData?.name) {
            newErrors.name = "Name is required";
        } 
        // else if (!isNaN(editedData?.name) || /^-\d+$/.test(editedData?.name)) {
        //     newErrors.name = "Name should not contain numeric or a negative integer";
        // } 
        else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.name = "Name must contain at least 2 letters";
        } 
        // else if (specialCharacterPattern.test(editedData?.name)) {
        //     newErrors.name = "Name should not contain special characters";
        // }

        // if(!versionForm.is_current_version) {
        if (editedData.address === "" || editedData.address === null || editedData.address === undefined) {
            newErrors.address = "Address is required"
        } 
        // else if (!isNaN(editedData?.address) || /^-\d+$/.test(editedData?.address)) {
        //     newErrors.address = "Address should not contain numeric or a negative integer";
        else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.address = "Address must contain at least 2 letters";
        }

        // if(!versionForm.is_current_version) {
        if (editedData.city === "" || editedData.city === null || editedData.city === undefined) {
            newErrors.city = "City is required"
        } else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.city = "City must contain at least 2 letters";
        }

        // if(!versionForm.is_current_version) {
        if (editedData.state === "" || editedData.state === null || editedData.state === undefined) {
            newErrors.state = "State is required"
        } else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.state = "State must contain at least 2 letters";
        }
        setEditErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handlePageChange = (page) => {
        setPagination({ ...pagination, page });
      };
      
    const handleRowsPerPageChange = (rowsPerPage) => {
        setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
    };
    
    // Function to get paginated data
    const getPaginatedData = () => {
        const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
        const endIndex = startIndex + pagination.rowsPerPage;
        return filteredData.slice(startIndex, endIndex);
    };

    useEffect(() => {
        // getStorageLocation();
        handleFilter();
    }, [])

    useEffect(() => {
        const result = storageLocationList.filter((item) => {
            return item?.name.toLowerCase().match(searchText.toLocaleLowerCase());
        });
        setFilteredData(result);
    }, [searchText]);

    return (
        <CContainer>
            <CRow className='mb-4'>
                <CCol>
                    <CFormLabel className="fw-semibold">City</CFormLabel>
                    <CFormInput
                        type="text"
                        placeholder="Enter city"
                        value={filterCity}
                        onChange={(e) => setFilterCity(e.target.value)}
                    />
                </CCol>
                <CCol>
                    <CFormLabel className="fw-semibold">State</CFormLabel>
                    <CFormInput
                        type="text"
                        placeholder="Enter state"
                        value={filterState}
                        onChange={(e) => setFilterState(e.target.value)}
                    />
                </CCol>
                <CCol className='d-flex flex-column'>
                    <CFormLabel className='text-white'>.</CFormLabel>
                    <CButton
                        color="primary"
                        onClick={handleFilter}
                        style={{
                            borderRadius: "8px",
                        }}
                        className='w-50'
                        disabled={filterLoading}
                    >
                        { filterLoading ? <Spinner size="sm" /> : "Show" }
                    </CButton>
                </CCol>
                {/* <------------- Add Form of Storage Location ------------> */}
                <CCol className="text-end d-flex flex-column">
                        <CFormLabel className='text-white'>.</CFormLabel>
                        <button
                          className="btn btn-warning"
                          onClick={handleAddNewOpenModal}
                          style={{ borderRadius: "18px" }}
                        >
                          ADD NEW STORAGE LOCATION <FaPlus style={{ marginTop: "-4px" }} size={15} />
                        </button>
                </CCol>
            </CRow>

            <CRow>
                {/* <------------- List Of Storage Locations ---------------> */}
                <CCol className='col-md-12 col-lg-12'>
                    <DataTableComponent 
                        columns={updatedColumns}
                        // data={filteredData}
                        title="STORAGE LOCATION LIST"
                        data={getPaginatedData()} // Use the paginated data here
                        searchText={searchText}
                        onChange={handleSearchChange}
                        striped
                        itemsPerPage={10}
                        pagination
                        totalRows={filteredData?.length}
                        paginationTotalRows={filteredData?.length} // Total number of items
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        onChangePage={handlePageChange}
                        paginationServer={false} // Set to false for client-side pagination
                    />
                </CCol>
            </CRow>

            {/* <----------------- Add Storage Location Modal ---------------------> */}
            {openAddModal ? (
                <CModal
                    alignment="center"
                    visible={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                            ADD STORAGE LOCATION
                        </CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleSubmit}>
                        <CModalBody>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Name <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="Storage Location Name"
                                    // required
                                    className="form-control"
                                    name="name"
                                    value={storageLocationInputData?.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.name}</div>}
                            </div>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    City <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="City"
                                    className="form-control"
                                    name="city"
                                    value={storageLocationInputData?.city}
                                    onChange={handleChange}
                                />
                                {errors.city && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.city}</div>}
                            </div>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    State <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="State"
                                    className="form-control"
                                    name="state"
                                    value={storageLocationInputData?.state}
                                    onChange={handleChange}
                                />
                                {errors.state && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.state}</div>}
                            </div>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Address <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormTextarea
                                    rows={3}
                                    type="text"
                                    placeholder="Address"
                                    className="form-control"
                                    name="address"
                                    value={storageLocationInputData?.address}
                                    onChange={handleChange}
                                />
                                {errors.address && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.address}</div>}
                            </div>
                            
                        </CModalBody>
                        <CModalFooter>
                            <PrimaryButton title="Add Storage Location" />
                        </CModalFooter>
                    </CForm>
                </CModal>
            ) : null}

            {/* <----------------- Edit Storage Location Modal ---------------------> */}
            {openEditModal ? (
                <CModal
                    alignment="center"
                    visible={openEditModal}
                    onClose={() => setopenEditModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                            EDIT STORAGE LOCATION
                        </CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleEditSubmit}>
                        <CModalBody>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Name <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="Name"
                                    // required
                                    className="form-control"
                                    name="name"
                                    value={editedData?.name}
                                    onChange={handleEditChange}
                                />
                                {editErrors.name && <div className='text-danger' style={{ fontSize : "12px"}}>{editErrors.name}</div>}
                            </div>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    City <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="City"
                                    className="form-control"
                                    name="city"
                                    value={editedData?.city}
                                    onChange={handleEditChange}
                                />
                                {editErrors.city && <div className='text-danger' style={{ fontSize : "12px"}}>{editErrors.city}</div>}
                            </div>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    State <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="State"
                                    className="form-control"
                                    name="state"
                                    value={editedData?.state}
                                    onChange={handleEditChange}
                                />
                                {editErrors.state && <div className='text-danger' style={{ fontSize : "12px"}}>{editErrors.state}</div>}
                            </div>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                Address <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormTextarea
                                    rows={3}
                                    type="text"
                                    placeholder="Address"
                                    className="form-control"
                                    name="address"
                                    value={editedData?.address}
                                    onChange={handleEditChange}
                                />
                                {editErrors.address && <div className='text-danger' style={{ fontSize : "12px"}}>{editErrors.address}</div>}
                            </div>
                        </CModalBody>
                        <CModalFooter>
                            <PrimaryButton title="Save Changes" />
                        </CModalFooter>
                    </CForm>
                </CModal>
            ) : null}

            {/* <------------------- Delete Storage Location Modal ---------------------> */}
            {openDeleteModal ? (
                <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteStorageLocation} isLoading={isDeleteBtnLoading} title="DELETE STORAGE LOCATION" />
            ) : null}

        </CContainer>
    )
}

export default StorageLocation