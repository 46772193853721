import React, { useEffect, useState } from 'react'
import { CBadge, CButton, CCol, CFormLabel, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import Select from 'react-select';

const RMReorderLevelReport = () => {
    const accessToken = localStorage.getItem("authToken");
    const [mainCategoryList, setMainCategoryList] = useState([]);
    const [mainCategoryId, setMainCategoryId] = useState('all');
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState('all');
    const [rMReportList, setRMReportList] = useState([]);
    const [rmOriginalList, setRMOriginalList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subBtnLoading, setSubBtnLoading] = useState(false);

    const mainCategoryOptions = [
        { value: 'all', label: 'All Main Categories' },
        ...mainCategoryList.map((rm) => ({
            value: rm._id,
            label: rm.name
        }))
    ];

    const subCategoryOptions = [
        { value: 'all', label: 'All Sub Categories' },
        ...subCategoryList.map((rm) => ({
            value: rm._id,
            label: rm.name
        }))
    ];

    const getReports = async () => {
        setLoading(false);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("raw-material/re-order-level-report", { headers });
            console.log("Response of the reports", response?.data?.data?.rawMaterials);
            setRMReportList(response?.data?.data?.rawMaterials);
            setRMOriginalList(response?.data?.data?.rawMaterials);

            //fetch the main categories from the above response
            const mainCategoryList = response?.data?.data?.rawMaterials.map(material => material.mainCategory);
            const mainCategoryUniqueList = mainCategoryList.filter((material, index, self) =>
                index === self.findIndex((obj) => obj._id === material._id)
            );
            setMainCategoryList(mainCategoryUniqueList ?? []);

            //fetch the sub categories from the above response
            const subCategoryList = response?.data?.data?.rawMaterials.map(material => material.subCategory);
            const subCategoryUniqueList = subCategoryList.filter((material, index, self) =>
                index === self.findIndex((obj) => obj._id === material._id)
            );
            console.log("set sub category in use effect");
            setSubCategoryList(subCategoryUniqueList ?? []);

        } catch (error) {
            let errorMessage = '';
            if (error.response?.status === 406) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
            }
            else if (error.response?.status === 412) {
                errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error
            }

            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleMainCategoryChange = (selectedOption) => {
        setMainCategoryId(selectedOption?.value);
        // getFilteredMainCategoryRawMaterials(selectedOption?.value);
    };

    const handleSubCategoryChange = (selectedOption) => {
        setSubCategoryId(selectedOption?.value);
        // getFilteredSubCategoryRawMaterials(selectedOption?.value);
    };

    const getFilteredSubCategoryRawMaterials = (selectedValue) => {
        if (selectedValue === 'all' || selectedValue === undefined) {
            setRMReportList(prev => [...prev]);
        } else {
            const filteredRawMaterialList = rmOriginalList?.filter(
                material => material?.subCategory?._id.toString() === selectedValue?.toString()
            );
            setRMReportList(filteredRawMaterialList ?? []);
        }
    };

    const getFilteredMainCategoryRawMaterials = (selectedCategoryId) => {
        console.log("selectedCategpry id:", selectedCategoryId);
        
        if (selectedCategoryId === 'all' || selectedCategoryId === undefined) {
            const originalRMList = [...rmOriginalList];
            setRMReportList(originalRMList);

            // Update the subcategory list
            const subCategoryList = rmOriginalList.map(material => material.subCategory);
            const updatedSubCategoryUniqueList = Array.from(
                new Map(subCategoryList.map(item => [item._id, item])).values()
            );
            setSubCategoryList(updatedSubCategoryUniqueList ?? []);
        } else {
            const filteredRawMaterialList = rmOriginalList?.filter(
                material => material?.mainCategory?._id?.toString() === selectedCategoryId?.toString()
            );
            setRMReportList(filteredRawMaterialList ?? []);

            // Update the subcategory list
            const subCategoryList = rmOriginalList?.map(material => material?.subCategory);
            const updatedSubCategoryList = subCategoryList?.filter(
                material => material?.mainCategory?.toString() === selectedCategoryId?.toString()
            );

            const updatedSubCategoryUniqueList = Array.from(
                new Map(updatedSubCategoryList?.map(item => [item._id, item])).values()
            );
            setSubCategoryList(updatedSubCategoryUniqueList);
        }
    };


    // const getFilteredMainCategoryRawMaterials = (e) => {
    //     setMainCategoryId(e.target.value);
    //     if (e.target.value === 'all') {
    //         const originalRMList = [...rmOriginalList];
    //         setRMReportList(originalRMList);

    //         //update the sub category list
    //         const subCategoryList = rmOriginalList.map(material => material.subCategory);

    //         const updatedSubCategoryUniqueList = Array.from(
    //             new Map(subCategoryList.map(item => [item._id, item])).values()
    //         );
    //         setSubCategoryList(updatedSubCategoryUniqueList ?? []);
    //     }
    //     else {
    //         const filteredRawMaterialList = rmOriginalList.filter(material => material.mainCategory._id.toString() === e.target.value.toString());
    //         setRMReportList(filteredRawMaterialList ?? []);

    //         //update the sub category list
    //         const subCategoryList = rmOriginalList.map(material => material.subCategory);
    //         const updatedSubCategoryList = subCategoryList.filter(material => material.mainCategory.toString() === e.target.value.toString());
    //         console.log("updated subcategory list: ", updatedSubCategoryList);

    //         const updatedSubCategoryUniqueList = Array.from(
    //             new Map(updatedSubCategoryList.map(item => [item._id, item])).values()
    //         );
    //         console.log("unnique sub category list: ", updatedSubCategoryUniqueList);
    //         setSubCategoryList(prev => [...updatedSubCategoryList]);
    //     }
    // }

    // const getFilteredSubCategoryRawMaterials = (e) => {
    //     setSubCategoryId(e.target.value);
    //     if (e.target.value === 'all') {
    //         setRMReportList(prev => [...prev]);
    //     }
    //     else {
    //         const filteredRawMaterialList = rmOriginalList.filter(material => material.subCategory._id.toString() === e.target.value.toString());
    //         setRMReportList(filteredRawMaterialList ?? []);
    //     }
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubBtnLoading(true);
        try {
            getFilteredMainCategoryRawMaterials(mainCategoryId);
            getFilteredSubCategoryRawMaterials(subCategoryId);
        } catch (error) {
            console.log("Error", error);
        } finally {
            setSubBtnLoading(false);
        }
    }

    useEffect(() => {
        getReports();
    }, []);

    return (
        <div className='container'>
            <h5>RM RE-ORDER LEVEL REPORT</h5>
            <CRow className='mt-3'>
                <CCol lg="3" md="4">
                    <CFormLabel className="fw-semibold">Select RM Main Category</CFormLabel>
                    <Select
                        isClearable
                        isSearchable
                        placeholder="RM Main Category"
                        options={mainCategoryOptions}
                        value={
                            mainCategoryOptions.find(option => option.value === mainCategoryId) || null
                        }
                        onChange={handleMainCategoryChange}
                    />
                </CCol>
                <CCol lg="3" md="4">
                    <CFormLabel className="fw-semibold">Select RM Sub Category</CFormLabel>
                    <Select
                        isClearable
                        isSearchable
                        placeholder="RM Sb Category"
                        options={subCategoryOptions}
                        isDisabled={!mainCategoryId}
                        value={
                            subCategoryOptions.find(option => option.value === subCategoryId) || null
                        }
                        onChange={handleSubCategoryChange}
                    />
                </CCol>
                <CCol xs={12} md={3}>
                    <CFormLabel className="text-white">.</CFormLabel>
                    <CButton
                        color="primary"
                        className="w-100"
                        onClick={handleSubmit}
                        disabled={subBtnLoading}
                    >
                        {subBtnLoading ? <CSpinner size="sm" /> : "Submit"}
                    </CButton>
                </CCol>
            </CRow>

            <CRow className='mt-4'>
                <CCol>
                    <CTable striped bordered hover responsive>
                        <CTableHead>
                            <CTableRow style={{ fontSize: '15px' }}>
                                <CTableHeaderCell>#</CTableHeaderCell>
                                <CTableHeaderCell>Raw Material</CTableHeaderCell>
                                <CTableHeaderCell>RM Main Category</CTableHeaderCell>
                                <CTableHeaderCell>Rm Sub Category</CTableHeaderCell>
                                <CTableHeaderCell className='text-center'>Re-Order Level</CTableHeaderCell>
                                <CTableHeaderCell className='text-center'>Current Stock</CTableHeaderCell>
                                <CTableHeaderCell>Status</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {loading ? (
                                <CTableRow>
                                    <CTableDataCell colSpan="10" className="text-center">
                                        <CSpinner color="primary" size="lg" />
                                    </CTableDataCell>
                                </CTableRow>
                            ) :
                                rMReportList?.length > 0 ? (

                                    <>
                                        {rMReportList?.map((customerData, index) => (
                                            <CTableRow key={index} style={{ fontSize: '15px', backgroundColor: (customerData?.inStock === true && customerData?.isReOrderLevelReached === true) ? "#9df5b4" : (customerData?.inStock === true && customerData?.isReOrderLevelReached === false) ? "#fff2a8" : "#ffafa8" }}>
                                                <CTableDataCell>{index + 1}</CTableDataCell>
                                                <CTableDataCell className='fw-semibold text-secondary'>{customerData?.name}</CTableDataCell>
                                                <CTableDataCell>{customerData?.mainCategory?.name}</CTableDataCell>
                                                <CTableDataCell>{customerData?.subCategory?.name}</CTableDataCell>
                                                <CTableDataCell className='text-center'><span>{customerData?.reOrderLevel}</span> <span>{customerData?.unit}</span></CTableDataCell>
                                                <CTableDataCell className='text-center'>{customerData?.currentCentralStock} {customerData?.unit}</CTableDataCell>
                                                <CTableDataCell className='text-center'>
                                                    {(customerData?.inStock === true && customerData?.isReOrderLevelReached === true) ? (
                                                        <CBadge className='w-75' color="success">AT RE-ORDER LEVEL</CBadge>
                                                    ) : (customerData?.inStock === true && customerData?.isReOrderLevelReached === false) ? (
                                                        <CBadge className='w-75' color="warning">LOW STOCK</CBadge>
                                                    ) : (
                                                        <CBadge className='w-75' color="danger">OUT OF STOCK</CBadge>
                                                    )}
                                                </CTableDataCell>
                                                {/* <CTableDataCell>{(customerData?.inStock === true &&  customerData?.isReOrderLevelReached === true) ? "AT RE-ORDER LEVEL ":(customerData?.inStock === true &&  customerData?.isReOrderLevelReached === false) ? "LOW STOCK" : "OUT OF STOCK" }</CTableDataCell> */}
                                            </CTableRow>
                                        ))}
                                    </>
                                ) : (
                                    <CTableRow>
                                        <CTableDataCell colSpan="7" className="text-center text-danger fw-5">
                                            No Reports Found
                                        </CTableDataCell>
                                    </CTableRow>
                                )}
                        </CTableBody>
                    </CTable>
                </CCol>
            </CRow>
        </div>
    )
}

export default RMReorderLevelReport