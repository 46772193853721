import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState, useRef } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { DataTableComponent, PrimaryButton } from "../../components";
import { FaMinus, FaPlus, FaTrash, FaPencilAlt } from "react-icons/fa";
import { isValidName } from "../../utils/validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const ProductAddOn = () => {
  const accessToken = localStorage.getItem("authToken");
  const animatedComponents = makeAnimated();
  const [inputData, setInputData] = useState({
    product: "",
    addonTitle: "",
    addonSerialNumber: "",
    isMultipleSelect: false,
    addonValues: [
      {
        value: "",
        mrp: "",
        offerPrice: "",
        sequenceNumber: "",
        icon: "",
        isDefault: false,
        subLabel: "",
        // status: "INACTIVE"
      },
    ],
  });
  const [addonProductsList, setAddonProductsList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    product: "",
    addonTitle: "",
    addonSerialNumber: "",
    addonValues: [],
  });

  const [editValidationErrors, setEditValidationErrors] = useState({
    addonTitle: "",
    addonSerialNumber: "",
    value: "",
    mrp: "",
    offerPrice: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [productAddonId, setProductAddonId] = useState("");
  const [editedData, setEditedData] = useState({});
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [addNewOpenModal, setAddNewOpenModal] = useState(false);
  const [addonItemId, setAddonItemId] = useState("");
  const [addNewItemModal, setAddNewItemModal] = useState(false);
  const [itemInputData, setItemInputData] = useState({
    isDefault: false,
    sequenceNumber: "",
    icon: "",
    value: "",
    mrp: "",
    offerPrice: "",
  });
  const [openAddonItemDeleteModal, setOpenAddonItemDeleteModal] =
    useState(false);

  const [isItemDeleteBtnLoading, setIsItemDeleteBtnLoading] = useState(false);
  const [iconsList, setIconsList] = useState([]);
  const [page, setPage] = useState('1');
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [limit, setLimit] = useState('15');
  const [totalRows, setTotalRows] = useState('');
  const [productId, setProductId] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(productsList);
  const [outlets, setOutlets] = useState([]);
  const [selectedExcludedOutlets, setSelectedExcludedOutlets] = useState([]);
  const [selectedValueExcludedOutlets, setSelectedValueExcludedOutlets] = useState([]);
  const [selectedItemExcludedOutlets, setSelectedItemExcludedOutlets] = useState([]);

  const [selectedEditedExcludedOutlets, setSelectedEditedExcludedOutlets] = useState([]);
  const [selectedEditedValueExcludedOutlets, setSelectedEditedValueExcludedOutlets] = useState([]);

  const outletData = outlets.map((outlet) => ({
    value: outlet._id,
    label: `${outlet.name} (${outlet.city})`,
  }));

  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        borderRadius: "6px",
        minWidth: "200px",
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
        "&:hover": {
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#6c757d",
    }),
  };

  // When the page or limit changes
  const handlePageChange = (newPage) => {
    setPage(newPage);
    getAddonProductsList(categoryId, newPage, limit, productId); // Fetch new data for the current page
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    getAddonProductsList(categoryId, page, newLimit, productId); // Fetch new data for the current rows per page
  };

  const columns = [
    {
      name: "Sr.No",
      width: "70px",
      cell: (row, index) => (
        <div className="fs-7">
          {row.serialNumber ? row.serialNumber : "----"}
        </div>
      ),
    },
    {
      name: "MENU NAME & ADDONS",
      center: 'true',
      selector: (row) => row.product.name,
      cell: (row) => (
        <div className="w-100">
          <div className="fs-5 fw-bold mt-3">
            <span style={{ color: '#09A5F7' }}>{row?.product?.category?.name}</span> {">"} {" "}
            <span style={{ color: '#0E4961' }}>{row?.product?.name ? row.product?.name : "--------"}</span>
          </div>

          {row?.addons &&
            row?.addons.map((addons) => (
              <CTable striped bordered className="mt-2" style={{ width: "100%" }}>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell style={{ color: '#800080', fontWeight: '700', fontSize: '16px', }} className="text-center">
                      {addons?.addonSerialNumber}
                    </CTableHeaderCell>
                    <CTableHeaderCell style={{ color: '#800080', fontWeight: '700', fontSize: '16px', }} className="text-center" colSpan={addons.addonTitle === "Size" || addons.addonTitle === "size" ? 6 : 5}>
                      <span>{addons?.addonTitle?.toUpperCase()}{" "} {addons?.isMultipleSelect ? "(Can Select Multiple Addons)" : ""}</span>
                        <br />
                      {addons?.excludedOutletsDetails?.length > 0 ?
                        <span className="text-muted" style={{ fontSize: '12px' }}>Excluded Outlets: {"["} {addons?.excludedOutletsDetails?.map(outlet => outlet?.name).join(", ") + "."} {"]"}</span>
                      : null } 
                    </CTableHeaderCell>
                    <CTableHeaderCell className="text-end">
                      <CButton
                        size="sm"
                        onClick={() => {
                          setAddNewItemModal(true);
                          setProductAddonId(addons._id);
                        }}
                      >
                        <FaPlus />
                      </CButton>
                    </CTableHeaderCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell className="text-center">
                      Seq. No.
                    </CTableHeaderCell>
                    <CTableHeaderCell className="text-center">
                      Value
                    </CTableHeaderCell>
                    <CTableHeaderCell style={{ textAlign: "right" }}>
                      MRP
                    </CTableHeaderCell>
                    <CTableHeaderCell style={{ textAlign: "right" }}>
                      Offer Price
                    </CTableHeaderCell>
                    <CTableHeaderCell style={{ textAlign: "right" }}>
                      Is Default
                    </CTableHeaderCell>
                    <CTableHeaderCell style={{ textAlign: "right" }}>
                      STATUS
                    </CTableHeaderCell>
                    {
                      addons?.addonTitle === "Size" || addons?.addonTitle === 'size' ? <CTableHeaderCell style={{ textAlign: "right" }}>
                        Sub Label
                      </CTableHeaderCell> : null
                    }
                    <CTableHeaderCell style={{ textAlign: "right", width: '140px' }}>
                      Actions
                    </CTableHeaderCell>
                  </CTableRow>

                </CTableHead>
                <CTableBody>
                  {addons.addonValues &&
                    addons.addonValues.map((item, index) => (
                      <CTableRow>
                        <CTableDataCell className="text-center">
                          {item?.sequenceNumber}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item?.value === null ? 0 : item?.value}
                        </CTableDataCell>
                        <CTableDataCell style={{ textAlign: "right" }}>
                          {item?.mrp === null || item?.isDefault === true
                            ? 0
                            : item?.mrp?.toFixed(2)}
                        </CTableDataCell>
                        <CTableDataCell style={{ textAlign: "right" }}>
                          {" "}
                          {item?.offerPrice === null || item?.isDefault === true
                            ? 0
                            : item?.offerPrice?.toFixed(2)}
                        </CTableDataCell>
                        <CTableDataCell style={{ textAlign: "right" }}>
                          {" "}
                          {item?.isDefault === false ? "False" : "True"}
                        </CTableDataCell>
                        <CTableDataCell style={{ textAlign: "right", color: item?.status === "ACTIVE" ? 'green' : 'red' }}>
                          {" "}
                          {item?.status ===
                            "ACTIVE" ? "ACTIVE" : "INACTIVE"}
                        </CTableDataCell>
                        {
                          addons.addonTitle === "Size" || addons?.addonTitle === 'size' ? <CTableDataCell style={{ textAlign: "right" }}>
                            {" "}

                            {item?.subLabel}

                          </CTableDataCell> : null
                        }

                        <CTableDataCell className="text-center">
                          <div className="d-flex justify-content-around align-items-center">
                            <a
                              href="#"
                              className="text-primary "
                              style={{ cursor: "pointer", textDecoration: "none" }}
                              onClick={() =>
                                handleEditModal(
                                  item,
                                  addons?.addonTitle,
                                  addons?._id,
                                  row?.product?._id,
                                  addons?.addonSerialNumber,
                                  addons?.isMultipleSelect,
                                  addons
                                )
                              }
                            >
                              <FaPencilAlt /> Edit
                            </a>{" "}
                            |
                            <a
                              href="#"
                              className="text-danger"
                              style={{ cursor: "pointer", textDecoration: "none" }}
                              onClick={() =>
                                handleOpenAddonItemDeleteModal(row, item?._id)
                              }
                            >
                              <FaTrash /> Delete
                            </a>
                          </div>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                </CTableBody>
              </CTable>
            ))
          }
        </div>
      ),
      maxWidth: "100%",
    },
    {
      name: "ACTIONS",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center me-4 gap-2 w-100">
          <a
            href="#"
            className="text-danger"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleDeleteModal(row)}
          >
            <FaTrash /> Delete
          </a>
        </div>
      ),
      width: "150px",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleAddNewOpenModal = () => {
    setAddNewOpenModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (name === "value" || name === "sequenceNumber") {
        if (index !== undefined) {
          updatedErrors.addonValues = [...prevErrors.addonValues];
          updatedErrors.addonValues[index] = {
            ...prevErrors.addonValues[index],
            [name]: "",
          };
        }
      } else {
        updatedErrors[name] = "";
      }

      return updatedErrors;
    });

    if (index !== undefined) {
      setInputData((prevData) => {
        const updatedAddonValues = [...prevData.addonValues];

        // Check if there's already an item with isDefault set to true
        const isDefaultExists = updatedAddonValues.some(
          (item, i) => item.isDefault === "true" && i !== index
        );

        if (name === "isDefault" && value === "true" && isDefaultExists) {
          alert("Only one addon can be set as default.");
          return prevData;
        }

        updatedAddonValues[index] = {
          ...updatedAddonValues[index],
          [name]: value,
        };

        // If isDefault is set to true, clear mrp and offerPrice fields
        if (name === "isDefault" && value === "true") {
          updatedAddonValues[index].mrp = "";
          updatedAddonValues[index].offerPrice = "";
        }

        return {
          ...prevData,
          addonValues: updatedAddonValues,
        };
      });
    } else {
      setInputData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (selected) => {
    setSelectedExcludedOutlets(selected);
  };

  const handleSelectValueExChange = (selected) => {
    setSelectedValueExcludedOutlets(selected);
  };

  const handleAddItem = () => {
    const addonValuesErrors = inputData.addonValues.map(
      validateProcurementItem
    );
    if (addonValuesErrors.some((errors) => Object.keys(errors).length > 0)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        addonValues: addonValuesErrors,
      }));
      return;
    }

    setInputData((prevData) => ({
      ...prevData,
      addonValues: [
        ...prevData.addonValues,
        {
          value: "",
          mrp: "",
          offerPrice: "",
          sequenceNumber: "",
          icon: "",
          isDefault: false,
        },
      ],
    }));
  };

  const handleRemoveItem = (index) => {
    setInputData((prevData) => {
      const updatedItems = [...prevData.addonValues];
      updatedItems.splice(index, 1);
      return {
        ...prevData,
        addonValues: updatedItems,
      };
    });
  };

  const validateProcurementItem = (item) => {
    // const errors = {};

    // if (!item.value.trim()) {
    //   errors.value = "Value is required.";
    // }

    // if (item.mrp.trim() === "") {
    //   errors.mrp = "MRP is required.";
    // }

    // if (item.offerPrice.trim() === "") {
    //   errors.offerPrice = "Offer Price is required.";
    // }
    // if (!item.sequenceNumber.trim()) {
    //   errors.value = "Sequence Number is required.";
    // }

    // if (item.icon.trim() === "") {
    //   errors.mrp = "ICON is required.";
    // }

    // // if (item.offerPrice.trim() === "") {
    // //   errors.offerPrice = "Offer Price is required.";
    // // }

    // return errors;

    const errors = {};
    if (!item.value) errors.value = "Value is required";
    if (!item.sequenceNumber)
      errors.sequenceNumber = "Sequence Number is required";
    return errors;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!isValidName(inputData?.addonTitle)) {
  //     toast.error("Addon Title must contain only characters", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       theme: "colored",
  //     });
  //     return;
  //   }

  //   setIsLoading(true);

  //   try {
  //     const headers = {
  //       Authorization: `Bearer ${accessToken}`,
  //     };
  //     const removeEmptyFields = (obj) => {
  //       return Object.fromEntries(
  //         Object.entries(obj).filter(([_, v]) => v !== "")
  //       );
  //     };

  //     // Function to check if an addonValue object is empty after cleaning
  //     const isEmptyAddonValue = (obj) => {
  //       return Object.keys(removeEmptyFields(obj)).length === 0;
  //     };

  //     // Clean inputData and remove empty addonValues
  //     const cleanedInputData = {
  //       ...removeEmptyFields(inputData),
  //       addonValues: inputData.addonValues
  //         .map(removeEmptyFields)
  //         .filter((value) => !isEmptyAddonValue(value)),
  //     };

  //     const response = await axios.post("product-addon", cleanedInputData, {
  //       headers,
  //     });

  //     if (response?.data?.status === true) {
  //       toast.success(response?.data?.data?.message, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         theme: "colored",
  //       });
  //       getAddonProductsList();

  //       setInputData({
  //         product: "",
  //         addonTitle: "",
  //         isMultipleSelect: "",
  //         addonValues: Array.from({ length: 1 }, () => ({
  //           value: "",
  //           mrp: "",
  //           offerPrice: "",
  //           sequenceNumber: "",
  //           icon: "",
  //           isDefault: false,
  //           subLabel: "",
  //           status: "INACTIVE"
  //         })),
  //       });

  //       setAddNewOpenModal(false);
  //     }
  //   } catch (error) {
  //     if (error?.response) {
  //       let errorMessage = "";
  //       if (error.response?.status === 406) {
  //         errorMessage =
  //           error?.response?.data?.error?.message ||
  //           error?.response?.data?.error;
  //       } else if (error.response?.status === 412) {
  //         errorMessage = error?.response?.data?.data?.message ||
  //           error?.response?.data?.error;
  //       } else if (error.response?.status === 500) {
  //         errorMessage = error?.response?.data?.error;
  //       }

  //       toast.error(errorMessage, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         theme: "colored",
  //       });
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isValidName(inputData?.addonTitle)) {
      toast.error("Addon Title must contain only characters", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
      return;
    }
  
    setIsLoading(true);
  
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let selectedTitleExcOutlets = selectedExcludedOutlets?.map((ex) => ex?.value);
      let selectedValueExOutlets = selectedValueExcludedOutlets?.map((ex) => ex?.value);    
  
      const removeEmptyFields = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).filter(([_, v]) => v !== "" && v !== null && v !== undefined)
        );
      };
  
      // Function to check if an addonValue object is empty after cleaning
      const isEmptyAddonValue = (obj) => {
        return Object.keys(removeEmptyFields(obj)).length === 0;
      };
  
      // Add excludedOutlets to each addonValue if it's not empty
      const cleanedAddonValues = inputData.addonValues
        .map((addonValue) => {
          const cleanedValue = removeEmptyFields(addonValue);
          if (selectedValueExOutlets?.length > 0) {
            cleanedValue.excludedOutlets = selectedValueExOutlets;
          }
          return cleanedValue;
        })
        .filter((value) => !isEmptyAddonValue(value));
  
      // Clean main inputData and add excludedOutlets if it's not empty
      const cleanedInputData = {
        ...removeEmptyFields(inputData),
        ...(selectedTitleExcOutlets?.length > 0 && { excludedOutlets: selectedTitleExcOutlets }),
        addonValues: cleanedAddonValues,
      };
  
      const response = await axios.post("product-addon", cleanedInputData, {
        headers,
      });
  
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAddonProductsList();
  
        setInputData({
          product: "",
          addonTitle: "",
          isMultipleSelect: "",
          addonValues: Array.from({ length: 1 }, () => ({
            value: "",
            mrp: "",
            offerPrice: "",
            sequenceNumber: "",
            icon: "",
            isDefault: false,
            subLabel: "",
            status: "INACTIVE",
          })),
        });
  
        setSelectedExcludedOutlets([]);
        setSelectedValueExcludedOutlets([]);
        setAddNewOpenModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage =
            error?.response?.data?.data?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }
  
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }; 

  const getAddonProductsList = async (categoryId, page = 1, limit = 10, productId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      // Create params object conditionally
      const params = { page, limit };

      if (categoryId !== "all" && categoryId !== '') {
        params.categoryId = categoryId;  // Add categoryId only if selected
      }

      if (productId !== "all" && productId !== '') {
        params.productId = productId;  // Add categoryId only if selected
      }

      const response = await axios.get("product-addon", { headers, params });
      if (response?.data?.status === true) {
        response?.data?.data?.productAddons.forEach((item, index) => {
          item.serialNumber = index + 1 + (page - 1) * limit; // Calculate serial number based on page
        });
        setAddonProductsList(response?.data?.data?.productAddons);
        setFilteredData(response?.data?.data?.productAddons);
        setTotalRows(response?.data?.data?.totalItems);
        console.log("Addon list response?.data?.data ", response?.data?.data);

      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleDeleteModal = async (data, itemId) => {
    setOpenDeleteModal(true);
    setProductAddonId(data?._id);
  };

  const handleDeleteProductAddons = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: productAddonId,
      };

      const response = await axios.delete(`product-addon`, {
        data,
        headers,
      });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAddonProductsList(categoryId, page, limit, productId);
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = '';
        // error.response.data.error
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleEditModal = async (data, title, id, productId, addonSerialNumber, isMultipleSelect, exOutlets) => {
    setEditedData({
      _id: id,
      product: productId,
      addonTitle: title,
      addonSerialNumber: addonSerialNumber,
      value: data?.value,
      mrp: data?.mrp,
      offerPrice: data.offerPrice,
      addonId: data?._id,
      isDefault: data?.isDefault,
      sequenceNumber: data?.sequenceNumber,
      icon: data?.icon?._id,
      subLabel: data?.subLabel,
      status: data?.status,
      isMultipleSelect: isMultipleSelect,
    });
    setOpenEditModal(true);
    console.log("Edit Modal :", data);
    console.log("Edit Modal Excluled outlets for Addon :", exOutlets);

    let mappedExoutlets = exOutlets?.excludedOutletsDetails?.map((ex) => ({
      value: ex?._id,
      label: ex?.name,
    }));
    
    console.log("mappedExoutlets :", mappedExoutlets);
    
    setSelectedEditedExcludedOutlets(mappedExoutlets);

    let mappedAddonValueExoutlets = data?.excludedOutlets?.map((ex) => ({
      value: ex?._id,
      label: ex?.name,
    }));
    setSelectedEditedValueExcludedOutlets(mappedAddonValueExoutlets);
    
  };

  const handleEditedExSelectChange = (selected) => {
    setSelectedEditedExcludedOutlets(selected);
  };

  const handleEditedValueExSelectChange = (selected) => {
    setSelectedEditedValueExcludedOutlets(selected);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;

    setEditedData((prevState) => {
      let updatedData = { ...prevState, [name]: value };

      if (name === "isDefault") {
        const isDefaultValue = value === "true";
        updatedData = { ...prevState, [name]: isDefaultValue };

        if (isDefaultValue) {
          updatedData = { ...updatedData, mrp: "", offerPrice: "" };
        }
      }

      return updatedData;
    });

    setEditValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!isValidName(editedData?.addonTitle)) {
      toast.error("Addon Title must contain only characters", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
      return;
    }
    if (!isValidName(editedData?.value)) {
      toast.error("Addon value must contain only characters", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
      return;
    }
    if (Object.values(editValidationErrors).some((error) => error !== "")) {
      return;
    }
    setIsEditBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let selectedAddonOutlets = selectedEditedExcludedOutlets?.map((ex) => ex?.value);
      let selectedAddonValueOutlets = selectedEditedValueExcludedOutlets?.map((ex) => ex?.value);

      const data = {
        _id: editedData._id,
        addonTitle: editedData.addonTitle,
        addonSerialNumber: editedData?.addonSerialNumber,
        product: editedData?.product,
        isMultipleSelect: editedData?.isMultipleSelect,
        excludedOutlets: selectedAddonOutlets,
        addonValues: [
          {
            value: editedData.value,
            mrp: editedData.mrp,
            offerPrice: editedData.offerPrice,
            _id: editedData.addonId,
            isDefault: editedData?.isDefault,
            isMultipleSelect: editedData?.isMultipleSelect,
            sequenceNumber: editedData?.sequenceNumber,
            icon: editedData?.icon,
            subLabel: editedData?.subLabel,
            status: editedData?.status,
            excludedOutlets: selectedAddonValueOutlets
          },
        ],
      };

      const cleanedData = {
        ...data,
        addonValues: data.addonValues.map((valueObj) =>
          Object.fromEntries(
            Object.entries(valueObj).filter(
              ([_, v]) => v !== "" && v !== null && v !== undefined
            )
          )
        ),
      };

      const response = await axios.put("product-addon", cleanedData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAddonProductsList(categoryId, page, limit, productId);
        setOpenEditModal(false);
        setEditedData({});
      }
    } catch (error) {
      if (error?.response) {
        console.log("error?.response", error.response);
        
        let errorMessage = "";
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error ||
            error?.response?.data?.message;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const getProductsList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("product", { headers });
      if (response?.data?.status === true) {
        setProductsList(response?.data?.data?.products);
        setFilteredProducts(response?.data?.data?.products)
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const btnDisable = () => {
    let isDisable = false;

    if (!inputData.product?.trim() || !inputData.addonTitle?.trim()) {
      isDisable = true;
    }

    for (let i = 0; i < inputData.addonValues.length; i++) {
      const item = inputData.addonValues[i];

      if (!item.value?.trim() || !item?.sequenceNumber) {
        isDisable = true;
        break;
      }
    }

    return isDisable;
  };

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    setItemInputData((prev) => ({ ...prev, [name]: value }));
  };

  const handleItemExSelectChange = (selected) => {
    setSelectedItemExcludedOutlets(selected);
  };

  const addNewAddonItem = async (e) => {
    e.preventDefault();

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      // Filter out empty or null values from itemInputData
      const filteredItemInputData = Object.fromEntries(
        Object.entries(itemInputData).filter(
          ([key, value]) => value !== "" && value !== null
        )
      );

      let selectedExOutletsForItems = selectedItemExcludedOutlets?.map((se) => se?.value);
      
      const data = {
        addonId: productAddonId,
        addonItemData: {
          ...filteredItemInputData,
          ...(selectedExOutletsForItems?.length > 0 && { excludedOutlets: selectedExOutletsForItems })
        }
      };
      
      const response = await axios.post("product-addon/addAddonItem", data, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAddonProductsList();
        setProductAddonId("");
        setItemInputData({
          value: "",
          mrp: "",
          offerPrice: "",
        });

        setAddNewItemModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenAddonItemDeleteModal = (data, itemId) => {
    setOpenAddonItemDeleteModal(true);
    setProductAddonId(data?._id);
    setAddonItemId(itemId);
  };

  const handleDeleteAddonItem = async (e) => {
    e.preventDefault();
    setIsItemDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        addonId: productAddonId,
        addonItemId: addonItemId,
      };

      const response = await axios.delete(`product-addon/deleteAddonItem`, {
        data,
        headers,
      });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAddonProductsList();
        setOpenAddonItemDeleteModal(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsItemDeleteBtnLoading(false);
    }
  };

  const getIconsList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("product-addon/getAddonIcons", {
        headers,
      });
      if (response?.data?.status === true) {
        const addOnIcons = response?.data?.data?.addonIcons;
        setIconsList(addOnIcons);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getCategoryList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/category/get", { headers });

      if (response?.data?.status === true) {
        setCategoryList(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        
        let filteredOutlets = response?.data?.data?.outlets?.filter((act) => act?.status === "ACTIVE");
        
        setOutlets(filteredOutlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const addBtnDisable = !itemInputData.value?.trim() || !itemInputData?.sequenceNumber;

  const scrollPositionRef = useRef(0);

  useEffect(() => {
    if (openEditModal) {
      // Save the current scroll position
      scrollPositionRef.current = window.scrollY;

      // Lock the scroll
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPositionRef.current}px`;
      document.body.style.width = '100%'; // Ensures no horizontal shift
    } else {
      // Restore the scroll position
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';

      // Scroll to the saved position
      window.scrollTo(0, scrollPositionRef.current);
    }

    // Cleanup function in case the component unmounts
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
    };
  }, [openEditModal]);

  useEffect(() => {
    getProductsList();
    getIconsList();
    getCategoryList();
    getOutlets();
  }, []);

  useEffect(() => {
    getAddonProductsList(categoryId, page, limit, productId);
  }, [categoryId, limit, page, productId])

  useEffect(() => {
    const result = addonProductsList.filter((item) => {
      return (
        (item.addonTitle &&
          item.addonTitle.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.product?.name &&
          item.product?.name.toLowerCase().includes(searchText.toLowerCase()))
      );
    });
    setFilteredData(result);
  }, [searchText, addonProductsList]);

  return (
    <CContainer>

      {/* <------- Category Filter and Add New Addon Button ------> */}
      <CRow className="align-item-center mb-3 justify-content-between">
        <CCol lg={4}>
          <CFormLabel className="fw-semibold">Select Category</CFormLabel>
          <CFormSelect name="select-field" value={categoryId} onChange={(e) => {
            const selectedCategory = e.target.value;
            setCategoryId(selectedCategory);
            if (selectedCategory === 'all') {
              setFilteredProducts(productsList);
            } else {
              setFilteredProducts(
                productsList.filter((product) => product.category._id === selectedCategory)
              );
            }
          }}>
            <option value="all">Category</option>
            {categoryList.map((rm) => (
              <option key={rm._id} value={rm._id}>
                {rm.name}
              </option>
            ))}
          </CFormSelect>
        </CCol>
        <CCol lg={4}>
          <CFormLabel className="fw-semibold">Select Menu</CFormLabel>
          <Select
            options={filteredProducts?.map((rm) => ({
              value: rm?._id,
              label: rm?.name,
            }))}
            value={
              productId
                ? { value: productId, label: filteredProducts?.find((rm) => rm._id === productId)?.name }
                : null
            }
            onChange={(selectedOption) => {
              setProductId(selectedOption ? selectedOption?.value : '');
            }}
            classNamePrefix="react-select"
            isClearable
            placeholder="Select Menu"
          />
        </CCol>
        <CCol lg={4}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CFormLabel className="text-end text-white">.</CFormLabel>
            <div className="text-end">
              <button
                className="btn btn-warning w-50 "
                onClick={handleAddNewOpenModal}
                style={{ borderRadius: "18px" }}
              >

                ADD NEW ADDONS <FaPlus style={{ marginTop: "-4px" }} size={15} />
              </button>
            </div>
          </div>
        </CCol>
      </CRow>

      <div>
        <DataTableComponent
          columns={updatedColumns}
          data={filteredData}
          title="PRODUCT ADDONS LIST"
          searchText={searchText}
          onChange={handleSearchChange}
          onChangePage={handlePageChange} // Handle page changes
          onChangeRowsPerPage={handleRowsPerPageChange} // Handle rows per page changes
          page={page} // Current page
          rowsPerPage={limit}
          totalRows={totalRows} // Total number of rows from API
        />
      </div>

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE MENU ADDON
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteProductAddons}>
            <CModalBody>
              Are you sure you want to delete this product addon ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenDeleteModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                CLOSE
              </CButton>

              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openEditModal ? (
        <CModal
          size="xl"
          alignment="center"
          visible={openEditModal}
          onClose={() => {
            setEditedData({});
            setOpenEditModal(false);
          }}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT MENU ADDON
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Addon Title <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Addon Title"
                      className="form-control"
                      name="addonTitle"
                      value={editedData?.addonTitle}
                      onChange={handleEditChange}
                    />
                    <div className="text-danger">
                      {editValidationErrors.addonTitle}
                    </div>
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Menu <span className="text-danger">*</span>
                    </CFormLabel>

                    <Select
                      options={productsList?.map((rm) => ({
                        value: rm?._id,
                        label: rm?.name,
                      }))}
                      value={
                        editedData?.product
                          ? productsList
                            .map((rm) => ({ value: rm?._id, label: rm?.name }))
                            .find((option) => option.value === editedData?.product)
                          : null
                      }
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          handleEditChange({
                            target: {
                              name: 'product',
                              value: selectedOption.value,
                            },
                          });
                        } else {
                          handleEditChange({
                            target: {
                              name: 'product',
                              value: '',
                            },
                          });
                        }
                      }}
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Select Menu"
                    />
                  </div>
                </CCol>
                <CCol>
                  <CFormLabel className="fw-semibold">
                  Addon Seq No. <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="number"
                    placeholder="Sequence No."
                    className="form-control no-arrows"
                    name="addonSerialNumber"
                    value={editedData.addonSerialNumber}
                    onChange={handleEditChange}
                  />
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">Is It Multi-Select Addon <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect size="sm" name="isMultipleSelect" onChange={handleEditChange} value={editedData?.isMultipleSelect}>
                      <option>Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>

              <CRow className="mb-3">
                <CCol className="col-md-12">
                  <CFormLabel className="fw-semibold">
                      Select Excluded Outlets For Addon
                  </CFormLabel>

                  <Select
                      isMulti
                      components={animatedComponents}
                      value={selectedEditedExcludedOutlets}
                      onChange={handleEditedExSelectChange}
                      options={outletData}
                      styles={customStyles}
                      placeholder="Select Excluded Outlets For Addon" // You can also use a dynamic placeholder from props or state
                      closeMenuOnSelect={false}
                      theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                              ...theme.colors,
                              primary25: "#e9ecef",
                              primary: "#007bff",
                          },
                      })}
                  />
                </CCol>
              </CRow>

              <h6>ADDONS</h6>
              <hr />

              <CRow>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Is Default <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      name="isDefault"
                      onChange={handleEditChange}
                      value={editedData?.isDefault}
                    >
                      <option>Is Default</option>
                      <option value="true">TRUE</option>
                      <option value="false">FALSE</option>
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">Addon MRP</CFormLabel>
                    <CFormInput
                      min={0}
                      step="0.01"
                      type="number"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="mrp"
                      value={editedData?.mrp}
                      onChange={handleEditChange}
                      disabled={editedData?.isDefault == true ? true : false}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Addon Offer Price
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      step="0.01"
                      type="number"
                      placeholder="Addon Offer Price"
                      className="form-control no-arrows"
                      name="offerPrice"
                      value={editedData?.offerPrice}
                      onChange={handleEditChange}
                      disabled={editedData?.isDefault == true ? true : false}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Status
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      name="status"
                      onChange={handleEditChange}
                      value={editedData?.status}
                    >
                      <option value="">Select Status</option>
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="INACTIVE">INACTIVE</option>
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Addon Value <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Addon Value"
                      className="form-control"
                      name="value"
                      value={editedData.value}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Sequence No. <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Sequence No."
                      className="form-control no-arrows"
                      name="sequenceNumber"
                      value={editedData.sequenceNumber}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Select Addon Icon
                    </CFormLabel>
                    <CFormSelect
                      name="icon"
                      onChange={handleEditChange}
                      value={editedData.icon ? editedData?.icon : ""}
                    >
                      <option value="">Select Addon Icon</option>
                      {iconsList.map((rm) => (
                        <option key={rm._id} value={rm._id}>
                          {rm.name}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                </CCol>
                {(editedData.addonTitle === "Size" || editedData.addonTitle === "size") && (
                  <CCol className="col-md-3">
                    <div className="mb-3">
                      <CFormLabel className="fw-semibold">
                        Sub Label
                      </CFormLabel>
                      <CFormInput
                        min={0}
                        type="text"
                        // id={`addonValue-${index}`}
                        placeholder="Sub Label"
                        className="form-control"
                        name="subLabel"
                        value={editedData.subLabel}
                        onChange={handleEditChange}
                      />
                      {/* <div className="text-danger">
                        {validationErrors.addonValues[index]?.subLabel}
                      </div> */}
                    </div>
                  </CCol>
                )}
                <CCol className="col-md-12">
                  <CFormLabel className="fw-semibold">
                      Select Excluded Outlets For Addon Value
                  </CFormLabel>

                  <Select
                      isMulti
                      components={animatedComponents}
                      value={selectedEditedValueExcludedOutlets}
                      onChange={handleEditedValueExSelectChange}
                      options={outletData}
                      styles={customStyles}
                      placeholder="Select Excluded Outlets For Addon Value" // You can also use a dynamic placeholder from props or state
                      closeMenuOnSelect={false}
                      theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                              ...theme.colors,
                              primary25: "#e9ecef",
                              primary: "#007bff",
                          },
                      })}
                  />
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                // isDisable={editButtonDisable}
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {addNewOpenModal ? (
        <CModal
          size="xl"
          alignment="center"
          visible={addNewOpenModal}
          onClose={() => setAddNewOpenModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle className="fs-6">ADD MENU ADDON</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleSubmit}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Menu <span className="text-danger">*</span>
                    </CFormLabel>
                    <Select
                      options={productsList?.map((rm) => ({
                        value: rm?._id,
                        label: rm?.name,
                      }))}
                      value={
                        inputData.product
                          ? { value: inputData?.product, label: productsList?.find(rm => rm._id === inputData?.product)?.name }
                          : null
                      }
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: 'product',
                            value: selectedOption ? selectedOption.value : '',
                          },
                        });
                      }}
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Select Menu"
                    />
                    {validationErrors.product && (
                      <div className="text-danger">
                        {validationErrors.product}
                      </div>
                    )}
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Addon Title <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Addon Title"
                      className="form-control"
                      name="addonTitle"
                      value={inputData?.addonTitle}
                      onChange={handleChange}
                    />
                    {validationErrors.addonTitle && (
                      <div className="text-danger">
                        {validationErrors.addonTitle}
                      </div>
                    )}
                  </div>
                </CCol>

                <CCol>
                  <CFormLabel className="fw-semibold">
                    Sequence No.<span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    min={0}
                    type="number"
                    placeholder="Sequence No"
                    className="form-control no-arrows"
                    name="addonSerialNumber"
                    value={inputData?.addonSerialNumber}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">Is It Multi-Select Addon <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect size="sm" name="isMultipleSelect" onChange={handleChange} value={inputData?.isMultipleSelect}>
                      <option value="" disabled>Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol className="col-md-12">
                  <CFormLabel className="fw-semibold">
                      Select Excluded Outlets
                  </CFormLabel>

                  <Select
                      isMulti
                      components={animatedComponents}
                      value={selectedExcludedOutlets}
                      onChange={handleSelectChange}
                      options={outletData}
                      styles={customStyles}
                      placeholder="Select Excluded Outlets" // You can also use a dynamic placeholder from props or state
                      closeMenuOnSelect={false}
                      theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                              ...theme.colors,
                              primary25: "#e9ecef",
                              primary: "#007bff",
                          },
                      })}
                  />
                </CCol>
              </CRow>

              <h6>ADDONS</h6>
              <hr />

              <CRow>
                {inputData.addonValues &&
                  inputData.addonValues.length > 0 &&
                  inputData.addonValues.map((item, index) => (
                    <div key={index} className="mt-2">
                      <CRow>
                        <CCol className="col-md-3">
                          <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                              Is Default <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormSelect
                              size="sm"
                              name="isDefault"
                              onChange={(e) => handleChange(e, index)}
                              value={item.isDefault}
                            >
                              <option value="">Select</option>
                              <option value="true">TRUE</option>
                              <option value="false">FALSE</option>
                            </CFormSelect>
                            <div className="text-danger">
                              {validationErrors.addonValues[index]?.isDefault}
                            </div>
                          </div>
                        </CCol>
                        <CCol>
                          <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                              Addon MRP
                            </CFormLabel>
                            <CFormInput
                              min={0}
                              step="0.01"
                              type="number"
                              id={`addonValue-${index}`}
                              placeholder="In Rupees"
                              className="form-control no-arrows"
                              name="mrp"
                              value={item.mrp}
                              onChange={(e) => handleChange(e, index)}
                              disabled={item.isDefault === "true"}
                            />
                            <div className="text-danger">
                              {validationErrors.addonValues[index]?.mrp}
                            </div>
                          </div>
                        </CCol>
                        <CCol>
                          <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                              Addon Offer Price
                            </CFormLabel>
                            <CFormInput
                              min={0}
                              step="0.01"
                              type="number"
                              id={`addonValue-${index}`}
                              placeholder="In Rupees"
                              className="form-control no-arrows"
                              name="offerPrice"
                              value={item.offerPrice}
                              onChange={(e) => handleChange(e, index)}
                              disabled={item.isDefault === "true"}
                            />
                            <div className="text-danger">
                              {validationErrors.addonValues[index]?.offerPrice}
                            </div>
                          </div>
                        </CCol>
                        <CCol className="col-md-1">
                          <div className="d-flex justify-content-end mt-2">
                            {index === inputData.addonValues.length - 1 && (
                              <div>
                                <CButton
                                  size="sm"
                                  onClick={handleAddItem}
                                  color="primary"
                                  style={{
                                    marginRight: "3px",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <FaPlus />
                                </CButton>
                              </div>
                            )}

                            {inputData.addonValues.length > 1 && (
                              <div>
                                <CButton
                                  size="sm"
                                  onClick={() => handleRemoveItem(index)}
                                  color="primary"
                                  style={{ borderRadius: "18px" }}
                                >
                                  <FaMinus />
                                </CButton>
                              </div>
                            )}
                          </div>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol className={inputData.addonTitle === "Size" || inputData.addonTitle === "size" ? 'col-md-3' : 'col-md-4'}>
                          <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                              Addon Value <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              type="text"
                              id={`addonValue-${index}`}
                              placeholder="Addon Value"
                              className="form-control"
                              name="value"
                              value={item.value}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <div className="text-danger">
                              {validationErrors.addonValues[index]?.value}
                            </div>
                          </div>
                        </CCol>
                        <CCol className={inputData.addonTitle === "Size" || inputData.addonTitle === "size" ? 'col-md-3' : 'col-md-4'}>
                          <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                              Sequence No.<span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              min={0}
                              type="number"
                              id={`addonValue-${index}`}
                              placeholder="Sequence No."
                              className="form-control no-arrows"
                              name="sequenceNumber"
                              value={item.sequenceNumber}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <div className="text-danger">
                              {
                                validationErrors.addonValues[index]
                                  ?.sequenceNumber
                              }
                            </div>
                          </div>
                        </CCol>
                        <CCol className={inputData.addonTitle === "Size" || inputData.addonTitle === "size" ? 'col-md-3' : 'col-md-4'}>
                          <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                              Select Addon Icon
                            </CFormLabel>
                            <CFormSelect
                              size="sm"
                              name="icon"
                              onChange={(e) => handleChange(e, index)}
                              value={item.icon}
                            >
                              <option value=""> Select Addon Icon</option>
                              {iconsList.map((rm) => (
                                <option key={rm._id} value={rm._id}>
                                  {rm.name}
                                </option>
                              ))}
                            </CFormSelect>
                            <div className="text-danger">
                              {validationErrors.addonValues[index]?.icon}
                            </div>
                          </div>
                        </CCol>

                        {(inputData.addonTitle === "Size" || inputData.addonTitle === "size") && (
                          <CCol className="col-md-3">
                            <div className="mb-3">
                              <CFormLabel className="fw-semibold">
                                Weight(20mg, 20ml,....)
                              </CFormLabel>
                              <CFormInput
                                min={0}
                                type="text"
                                id={`addonValue-${index}`}
                                placeholder="Weight(20mg, 20ml,....)"
                                className="form-control"
                                name="subLabel"
                                value={item.subLabel}
                                onChange={(e) => handleChange(e, index)}
                              />
                              <div className="text-danger">
                                {validationErrors.addonValues[index]?.subLabel}
                              </div>
                            </div>
                          </CCol>
                        )}
                        <CCol className="col-md-12 mb-2">
                            <CFormLabel className="fw-semibold">
                                Select Excluded Outlets For Values
                            </CFormLabel>

                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedValueExcludedOutlets}
                                onChange={handleSelectValueExChange}
                                options={outletData}
                                styles={customStyles}
                                placeholder="Select Excluded Outlets For Values" // You can also use a dynamic placeholder from props or state
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                          </CCol>
                      </CRow>
                    </div>
                  ))}
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                isDisable={btnDisable()}
                title="ADD PRODUCT ADDON"
                isLoading={isLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {addNewItemModal ? (
        <CModal
          size="xl"
          alignment="center"
          visible={addNewItemModal}
          onClose={() => {
            setItemInputData({});
            setSelectedItemExcludedOutlets([]);
            setAddNewItemModal(false);
          }}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle className="fs-6">ADD ADDON ITEM</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={addNewAddonItem}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Is Default <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      onChange={handleItemChange}
                      value={itemInputData.isDefault}
                      name="isDefault"
                    >
                      <option>Is Default</option>
                      <option value="true">TRUE</option>
                      <option value="false">FALSE</option>
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">Addon MRP</CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="mrp"
                      value={itemInputData.mrp}
                      onChange={handleItemChange}
                      disabled={itemInputData?.isDefault === "true"}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Addon Offer Price
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="offerPrice"
                      value={itemInputData.offerPrice}
                      onChange={handleItemChange}
                      disabled={itemInputData?.isDefault === "true"}
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Addon Value <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Addon Value"
                      className="form-control"
                      name="value"
                      value={itemInputData.value}
                      onChange={handleItemChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Sequence No. <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Sequence No"
                      className="form-control no-arrows"
                      name="sequenceNumber"
                      value={itemInputData.sequenceNumber}
                      onChange={handleItemChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">Select Icon</CFormLabel>
                    <CFormSelect>
                      <option>Select Icon</option>
                      {iconsList.map((rm) => (
                        <option key={rm._id} value={rm._id}>
                          {rm.name}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol className="col-md-12">
                  <CFormLabel className="fw-semibold">
                      Select Excluded Outlets For Addon Value
                  </CFormLabel>

                  <Select
                      isMulti
                      components={animatedComponents}
                      value={selectedItemExcludedOutlets}
                      onChange={handleItemExSelectChange}
                      options={outletData}
                      styles={customStyles}
                      placeholder="Select Excluded Outlets For Addon Value" // You can also use a dynamic placeholder from props or state
                      closeMenuOnSelect={false}
                      theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                              ...theme.colors,
                              primary25: "#e9ecef",
                              primary: "#007bff",
                          },
                      })}
                  />
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton title="ADD ADDON ITEM" isDisable={addBtnDisable} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openAddonItemDeleteModal ? (
        <CModal
          alignment="center"
          visible={openAddonItemDeleteModal}
          onClose={() => setOpenAddonItemDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE MENU ADDON ITEM
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteAddonItem}>
            <CModalBody>
              Are you sure you want to delete this product addon item?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenAddonItemDeleteModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                CLOSE
              </CButton>

              <PrimaryButton
                title="CONFIRM"
                isLoading={isItemDeleteBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </CContainer>
  );
};

export default ProductAddOn;
