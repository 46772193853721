import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { PrimaryButton } from "../../components";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const Products = () => {
  const accessToken = localStorage.getItem("authToken");
  const [inputData, setInputData] = useState({
    sequenceNumber: "",
    category: "",
    name: "",
    description: "",
    hsn: "",
    taxPercent: "",
    makingTime: "",
    mrp: "",
    offerPrice: "",
    productTagId: "",
    productTagName: "",
    keywords: [],
    foodType: "",
    classType: "",
    calories: "",
    weight: "",
    weightUnit: "",
    isEventProduct: "",
  });
  const [categoryList, setCategoryList] = useState([]);
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productTags, setProductTags] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [selected, setSelected] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [selectedExcludedOutlets, setSelectedExcludedOutlets] = useState([]);
  const animatedComponents = makeAnimated();
  const [coverImage, setCoverImage] = useState(null);

  const outletData = outlets.map((outlet) => ({
    value: outlet._id,
    label: `${outlet.name} (${outlet.city})`,
  }));

  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        borderRadius: "6px",
        minWidth: "200px",
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
        "&:hover": {
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#6c757d",
    }),
  };
  
  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        
        let filteredOutlets = response?.data?.data?.outlets?.filter((act) => act?.status === "ACTIVE");
        
        setOutlets(filteredOutlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleSelectChange = async (selected) => {
    setSelectedExcludedOutlets(selected);
  }

  const handleSearchText = (e) => {
    setSearchText(e.target.value)
    setInputData((prev) => ({ ...prev, productTagName: e.target.value }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevState) => {
      if (name === "productTagId") {
        const selectedTag = productTags.find((tag) => tag._id === value);
        return {
          ...prevState,
          [name]: value,
          productTagName: selectedTag ? selectedTag.tagName : "",
        };
      }
      return { ...prevState, [name]: value };
    });
  };

  const handleImageChange = (e) => {
    const input = document.getElementById("imageInput");

    const file = input.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const maxWidth = 400;
          const maxHeight = 400;

          if (width === maxWidth && height === maxHeight) {
            alert("Image dimensions are valid!");
            setImage(file);
          } else {
            alert(
              "Image dimensions are not valid. Please choose an image with dimensions equal to " +
              maxWidth +
              "x" +
              maxHeight
            );
            input.value = "";
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCoverImageChange = () => {
    const input = document.getElementById("coverImageInput");

    const file = input.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;
        setCoverImage(file);

        // image.onload = () => {
        //   const width = image.width;
        //   const height = image.height;

        //   const maxWidth = 400;
        //   const maxHeight = 400;

        //   if (width === maxWidth && height === maxHeight) {
        //     alert("Image dimensions are valid!");
        //     setCoverImage(file);
        //   } else {
        //     alert(
        //       "Image dimensions are not valid. Please choose an image with dimensions equal to " +
        //       maxWidth +
        //       "x" +
        //       maxHeight
        //     );
        //     input.value = "";
        //   }
        // };
      };

      reader.readAsDataURL(file);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData();
      let _prodKeywords;
      if (selected.length > 0) {
        const _selectedodified = selected.map(val => val.trim());
        _prodKeywords = _selectedodified.join(',');
      }
      else {
        toast.error("Product keywords is required", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        return;
      }

      let selectOutlets = selectedExcludedOutlets.map((val) => val?.value);
      console.log("Selected Outlets :", selectOutlets);
      
      let newInputData = {
        ...inputData,
        excludedOutlets: selectOutlets
      }

      if (image) {
        formData.append("displayImage", image);
      }
      if (coverImage) {
        formData.append("coverPicture", coverImage);
      }
      formData.append("sequenceNumber", newInputData.sequenceNumber);
      formData.append("category", newInputData.category);
      formData.append("name", newInputData.name);
      formData.append("description", newInputData.description);
      formData.append("hsn", newInputData.hsn);
      formData.append("taxPercent", newInputData.taxPercent);
      formData.append("makingTime", newInputData.makingTime);
      formData.append("mrp", newInputData.mrp);
      formData.append("offerPrice", newInputData.offerPrice);
      formData.append("isEventProduct", newInputData.isEventProduct);
      formData.append("keywords", _prodKeywords);
      if (newInputData?.productTagId) {
        formData.append("productTag[productTagId]", newInputData.productTagId);
      }
      formData.append("productTag[productTagName]", newInputData.productTagName);
      formData.append("foodType", newInputData.foodType);
      formData.append("classType", newInputData.classType);
      formData.append("calories", newInputData.calories);
      formData.append("weight", newInputData.weight);
      formData.append("weightUnit", newInputData.weightUnit);

      // Append each excluded outlet as excludedOutlets[0], excludedOutlets[1], etc.
      if (selectOutlets?.length > 0) {
        selectOutlets.forEach((outlet, index) => {
          formData.append(`excludedOutlets[${index}]`, outlet);
        });
      }

      // Debugging: log the formData content
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post("product", formData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        e.target.reset();

        setInputData({
          sequenceNumber: "",
          category: "",
          name: "",
          description: "",
          hsn: "",
          taxPercent: "",
          makingTime: "",
          mrp: "",
          offerPrice: "",
          hasAddon: "",
          status: "",
          classType: "",
          isEventProduct: ""
        });
        setSelected([]);
        setSelectedExcludedOutlets([]);
        const input = document.getElementById("imageInput");
        input.value = "";
        setImage("");
        setCoverImage("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getCategoryList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/category/get", { headers });

      if (response?.data?.status === true) {
        setCategoryList(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getProductTags = async (text) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`/product/tags?search=${text}`, { headers });

      if (response?.data?.status === true) {
        setIsSelected(true)
        setProductTags(response?.data?.data?.productTags);
      } else {
        setProductTags([])
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleOptionSelect = (selectedOption, selectedName) => {
    setInputData((prev) => ({ ...prev, productTagName: selectedName, productTagId: selectedOption }));
    setProductTags([]);
  };

  useEffect(() => {
    getCategoryList();
    getOutlets();
  }, []);

  useEffect(() => {
    getProductTags(searchText);
  }, [searchText]);

  return (
    <CContainer>
      <CRow className="mb-3">
        <CCol className="m-auto col-md-12">
          <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
            <CCardHeader>
              <CCardTitle className="fs-6 fw-semibold pt-1">
                ADD MENU
              </CCardTitle>
            </CCardHeader>
            <CForm onSubmit={handleSubmit}>
              <CCardBody>
              
              <CRow className="gy-3"> {/* Adding consistent vertical spacing */}
                  {/* Sequence Number */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Sequence Number <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="Sequence Number"
                      className="form-control no-arrows"
                      name="sequenceNumber"
                      value={inputData?.sequenceNumber}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Select Category */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Select Category <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="category"
                      value={inputData.category}
                    >
                      <option value="">Select Category</option>
                      {categoryList.map((rm) => (
                        <option key={rm._id} value={rm._id}>{rm.name}</option>
                      ))}
                    </CFormSelect>
                  </CCol>

                  {/* Name */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Name <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={inputData.name}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Class Type */}
                  <CCol md={6} lg={3}>
                    <CFormLabel className="fw-semibold">Class Type <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="classType"
                      value={inputData.classType}
                    >
                      <option value="">Class Type</option>
                      <option value="FOOD">FOOD</option>
                      <option value="BEVERAGE">BEVERAGE</option>
                    </CFormSelect>
                  </CCol>

                  {/* Food Type */}
                  <CCol md={6} lg={3}>
                    <CFormLabel className="fw-semibold">Food Type <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="foodType"
                      value={inputData.foodType}
                    >
                      <option value="">Food Type</option>
                      <option value="VEG">VEG</option>
                      <option value="NON-VEG">NON-VEG</option>
                      <option value="VEGAN">VEGAN</option>
                      <option value="VEG/VEGAN">VEG/VEGAN</option>
                    </CFormSelect>
                  </CCol>

                  {/* HSN */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">HSN <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="HSN"
                      name="hsn"
                      value={inputData.hsn}
                      onChange={handleChange}
                    />
                  </CCol>  

                  {/* Weight */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Weight <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      step= "any" // Allows decimal values
                      type="number"
                      placeholder="Weight"
                      className="form-control no-arrows"
                      name="weight"
                      value={inputData.weight}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Weight Unit */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Weight Unit <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="weightUnit"
                      value={inputData.weightUnit}
                    >
                      <option value="">Weight Unit</option>
                      <option value="KG">KILOGRAMS</option>
                      <option value="GMS">GRAMS</option>
                      <option value="LITRES">LITRES</option>
                      <option value="ML">MILLI-LITRE</option>
                    </CFormSelect>
                  </CCol>

                  {/* Calories */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Calories <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      step= "any" // Allows decimal values
                      type="number"
                      placeholder="Calories"
                      className="form-control no-arrows"
                      name="calories"
                      value={inputData.calories}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Tax Percent */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Tax Percent <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      max={100}
                      step= "any" // Allows decimal values
                      type="number"
                      placeholder="Tax Percent"
                      name="taxPercent"
                      value={inputData.taxPercent}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Making Time */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Making Time <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="In Mins"
                      name="makingTime"
                      value={inputData.makingTime}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* MRP */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">MRP <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="In Rupees"
                      name="mrp"
                      value={inputData.mrp}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Offer Price */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Offer Price <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="In Rupees"
                      name="offerPrice"
                      value={inputData.offerPrice}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Product Tag Name */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Product Tag Name <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Product Tag Name"
                      name="productTagName"
                      onChange={handleSearchText}
                      value={inputData?.productTagName}
                    />
                    {productTags && productTags.length > 0 && (
                      <div className="dropdown-menu show">
                        {productTags.map((prod) => (
                          <div style={{ marginTop: '-10px', padding: '5px', cursor: 'pointer' }} key={prod._id} onClick={() => handleOptionSelect(prod._id, prod.tagName)}>
                            {prod.tagName}
                          </div>
                        ))}
                      </div>
                    )}
                  </CCol>

                  {/* Is Product Event */}                 

                  {/* Image Upload */}
                  <CCol md={12} lg={3}>
                    <CFormLabel className="fw-semibold">Choose Image <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      id="imageInput"
                      accept="image/*"
                      type="file"
                      onChange={handleImageChange}
                    />
                  </CCol>
                  <CCol md={12} lg={3}>
                    <CFormLabel className="fw-semibold">Choose Cover Image <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      id="coverImageInput"
                      accept="image/*"
                      type="file"
                      onChange={handleCoverImageChange}
                    />
                  </CCol>

                  <CRow className="mt-2">
                    <CCol className="col-md-9">
                      <CFormLabel className="fw-semibold">
                          Select Excluded Outlets
                      </CFormLabel>

                      <Select
                          isMulti
                          components={animatedComponents}
                          value={selectedExcludedOutlets}
                          onChange={handleSelectChange}
                          options={outletData}
                          styles={customStyles}
                          placeholder="Select Excluded Outlets" // You can also use a dynamic placeholder from props or state
                          closeMenuOnSelect={false}
                          theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                  ...theme.colors,
                                  primary25: "#e9ecef",
                                  primary: "#007bff",
                              },
                          })}
                      />
                    </CCol>
                    <CCol md={4} lg={3}>
                      <CFormLabel className="fw-semibold">Outlet Type <span className="text-danger">*</span></CFormLabel>
                      <CFormSelect
                        size="sm"
                        onChange={handleChange}
                        name="isEventProduct"
                        value={inputData.isEventProduct}
                      >
                        <option value="" disabled>Select Outlet Type</option>
                        <option value="false">Regular Outlet</option>
                        <option value="true">Event Outlet</option>
                      </CFormSelect>
                    </CCol>
                  </CRow>

                  {/* Product Keywords */}
                  <CCol md={12} lg={6}>
                    <CFormLabel className="fw-semibold">Product Keywords <span className="text-danger">*</span> <span style={{ fontSize: '13px', color: 'purple', fontWeight: 'normal' }}>(Press Enter After Adding Each Keyword).</span></CFormLabel>
                    <TagsInput
                      value={selected}
                      onChange={setSelected}
                      name="keywords"
                      placeHolder="Product Keywords"
                    />
                  </CCol>

                  {/* Description */}
                  <CCol md={12} lg={6}>
                    <CFormLabel className="fw-semibold">Description <span className="text-danger">*</span></CFormLabel>
                    <CFormTextarea
                      rows="2"
                      placeholder="Description"
                      name="description"
                      value={inputData.description}
                      onChange={handleChange}
                    />
                  </CCol>
              </CRow>

              </CCardBody>
              <CCardFooter>
                <PrimaryButton
                  // isDisable={btnDisable()}
                  title="ADD PRODUCT"
                  isLoading={isLoading}
                />
              </CCardFooter>
            </CForm>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default Products;
