import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormLabel, CFormSelect, CRow } from '@coreui/react'
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { PrimaryButton } from '../../components';

const BoosterPoint = ({ levels, couponsData }) => {
    const accessToken = localStorage.getItem("authToken");
    const [inputData, setInputData] = useState({
        level: "",
        type: "",
        rewardType: "",
        interval: "",
        value: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [filteredLevels, setFilteredLevels] = useState([])

    const filterLevels = () => {
        const level = levels?.filter((item) => item.sequenceNo !== 1);
        const filteredLevels = level?.filter((item) => item?.sequenceNo !== 2)
        setFilteredLevels(filteredLevels)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setInputData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const data = {
                level: inputData?.level,
                type: inputData?.type,
                rewardType: inputData?.rewardType,
                applyingConditions: {
                    interval: inputData?.interval,
                    value: inputData?.value
                }
            }
            const response = await axios.post("level-reward", data, { headers });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setInputData({
                    level: "",
                    type: "",
                    rewardType: "",
                    interval: "",
                    value: ""
                })
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        filterLevels();
    }, [])
    return (
        <CCard>
            <CCardHeader>
                <CCardTitle>
                    ADD POINT BOOSTER REWARD
                </CCardTitle>
            </CCardHeader>
            <CForm onSubmit={handleSubmit}>
                <CCardBody>
                    <CRow>
                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Level
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="level"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.level}
                                >
                                    <option value="">Select Level</option>
                                    {filteredLevels.map((level) => (
                                        <option key={level._id} value={level._id}>
                                            {level.title}
                                        </option>
                                    ))}
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Type
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="type"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.type}
                                >
                                    <option value="">Select Type</option>
                                    <option value="COUPON">COUPON</option>
                                    <option value="REWARD">REWARD</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Reward Type
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="rewardType"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.rewardType}
                                >
                                    <option value="">Select Reward Type</option>
                                    <option value="POINT_BOOSTER">POINT BOOSTER</option>
                                    <option value="GIFT">GIFT</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        <hr />
                        <h6>Applying Conditions</h6>
                        <hr />

                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Time Interval
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="interval"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.interval}
                                >
                                    <option value="">Select Time Interval</option>
                                    <option value="WEEKLY">WEEKLY</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Day
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="value"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.value}
                                >
                                    <option value="">Select Day</option>
                                    <option value="0">SUNDAY</option>
                                    <option value="1">MONDAY</option>
                                    <option value="2">TUESDAY</option>
                                    <option value="3">WEDNESDAY</option>
                                    <option value="4">THURSDAY</option>
                                    <option value="5">FRIDAY</option>
                                    <option value="6">SATURDAY</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton title={"ADD"} isLoading={isLoading} />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default BoosterPoint
