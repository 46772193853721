import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CContainer, CFooter, CForm, CFormInput, CFormLabel, CFormSelect, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { PrimaryButton } from '../../components';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";

const AddAdvertisement = () => {
  const accessToken = localStorage.getItem("authToken");
  const [productsList, setProductsList] = useState([]);
  const [inputData, setInputData] = useState({
    title: "",
    type: "",
    sequenceNo: "",
    product: "",
    isTopImage: "",
    isBottomImage: "",
    link: ""
  });
  const animatedComponents = makeAnimated();
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [selectedExcludedOutlets, setSelectedExcludedOutlets] = useState([]);

  const outletData = outlets?.map((outlet) => ({
    value: outlet._id,
    label: `${outlet.name} (${outlet.city})`,
  }));

  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        borderRadius: "6px",
        minWidth: "200px",
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
        "&:hover": {
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#6c757d",
    }),
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  }

  const handleImageChange = () => {
    const input = document.getElementById("imageInput");
    const file = input.files[0];

    if (file) {
      const reader = new FileReader();

      // reader.onloadend = () => {
      //   const image = new Image();
      //   image.src = reader.result;

      //   image.onload = () => {
      //     const width = image.width;
      //     const height = image.height;

      //     const aspectRatio = width / height;
      //     const targetAspectRatio = 16 / 9;

      //     if (Math.abs(aspectRatio - targetAspectRatio) < 0.01) {
      //       alert("Image dimensions are valid!");
      //       setImage(file);
      //     } else {
      //       alert(
      //         "Image dimensions are not valid. Please choose an image with a 16:9 aspect ratio."
      //       );
      //       input.value = "";
      //     }
      //   };
      // };

      setImage(file);

      reader.readAsDataURL(file);
    }
  };

  const getProductsList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("product", { headers });
      if (response?.data?.status === true) {
        setProductsList(response?.data?.data?.products);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        
        let filteredOutlets = response?.data?.data?.outlets?.filter((act) => act?.status === "ACTIVE");
        
        setOutlets(filteredOutlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {        
    setToDate(date);
  };

  const handleSelectChange = (selected) => {
    setSelectedExcludedOutlets(selected);
  }

  const addAdvertisement = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
      // Format the dates
      const updatedStartDate = fromDate
          ? new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000)
                .toISOString()
                .split("T")[0]
          : null;

      const updatedEndDate = toDate
          ? new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000)
                .toISOString()
                .split("T")[0]
          : null;


      console.log("updatedStartDate :", updatedStartDate);
      console.log("updatedEndDate :", updatedEndDate);

      let selectOutlets = selectedExcludedOutlets.map((val) => val?.value);
      console.log("Selected Outlets :", selectOutlets);
      
      let newInputData = {
        ...inputData,
        excludedOutlets: selectOutlets
      }

      const formData = new FormData();
      formData.append("title", newInputData.title);
      formData.append("type", newInputData.type);
      formData.append("sequenceNo", newInputData.sequenceNo);
      if(updatedStartDate !== null){
        formData.append("validityStartDate", updatedStartDate);
      }
      if(updatedEndDate !== null){
        formData.append("validityEndDate", updatedEndDate);
      }
      if (newInputData?.product !== "") {
        formData.append("product", newInputData.product);
      }
      if (newInputData?.isTopImage !== "") {
        formData.append("isTopImage", newInputData.isTopImage);
      }
      if (newInputData?.isBottomImage !== "") {
        formData.append("isBottomImage", newInputData.isBottomImage);
      }
      if (newInputData?.link !== "") {
        formData.append("link", newInputData.link);
      }
      formData.append("adImage", image);

      // Append each excluded outlet as excludedOutlets[0], excludedOutlets[1], etc.
      if (selectOutlets?.length > 0) {
        selectOutlets.forEach((outlet, index) => {
          formData.append(`excludedOutlets[${index}]`, outlet);
        });
      }

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post("advertisement", formData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        e.target.reset();
        setInputData({
          title: '',
          type: '',
          sequenceNo: '',
          product: '',
          link: '',
          isTopImage: '',
          isBottomImage: ''
        })
        setFromDate(null);
        setToDate(null);
        setSelectedExcludedOutlets([]);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if ( error.response?.status === 406 ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if ( error.response?.status === 412 ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getProductsList();
    getOutlets();
  }, [])

  return (
    <CContainer className='d-flex justify-content-center'>
      <CCard 
      // className='w-100 w-lg-100 w-md-100' 
        className='w-75 w-lg-75 w-md-90'
      >
        <CCardHeader>
          <CCardTitle className="fs-6 fw-semibold pt-1">
            ADD ADVERTISEMENT
          </CCardTitle>
        </CCardHeader>
        <CForm onSubmit={addAdvertisement}>
          <CCardBody>
            <CRow>
              <CCol>
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="Title"
                    className="form-control"
                    name="title"
                    value={inputData.title}
                    onChange={handleInputChange}
                  />
                </div>
              </CCol>
              <CCol>
                <div>
                  <CFormLabel className="fw-semibold">
                    Select Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    onChange={handleInputChange}
                    value={inputData?.type}
                    name="type"
                    className="mb-3"
                    aria-label="Small select example"
                  >
                    <option value="">Select Type</option>
                    <option value="CLICKABLE">CLICKABLE</option>
                    <option value="NON-CLICKABLE">NON CLICKABLE</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Sequence No. <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="number"
                    placeholder="Sequence No."
                    className="form-control no-arrows"
                    name="sequenceNo"
                    value={inputData?.sequenceNo}
                    onChange={handleInputChange}
                  />
                </div>
              </CCol>
              { inputData?.type === "NON-CLICKABLE" ? (
                <>
                  <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Top Image{" "}
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleInputChange}
                      name="isTopImage"
                      value={inputData?.isTopImage}
                      className="mb-3"
                      aria-label="Small select example"
                    >
                      <option value="" disabled>Is it Top Image</option>
                      <option key="isTopImage" value="true">YES</option>
                      <option key="isTopImage" value="false">NO</option>
                    </CFormSelect>
                  </div>
                  </CCol>
                  <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Bottom Image{" "}
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleInputChange}
                      name="isBottomImage"
                      value={inputData?.isBottomImage}
                      className="mb-3"
                      aria-label="Small select example"
                    >
                      <option value="" disabled>Is It Bottom Image</option>
                        <option key="isBottomImage" value="true">YES</option>
                        <option key="isBottomImage" value="false">NO</option>
                    </CFormSelect>
                  </div>
                  </CCol>
                </>
              ) : (
                // <CCol>
                //   <div className="mb-3">
                //     <CFormLabel className="fw-semibold">
                //       Select Product{" "}
                //       <span className="text-danger">*</span>
                //     </CFormLabel>
                //     <CFormSelect
                //       size="sm"
                //       onChange={handleInputChange}
                //       name="product"
                //       value={inputData?.product}
                //       className="mb-3"
                //       aria-label="Small select example"
                //     >
                //       <option value="">Select Product</option>
                //       {productsList.map((rm) => (
                //         <option key={rm._id} value={rm._id}>
                //           {rm.name}
                //         </option>
                //       ))}
                //     </CFormSelect>
                //   </div>
                // </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Product <span className="text-danger">*</span>
                    </CFormLabel>
                    <Select
                      options={productsList.map((rm) => ({
                        value: rm._id,
                        label: rm.name,
                      }))}
                      value={
                        inputData?.product
                          ? productsList
                              .map((rm) => ({ value: rm._id, label: rm.name }))
                              .find((option) => option.value === inputData.product)
                          : null
                      }
                      onChange={(selectedOption) => {
                        if (typeof selectedOption === 'object' && selectedOption !== null) {
                          handleInputChange({ target: { name: 'product', value: selectedOption.value } });
                        } else {
                          handleInputChange({ target: { name: 'product', value: '' } });
                        } 
                      }}
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Select Product"
                    />
                  </div>
                </CCol>
              ) }
              
            </CRow>

            <CRow className='mb-2'>
              <CCol md="3">
                <div className='d-flex' style={{ flexDirection: 'column' }}>
                    <CFormLabel className="fw-semibold">
                        Validity Start Date
                    </CFormLabel>
                    <DatePicker
                        selected={fromDate}
                        onChange={handleFromDateChange}
                        placeholderText="Start Date"
                        className="form-control"
                        isClearable
                    />
                </div>
              </CCol>
              <CCol md="3">
                  <div className='d-flex' style={{ flexDirection: 'column' }}>
                      <CFormLabel className="fw-semibold">
                          Validity End Date
                      </CFormLabel>
                      <DatePicker
                          selected={toDate}
                          onChange={handleToDateChange}
                          placeholderText="End Date"
                          className="form-control"
                          isClearable
                      />
                  </div>
              </CCol>
              <CCol className="col-md-6">
                <CFormLabel className="fw-semibold">
                    Select Excluded Outlets
                </CFormLabel>

                <Select
                    isMulti
                    components={animatedComponents}
                    value={selectedExcludedOutlets}
                    onChange={handleSelectChange}
                    options={outletData}
                    styles={customStyles}
                    placeholder="Select Excluded Outlets"
                    closeMenuOnSelect={false}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: "#e9ecef",
                            primary: "#007bff",
                        },
                    })}
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6" lg="6">
                <CFormLabel className='fw-semibold'>Add Link</CFormLabel>
                <CFormInput 
                  type="text"
                  placeholder="Add Link"
                  className="form-control"
                  name="link"
                  value={inputData.link}
                  disabled={inputData.type === "CLICKABLE"}
                  onChange={handleInputChange} 
                />
              </CCol>
              <CCol md="6" lg="6">
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Choose File <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="file"
                    onChange={handleImageChange}
                    className="form-control"
                    id="imageInput"
                  />
                  <p className='text-danger' style={{ fontSize: '13px' }}>Choose an image with 1920x1080 pixels</p>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <PrimaryButton title="ADD ADVERTISEMENT" isLoading={isLoading} />
          </CCardFooter>
        </CForm>
      </CCard>

    </CContainer>
  )
}

export default AddAdvertisement
