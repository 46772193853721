import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import {
  CForm,
  CFormSelect,
  CFormInput,
  CButton,
  CContainer,
  CRow,
  CCol,
  CCard,
  CFormLabel,
} from "@coreui/react";
import Select from "react-select";

const StorageLocationStTransfer = () => {
    const accessToken = localStorage.getItem("authToken");
    const [storageLocations, setStorageLocations] = useState([]);
    const [rawMaterials, setRawMaterials] = useState([]);
    const [secondaryStorageLocations, setSecondaryStorageLocations] = useState([]);
    const [formData, setFormData] = useState({
      primaryStorageLocation: "",
      secondaryStorageLocation: "",
      centralStockTransferData: [],
    });
    const [selectedRawMaterial, setSelectedRawMaterial] = useState({
      rawMaterialId: "",
      currentStockQuantity: 0,
      transferQuantity: 0,
      unit: "",
    });
  
    const storageLocationOptions = storageLocations.map((location) => ({
      value: location._id,
      label: `${location.name} - ${location.city}, ${location.state} `,
    }));

    const secondaryStorageLocationOptions = secondaryStorageLocations.map((location) => ({
      value: location._id,
      label: `${location.name} - ${location.city}, ${location.state} `,
    }));

    console.log("secondaryStorageLocationOptions :", secondaryStorageLocationOptions);
    
  
    const getNewStorageLocationData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.get("storage-location/getCurrentStock", { headers });
        if (response?.data?.data?.storageLocations) {
          setStorageLocations(response?.data?.data?.storageLocations);
        }
      } catch (error) {
        toast.error("Failed to fetch storage location data.", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    };
  
    const handlePrimaryStorageChange = (option) => {
      setFormData({
        ...formData,
        primaryStorageLocation: option?.value || "",
        centralStockTransferData: [],
      });
  
      const selectedStorage = storageLocations.find((loc) => loc._id === option?.value);
      setRawMaterials(selectedStorage?.stockInfo || []);
    };
  
    const handleSecondaryStorageChange = (option) => {
      setFormData({
        ...formData,
        secondaryStorageLocation: option?.value || "",
      });
    };
  
    const rawMaterialOptions = rawMaterials
      .filter((material) => {
        const primaryLocation = storageLocations.find(
          (loc) => loc._id === formData.primaryStorageLocation
        );
        return (
          primaryLocation &&
          primaryLocation.stockInfo.some(
            (stockItem) => stockItem.rawMaterial._id === material.rawMaterial._id
          )
        );
      })
      .map((material) => ({
        value: material.rawMaterial._id,
        label: material.rawMaterial.name,
        unit: material.rawMaterial.unit,
        currentStock: material.currentStock,
      }));
  
    const handleRawMaterialChange = (selectedOption) => {
      if (selectedOption) {
        setSelectedRawMaterial({
          rawMaterialId: selectedOption.value,
          currentStockQuantity: selectedOption.currentStock,
          transferQuantity: "",
          unit: selectedOption.unit,
        });
      } else {
        setSelectedRawMaterial({
          rawMaterialId: "",
          currentStockQuantity: 0,
          transferQuantity: "",
          unit: "",
        });
      }
    };
  
    const handleTransferQuantityChange = (e) => {
      setSelectedRawMaterial((prev) => ({
        ...prev,
        transferQuantity: e.target.value,
      }));
    };

    const handleRawMaterialChangeInList = (selectedOption, index) => {
        const updatedTransferData = [...formData.centralStockTransferData];
        updatedTransferData[index].rawMaterialId = selectedOption ? selectedOption.value : "";  // Get the value from the selected option
        setFormData((prev) => ({
          ...prev,
          centralStockTransferData: updatedTransferData,
        }));
    };
      

    const handleCurrentStockQuantityChangeInList = (e, index) => {
        const updatedTransferData = [...formData.centralStockTransferData];
        updatedTransferData[index].currentStockQuantity = e.target.value;
        setFormData((prev) => ({
          ...prev,
          centralStockTransferData: updatedTransferData,
        }));
    };
      
    const handleUnitChangeInList = (e, index) => {
        const updatedTransferData = [...formData.centralStockTransferData];
        updatedTransferData[index].unit = e.target.value;
        setFormData((prev) => ({
          ...prev,
          centralStockTransferData: updatedTransferData,
        }));
    };
  
    const addToTransferList = () => {
      if ( selectedRawMaterial.rawMaterialId && selectedRawMaterial.transferQuantity > 0 ) {
        setFormData((prev) => ({
          ...prev,
          centralStockTransferData: [...prev.centralStockTransferData, selectedRawMaterial],
        }));
        setSelectedRawMaterial({
          rawMaterialId: "",
          currentStockQuantity: 0,
          transferQuantity: 0,
          unit: "",
        });
      } else {
        toast.error("Please select a material and enter a valid quantity.");
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.post("/stock-transactions/stockTransferBetweenStorageLocations", formData, { headers });
        if (response?.data?.status === true) {
          toast.success("Stock transfer successful!", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          // Clear form data and selected raw material
          setFormData({
            primaryStorageLocation: "",
            secondaryStorageLocation: "",
            centralStockTransferData: [],
          });

          setSelectedRawMaterial({
            rawMaterialId: "",
            currentStockQuantity: 0,
            transferQuantity: 0,
            unit: "",
          });
          
          // Trigger data refresh (assuming getNewStorageLocationData handles data refresh)
          getNewStorageLocationData();
        }
      } catch (error) {
        toast.error("Stock transfer failed.", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    };

    const fetchSecondaryStorageLocations = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`
        }
        const response = await axios.get("storage-location", { headers });
        if (response?.data?.status === true) {
          // setSecondaryStorageLocations(
          //   response.data.data.map((location) => ({
          //     value: location._id,
          //     label: `${location.name} -> ${location.city}, ${location.state}`,
          //   }))
          // );

          console.log("reesposne  :", response?.data?.data?.storageLocations);
          setSecondaryStorageLocations(response?.data?.data?.storageLocations);
          
        }
      } catch (error) {
        console.error("Error fetching secondary storage locations:", error);
      }
    };
  
    useEffect(() => {
      getNewStorageLocationData();
      fetchSecondaryStorageLocations();
    }, []);
  
    const handleTransferQuantityChangeInList = (e, index) => {
        const updatedTransferData = [...formData.centralStockTransferData];
        updatedTransferData[index].transferQuantity = e.target.value;
        setFormData((prev) => ({
          ...prev,
          centralStockTransferData: updatedTransferData,
        }));
    };
      
  
    const handleRemoveFromList = (index) => {
      const updatedTransferData = formData.centralStockTransferData.filter(
        (_, i) => i !== index
      );
      setFormData((prev) => ({
        ...prev,
        centralStockTransferData: updatedTransferData,
      }));
    };
  
    return (
      <CContainer>
        <CCard className="p-4">
          <h6 className="fw-bold">STORAGE LOCATION STOCK TRANSFER</h6>
          <hr className="mt-0" />
          <CForm onSubmit={handleSubmit}>
          <CRow className="mb-3 mt-2">
            <CCol md={6}>
              <CFormLabel className="fw-semibold">
                Primary Storage Location <span className="text-danger">*</span> [ From ]
              </CFormLabel>
              <Select
                isClearable
                options={storageLocationOptions.filter(
                  (loc) => loc.value !== formData.secondaryStorageLocation // Exclude selected Secondary Storage Location
                )}
                onChange={handlePrimaryStorageChange}
                value={storageLocationOptions.find(
                  (option) => option.value === formData.primaryStorageLocation
                )}
                placeholder="Select Primary Storage Location"
                isDisabled={formData.centralStockTransferData.length > 0}
              />
            </CCol>
            <CCol md={6}>
              <CFormLabel className="fw-semibold">
                Secondary Storage Location <span className="text-danger">*</span> [ To ]
              </CFormLabel>
              <Select
                isClearable
                options={secondaryStorageLocationOptions.filter(
                  (loc) => loc.value !== formData.primaryStorageLocation // Exclude selected Primary Storage Location
                )}
                onChange={handleSecondaryStorageChange}
                value={secondaryStorageLocationOptions.find(
                  (option) => option.value === formData.secondaryStorageLocation
                )}
                placeholder="Select Secondary Storage Location"
                isDisabled={formData.centralStockTransferData.length > 0}
              />
            </CCol>
          </CRow>


            <CRow className="mb-3">
              <CCol md={6}>
                <CFormLabel className="fw-semibold">
                  Raw Materials <span className="text-danger">*</span>
                </CFormLabel>
                {/* <Select
                  isClearable
                  options={rawMaterialOptions}
                  onChange={handleRawMaterialChange}
                  value={rawMaterialOptions.find(
                    (option) => option.value === selectedRawMaterial.rawMaterialId
                  )}
                  placeholder="Select Raw Materials"
                /> */}
                <Select
                  isClearable
                  options={rawMaterialOptions.filter((option) =>
                    !formData.centralStockTransferData.some(
                      (item) => item.rawMaterialId === option.value
                    )
                  )}
                  onChange={handleRawMaterialChange}
                  value={rawMaterialOptions.find(
                    (option) => option.value === selectedRawMaterial.rawMaterialId
                  )}
                  placeholder="Select Raw Materials"
                />

              </CCol>
              <CCol md={2}>
                <CFormLabel className="fw-semibold">Current Qty</CFormLabel>
                <CFormInput
                  disabled
                  type="text"
                  value={
                    selectedRawMaterial.currentStockQuantity
                      ? `${selectedRawMaterial?.currentStockQuantity?.toFixed(2)} ${selectedRawMaterial.unit || ""}`
                      : "0"
                  }
                />
              </CCol>

              <CCol md={2}>
                <CFormLabel className="fw-semibold">
                  Transfer Qty <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="number"
                  min="0"
                  value={selectedRawMaterial.transferQuantity}
                  onChange={handleTransferQuantityChange}
                />
              </CCol>
              <CCol md={2} className="d-flex align-items-end">
                <CButton color="primary" onClick={addToTransferList}>
                  Add
                </CButton>
              </CCol>
            </CRow>

                  <hr />

            {formData.centralStockTransferData.map((item, index) => {
              const selectedRawMaterial = rawMaterials.find(
                (material) => material.rawMaterial._id === item.rawMaterialId
              );

              return (
                <div
                  key={index}
                  className="mb-4 p-3"
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <CRow className="align-items-center">
                    <CCol md={4}>
                      <CFormLabel className="fw-semibold">Raw Material</CFormLabel>
                      {/* <Select
                        value={selectedRawMaterial ? { value: selectedRawMaterial.rawMaterial._id, label: selectedRawMaterial.rawMaterial.name } : null}
                        onChange={(selectedOption) => handleRawMaterialChangeInList(selectedOption, index)}
                        options={rawMaterials.map((material) => ({
                          value: material?.rawMaterial?._id,
                          label: material?.rawMaterial?.name,
                        }))}
                        placeholder="Select Raw Material"
                      /> */}
                      <Select
                        value={selectedRawMaterial ? { value: selectedRawMaterial.rawMaterial._id, label: selectedRawMaterial.rawMaterial.name } : null}
                        onChange={(selectedOption) => handleRawMaterialChangeInList(selectedOption, index)}
                        options={rawMaterials.map((material) => ({
                          value: material?.rawMaterial?._id,
                          label: material?.rawMaterial?.name,
                          isDisabled: formData.centralStockTransferData.some(item => item.rawMaterialId === material?.rawMaterial?._id)
                        }))}
                        isClearable
                        placeholder="Select Raw Material"
                      />
                    </CCol>
                    <CCol md={2}>
                      <CFormLabel className="fw-semibold">Current Stock</CFormLabel>
                      <CFormInput
                        type="text"
                        disabled
                        value={selectedRawMaterial ? `${selectedRawMaterial.currentStock?.toFixed(2)} ${selectedRawMaterial.rawMaterial.unit}` : "0"}
                      />
                    </CCol>
                    <CCol md={2}>
                      <CFormLabel className="fw-semibold">Unit</CFormLabel>
                      <CFormInput
                        type="text"
                        value={item.unit}
                        disabled
                        onChange={(e) => handleUnitChangeInList(e, index)}
                      />
                    </CCol>
                    <CCol md={2}>
                      <CFormLabel className="fw-semibold">Transfer Quantity</CFormLabel>
                      <CFormInput
                        type="number"
                        min="0"
                        value={item.transferQuantity}
                        onChange={(e) => handleTransferQuantityChangeInList(e, index)}
                      />
                    </CCol>
                    <CCol md={2} className="d-flex align-items-center">
                      <CButton
                        color="danger"
                        onClick={() => handleRemoveFromList(index)}
                      >
                        Remove
                      </CButton>
                    </CCol>
                  </CRow>
                </div>
              );
            })}

            <CButton color="success" type="submit">
              Submit Transfer
            </CButton>
          </CForm>

        </CCard>
      </CContainer>
    );
};
  

export default StorageLocationStTransfer;
