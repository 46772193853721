import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormLabel, CFormSelect, CRow } from '@coreui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import { PrimaryButton } from '../../components';

const BirthdayCoupon = ({ levels }) => {
    const accessToken = localStorage.getItem("authToken");
    const [inputData, setInputData] = useState({
        level: "",
        type: "",
        couponData: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [couponData, setCouponData] = useState({})

    const fetchCouponData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("settings", { headers });
            if (response?.data?.status === true) {
                const coupon = response?.data?.data?.settings?.couponInfo?.coupon;
                console.log("Coupon :", response);
                
                setCouponData(coupon)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setInputData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            if(inputData?.type === "REWARD"){
                delete inputData?.couponData
            }

            const response = await axios.post("level-reward", inputData, { headers });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setInputData({
                    level: "",
                    type: "",
                    couponData: ""
                })
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCouponData()
    }, [])

    return (
        <CCard>
            <CCardHeader>
                <CCardTitle>
                    ADD BIRTHDAY REWARD
                </CCardTitle>
            </CCardHeader>
            <CForm onSubmit={handleSubmit}>
                <CCardBody>
                    <CRow>
                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Level
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="level"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.level}
                                >
                                    <option value="">Select Level</option>
                                    {levels.map((level) => (
                                        <option key={level._id} value={level._id}>
                                            {level.title}
                                        </option>
                                    ))}
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Type
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="type"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.type}
                                >
                                    <option value="">Select Type</option>
                                    <option value="COUPON">COUPON</option>
                                    <option value="REWARD">REWARD</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Coupon
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="couponData"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.couponData}
                                    disabled={inputData?.type === "REWARD"}
                                >
                                    <option value="">Select Coupon</option>
                                    <option value={couponData?._id}>{couponData?.title}</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton title="ADD" isLoading={isLoading} />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default BirthdayCoupon
