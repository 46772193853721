import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../components";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import { FaArrowLeft, FaArrowRight, FaEye } from "react-icons/fa";
import Select from 'react-select';

const RmStockReports = () => {
  const accessToken = localStorage.getItem("authToken");
  const [stockReportsList, setStockReportsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [mainCategoryRawMateialList, setMainCategoryRawMateialList] = useState(
    []
  );
  const [subCategoryRawMaterials, setSubCategoryRawMaterials] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [rmMainCategoryId, setRMMainCategoryId] = useState("");
  const [rmSubCategoryId, setRMSubCategoryId] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [recordsValue, setRecordsValue] = useState("15");
  const [totalPages, setTotalPages] = useState(0);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [rawMaterialId, setrawMaterialId] = useState('');
  const [singleRmData, setSingleRmData] = useState([]);
  const [printReportsData, setPrintReportsData] = useState([]);
  const [printBtnLoading, setPrintBtnLoading] = useState(false);

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: '#00376E',
        borderBottom: '2px solid #dee2e6',
        color: '#ffffff'
      },
    },
    headCells: {
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
    },
    cells: {
      style: {
        fontSize: '14px',
      },
    },
    pagination: {
      style: {
        borderTop: '2px solid #869fbf',
      },
    },
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => recordsValue === 'all' ? index + 1 : (pageNumber - 1) * recordsValue + index + 1,
      width: "50px",
    },
    {
      name: "FIN.YEAR",
      selector: (row) => row.currentFinancialYear,
      cell: (row) => (
        <div className="fs-7">
          {row.currentFinancialYear ? row.currentFinancialYear : "--------"}
        </div>
      ),
    },
    // {
    //   name: "IMAGE",
    //   cell: (row) => (
    //     <div className="d-flex flex-column align-items-center gap-2">
    //       <img
    //         className="fs-7"
    //         style={{
    //           width: "60px",
    //           height: "60px",
    //           borderRadius: "50%",
    //           margin: "5px 0",
    //         }}
    //         src={row.rawMaterial?.displayImage || "/dummy-image.png"}
    //         alt="rm-image"
    //       />
    //     </div>
    //   ),
    // },
    {
      name: "ITEM NAME",
      selector: (row) => row.rawMaterial?.name,
      cell: (row) => (
        <div>
          <div className="fs-7">
            {row.rawMaterial?.name ? row.rawMaterial?.name : "--------"}
          </div>
          <div className="fs-7" style={{ color: row.stockInfo === "In Stock" ? 'green' : 'red' }}>
            {row.stockInfo ? row.stockInfo : "--------"}
          </div>
        </div>
      ),
      width: "170px",
    },
    {
      name: "RM CATEGORY",
      selector: (row) => row.rawMaterial?.name,
      cell: (row) => (
        <div>
          <div className="fs-7">
            <span style={{ color: 'purple' }}>{row.rawMaterial?.mainCategory?.name ? row.rawMaterial?.mainCategory?.name : "--------"}</span> {" >> "}  <br />
           <span style={{ color: 'blue' }}>{row.rawMaterial?.subCategory?.name ? row.rawMaterial?.subCategory?.name : "--------"}</span>
          </div>
        </div>
      ),
      width: "240px",
    },
    // {
    //   name: "RM SUB CATEGORY",
    //   selector: (row) => row.rawMaterial?.name,
    //   cell: (row) => (
    //     <div>
    //       <div className="fs-7">
    //         {row.rawMaterial?.subCategory?.name ? row.rawMaterial?.subCategory?.name : "--------"}
    //       </div>
    //     </div>
    //   ),
    //   width: "170px",
    // },
    {
      name: "CREDIT QTY",
      selector: (row) => row.totalCreditQuantity,
      cell: (row) => (
        <div className="fs-7">
          {row.totalCreditQuantity ? row.totalCreditQuantity : 0} <span style={{ fontSize: '12px' }}>{row.rawMaterial?.unit ? row.rawMaterial?.unit : ""}</span>
        </div>
      ),
    },
    {
      name: "DEBITED QTY",
      selector: (row) => row.totalDebitQuantity,
      cell: (row) => (
        <div className="fs-7">
          {row.totalDebitQuantity ? row.totalDebitQuantity : 0} <span style={{ fontSize: '12px' }}>{row.rawMaterial?.unit ? row.rawMaterial?.unit : ""}</span>
        </div>
      ),
    },
    {
      name: "BALANCE",
      selector: (row) => row.currentStock,
      cell: (row) => (
        <div className="fs-7">{row.currentStock ? row.currentStock : 0} <span style={{ fontSize: '12px' }}>{row.rawMaterial?.unit ? row.rawMaterial?.unit : ""}</span></div>
      ),
    },
    {
      name: "ACTIONS",
      center: "true",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center">
          <a
            href="#"
            className="text-info ms-2 me-2 "
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleOpenViewModal(row)}
          >
            <FaEye /> View
          </a>
        </div>
      ),
      minWidth: "140px",
    },
  ];

  const updatedColumns = columns?.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const getAllStockReports = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(
        `stock-transactions/getCurrentStock?page=1&limit=${recordsValue}`,
        {
          headers,
        }
      );

      if (response?.data?.status === true) {
        setStockReportsList(response?.data?.data?.totalRecords);
        setFilteredData(response?.data?.data?.totalRecords);
        setTotalPages(response?.data?.data?.paginationData?.NumberOfPages);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getAllStockReportsWithoutPagination = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(
        `stock-transactions/getCurrentStock`,
        {
          headers,
        }
      );

      if (response?.data?.status === true) {
        setPrintReportsData(response?.data?.data?.totalRecords);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getMainCategoryRawMaterials = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/rm-main-category/get", {
        headers,
      });

      if (response?.data?.status === true) {
        setMainCategoryRawMateialList(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getSubCategoryRawMaterials = async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/rm-sub-category/get", {
        headers,
      });
      if (response?.data?.status === true) {
        setSubCategoryRawMaterials(response?.data?.data?.categories);
        setFilteredSubCategories(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleMainCategoryChange = (e) => {
    setRMMainCategoryId(e.target.value);

    const result =
      e.target.value !== ""
        ? subCategoryRawMaterials.filter(
          (item) => item?.mainCategory === e.target.value
        )
        : subCategoryRawMaterials;
    setFilteredSubCategories(result);
  };

  const handleFilter = async (e) => {
    e.preventDefault();

    setIsFilterLoading(true);

    const hasMainCategoryId = rmMainCategoryId !== "";
    const hasSubCategoryId = rmSubCategoryId !== "";

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let url = "stock-transactions/getCurrentStock";

      if (hasMainCategoryId && hasSubCategoryId) {
        url += `?mainCategory=${rmMainCategoryId}&subCategory=${rmSubCategoryId}`;
      } else if (hasMainCategoryId) {
        url += `?mainCategory=${rmMainCategoryId}`;
      } else if (hasSubCategoryId) {
        url += `?subCategory=${rmSubCategoryId}`;
      }

      // Append page and limit only if recordsValue is not "all"
      if (recordsValue !== "all") {
        if (url.includes('?')) {
          url += `&page=${pageNumber}&limit=${recordsValue}`;
        } else {
          url += `?page=${pageNumber}&limit=${recordsValue}`;
        }
      }

      const response = await axios.get(url, { headers });

      if (
        response?.data?.status === true &&
        response?.data?.data?.totalRecords !== "No Records"
      ) {
        // setRMMainCategoryId("");
        // setRMSubCategoryId("");
        setStockReportsList(response?.data?.data?.totalRecords);
        setFilteredData(response?.data?.data?.totalRecords);
        setTotalPages(response?.data?.data?.paginationData?.NumberOfPages);
      } else {
        toast.error(response?.data?.data?.totalRecords, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setRMMainCategoryId("");
        setRMSubCategoryId("");
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsFilterLoading(false);
    }
  };

  const handlePrevClick = () => {
    const prevPage = Math.max(pageNumber - 1, 1);
    setPageNumber(prevPage);
    pagination(prevPage, recordsValue);
  };

  const handleNextClick = async () => {
    const nextPage = pageNumber + 1;
    if (nextPage <= totalPages) {
      setPageNumber(nextPage);
      pagination(nextPage, parseInt(recordsValue, 10));
    } else {
      toast.error("Already on the last page", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  };

  const pagination = async (pageNumber, recordsValue) => {
    try {
      let apiEndpoint = "stock-transactions/getCurrentStock";
      const queryParams = {
        page: pageNumber,
        limit: recordsValue,
      };

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.get(apiEndpoint, {
        params: queryParams,
        headers: headers,
      });

      if (response.data.status === true) {
        setStockReportsList(response?.data?.data?.totalRecords);
        setFilteredData(response?.data?.data?.totalRecords);
        setTotalPages(response?.data?.data?.paginationData?.NumberOfPages);
      } else {
        toast.error("No records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data
      ) {
        toast.error("No customer records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching customers list.";
        if (error.response && error.response.data) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleOpenViewModal = (data) => {
    setOpenViewModal(true);
    setrawMaterialId(data?.rawMaterial?._id);
  }

  const getSingleStockReportDetails = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`
      }
      const response = await axios.get(`stock-transactions/central-outlet-transaction?rawMaterialId=${rawMaterialId}`, { headers });
      setSingleRmData(response?.data?.data?.records);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  }

  const handlePrintReport = async() => {

    setPrintBtnLoading(true);
    // Fetch all order data for the report
    const allOrdersData = await getAllStockReportsWithoutPagination();
    setPrintBtnLoading(false);

    const tableContent = `
      <table style="border-collapse: collapse; width: 100%;">
        <thead>
          <tr style="font-size: 12px">
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Fin. Year</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Raw Material Name</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">RM Main Category</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">RM Sub Category</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Credited Quantity</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Debited Quantity</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Balance</th>
          </tr>
        </thead>
        <tbody>
          ${printReportsData?.length > 0 ? 
            printReportsData?.map((stockInfo, index) => `
              <tr style="font-size: 11px">
                <td style="border: 1px solid black; padding: 8px;">${index + 1}</td>
                <td style="border: 1px solid black; padding: 8px; width: 60px">${stockInfo?.currentFinancialYear}</td>
                <td style="border: 1px solid black; padding: 8px;">${stockInfo?.rawMaterial?.name}</td>
                <td style="border: 1px solid black; padding: 8px;">${stockInfo?.rawMaterial?.mainCategory?.name}</td>
                <td style="border: 1px solid black; padding: 8px; width: 100px">${stockInfo?.rawMaterial?.subCategory?.name}</td>
                <td style="border: 1px solid black; padding: 8px;">${stockInfo?.totalCreditQuantity}</td>
                <td style="border: 1px solid black; padding: 8px;">${stockInfo?.totalDebitQuantity}</td>
                <td style="border: 1px solid black; padding: 8px;">${stockInfo?.stockInfo}</td>
              </tr>
            `).join("") :
            `<tr>
              <td colspan="8" style="border: 1px solid black; padding: 8px; text-align: center;">No Customers Found</td>
            </tr>`
          }
        </tbody>
      </table>
    `;

    const win = window.open("", "", "width=900,height=600");
    win.document.write(`
      <html>
        <head>
          <title>Raw Materials Stock Report</title>
          <style>
            table { border-collapse: collapse; width: 100%; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body onload="window.print();window.close()">
          <h2>Raw Materials Stock Report</h2>
          <div>${tableContent}</div>
        </body>
      </html>
    `);
    win.document.close();
  }

  useEffect(() => {
    getAllStockReports();
    getMainCategoryRawMaterials();
    getSubCategoryRawMaterials();
  }, []);

  useEffect(() => {
    const result = stockReportsList.filter((item) => {
      return (
        item.rawMaterial?.name
          .toLowerCase()
          .match(searchText.toLocaleLowerCase()) ||
        item.rawMaterial?.unit
          .toLowerCase()
          .match(searchText.toLocaleLowerCase()) ||
        item.currentStock.toString().includes(searchText) ||
        item.totalCreditQuantity.toString().includes(searchText) ||
        item.totalDebitQuantity.toString().includes(searchText) ||
        (item.currentFinancialYear &&
          item.currentFinancialYear.toString().includes(searchText))
      );
    });
    setFilteredData(result);
  }, [searchText]);

  useEffect(() => {
    rawMaterialId && getSingleStockReportDetails();
  }, [rawMaterialId])

  return (
    <CContainer>
      <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
        <CCardHeader>
          <CCardTitle className="fs-6 fw-semibold">RAW MATERIALS STOCK REPORT</CCardTitle>
        </CCardHeader>
        <CCardBody>
          <div className="mb-3">
            <CForm onSubmit={handleFilter}>
              <CRow>
                <CCol md="4" lg="2" className="d-flex align-items-center mb-3">
                  <div
                    className="w-100 text-center"
                    style={{
                      fontSize: "13px",
                      padding: "8px 0",
                      background: "#6c757d",
                      color: "white",
                      borderRadius: "4px",
                    }}
                  >
                    Page No.
                  </div>
                  <div className="d-flex">
                    <CFormInput
                      type="number"
                      step= "any" // Allows decimal values
                      min={1}
                      value={pageNumber}
                      onChange={(e) => setPageNumber(e.target.value)}
                      className="no-arrows"
                    />
                  </div>
                </CCol>
                <CCol md="4" lg="2">
                  <CFormSelect
                    value={recordsValue}
                    onChange={(e) => setRecordsValue(e.target.value)}
                  >
                    <option value="">Select Records</option>
                    <option value="20">20</option>
                    <option value="40">40</option>
                    <option value="60">60</option>
                    <option value="80">80</option>
                    <option value="100">100</option>
                    <option value="all">ALL</option>
                  </CFormSelect>
                </CCol>

                <CCol  md="4" lg="3" className="col-md-3">
                  {/* <CFormSelect
                    value={rmMainCategoryId}
                    onChange={handleMainCategoryChange}
                  >
                    <option value="">Select Main Category</option>
                    {mainCategoryRawMateialList?.map((rm) => (
                      <option key={rm._id} value={rm._id}>
                        {rm.name}
                      </option>
                    ))}
                  </CFormSelect> */}
                  <Select
                    options={mainCategoryRawMateialList.map((rm) => ({
                      value: rm._id,
                      label: rm.name,
                    }))}
                    value={
                      rmMainCategoryId
                        ? {
                            value: rmMainCategoryId,
                            label: mainCategoryRawMateialList.find((rm) => rm._id === rmMainCategoryId)?.name,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      handleMainCategoryChange({
                        target: { name: "rmMainCategoryId", value: selectedOption ? selectedOption.value : "" },
                      });
                    }}
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Select Main Category"
                  />
                </CCol>
                <CCol  md="4" lg="3" className="col-md-3">
                  {/* <CFormSelect
                    value={rmSubCategoryId}
                    onChange={(e) => setRMSubCategoryId(e.target.value)}
                  >
                    <option value="">Select Sub Category</option>
                    {filteredSubCategories?.map((rm) => (
                      <option key={rm._id} value={rm._id}>
                        {rm.name}
                      </option>
                    ))}
                  </CFormSelect> */}
                  <Select
                    options={filteredSubCategories.map((rm) => ({
                      value: rm._id,
                      label: rm.name,
                    }))}
                    value={
                      rmSubCategoryId
                        ? {
                            value: rmSubCategoryId,
                            label: filteredSubCategories.find((rm) => rm._id === rmSubCategoryId)?.name,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      setRMSubCategoryId(selectedOption ? selectedOption.value : "");
                    }}
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Select Sub Category"
                  />
                </CCol>
                <CCol  md="4" lg="2" className="col-md-2">
                  <PrimaryButton
                    title="SHOW"
                    style={{ width: "100%", borderRadius: "18px", fontWeight: '500' }}
                  />
                </CCol>
              </CRow>
            </CForm>
          </div>

          <div className="d-flex justify-content-end mt-4 mb-3">
            <div className="me-4">
              <CButton className="me-5 fw-semibold bg-success border-success rounded-pill w-100" onClick={handlePrintReport}>
                { printBtnLoading ? <CSpinner size="sm" /> : "Print Report" } 
              </CButton>
            </div>
            <div>
              <CButton
                disabled={pageNumber === 1}
                onClick={handlePrevClick}
                className="d-flex align-items-center justify-content-center"
                color="warning"
              >
                <FaArrowLeft style={{ marginRight: "5px" }} /> PREV
              </CButton>
            </div>

            <div className="border rounded text-center p-1">
              Page No.{pageNumber}
            </div>

            <div>
              <CButton
                onClick={handleNextClick}
                disabled={pageNumber >= totalPages}
                className="d-flex align-items-center justify-content-center"
                color="warning"
              >
                NEXT
                <FaArrowRight style={{ marginLeft: "5px" }} />
              </CButton>
            </div>
          </div>

          <DataTable
            columns={updatedColumns}
            data={filteredData}
            className="mt-3"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <div className="mb-3">
                <CFormInput
                  type="text"
                  className="form-control w-100"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            }
            subHeaderAlign="right"
            customStyles={customStyles}
          />
        </CCardBody>
        <CCardFooter>
          <div className="d-flex justify-content-end mt-4 mb-3">
            <div>
              <CButton
                disabled={pageNumber === 1}
                onClick={handlePrevClick}
                className="d-flex align-items-center justify-content-center"
                color="warning"
              >
                <FaArrowLeft style={{ marginRight: "5px" }} /> PREV
              </CButton>
            </div>

            <div className="border rounded text-center p-1">
              Page No.{pageNumber}
            </div>

            <div>
              <CButton
                onClick={handleNextClick}
                disabled={pageNumber >= totalPages}
                className="d-flex align-items-center justify-content-center"
                color="warning"
              >
                NEXT
                <FaArrowRight style={{ marginLeft: "5px" }} />
              </CButton>
            </div>
          </div>
        </CCardFooter>
      </CCard>

      { openViewModal ? (
        <CModal
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
          size="xl"
        >
          <CModalHeader>
            <CModalTitle>View Modal</CModalTitle>
          </CModalHeader>
          <CModalBody>
            {/* Display data in a table */}
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Financial Year</th>
                  <th>Outlet Name</th>
                  <th>Raw Material Name</th>
                  <th>Stock Transfered To Outlet</th>
                  <th>From Storage Location</th>
                  <th>Outlet Status</th>
                </tr>
              </thead>
              <tbody>
                  {singleRmData?.length > 0 ? (
                      singleRmData.map((rmData, index) => (
                        <tr key={index}>
                          <td>{rmData?.financialYear}</td>
                          <td>{rmData?.outlet?.name}</td>
                          <td>{rmData?.rawMaterial?.name}</td>
                          <td>{rmData?.totalQuantity} {rmData?.rawMaterial?.unit}</td>
                          <td>
                            <span className="fw-semibold">{rmData?.storageLocation?.name}</span>
                            <br />
                            <span className="text-muted">{rmData?.storageLocation?.city}{","} {rmData?.storageLocation?.state}.</span>
                          </td>
                          <td style={{color: `${rmData?.outlet?.status === "ACTIVE" ? "green" : "red"}`, fontWeight: '500'}}>
                            {rmData?.outlet?.status}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center text-danger">
                          <strong>No records found</strong>
                        </td>
                      </tr>
                    )
                  }
              </tbody>
            </table>
          </CModalBody>
        </CModal>
      ) : null }

    </CContainer>
  );
};

export default RmStockReports;
