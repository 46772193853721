import {
  CButton,
  CCol,
  CFormLabel,
  CFormSelect,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";

const MonthSummaryReport = () => {
  const accessToken = localStorage.getItem("authToken");
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [subBtnLoading, setSubBtnLoading] = useState(false);
  const [printBtnLoading, setPrintBtnLoading] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [monthReportList, setMonthReportList] = useState([]);
  const [outletName, setOutletName] = useState('');

  useEffect(() => {
    getOutlets();
  }, []);

  const getMonthReport = async (monthDate, outlet) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      // Extract month and year
      const month = monthDate.getMonth() + 1; // getMonth() is 0-based, so add 1
      const year = monthDate.getFullYear();
      const response = await axios.get(
        `order/monthlyOrderSummary?outletId=${outlet}&year=${year}&month=${month}`,
        { headers }
      );
      if (response?.data?.status === true) {
        setMonthReportList(response?.data?.data?.reportData);
        toast.success("Data Fetched Successfully", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        const activeOutlets = response?.data?.data?.outlets.filter(
          (active) => active?.status === "ACTIVE"
        );
        setOutlets(activeOutlets);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubBtnLoading(true);
    getMonthReport(selectedMonth, selectedOutlet);
    setSubBtnLoading(false);
  };

  const getOutletName = async (e) => {   
    const value = outlets?.filter(val => val._id === e )
    setOutletName(value[0].name)  
  };

  const handlePrintReport = (e) => {
    e.preventDefault();
    // Calculate Grand Totals
    let totalInv = 0;
    let totalCancelledInv = 0;
    let totalDiscountAmt = 0;
    let grandTotalCancelledAmount = 0;
    let grandTotalTaxable = 0;
    let grandTotalCGST = 0;
    let grandTotalSGST = 0;
    let grandTotal = 0;

    if (monthReportList?.length > 0) {
      monthReportList.forEach((report) => {
        totalInv += report?.numberOfInvoices || 0;
        totalCancelledInv += report?.cancelledOrderCount || 0;
        totalDiscountAmt += report?.totalDiscount || 0;
        grandTotalCancelledAmount += report?.cancelledOrderAmount || 0;
        grandTotalTaxable += report?.totalTaxable || 0;
        grandTotalCGST += report?.CGST || 0;
        grandTotalSGST += report?.SGST || 0;
        grandTotal += report?.total || 0;
      });
    }
    const tableContent = `
          <table style="border-collapse: collapse; width: 100%;">
            <thead>
              <tr style="font-size: 10px">
                <th style="border: 1px solid black; padding: 8px; text-align: left;  width: 70px">DATE</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;  width: 60px">INV. START NO</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;  width: 60px">INV. END NO</th>
                <th style="border: 1px solid black; padding: 8px; text-align: center;  width: 60px">TOT. INV.</th>
                <th style="border: 1px solid black; padding: 8px; text-align: center;  width: 60px">CANC. INV.</th>
                <th style="border: 1px solid black; padding: 8px; text-align: right; width: 70px">DIS. AMT</th>
                <th style="border: 1px solid black; padding: 8px; text-align: right; width: 70px">CANC. AMT</th>
                <th style="border: 1px solid black; padding: 8px; text-align: right; width: 80px">TOT. TAXABLE</th>
                <th style="border: 1px solid black; padding: 8px; text-align: right; width: 60px">CGST</th>
                <th style="border: 1px solid black; padding: 8px; text-align: right; width: 60px">SGST</th>
                <th style="border: 1px solid black; padding: 8px; text-align: right; width: 90px">GRAND TOTAL</th>
              </tr>
            </thead>
            <tbody>
                ${
                  monthReportList?.length > 0
                    ? monthReportList
                        ?.map(
                          (report, index) => `
                    <tr style="font-size: 11px">
                        <td style="border: 1px solid black; padding: 8px;">${
                          report?.date
                        }</td>
                        <td style="border: 1px solid black; padding: 8px;">${
                          report?.invoiceStartNo
                        }</td>
                        <td style="border: 1px solid black; padding: 8px;">${
                          report?.invoiceEndNo
                        }</td>
                        <td style="border: 1px solid black; padding: 8px; text-align: center">${
                          report?.numberOfInvoices
                        }</td>
                        <td style="border: 1px solid black; padding: 8px; text-align: center">${
                          report?.cancelledOrderCount
                        }</td>
                        <td style="border: 1px solid black; padding: 8px; text-align: end">₹. ${report?.totalDiscount?.toFixed(
                          2
                        )}</td>
                        <td style="border: 1px solid black; padding: 8px; text-align: end">₹. ${report?.cancelledOrderAmount?.toFixed(
                          2
                        )}</td>
                        <td style="border: 1px solid black; padding: 8px; text-align: end">₹. ${report?.totalTaxable?.toFixed(
                          2
                        )}</td>
                        <td style="border: 1px solid black; padding: 8px; text-align: end">₹. ${report?.CGST?.toFixed(
                          2
                        )}</td>
                        <td style="border: 1px solid black; padding: 8px; text-align: end">₹. ${report?.SGST?.toFixed(
                          2
                        )}</td>
                        <td style="border: 1px solid black; padding: 8px; text-align: end">₹. ${report?.total?.toFixed(
                          2
                        )}</td>
                    </tr>
                    `
                        )
                        .join("")
                    : `<tr>
                    <td colspan="11" style="border: 1px solid black; padding: 8px; text-align: center;">No Reports Found</td>
                    </tr>`
                }
                <tr style="font-size: 11px">
                    <td colspan="3" style="border: 1px solid black; padding: 8px; text-align: right; font-weight: bold;">GRAND TOTAL :</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: center; font-weight: bold">${totalInv}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: center; font-weight: bold">${totalCancelledInv}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: end; font-weight: bold">₹. ${totalDiscountAmt?.toFixed(
                      2
                    )}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: end; font-weight: bold">₹. ${grandTotalCancelledAmount?.toFixed(
                      2
                    )}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: end; font-weight: bold">₹. ${grandTotalTaxable?.toFixed(
                      2
                    )}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: end; font-weight: bold">₹. ${grandTotalCGST?.toFixed(
                      2
                    )}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: end; font-weight: bold">₹. ${grandTotalSGST?.toFixed(
                      2
                    )}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: end; font-weight: bold">₹. ${grandTotal?.toFixed(
                      2
                    )}</td>
                </tr>

            </tbody>
          </table>
        `;
    const formattedMonth = selectedMonth.toLocaleString("en-US", {
      month: "long",
      year: "numeric",
    });
    const win = window.open("", "", "width=900,height=600");
    win.document.write(`
          <html>
            <head>
              <title>MONTH SUMMARY REPORT</title>
              <style>
                table { border-collapse: collapse; width: 100%; }
                th, td { border: 1px solid black; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body onload="window.print();window.close()">
              <h3><div style: "display: flex; justify-content: space-between"><span>Monthly Summary Report<span> <span style="margin-left: '10px">(For - ${formattedMonth} In ${outletName})</span></div></h3>
              <div>${tableContent}</div>
            </body>
          </html>
        `);
    win.document.close();
  };

  const formatMonth = selectedMonth
    .toLocaleString("en-US", { month: "long", year: "numeric" })
    .toUpperCase();

  return (
    <div className="container">
      <h5>
        MONTH SUMMARY REPORT FOR - {formatMonth}
      </h5>
      <CRow className="mt-3">
        <CCol md={4} lg={4}>
          <CFormLabel className="fw-semibold">
            Select Outlet <span className="text-danger">*</span>
          </CFormLabel>
          <CFormSelect
            id="selectField"
            name="selectField"
            value={selectedOutlet}
            onChange={(e) => {
              setSelectedOutlet(e.target.value);
              getOutletName(e.target.value);
            }}
          >
            <option value="">All Outlets</option>

            {outlets.map((outName, index) => {
              return (
                <option key={index} value={outName?._id}>
                  {outName?.name}
                </option>
              );
            })}
          </CFormSelect>
        </CCol>
        <CCol md={2} lg={2}>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <CFormLabel className="fw-semibold">
              Select Month <span className="text-danger">*</span>
            </CFormLabel>
            <div className="d-flex">
              <ReactDatePicker
                selected={selectedMonth}
                onChange={(month) => setSelectedMonth(month)}
                dateFormat="MM-yyyy" // Format to display month and year only
                showMonthYearPicker // Allows selecting only the month and year
                placeholderText="Select Month"
                className="form-control"
              />
            </div>
          </div>
        </CCol>
        <CCol md="2" lg="2" className="text-center">
          <CFormLabel className="fw-semibold text-white">.</CFormLabel>
          <CButton className="btn rounded-pill w-100" onClick={handleSubmit}>
            {subBtnLoading ? <CSpinner size="sm" /> : "Show Report"}
          </CButton>
        </CCol>
        <CCol md="2" lg="2" className="text-center">
          <CFormLabel className="fw-semibold text-white">.</CFormLabel>
          <CButton
            className="btn rounded-pill w-100 bg-success border-success"
            disabled={printBtnLoading}
            onClick={handlePrintReport}
          >
            {printBtnLoading ? <CSpinner size="sm" /> : "Print Report"}
          </CButton>
        </CCol>
      </CRow>

      <CRow className="mt-4">
        {monthReportList?.length === 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <h5 className="text-danger">
              Please Select Outlet and Required Month
            </h5>
          </div>
        ) : (
          <CTable striped bordered hover responsive>
            <CTableHead>
              <CTableRow style={{ fontSize: "15px" }}>
                <CTableHeaderCell>DATE</CTableHeaderCell>
                <CTableHeaderCell>INV. START NO</CTableHeaderCell>
                <CTableHeaderCell>INV. END NO</CTableHeaderCell>
                <CTableHeaderCell className="text-center">
                  TOT INV.
                </CTableHeaderCell>
                <CTableHeaderCell className="text-center">
                  CANC. INV.
                </CTableHeaderCell>
                <CTableHeaderCell className="text-end">
                  DIS. AMT
                </CTableHeaderCell>
                <CTableHeaderCell className="text-end">
                  CANC. AMT
                </CTableHeaderCell>
                <CTableHeaderCell className="text-end">
                  TOT. TAXABLE
                </CTableHeaderCell>
                <CTableHeaderCell className="text-end">CGST</CTableHeaderCell>
                <CTableHeaderCell className="text-end">SGST</CTableHeaderCell>
                <CTableHeaderCell className="text-end">
                  GRAND TOTAL
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {loading ? (
                <CTableRow>
                  <CTableDataCell colSpan="10" className="text-center">
                    <CSpinner color="primary" size="lg" />
                  </CTableDataCell>
                </CTableRow>
              ) : monthReportList?.length > 0 ? (
                <>
                  {monthReportList?.map((report, index) => (
                    <CTableRow key={index} style={{ fontSize: "15px" }}>
                      <CTableDataCell>{report?.date}</CTableDataCell>
                      <CTableDataCell>{report?.invoiceStartNo}</CTableDataCell>
                      <CTableDataCell>{report?.invoiceEndNo}</CTableDataCell>
                      <CTableDataCell className="text-center">
                        {report?.numberOfInvoices}
                      </CTableDataCell>
                      <CTableDataCell className="text-center text-danger">
                        {report?.cancelledOrderCount}
                      </CTableDataCell>
                      <CTableDataCell className="text-end">
                        ₹. {report?.totalDiscount?.toFixed(2)}
                      </CTableDataCell>
                      <CTableDataCell className="text-end text-danger">
                        ₹. {report?.cancelledOrderAmount?.toFixed(2)}
                      </CTableDataCell>
                      <CTableDataCell className="text-end">
                        ₹. {report?.totalTaxable?.toFixed(2)}
                      </CTableDataCell>
                      <CTableDataCell className="text-end">
                        ₹. {report?.CGST?.toFixed(2)}
                      </CTableDataCell>
                      <CTableDataCell className="text-end">
                        ₹. {report?.SGST?.toFixed(2)}
                      </CTableDataCell>
                      <CTableDataCell className="text-end fw-semibold text-success">
                        ₹. {report?.total?.toFixed(2)}
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                  {/* Calculate Totals */}
                  <CTableRow style={{ border: "1.6px solid #dedcdc" }}>
                    <CTableDataCell
                      colSpan="3"
                      className="text-end fw-semibold"
                    >
                      GRAND TOTAL:
                    </CTableDataCell>
                    <CTableDataCell className="text-center fw-semibold ">
                      {monthReportList.reduce(
                        (acc, report) => acc + report?.numberOfInvoices,
                        0
                      )}
                    </CTableDataCell>
                    <CTableDataCell className="text-center fw-semibold ">
                      {monthReportList.reduce(
                        (acc, report) => acc + report?.cancelledOrderCount,
                        0
                      )}
                    </CTableDataCell>
                    <CTableDataCell className="text-end fw-semibold ">
                      ₹.{" "}
                      {monthReportList
                        .reduce(
                          (acc, report) =>
                            acc + (report?.totalDiscount || 0),
                          0
                        )
                        .toFixed(2)}
                    </CTableDataCell>
                    <CTableDataCell className="text-end fw-semibold ">
                      ₹.{" "}
                      {monthReportList
                        .reduce(
                          (acc, report) =>
                            acc + (report?.cancelledOrderAmount || 0),
                          0
                        )
                        .toFixed(2)}
                    </CTableDataCell>
                    <CTableDataCell className="text-end fw-semibold ">
                      ₹.{" "}
                      {monthReportList
                        .reduce(
                          (acc, report) => acc + (report?.totalTaxable || 0),
                          0
                        )
                        .toFixed(2)}
                    </CTableDataCell>
                    <CTableDataCell className="text-end fw-semibold ">
                      ₹.{" "}
                      {monthReportList
                        .reduce((acc, report) => acc + (report?.CGST || 0), 0)
                        .toFixed(2)}
                    </CTableDataCell>
                    <CTableDataCell className="text-end fw-semibold ">
                      ₹.{" "}
                      {monthReportList
                        .reduce((acc, report) => acc + (report?.SGST || 0), 0)
                        .toFixed(2)}
                    </CTableDataCell>
                    <CTableDataCell className="text-end fw-bold text-success">
                      ₹.{" "}
                      {monthReportList
                        .reduce((acc, report) => acc + (report?.total || 0), 0)
                        .toFixed(2)}
                    </CTableDataCell>
                  </CTableRow>
                </>
              ) : (
                <CTableRow>
                  <CTableDataCell
                    colSpan="11"
                    className="text-center text-danger fw-5"
                  >
                    No Data Found
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        )}
      </CRow>
    </div>
  );
};

export default MonthSummaryReport;
