import React, { useEffect, useState } from 'react';
import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow } from '@coreui/react'
import Select from "react-select";
import DatePicker from "react-datepicker";
import { PrimaryButton } from '../../components';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import makeAnimated from "react-select/animated";
import { format } from 'date-fns';

const SpecialProductCoupon = ({ outlets, selectedOptions, setSelectedOptions, selectedProducts, setSelectedProducts, setSelectedCouponOption }) => {

    const accessToken = localStorage.getItem("authToken");
    const animatedComponents = makeAnimated();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [specialProductBtnLoading, setSpecialProductBtnLoading] = useState(false)
    const [specialProductCouponInputData, setSpecialProductCouponInputData] = useState({
        isHidden: "",
        title: "",
        couponCode: "",
        description: "",
        usageType: "",
        discountPrice: "",
        applicableQuantityType: "",
        // dependantAddonInfo: "",
        validityStartDate: "",
        validityEndDate: "",
        // startTime: "", New Changes
        // endTime: "", New Changes
        // dayOfWeek: "", New Changes
        // applicableLevels: "", New Changes
        // minOrderAmount: "", New Changes
        termsAndConditions: [], // Array to store terms
    });
    const [levels, setLevels] = useState([]);
    const [selectedLevelOptions, setSelectedLevelOptions] = useState([]);
    const [selectedIncludedOptions, setSelectedIncludedOptions] = useState([]);
    const [currentTerm, setCurrentTerm] = useState('');
    const [selectedDependentCategories, setSelectedDependentCategories] = useState([]);
    const [selectedDependentProduct, setSelectedDependentProduct] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [addonsList, setAddonsList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const outletData = outlets.map((outlet) => ({
        value: outlet._id,
        label: `${outlet.name} (${outlet.city})`,
    }));
    const productsInfo = productsList.map((prod) => ({
        value: prod?._id,
        label: `${prod?.name}`
    }))

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%",
            borderRadius: "6px",
            minWidth: "200px",
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d",
        }),
    };

    const addTerm = () => {
        if (!currentTerm.trim()) return;
        setSpecialProductCouponInputData((prevData) => ({
            ...prevData,
            termsAndConditions: [...prevData.termsAndConditions, currentTerm],
        }));
        setCurrentTerm(''); // Clear input after adding
    };

    const removeTerm = (index) => {
        setSpecialProductCouponInputData((prevData) => ({
            ...prevData,
            termsAndConditions: prevData.termsAndConditions.filter(
                (_, i) => i !== index
            ),
        }));
    };

    const getProductsList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product", { headers });
            if (response?.data?.status === true) {
                setProductsList(response?.data?.data?.products);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected)
    }

    const handleSelectIncChange = (selected) => {        
        setSelectedIncludedOptions(selected);
    };

    const handleSelectLevelChange = (selected) => {
        setSelectedLevelOptions(selected);
    }

    // const handleSelectSpecialProductChange = (prods) => {
    //     setSelectedProducts(prods);
    // }

    const handleSelectSpecialProductChange = (selectedOptions) => {
        // Extract product IDs from selected options
        const selectedProductIds = selectedOptions.map((option) => option.value);
    
        // Update the selectedProducts state
        setSelectedProducts(selectedOptions);
    
        // Update the productCouponInputData state with selected product IDs
        setSpecialProductCouponInputData((prev) => ({
            ...prev,
            applicableProducts: selectedProductIds,
        }));
    
        // Fetch the addons for the selected products
        if (selectedProductIds.length > 0) {
            getAddonProductsList(selectedProductIds);
        } else {
            // Clear addons if no products are selected
            setAddonsList([]);
        }
    };

    const handleSelectDepCategoryChange = (cats) => {
        setSelectedDependentCategories(cats);
    }

    const handleSelectDependentProductChange = (products) => {
        setSelectedDependentProduct(products);
    }

    const handleSpecialProductCouponChange = (e) => {
        const { name, value } = e.target;

        const numericFields = "discountPrice";
        if (numericFields.includes(name)) {
            const numberValue = Number(value);
            if (numberValue >= 0 || value === "") {
                setSpecialProductCouponInputData((prev) => ({ ...prev, [name]: value }));
            }
        } else {
            setSpecialProductCouponInputData((prev) => ({ ...prev, [name]: value }));
        }
    }

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const getAddonProductsList = async (productId) => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          // Create params object conditionally
    
        //   if (productId !== "all" && productId !== '') {
        //     params.productId = productId;  // Add categoryId only if selected
        //   }
          
        //   const response = await axios.get("product-addon", { headers, params });
        const response = await axios.get(`product-addon?productId=${productId}`, { headers })
          if (response?.data?.status === true) {
            setAddonsList(response?.data?.data?.productAddons);
            console.log("Addon list response?.data?.data ", response?.data?.data);
            
          }
        } catch (error) {
          if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response.data.error;
            // toast.error(errorMessage, {
            //   position: "top-right",
            //   autoClose: 3000,
            //   theme: "colored",
            // });
          }
        }
    };
    
    const getCategoryList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("masters/category/get", { headers });

            if (response?.data?.status === true) {
                setCategoryList(response?.data?.data?.categories);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const categoriesData = categoryList.map((category) => ({
        value: category._id,
        label: `${category.name}`
    }))

    const submitSpecialProductCouponDetails = async (e) => {
        e.preventDefault();
        setSpecialProductBtnLoading(true);

        let selectedOutlet = selectedOptions.map((option) => option.value);
        // let selectedIncOutlet = selectedIncludedOptions.map((option) => option.value); New Changes
        // let selectedLevel = selectedLevelOptions?.map((options) => options?.value); New Changes
        let selectedProductsList = selectedProducts.map((prod) => prod?.value);
        // let selectedDepProductList = selectedDependentProduct?.map((prod) => prod?.value); New Changes
        // let selectedDepCategoryList = selectedDependentCategories?.map((cat) => cat?.value); New Changes
        let productCouponData = {
            ...specialProductCouponInputData,
            excludedOutlets: selectedOutlet,
            // includedOutlets: selectedIncOutlet, New Changes
            // applicableLevels: selectedLevel, New Changes
            applicableProducts: selectedProductsList,
            // dependantProducts: selectedDepProductList, New Changes
            // dependantCategories: selectedDepCategoryList New Changes
        }

        if(productCouponData?.excludedOutlets?.length === 0) {
            delete productCouponData?.excludedOutlets
        }

        // if(productCouponData?.applicableLevels?.length === 0) {
        //     delete productCouponData?.applicableLevels
        // } New Changes

        // if(productCouponData?.minOrderAmount?.length === 0) {
        //     delete productCouponData?.minOrderAmount
        // } New Changes

        // if(productCouponData?.dependantCategories?.length === 0) {
        //     delete productCouponData?.dependantCategories
        // } New Changes

        // if(productCouponData?.dependantProducts?.length === 0) {
        //     delete productCouponData?.dependantProducts
        // } New Changes
        
        // if(productCouponData?.dependantAddonInfo === "") {
        //     delete productCouponData?.dependantAddonInfo
        // } New Changes

        if (productCouponData?.validityStartDate === "") {
            delete productCouponData?.validityStartDate
        }

        if (productCouponData?.validityEndDate === "") {
            delete productCouponData?.validityEndDate
        }

        // if(productCouponData?.includedOutlets?.length === 0) {
        //     delete productCouponData?.includedOutlets
        // } New Changes

        // if(productCouponData?.startTime === "") {
        //     delete productCouponData?.startTime
        // } New Changes

        // if(productCouponData?.endTime === "") {
        //     delete productCouponData?.endTime
        // } New Changes

        // if(productCouponData?.applicableLevels === "") {
        //     delete productCouponData?.applicableLevels
        // } New Changes

        // if(productCouponData?.dayOfWeek === "") {
        //     delete productCouponData?.dayOfWeek
        // } New Changes

        if (Array.isArray(productCouponData?.termsAndConditions) && productCouponData.termsAndConditions.length === 0) {
            delete productCouponData.termsAndConditions;
        }

        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.post("coupons", productCouponData, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });

                e.target.reset();
                setSelectedOptions([]);
                selectedProducts([]);
                setSelectedIncludedOptions([]);
                setSelectedCouponOption("");
                setSpecialProductCouponInputData({
                    isHidden: "",
                    title: "",
                    couponCode: "",
                    description: "",
                    usageType: "",
                    discountPercent: "",
                    discountAmount: "",
                    applicableProduct: "",
                    validityStartDate: "",
                    validityEndDate: "",
                    // applicableLevels: "", New Changes
                    // dayOfWeek: "", New Changes
                    // endTime: "", New Changes
                    // startTime: "", New Changes
                });
                setFromDate(null);
                setToDate(null);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message || error?.response?.data?.data?.title
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setSpecialProductBtnLoading(false);
        }
    };

    const productBtnDisable = () => {
        let isDisable = false;

        if (
            !specialProductCouponInputData?.title?.trim() ||
            !specialProductCouponInputData?.couponCode?.trim() ||
            !specialProductCouponInputData?.description?.trim() ||
            !specialProductCouponInputData?.usageType ||
            !selectedProducts
        ) {
            isDisable = true;
        }

        return isDisable;
    };

    const fetchLevelsData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("level", { headers });
            if (response?.data?.status === true) {
                const levels = response?.data?.data?.levels;
                console.log("Levels :", levels);
                
                setLevels(levels)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        fetchLevelsData();
    }, [])

    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setSpecialProductCouponInputData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);

    useEffect(() => {
        getProductsList();
        getCategoryList();
    }, [])

    return (
        <CCard>
            <CCardHeader>
                <CCardTitle className="fs-6">
                    ADD SPECIAL PRODUCT COUPON
                </CCardTitle>
            </CCardHeader>
            <CForm onSubmit={submitSpecialProductCouponDetails}>
                <CCardBody>
                    <CRow className="mb-3">
                        <CCol className="col-md-4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Title <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Title"
                                    name="title"
                                    value={specialProductCouponInputData?.title}
                                    onChange={handleSpecialProductCouponChange}
                                />
                            </div>
                        </CCol>
                        <CCol className="col-md-4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Coupon Code <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Coupon Code"
                                    name="couponCode"
                                    value={specialProductCouponInputData?.couponCode}
                                    onChange={handleSpecialProductCouponChange}
                                />
                            </div>
                        </CCol>
                        <CCol className="col-md-4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Usage Type <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="usageType"
                                    value={specialProductCouponInputData?.usageType}
                                    onChange={handleSpecialProductCouponChange}
                                >
                                    <option value="">Select Usage</option>
                                    <option value="MULTIPLE">MULTIPLE</option>
                                    <option value="ONE-TIME">ONE-TIME</option>
                                </CFormSelect>
                            </div>
                        </CCol>             
                    </CRow>

                    <CRow className="mb-3">
                        <CCol className="col-md-6">
                            <CFormLabel className="fw-semibold">
                                Select Products <span className="text-danger">*</span>
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                options={productsInfo}
                                value={selectedProducts}
                                onChange={handleSelectSpecialProductChange}
                                placeholder= "Select Products"
                                styles={customStyles}
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>

                        <CCol className="col-md-6">
                            <CFormLabel className="fw-semibold">
                                Select Excluded Outlets
                            </CFormLabel>

                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedOptions}
                                onChange={handleSelectChange}
                                options={outletData}
                                styles={customStyles}
                                placeholder="Select Excluded Outlets" // You can also use a dynamic placeholder from props or state
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>

                        {/* <CCol md="6" lg="6">
                            <CFormLabel className="fw-semibold">
                                Select Product Addons
                            </CFormLabel>
                            <CFormSelect
                                name="dependantAddonInfo"
                                value={specialProductCouponInputData.dependantAddonInfo}
                                onChange={handleSpecialProductCouponChange}
                                // disabled={!inputData.applicableProducts}
                            >
                                <option value="">Select Product Addon</option>
                                {addonsList?.flatMap((item) =>
                                    item.addons.map((addon) => (
                                        <option key={addon.addonId} value={addon.addonId}>
                                            {addon.addonTitle}
                                        </option>
                                    ))
                                )}
                            </CFormSelect>
                        </CCol> New Changes */}
                    </CRow>

                    {/* <CRow className="mb-3">
                        <CCol>
                            <CFormLabel className="fw-semibold">
                                Select Dependent Categories <span className="text-danger">*</span>
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                options={categoriesData}
                                value={selectedDependentCategories}
                                onChange={handleSelectDepCategoryChange}
                                placeholder="Select Dependent Categories"
                                closeMenuOnSelect={false}
                                styles={customStyles}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                        <CCol>
                            <CFormLabel className="fw-semibold">
                                Select Dependent Products <span className="text-danger">*</span>
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                options={productsInfo}
                                value={selectedDependentProduct}
                                onChange={handleSelectDependentProductChange}
                                placeholder="Select Dependent Products"
                                styles={customStyles}
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                    </CRow> New Changes */}

                    {/* <CRow className='mb-3'>
                        <CCol md="6" lg="6">
                            <CFormLabel className="fw-semibold">
                                Select Included Outlets
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedIncludedOptions}
                                onChange={handleSelectIncChange}
                                options={outletData}
                                styles={customStyles}
                                placeholder="Select Included Outlets"
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                    </CRow> New Changes */}

                    <CRow>
                        <CCol className="mb-3" md="12" lg="12">
                            <CFormLabel className="fw-semibold">
                                Description <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormTextarea
                                rows={4}
                                placeholder="Description"
                                name="description"
                                value={specialProductCouponInputData?.description}
                                onChange={handleSpecialProductCouponChange}
                            />
                        </CCol>
                        
                    </CRow>

                    <CRow className="mb-3">
                        {/* <CCol md="6" lg="6">
                            <CFormLabel className="fw-semibold">
                                Select Level
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedLevelOptions}
                                onChange={handleSelectLevelChange}
                                options={levels?.map((le) => ({
                                    value: le._id,
                                    label: le.title,
                                }))}
                                styles={customStyles}
                                placeholder="Select Level"
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                    ...theme.colors,
                                    primary25: "#e9ecef",
                                    primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol> New Changes */}
                        <CCol md="4" lg="4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Hide Coupon <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="isHidden"
                                    value={specialProductCouponInputData?.isHidden}
                                    onChange={handleSpecialProductCouponChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol md="4" lg="4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                  Validity Start Date
                                </CFormLabel>
                                <DatePicker
                                    selected={fromDate}
                                    onChange={handleFromDateChange}
                                    placeholderText="Start Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                        <CCol md="4" lg="4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                Validity End Date
                                </CFormLabel>
                                <DatePicker
                                    selected={toDate}
                                    onChange={handleToDateChange}
                                    placeholderText="End Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                    </CRow>

                    {/* <CRow className='mb-3'>
                        <CCol md="3" lg="3">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Select Day
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleSpecialProductCouponChange}
                                    name="dayOfWeek"
                                    aria-label="Small select example"
                                    value={specialProductCouponInputData?.dayOfWeek}
                                >
                                    <option value="">Select Day</option>
                                    <option value="0">SUNDAY</option>
                                    <option value="1">MONDAY</option>
                                    <option value="2">TUESDAY</option>
                                    <option value="3">WEDNESDAY</option>
                                    <option value="4">THURSDAY</option>
                                    <option value="5">FRIDAY</option>
                                    <option value="6">SATURDAY</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        <CCol md="3" lg="3">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Set Start Time
                                </CFormLabel>
                                <CFormInput
                                    type="time"
                                    size="sm"
                                    onChange={handleSpecialProductCouponChange}
                                    name="startTime"
                                    value={specialProductCouponInputData?.startTime}
                                />
                            </div>
                        </CCol>

                        <CCol md="3" lg="3">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Set End Time
                                </CFormLabel>
                                <CFormInput
                                    type="time"
                                    size="sm"
                                    onChange={handleSpecialProductCouponChange}
                                    name="endTime"
                                    value={specialProductCouponInputData?.endTime}
                                />
                            </div>
                        </CCol>

                        <CCol md={3} lg={3}>
                            <CFormLabel className="fw-semibold">
                                Min. Order Amount
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                placeholder="Min. Order Amount"
                                type="number"
                                className="no-arrows"
                                name="minOrderAmount"
                                value={specialProductCouponInputData.minOrderAmount}
                                onChange={handleSpecialProductCouponChange}
                            />
                        </CCol>
                    </CRow> New Changes */}

                    <CRow>
                        <CCol>
                            <CFormLabel className="fw-semibold">
                                Discount Price <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                placeholder="Discount Price"
                                type="number"
                                className="no-arrows"
                                name="discountPrice"
                                value={specialProductCouponInputData?.discountPrice}
                                onChange={handleSpecialProductCouponChange}
                            />
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Applicable Quantity Type <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="applicableQuantityType"
                                    value={specialProductCouponInputData?.applicableQuantityType}
                                    onChange={handleSpecialProductCouponChange}
                                >
                                    <option value="">Select Quantity Type</option>
                                    <option value="SINGLE">SINGLE</option>
                                    <option value="MULTIPLE">MULTIPLE</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow className="mt-3">
                        {/* Other fields */}
                        <CCol md="12" className="mb-3">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Terms and Conditions <span className="text-danger">*</span>
                                </CFormLabel>
                                <div className="d-flex mb-2">
                                    <CFormInput
                                        placeholder="Add a term"
                                        value={currentTerm}
                                        onChange={(e) => setCurrentTerm(e.target.value)}
                                        className="me-2"
                                    />
                                    <CButton disabled={!currentTerm.trim()} onClick={addTerm}>ADD</CButton>
                                </div>
                                {specialProductCouponInputData?.termsAndConditions?.length > 0 && (
                                    <ul className="list-group">
                                        {specialProductCouponInputData?.termsAndConditions?.map((term, index) => (
                                            <li
                                                key={index}
                                                className="list-group-item d-flex justify-content-between align-items-center"
                                            >
                                                {term}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-danger rounded-pill"
                                                    onClick={() => removeTerm(index)}
                                                >
                                                    Remove
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton
                        isDisable={productBtnDisable()}
                        isLoading={specialProductBtnLoading}
                        title="ADD SPECIAL PRODUCT COUPON"
                    />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default SpecialProductCoupon