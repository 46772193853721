import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { DataTableComponent, PrimaryButton } from "../../components";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { FaTrash, FaPencilAlt, FaPlus } from "react-icons/fa";
import Select from 'react-select';

const RawMaterialSubCategory = () => {
  const accessToken = localStorage.getItem("authToken");
  const [inputData, setInputData] = useState({
    main_category: "",
    name: "",
    description: "",
  });
  const [rawMateialList, setRawMateialList] = useState([]);
  const [subCategoryRawMaterials, setSubCategoryRawMaterials] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [categoryId, setCategoryId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [addNewOpenModal, setAddNewOpenModal] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
  const [rmMaincategoryList, setRmMaincategoryList] = useState([]);
  const [selectedRMCategory, setSelectedRMCategory] = useState('');

  const rawMaterialsOptions = rawMateialList.map((rm) => ({
    value: rm._id,
    label: rm.name
  }));

  const options = rmMaincategoryList.map((rmcat) => ({
    value: rmcat?._id,
    label: rmcat?.name,
}));

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      cell: (row) => (
        <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>
      ),
      width: "10%",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="fs-7">{row.name ? row.name : "--------"}</div>
      ),
      width: "20%",
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
      cell: (row) => (
        <div className="fs-7">
          {row.description ? row.description : "--------"}
        </div>
      ),
      width: "25%",
    },
    {
      name: "MAIN CATEGORY",
      center: "true",
      selector: (row) => row.mainCategoryName,
      cell: (row) => (
        <div className="fs-7">
          {row.mainCategoryName ? row.mainCategoryName : "--------"}
        </div>
      ),
      width: "25%",
    },
    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center">
          <a
            href="#"
            className="text-primary me-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleEditModal(row)}
          >
            <FaPencilAlt /> Edit
          </a>{" "}
          |
          <a
            href="#"
            className="text-danger ms-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleDeleteModal(row)}
          >
            <FaTrash /> Delete
          </a>
        </div>
      ),
      minWidth: "20%",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleAddNewOpenModal = () => {
    setAddNewOpenModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (input) => {
    if (input && input.target) {
      const { name, value } = input.target;
      setInputData((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setInputData((prevState) => ({
        ...prevState,
        main_category: input ? input.value : "",
      }));
    }
  };

  const addRmSubCategory = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post(
        "masters/rm-sub-category/add",
        inputData,
        { headers }
      );

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        getSubCategoryRawMaterials();
        setInputData({
          main_category: "",
          name: "",
          description: "",
        });
        setAddNewOpenModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getRawMaterialsMainCategory = async () => {
    try {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.get("masters/rm-main-category/get", {
            headers,
        });

        if (response?.data?.status === true) {
            response?.data?.data?.categories.forEach((item, index) => {
                item.serialNumber = index + 1;
            });
            console.log("Raw Material catergory :", response?.data?.data?.categories);

            setRmMaincategoryList(response?.data?.data?.categories);
        }
    } catch (error) {
        if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response.data.error;
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        }
    }
  };

  const getSubCategoryRawMaterials = async (mainId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/rm-sub-category/get", {
        headers,
      });
      if (response?.data?.status === true) {
        response?.data?.data?.categories.forEach((item, index) => {
          item.serialNumber = index + 1;
        });
        let filteredList = response?.data?.data?.categories?.filter((cat) => cat?.mainCategory === mainId);

        if(mainId === undefined) {
          setFilteredData(response?.data?.data?.categories);
        } else {
          setFilteredData(filteredList);
        }
        
        setSubCategoryRawMaterials(response?.data?.data?.categories);
        
        // setFilteredData(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getRawMaterials = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/rm-main-category/get", {
        headers,
      });

      if (response?.data?.status === true) {
        setRawMateialList(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    setEditedData({
      id: data._id,
      main_category: data.mainCategory || "",
      name: data.name,
      description: data.description,
    });
    setOpenEditModal(true);
  };

  const handleEditChange = (input) => {
    if (input && input.target) {
      const { name, value } = input.target;
      setEditedData((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setEditedData((prevState) => ({
        ...prevState,
        main_category: input ? input.value : "",
      }));
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put(
        "masters/rm-sub-category/update",
        editedData,
        { headers }
      );
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getSubCategoryRawMaterials();
        setOpenEditModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setCategoryId(data?._id);
  };

  const handleDeleteCategory = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.delete(
        `masters/rm-sub-category/delete?id=${categoryId}`,
        { headers }
      );
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getSubCategoryRawMaterials();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };

  const handleRowsPerPageChange = (rowsPerPage) => {
    setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };

  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  const handleRMCategoryChange = (selected) => {
    console.log("Selected In main category :", selected);
    let mainCatId = selected?.value;
    getSubCategoryRawMaterials(mainCatId);  
  }


  // const disableButton =
  //   !inputData.main_category.trim() ||
  //   inputData.main_category.trim() === "" ||
  //   !inputData.name.trim() ||
  //   inputData.name.trim() === "" ||
  //   !inputData.description.trim() ||
  //   inputData.description.trim() === "";

  // const editButtonDisable =
  //   !editedData ||
  //   !editedData.main_category ||
  //   !editedData.main_category.trim() ||
  //   !editedData.name ||
  //   !editedData.name.trim() ||
  //   !editedData.description ||
  //   !editedData.description.trim();

  useEffect(() => {
    getRawMaterials();
    getRawMaterialsMainCategory();
    getSubCategoryRawMaterials();
  }, []);

  useEffect(() => {
    const result = subCategoryRawMaterials.filter((item) => {
      return (
        item.name.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.description.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.mainCategoryName
          .toLowerCase()
          .match(searchText.toLocaleLowerCase())
      );
    });
    setFilteredData(result);
  }, [searchText]);

  return (
    <>
      <CContainer>

        <CRow className="mb-3">
          <CCol md="3" lg="3">
            <CFormLabel className="fw-semibold">Select RM Main Category</CFormLabel>
            <Select
              id="selectField"
              name="selectField"
              value={options.find(option => option.value === selectedRMCategory)}
              onChange={(selectedOption) => {
                  handleRMCategoryChange(selectedOption);
              }}
              options={options}
              placeholder="Select Main RM Categories"
              isSearchable
              isClearable
            />
          </CCol>
          <CCol md="2" lg="2"></CCol>
          <CCol md="3" lg="3"></CCol>
          <CCol md="4" lg="4" className="fw-semibold">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <CFormLabel className="text-white">.</CFormLabel>
              <button
                className="btn btn-warning"
                onClick={handleAddNewOpenModal}
                style={{ borderRadius: "18px" }}
              >
                ADD NEW RAW MATERIAL SUB CATEGORY <FaPlus style={{ marginTop: "-4px" }} size={15} />
              </button>
            </div>
          </CCol>
        </CRow>

        
        <CRow>
          <CCol className="col-12">
            <div>
              <DataTableComponent
                columns={updatedColumns}
                // data={filteredData}
                data={getPaginatedData()}
                title="RAW MATERIAL SUB CATEGORIES LIST"
                searchText={searchText}
                onChange={handleSearchChange}
                pagination
                totalRows={filteredData?.length}
                paginationTotalRows={filteredData?.length} // Total number of items
                onChangeRowsPerPage={handleRowsPerPageChange}
                onChangePage={handlePageChange}
                paginationServer={false} // Set to false for client-side pagination
              />
            </div>
          </CCol>
        </CRow>
      </CContainer>

      {openEditModal ? (
        <CModal
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT RAW MATERIAL SUB CATEGORY
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Select main category <span className="text-danger">*</span>
                </CFormLabel>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={rawMaterialsOptions.find((option) => option.value === editedData.main_category)}
                  onChange={(selectedOption) => handleEditChange(selectedOption)}
                  isClearable
                  isSearchable
                  name="main_category"
                  options={rawMaterialsOptions}
                  placeholder="Select Main Category"
                />
              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Name <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  name="name"
                  value={editedData.name}
                  onChange={handleEditChange}
                />
              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={4}
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Description"
                  className="form-control"
                  name="description"
                  value={editedData.description}
                  onChange={handleEditChange}
                />
              </div>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                // isDisable={editButtonDisable}
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE RAW MATERIAL SUB CATEGORY
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteCategory}>
            <CModalBody>
              Are you sure you want to delete this raw material sub category ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenDeleteModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                CLOSE
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {addNewOpenModal ? (
        <CModal
          alignment="center"
          visible={addNewOpenModal}
          onClose={() => setAddNewOpenModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle> ADD RAW MATERIAL SUB CATEGORY</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={addRmSubCategory}>
            <CModalBody>
              <div className="mb-3">
                <label className="fw-semibold">
                  Select main category <span className="text-danger">*</span>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={rawMaterialsOptions.find((option) => option.value === inputData.main_category)}
                  onChange={(selectedOption) => handleChange(selectedOption)}
                  isClearable
                  isSearchable
                  name="main_category"
                  options={rawMaterialsOptions}
                  placeholder="Select Main Category"
                />
              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Name <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  value={inputData.name}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={4}
                  type="text"
                  placeholder="Description"
                  className="form-control"
                  name="description"
                  value={inputData.description}
                  onChange={handleChange}
                />
              </div>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                // isDisable={disableButton}
                title="ADD SUB CATEGORY"
                isLoading={isLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </>
  );
};

export default RawMaterialSubCategory;
