import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormLabel, CFormSelect, CRow } from '@coreui/react'
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import { PrimaryButton } from '../../components';

const SizeUpgrade = ({ levels, couponsData }) => {
    const accessToken = localStorage.getItem("authToken");
    const [filteredLevels, setFilteredLevels] = useState([])
    const animatedComponents = makeAnimated();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputData, setInputData] = useState({
        level: "",
        type: "",
        interval: "",
        value: "",
        numberOfTimes: ""
    });
    const [isLoading, setIsLoading] = useState(false);

    const couponsList = couponsData
        .filter(item => item.sizeUpgradeAddon !== null && item.sizeUpgradeAddon !== undefined)
        .map(item => ({
            value: item._id,
            label: `${item.title} (${item.couponCode})`
        }));


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%", // Adjust width based on your requirement
            borderRadius: '6px',
            minWidth: "200px", // Add a minimum width to avoid layout issues
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d"
        }),
    };
    const filterLevels = () => {
        const level = levels?.filter((item) => item.sequenceNo !== 1);
        setFilteredLevels(level)
    }

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setInputData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const selectedCoupons = selectedOptions.map((option) => option.value);

            const data = {
                level: inputData?.level,
                type: inputData?.type,
                applyingConditions: {
                    interval: inputData?.interval,
                    value: inputData?.value,
                    numberOfTimes: inputData?.numberOfTimes
                },
                couponData: selectedCoupons
            }

            if(data?.type === "REWARD"){
                delete data?.couponData
            }

            if(data?.applyingConditions?.value === "null"){
                delete data?.applyingConditions?.value
            }

            const response = await axios.post("level-reward", data, { headers });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setInputData({
                    level: "",
                    type: "",
                    interval: "",
                    value: "",
                    numberOfTimes: ""
                });
                setSelectedOptions([])
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        filterLevels();
    }, [])
    return (
        <CCard>
            <CCardHeader>
                <CCardTitle>
                    ADD SIZE UPGRADE REWARD
                </CCardTitle>
            </CCardHeader>
            <CForm onSubmit={handleSubmit}>
                <CCardBody>
                    <CRow>
                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Level
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="level"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.level}
                                >
                                    <option value="">Select Level</option>
                                    {filteredLevels.map((level) => (
                                        <option key={level._id} value={level._id}>
                                            {level.title}
                                        </option>
                                    ))}
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Type
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="type"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.type}
                                >
                                    <option value="">Select Type</option>
                                    <option value="COUPON">COUPON</option>
                                    <option value="REWARD">REWARD</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                                Select Coupons <span className="text-danger">*</span>
                            </CFormLabel>

                            <Select
                                isDisabled={inputData?.type === "REWARD"}
                                isMulti
                                components={animatedComponents}
                                value={selectedOptions}
                                onChange={handleSelectChange}
                                options={couponsList}
                                styles={customStyles}
                                placeholder="Select Coupons"
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </div>

                        <hr />
                        <h6 className='mb-3'>APPLYING CONDITIONS</h6>
                        <hr />

                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Time Interval
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="interval"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.interval}
                                >
                                    <option value="">Select Time Interval</option>
                                    <option value="WEEKLY">WEEKLY</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Day
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="value"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.value}
                                >
                                    <option value="">Select Day</option>
                                    <option value="0">SUNDAY</option>
                                    <option value="1">MONDAY</option>
                                    <option value="2">TUESDAY</option>
                                    <option value="3">WEDNESDAY</option>
                                    <option value="4">THURSDAY</option>
                                    <option value="5">FRIDAY</option>
                                    <option value="6">SATURDAY</option>
                                    <option value="null">ANY DAY OF THE WEEK</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Number Of Times
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="numberOfTimes"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.numberOfTimes}
                                >
                                    <option value="">Number Of Times</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton title={"ADD"} isLoading={isLoading} />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default SizeUpgrade
