import { Route, Routes } from "react-router-dom";
import { Login } from "./auth";
import AppLayout from "./AppLayout";
import {
  AddAdvertisement,
  AddCoupon,
  AddStory,
  Category,
  CouponCard,
  Dashboard,
  Level,
  LevelRewards,
  OutletUsers,
  Outlets,
  ProcurementCatalog,
  Procurements,
  ProductAddOn,
  Products,
  ProductsCatalog,
  RawMaterialMainCategory,
  RawMaterialSubCategory,
  RawMaterials,
  RmStockReports,
  Roles,
  StockTransfer,
  Vendors,
  ViewAdvertisement,
  ViewLevelRewards,
  AssetType,
  Assets,
  AssetsStockTransaction,
  AssetStockTransactionReport,
  StorageLocation,
  VersionControl,
  Settings,
  KioskOutletUser,
  CustomerSupportMgt,
  Add,
  CustomerReport,
  ProductSalesReport,
  AddonWiseReport,
  OrdersReport,
  CancellationRequests,
  KioskUserReport,
  DaySummaryReport,
  UserCouponReport,
  StockTransactionReport,
  ProductIngredients,
  MonthSummaryReport,
  RMReorderLevelReport,
  CreateCoupon,
  CentralStTransReport,
  ViewCoupon,
  StorageLocationStTransfer
} from "./screens/index";

import ProtectedRoutes from "./ProtectedRoutes";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/" element={<AppLayout />}>
        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/masters/admin-roles" element={<Roles />} />
          <Route
            path="/asset-management/assets"
            element={<Assets />}
          />
          <Route
            path="/masters/asset-type"
            element={<AssetType />}
          />
          <Route
            path="/asset-management/assets-stock-transaction"
            element={<AssetsStockTransaction />}
          />
          <Route
            path="masters/storage-type"
            element={<StorageLocation />}
          />
          <Route
            path="/transaction/asset-stock-transaction-report"
            element={<AssetStockTransactionReport />}
          />
          <Route
            path="/material-mgt/rm-main-category"
            element={<RawMaterialMainCategory />}
          />
          <Route
            path="/material-mgt/rm-sub-category"
            element={<RawMaterialSubCategory />}
          />
          <Route
            path="/material-mgt/rawmaterials"
            element={<RawMaterials />}
          />
          <Route path="/outlet-management/outlets" element={<Outlets />} />
          <Route
            path="/outlet-management/outletusers"
            element={<OutletUsers />}
          />
          <Route path="/outlet-management/kisok-outlet-user" element={<KioskOutletUser />} />
          <Route path="/customer-support" element={<CustomerSupportMgt />} />
          <Route path="/masters/vendors" element={<Vendors />} />
          <Route path="/menu/categories" element={<Category />} />
          <Route
            path="/menu/add-menu"
            element={<Products />}
          />
          <Route
            path="/menu/menu-catalog"
            element={<ProductsCatalog />}
          />
          <Route
            path="/menu/menu-addon"
            element={<ProductAddOn />}
          />
          <Route
            path="/menu/menu-ingredients"
            element={<ProductIngredients />}
          />

          <Route
            path="/purchase/add-purchase"
            element={<Procurements />}
          />
          <Route
            path="/purchase/purchase-list"
            element={<ProcurementCatalog />}
          />
          <Route 
            path="/purchase/storage-location-stock-transfer"
            element={<StorageLocationStTransfer />}
          />
          <Route
            path="/purchase/stock-transfer"
            element={<StockTransfer />}
          />
          <Route
            path="/central-inventory/rm-stock-reports"
            element={<RmStockReports />}
          />
          <Route
            path="/transaction/central-stock-transaction-report"
            element={<CentralStTransReport />}
          />
          <Route path="/coupons/view" element={<CouponCard />} />
          <Route path="/coupons/add" element={<AddCoupon />} />
          <Route path="/content-management/stories" element={<AddStory />} />
          <Route path="/content-management/view-advertisement" element={<ViewAdvertisement />} />
          <Route path="/content-management/add-advertisement" element={<AddAdvertisement />} />
          <Route path="/user-levels/level" element={<Level />} />
          <Route path="/user-levels/add-reward" element={<LevelRewards />} />
          <Route path="/user-levels/view" element={<ViewLevelRewards />} />
          <Route path="/system-preference/settings" element={<Settings />} />
          <Route path="/system-preference/version-control" element={<VersionControl />} />
          <Route path="/reports/customer-report" element={<CustomerReport />} />
          <Route path="/reports/order-report" element={<OrdersReport />} />
          <Route path="/reports/product-report" element={<ProductSalesReport />} />
          <Route path="/reports/addon-report" element={<AddonWiseReport />} />
          <Route path="/reports/kiosk-user-report" element={<KioskUserReport />} />
          <Route path="/reports/day-summary-report" element={<DaySummaryReport />} />
          <Route path="/reports/month-summary-report" element={<MonthSummaryReport />} />
          <Route path="/reports/user-coupon-report" element={<UserCouponReport />} />
          <Route path="/reports/rm-reorder-level-report" element={<RMReorderLevelReport />} />
          <Route path="/transaction/stock-transaction-report" element={<StockTransactionReport />} />
          <Route path="/user-coupon-allocation" element={< Add />} />
          <Route path="/cancellation-request" element={<CancellationRequests />} />
          <Route path="/create-coupon/add" element={<CreateCoupon />} />
          <Route path="/create-coupon/view" element={<ViewCoupon />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
