import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow } from '@coreui/react'
import { PrimaryButton } from "../../components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import { format } from "date-fns";

const SizeUpgradeCoupon = ({ outlets, selectedOptions, setSelectedOptions, setSelectedCouponOption }) => {
    const accessToken = localStorage.getItem("authToken");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const animatedComponents = makeAnimated();
    const outletData = outlets.map((outlet) => ({
        value: outlet._id,
        label: `${outlet.name} (${outlet.city})`,
    }));
    const [inputData, setInputData] = useState({
        isHidden: "",
        title: "",
        couponCode: "",
        sizeUpgradeAddon: "",
        description: "",
        usageType: "",
        validityStartDate: "",
        validityEndDate: "",
        // startTime: "", New Changes
        // endTime: "", New Changes
        // dayOfWeek: "", New Changes
        // minOrderAmount: "", New Changes
        applicableProducts: [],
        termsAndConditions: [], // Array to store terms
    });
    const [currentTerm, setCurrentTerm] = useState('');
    const [levels, setLevels] = useState([]);
    const [selectedLevelOptions, setSelectedLevelOptions] = useState([]);
    const [selectedIncludedOptions, setSelectedIncludedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [addonList, setAddonList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%",
            borderRadius: "6px",
            minWidth: "200px",
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d",
        }),
    };

    const addTerm = () => {
        if (!currentTerm.trim()) return;
        setInputData((prevData) => ({
            ...prevData,
            termsAndConditions: [...prevData.termsAndConditions, currentTerm],
        }));
        setCurrentTerm(''); // Clear input after adding
    };

    const removeTerm = (index) => {
        setInputData((prevData) => ({
            ...prevData,
            termsAndConditions: prevData.termsAndConditions.filter(
                (_, i) => i !== index
            ),
        }));
    };

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handleSelectIncChange = (selected) => {
        setSelectedIncludedOptions(selected);
    }

    const handleSelectLevelChange = (selected) => {
        setSelectedLevelOptions(selected);
    }

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const getAddonProductsList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product-addon?limit=1000&page=1", { headers });
            if (response?.data?.status === true) {
                const addOns = response?.data?.data?.productAddons
                // const sizeAddons = addOns.filter((item) => item?.addonTitle === "Size");
                const productsWithSizeAddon = addOns.filter((item) =>
                    item.addons.some((addon) => addon.addonTitle.toLowerCase() === 'size')
                );
                setAddonList(productsWithSizeAddon)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "applicableProducts") {
            setSelectedProduct(value)
        }
        if (name === "applicableProducts") {
            setInputData((prev) => ({ ...prev, [name]: [value] }));
        }
        else {
            setInputData((prev) => ({ ...prev, [name]: value }));
        }

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        let selectedOutlet = selectedOptions.map((option) => option.value);
        // let selectedIncOutlet = selectedIncludedOptions.map((option) => option.value); New Changes
        // let selectedLevels = selectedLevelOptions?.map((options) => options.value); New Changes
        let data = {
            ...inputData,
            excludedOutlets: selectedOutlet,
            // includedOutlets: selectedIncOutlet, New Changes
            // applicableLevels: selectedLevels New Changes
        };


        if (data?.excludedOutlets?.length === 0) {
            delete data?.excludedOutlets
        }

        if (data?.validityStartDate === "") {
            delete data.validityStartDate
        }

        if (data?.validityEndDate === "") {
            delete data.validityEndDate
        }

        // if (data?.applicableLevels?.length === 0) {
        //     delete data.applicableLevels
        // } New Changes

        // if (data?.minOrderAmount === "") {
        //     delete data.minOrderAmount
        // } New Changes

        // if(data?.includedOutlets?.length === 0) {
        //     delete data?.includedOutlets
        // } New Changes

        // if(data?.startTime === "") {
        //     delete data?.startTime
        // } New Changes

        // if(data?.endTime === "") {
        //     delete data?.endTime
        // } New Changes

        // if(data?.applicableLevels === "") {
        //     delete data?.applicableLevels
        // } New Changes

        // if(data?.dayOfWeek === "") {
        //     delete data?.dayOfWeek
        // } New Changes

        if (Array.isArray(data?.termsAndConditions) && data.termsAndConditions.length === 0) {
            delete data.termsAndConditions;
        }

        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const response = await axios.post("coupons", data, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                e.target.reset();
                setSelectedOptions([]);
                setSelectedIncludedOptions([]);
                setSelectedLevelOptions([]);
                setSelectedCouponOption("");
                setInputData({
                    isHidden: "",
                    title: "",
                    couponCode: "",
                    description: "",
                    usageType: "",
                    validityStartDate: "",
                    validityEndDate: "",
                    // minOrderAmount: "", New Changes
                    // dayOfWeek: "", New Changes
                    // endTime: "", New Changes
                    // startTime: "", New Changes
                });
                setFromDate(null);
                setToDate(null);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage =
                        error?.response?.data?.data?.message ||
                        error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const filteredAddons = selectedProduct
        ? addonList.filter(rm => rm.product._id === selectedProduct)
        : [];
    const updatedFilteredAddons = filteredAddons?.map((item) => item.addons?.map((mm) => mm))

    const fetchLevelsData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("level", { headers });
            if (response?.data?.status === true) {
                const levels = response?.data?.data?.levels;
                console.log("Levels :", levels);
                
                setLevels(levels)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        fetchLevelsData();
    }, [])
    
    useEffect(() => {
        getAddonProductsList()
    }, []);

    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setInputData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);

    return (
        <CCard>
            <CCardHeader>
                <CCardTitle className="fs-6">ADD SIZE UPGRADE COUPON</CCardTitle>
            </CCardHeader>
            <CForm onSubmit={handleSubmit}>
                <CCardBody>
                    <CRow className="mb-3">
                        <CCol lg="4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Title <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Title"
                                    name="title"
                                    value={inputData.title}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </CCol>
                        <CCol lg="4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Coupon Code <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Coupon Code"
                                    name="couponCode"
                                    value={inputData.couponCode}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </CCol>
                        <CCol lg="4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Usage Type <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="usageType"
                                    value={inputData.usageType}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Usage</option>
                                    <option value="MULTIPLE">MULTIPLE</option>
                                    <option value="ONE-TIME">ONE-TIME</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow className="mb-3">
                        <CCol lg="6">
                            <CFormLabel className="fw-semibold">
                                Select Product <span className="text-danger">*</span>
                            </CFormLabel>
                            <Select
                                options={addonList.map((rm) => ({
                                    value: rm?.product?._id,
                                    label: rm?.product?.name,
                                }))}
                                value={
                                    inputData?.applicableProducts
                                        ? addonList
                                            .map((rm) => ({ value: rm?.product?._id, label: rm?.product?.name }))
                                            .find((option) => option.value === inputData?.applicableProducts[0]) // Since applicableProducts is an array
                                        : null
                                }
                                onChange={(selectedOption) => {
                                    if (typeof selectedOption === 'object' && selectedOption !== null) {
                                        handleInputChange({ target: { name: 'applicableProducts', value: selectedOption.value } });
                                    } else {
                                        handleInputChange({ target: { name: 'applicableProducts', value: '' } });
                                    }
                                }}
                                classNamePrefix="react-select"
                                isClearable
                                placeholder="Select Product"
                            />
                        </CCol>
                        <CCol lg="6">
                            <CFormLabel className="fw-semibold">
                                Select Size Upgrade Addon <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormSelect
                                name="sizeUpgradeAddon"
                                value={inputData.sizeUpgradeAddon}
                                onChange={handleInputChange}
                                disabled={!inputData.applicableProducts}
                            >
                                <option value="">Select Size Upgrade Addon</option>
                                <option key={updatedFilteredAddons[0]?.[0]?.addonId} value={updatedFilteredAddons[0]?.[0]?.addonId}>
                                    {updatedFilteredAddons[0]?.[0]?.addonTitle}
                                </option>
                            </CFormSelect>
                        </CCol>
                    </CRow>

                    {/* <CRow className='mb-3'>
                        <CCol md="8" lg="8">
                            <CFormLabel className="fw-semibold">
                                Select Included Outlets
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedIncludedOptions}
                                onChange={handleSelectIncChange}
                                options={outletData}
                                styles={customStyles}
                                placeholder="Select Included Outlets"
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                        <CCol md="4" lg="4">
                            <CFormLabel className="fw-semibold">
                                Select Level
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedLevelOptions}
                                onChange={handleSelectLevelChange}
                                options={levels?.map((le) => ({
                                    value: le._id,
                                    label: le.title,
                                }))}
                                styles={customStyles}
                                placeholder="Select Level"
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                    ...theme.colors,
                                    primary25: "#e9ecef",
                                    primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                    </CRow> New Changes */}

                    <CRow className='mb-3'>
                        <CCol md={12} lg={12}>
                            <CFormLabel className="fw-semibold">
                                Select Excluded Outlets
                            </CFormLabel>

                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedOptions}
                                onChange={handleSelectChange}
                                options={outletData}
                                styles={customStyles}
                                placeholder="Select Excluded Outlets"
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                        {/* <CCol md={4} lg={4}>
                            <CFormLabel className="fw-semibold">
                                Min. Order Amount <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                placeholder="Min. Order Amount"
                                type="number"
                                className="no-arrows"
                                name="minOrderAmount"
                                value={inputData.minOrderAmount}
                                onChange={handleInputChange}
                            />
                        </CCol> New Changes */}
                    </CRow>
                    
                    <div className="mb-3">
                        <CFormLabel className="fw-semibold">
                            Description <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormTextarea
                            rows={4}
                            placeholder="Description"
                            name="description"
                            value={inputData.description}
                            onChange={handleInputChange}
                        />
                    </div>

                    <CRow>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Hide Coupon <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="isHidden"
                                    value={inputData.isHidden}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity Start Date
                                </CFormLabel>
                                <DatePicker
                                    selected={fromDate}
                                    onChange={handleFromDateChange}
                                    placeholderText="Start Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity End Date
                                </CFormLabel>
                                <DatePicker
                                    selected={toDate}
                                    onChange={handleToDateChange}
                                    placeholderText="End Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                    </CRow>

                    {/* <CRow className="mt-3">
                        <CCol md="4" lg="4">
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Day
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleInputChange}
                                    name="dayOfWeek"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.dayOfWeek}
                                >
                                    <option value="">Select Day</option>
                                    <option value="0">SUNDAY</option>
                                    <option value="1">MONDAY</option>
                                    <option value="2">TUESDAY</option>
                                    <option value="3">WEDNESDAY</option>
                                    <option value="4">THURSDAY</option>
                                    <option value="5">FRIDAY</option>
                                    <option value="6">SATURDAY</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                        
                        <CCol md="4" lg="4">
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Set Start Time
                                </CFormLabel>
                                <CFormInput
                                    type="time"
                                    size="sm"
                                    onChange={handleInputChange}
                                    name="startTime"
                                    className="mb-3"
                                    value={inputData?.startTime}
                                />
                            </div>
                        </CCol>

                        <CCol md="4" lg="4">
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Set End Time
                                </CFormLabel>
                                <CFormInput
                                    type="time"
                                    size="sm"
                                    onChange={handleInputChange}
                                    name="endTime"
                                    className="mb-3"
                                    value={inputData?.endTime}
                                />
                            </div>
                        </CCol>

                    </CRow> New Changes */}

                    <CRow className='mt-3'>
                        {/* Other fields */}
                        <CCol md="12" className="mb-3">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Terms and Conditions <span className="text-danger">*</span>
                                </CFormLabel>
                                <div className="d-flex mb-2">
                                    <CFormInput
                                        placeholder="Add a term"
                                        value={currentTerm}
                                        onChange={(e) => setCurrentTerm(e.target.value)}
                                        className="me-2"
                                    />
                                    <CButton disabled={!currentTerm.trim()} onClick={addTerm}>ADD</CButton>
                                </div>
                                {inputData?.termsAndConditions?.length > 0 && (
                                    <ul className="list-group">
                                        {inputData?.termsAndConditions?.map((term, index) => (
                                            <li
                                                key={index}
                                                className="list-group-item d-flex justify-content-between align-items-center"
                                            >
                                                {term}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-danger rounded-pill"
                                                    onClick={() => removeTerm(index)}
                                                >
                                                    Remove
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton
                        // isDisable={bogoBtnDisable()}
                        isLoading={isLoading}
                        title="ADD SIZE UPGRADE COUPON"
                    />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default SizeUpgradeCoupon
