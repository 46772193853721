import axios from "axios";
// const BASE_URL = 'https://xpanse.dev.bexcart.com/'
// const BASE_URL = 'https://xpansedev.theuplife.in/'

// <-------- Development URL --------> 
// const BASE_URL = 'https://xpanse.dev.bexcart.com/'
// const BASE_URL = 'https://a9e0-150-107-41-97.ngrok-free.app/'
// const BASE_URL = 'http://192.168.1.45:2023/'

// <-------- Production URL -------->
// const BASE_URL = 'https://api.xpansecafe.com/'

// -----------> NEW PRODUCTION URL FOR ADMIN PANEL <-----------------
const BASE_URL = 'https://admin.api.xpansecafe.com/';

// const BASE_URL = 'http://localhost:2023/'

export default axios.create({
    baseURL: BASE_URL,
})