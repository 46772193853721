import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/PrimaryButton";
import { FaPlus, FaMinus } from "react-icons/fa";
import Select from 'react-select';

const Procurements = () => {
  const accessToken = localStorage.getItem("authToken");
  const [inputData, setInputData] = useState({
    vendor: "",
    idNumber: "",
    additionalCharges: "",
    procurementItems: [
      {
        material: "",
        quantity: "",
        rate: "",
        taxPercent: "",
        // unit: "",
      },
    ],
  });
  const [vendors, setVendors] = useState([]);
  const [rawMaterialList, setRawMaterialList] = useState([]);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [rawMaterialDetail, setRawMaterialDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    vendor: "",
    idNumber: "",
    additionalCharges: "",
    invoiceFile: "",
    procurementItems: [
      {
        material: "",
        quantity: "",
        rate: "",
      },
    ],
  });

  console.log("inputData", inputData);
  

  const handleAddItem = () => {
    const procurementItemErrors = inputData.procurementItems.map(
      validateProcurementItem
    );
    if (
      procurementItemErrors.some((errors) => Object.keys(errors).length > 0)
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        procurementItems: procurementItemErrors,
      }));
      return;
    }

    setInputData((prevData) => ({
      ...prevData,
      procurementItems: [
        ...prevData.procurementItems,
        {
          material: "",
          quantity: "",
          rate: "",
          taxPercent: "",
        },
      ],
    }));
  };

  const handleRemoveItem = (index) => {
    setInputData((prevData) => {
      const updatedItems = [...prevData.procurementItems];
      updatedItems.splice(index, 1);
      return {
        ...prevData,
        procurementItems: updatedItems,
      };
    });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    let sanitizedValue = value;

    // if (name === "additionalCharges") {
    //   // Remove leading zeros
    //   sanitizedValue = value.replace(/^0+/, '');
    //   // Remove any non-numeric characters
    //   sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');
    //   // Ensure value is not negative
    //   sanitizedValue = Math.max(0, sanitizedValue);
    // }

    if (name === "idNumber") {
      // Allow only underscores (_) and alphanumeric characters
      // const sanitizedValue = value.replace(/[^a-zA-Z0-9_]/g, '');
      sanitizedValue = sanitizedValue.replace(/[^a-zA-Z0-9_]/g, '');
      // Trim leading and trailing spaces
      sanitizedValue = sanitizedValue.trim();
    }

    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      procurementItems: [],
    }));
    if (name === "material" || name === "quantity" || name === "rate") {
      if (name === "material") {
        getSingleRawMaterialDetail(value, index);
      }

      setInputData((prevData) => {
        const updatedProcurementItems = [...prevData.procurementItems];
        updatedProcurementItems[index] = {
          ...updatedProcurementItems[index],
          [name]: value,
        };

        const { taxAmount, taxable, grandTotal } = calculateTaxAmount(
          updatedProcurementItems[index].rate,
          updatedProcurementItems[index].quantity,
          updatedProcurementItems[index].taxPercent
          // rawMaterialDetail.taxPercent
        );

        updatedProcurementItems[index] = {
          ...updatedProcurementItems[index],
          taxable,
          taxAmount,
          grandTotal,
        };

        return {
          ...prevData,
          procurementItems: updatedProcurementItems,
        };
      });
    } else {
      setInputData((prevData) => ({
        ...prevData,
        [name]: sanitizedValue,
        // [name]: value
      }));
    }
  };

  const calculateTaxAmount = (rate, quantity, taxPercent) => {
    const taxable = quantity * rate;
    const taxAmount = (taxable * taxPercent) / 100;
    const grandTotal = taxAmount + taxable;
    return {
      taxAmount,
      taxable,
      grandTotal,
    };
  };

  const handleFileChange = (e) => {
    setValidationErrors((prevErrors) => ({ ...prevErrors, invoiceFile: "" }));
    const selectedFile = e.target.files[0];
    setInvoiceFile(selectedFile);
  };

  const getSingleRawMaterialDetail = async (materialId, index) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`raw-material?_id=${materialId}`, {
        headers,
      });

      if (response?.data?.status === true) {
        setRawMaterialDetail(response?.data?.data?.rawMaterials);
        setInputData((prevData) => {
          const updatedProcurementItems = [...prevData.procurementItems];
          updatedProcurementItems[index] = {
            ...updatedProcurementItems[index],
            taxPercent: response?.data?.data?.rawMaterials?.taxPercent || 0,
            unit: response?.data?.data?.rawMaterials?.unit || "",
          };
        
          return {
            ...prevData,
            procurementItems: updatedProcurementItems,
          };
        });
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const validateProcurementItem = (item) => {
    const errors = {};

    if (!item.material.trim()) {
      errors.material = "Material is required.";
    }

    if (item.quantity.trim() === "") {
      errors.quantity = "Quantity is required.";
    }

    if (item.rate.trim() === "") {
      errors.rate = "Rate is required.";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputData.vendor) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        vendor: "Vendor is required.",
      }));
      return;
    }

    if (!inputData.idNumber) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        idNumber: "ID Number is required.",
      }));
      return;
    }

    if (!inputData.additionalCharges) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        additionalCharges: "Additional Charges is required.",
      }));
      return;
    }

    const procurementItemErrors = inputData.procurementItems.map(
      validateProcurementItem
    );
    if (
      procurementItemErrors.some((errors) => Object.keys(errors).length > 0)
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        procurementItems: procurementItemErrors,
      }));
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("procurement[vendor]", inputData.vendor);
      formData.append("procurement[idNumber]", inputData.idNumber);
      formData.append(
        "procurement[additionalCharges]",
        inputData.additionalCharges
      );
      formData.append("procurement.scannedInvoice", invoiceFile);
      inputData.procurementItems.forEach((item, index) => {
        formData.append(`procurementItems[${index}][material]`, item.material);
        formData.append(`procurementItems[${index}][quantity]`, item.quantity);
        formData.append(`procurementItems[${index}][rate]`, item.rate);
      });

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post("procurement", formData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        e.target.reset()
        setInputData({
          vendor: "",
          idNumber: "",
          additionalCharges: "",
          procurementItems: Array.from({ length: 1 }, () => ({
            material: "",
            quantity: "",
            rate: "",
          })),
        });
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getVendors = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("vendor", { headers });
      if (response?.data?.status === true) {
        setVendors(response?.data?.data?.vendors);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getRawMaterials = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("raw-material", { headers });

      if (response?.data?.status === true) {
        setRawMaterialList(response?.data?.data?.rawMaterials);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const btnDisable = () => {
    let isDisable = false;

    if (
      !inputData.vendor ||
      !inputData.idNumber ||
      !inputData.additionalCharges 
    ) {
      isDisable = true;
    }

    for (let i = 0; i < inputData.procurementItems.length; i++) {
      const item = inputData.procurementItems[i];

      if (!item.material || !item.quantity || !item.rate) {
        isDisable = true;
        break;
      }
    }

    return isDisable;
  };

  useEffect(() => {
    getVendors();
    getRawMaterials();
  }, []);

  return (
    <CContainer>
      <CForm onSubmit={handleSubmit}>
        <CCard>
          <CCardHeader>
            <CCardTitle className="pt-1 text-center">ADD PROCUREMENT</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="6" lg="3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Select Vendor <span className="text-danger">*</span>
                  </CFormLabel>
                  <Select
                    options={vendors.map((vendor) => ({
                      value: vendor._id,
                      label: vendor.name,
                    }))}
                    value={
                      inputData.vendor
                        ? {
                          value: inputData.vendor,
                          label: vendors.find((vendor) => vendor._id === inputData.vendor)?.name
                        }
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleChange({
                          target: { name: "vendor", value: selectedOption.value },
                        });
                      } else {
                        handleChange({ target: { name: "vendor", value: "" } });
                      }
                    }}
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Select Vendor"
                  />
                  <div className="text-danger">{validationErrors.vendor}</div>
                </div>
              </CCol>
              <CCol md="6" lg="3">
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    ID Number <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="ID Number"
                    className="form-control"
                    name="idNumber"
                    value={inputData?.idNumber}
                    onChange={handleChange}
                  />
                  <div className="text-danger">{validationErrors.idNumber}</div>
                </div>
              </CCol>
              <CCol md="6" lg="3" className="col-md-3">
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Additional Charges <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    min={0}
                    type="number"
                    step="any"
                    placeholder="Additional Charges"
                    className="form-control no-arrows"
                    name="additionalCharges"
                    value={inputData.additionalCharges}
                    onChange={handleChange}
                  />
                  <div className="text-danger">
                    {validationErrors.additionalCharges}
                  </div>
                </div>
              </CCol>
              <CCol md="6" lg="3">
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Scanned Invoice
                  </CFormLabel>
                  <CFormInput
                    type="file"
                    onChange={handleFileChange}
                    className="form-control"
                    id="formFile"
                    // accept=".txt, .png, .jpg"
                    accept=".txt, .png, .jpg, .jpeg, .pdf, .doc, .docx"
                  />
                  {/* <div className="text-danger">
                    {validationErrors.invoiceFile}
                  </div> */}
                </div>
              </CCol>
            </CRow>

            <h6>ADD PROCUREMENT ITEM</h6>
            <hr />
            <CRow>
              {inputData.procurementItems &&
                inputData.procurementItems.length > 0 &&
                inputData.procurementItems.map((item, index) => (
                  <div key={index} className="mt-2">
                    <CRow>
                      <CCol md="4">
                        <div className="mb-3">
                          <CFormLabel className="fw-semibold">
                            Select Raw Material{" "}
                            <span className="text-danger">*</span>
                          </CFormLabel>
                          <Select
                            options={rawMaterialList.map((rm) => ({
                              value: rm._id,
                              label: rm.name,
                            }))}
                            value={
                              item?.material
                                ? {
                                  value: item.material,
                                  label: rawMaterialList.find((rm) => rm._id === item.material)?.name,
                                }
                                : null
                            }
                            onChange={(selectedOption) => {
                              handleChange(
                                { target: { name: "material", value: selectedOption ? selectedOption.value : "" } },
                                index
                              );
                            }}
                            classNamePrefix="react-select"
                            isClearable
                            placeholder="Select Raw Material"
                          />

                          <div className="text-danger">
                            {validationErrors.procurementItems[index]?.material}
                          </div>
                        </div>
                      </CCol>
                      <CCol md="2">
                        <div className="mb-3">
                          <CFormLabel className="fw-semibold">
                            Quantity <span className="text-danger">*</span>
                          </CFormLabel>
                          <CFormInput
                            min={0}
                            step="any" // Allows decimal values
                            type="number"
                            placeholder="Quantity"
                            className="form-control no-arrows"
                            name="quantity"
                            value={item.quantity}
                            onChange={(e) => handleChange(e, index)}
                          />
                          <div className="text-danger">
                            {validationErrors.procurementItems[index]?.quantity}
                          </div>
                        </div>
                      </CCol>
                      <CCol md="1">
                        <div className="mb-3">
                          <CFormLabel className="fw-semibold">
                            Unit <span className="text-danger">*</span>
                          </CFormLabel>
                          <CFormInput
                            disabled
                            type="text"
                            placeholder="unit"
                            className="form-control no-arrows"
                            name="unit"
                            value={inputData.procurementItems[index]?.unit || ""}
                          />
                        </div>
                      </CCol>
                      <CCol md="3" lg="3">
                        <div className="mb-3">
                          <CFormLabel className="fw-semibold">
                            Rate <span className="text-danger">*</span>
                          </CFormLabel>
                          <CFormInput
                            step="any" // Allows decimal values
                            min={0}
                            type="number"
                            placeholder="In Rupees"
                            className="form-control no-arrows"
                            name="rate"
                            value={item.rate}
                            onChange={(e) => handleChange(e, index)}
                          />
                          <div className="text-danger">
                            {validationErrors.procurementItems[index]?.rate}
                          </div>
                        </div>
                      </CCol>
                      <CCol md="2">
                        <div className="d-flex justify-content-end align-items-center h-100">
                          {index === inputData.procurementItems.length - 1 && (
                            <div>
                              <CButton
                                size="sm"
                                onClick={handleAddItem}
                                color="success"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "50%",
                                  width: "36px",
                                  height: "36px",
                                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                }}
                                title="Add Item"
                              >
                                <FaPlus style={{ fontSize: "16px" }} />
                              </CButton>
                            </div>
                          )}

                          {inputData.procurementItems.length > 1 && (
                            <div className="ms-2">
                              <CButton
                                size="sm"
                                onClick={() => handleRemoveItem(index)}
                                color="danger"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "50%",
                                  width: "36px",
                                  height: "36px",
                                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                }}
                                title="Remove Item"
                              >
                                <FaMinus style={{ fontSize: "16px" }} />
                              </CButton>
                            </div>
                          )}
                        </div>
                      </CCol>


                      <CCol md="3" lg="3">
                        <div className="mb-3">
                          <CFormLabel className="fw-semibold">
                            Tax Percent
                          </CFormLabel>
                          <CFormInput
                            disabled
                            type="number"
                            step="any"
                            placeholder="In Rupees"
                            className="form-control no-arrows"
                            value={
                              inputData.procurementItems[index].taxPercent || ""
                            }
                          />
                        </div>
                      </CCol>
                      <CCol md="3" lg="3">
                        <div className="mb-3">
                          <CFormLabel className="fw-semibold">
                            Taxable
                          </CFormLabel>
                          <CFormInput
                            disabled
                            type="number"
                            placeholder="In Rupees "
                            className="form-control no-arrows"
                            value={parseFloat(
                              inputData.procurementItems[index].taxable || "0"
                            ).toFixed(2)}
                          />
                        </div>
                      </CCol>
                      <CCol md="3" lg="3" >
                        <div className="mb-3">
                          <CFormLabel className="fw-semibold">
                            Tax Amount
                          </CFormLabel>
                          <CFormInput
                            disabled
                            step="any" // Allows decimal values
                            type="number"
                            placeholder="In Rupees"
                            className="form-control no-arrows"
                            value={parseFloat(
                              inputData.procurementItems[index].taxAmount || "0"
                            ).toFixed(2)}
                          />
                        </div>
                      </CCol>
                      <CCol md="3" lg="3" >
                        <div className="mb-3">
                          <CFormLabel className="fw-semibold">
                            Grand Total
                          </CFormLabel>
                          <CFormInput
                            disabled
                            step="any" // Allows decimal values
                            type="number"
                            placeholder="In Rupees"
                            className="form-control no-arrows"
                            value={
                              inputData.procurementItems[index].grandTotal || ""
                            }
                          />
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                ))}
            </CRow>
          </CCardBody>
          <CCardFooter className="d-flex justify-content-center align-items-center">
            <PrimaryButton
              isDisable={btnDisable()}
              title="ADD PROCUREMENT"
              isLoading={isLoading}
            />
          </CCardFooter>
        </CCard>
      </CForm>
    </CContainer>
  );
};

export default Procurements;
