import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CContainer, CForm, CFormInput, CFormLabel, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import { DataTableComponent, DeleteModal, PrimaryButton } from '../../components';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';

const AssetType = () => {

  const accessToken = localStorage.getItem("authToken")
  const [title, setTitle] = useState("");
  const [updateTitle, setUpdateTitle] = useState("");
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [assets, setAssets] = useState([]);
  const [assetType, setAssetType] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [assetTypeId, setAssetTypeId] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [FilteredData, setFilteredData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });

  const columns = [
    {
      name: "Sr.No",
      // cell: (row, index) => (pagination.page - 1) * pagination.rowsPerPage + index + 1,
      cell: (row, index) => <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>,
      center: true,
    },
    {
      name: "TITLE",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="fs-7">{row.title ? row.title : "--------"}</div>
      ),
    },
    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center">
          <a
            href="#"
            onClick={() => handleEditModal(row)}
            className="text-primary me-2 d-flex align-items-center gap-1"
            style={{ textDecoration: "none" }}
          >
            <FaPencilAlt /> EDIT
          </a>{" "}
          |
          <a
            href="#"
            className="text-danger ms-2 d-flex align-items-center gap-1"
            onClick={() => handleDeleteModal(row)}
            style={{ textDecoration: "none" }}
          >
            <FaTrash /> DELETE
          </a>
        </div>
      ),
      minWidth: "165px",
    },
  ]

  const updatedColumns = columns.map((column) => ({
    ...column,
    name : (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    )
  }) )

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value);
  }
  
  const addAssetType = async(e) => {
    e.preventDefault();
    if( validateForm() ) {
      setIsLoading(true);
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        const inputData = {
          title: title,
        };
        const response = await axios.post("asset-type", inputData, { headers });

        if (response?.data?.status === true) {
          toast.success(response?.data?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          getAssetType();
          setTitle("");
        }
      } catch (error) {
        if (error?.response) {
          let errorMessage = '';
          if (
            error.response?.status === 406
          ) {
            errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
          }
          else if (
            error.response?.status === 412
          ) {
            errorMessage = error?.response?.data?.data?.message;
          } else if (error.response?.status === 500) {
            errorMessage = error?.response?.data?.error
          }

          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  const getAssetType = async() => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("asset-type", { headers });
      response.data?.data?.assetTypes.forEach((item, index) => {
        item.serialNumber = index + 1;
      });
      setAssetType(response.data?.data?.assetTypes);
      setFilteredData(response.data?.data?.assetTypes);
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  }

  const handleEditModal = (data) => {
    // setEditedData({
    //   _id: data?._id,
    //   title: data.title,
    // });
    setUpdateTitle({
      _id: data?._id,
      title: data.title,
    })
    setOpenEditModal(true);
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    // setEditedData((prevState) => ({ ...prevState, [name]: value }));
    setUpdateTitle((prevState) => ({ ...prevState, [name]: value }));
  }

  const editAssetTypeSubmit = async(e) => {
    e.preventDefault();
    if ( validateForm2() ) {
      setIsEditBtnLoading(true);
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.put("asset-type", updateTitle, { headers });
        if (response?.data?.status === true) {
          toast.success(response?.data?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          getAssetType();
          setOpenEditModal(false);
        }
      } catch (error) {
        if (error?.response) {
          let errorMessage = '';
          if (
            error.response?.status === 406
          ) {
            errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
          }
          else if (
            error.response?.status === 412
          ) {
            errorMessage = error?.response?.data?.data?.message;
          } else if (error.response?.status === 500) {
            errorMessage = error?.response?.data?.error
          }
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      } finally {
        setIsEditBtnLoading(false);
      }
    }
  };

  const handleDeleteModal = (data) => {
    setOpenDeleteModal(true);
    setAssetTypeId(data?._id);
  }

  const handleDeleteAssetType = async(e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: assetTypeId,
      };

      const response = await axios.delete("asset-type", { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAssetType();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }

      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  }

  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
  }

  const validateForm = () => {
    const newErrors = {};
    const trimmedTitle = title.trim();
    const wordCount = trimmedTitle.split(/\s+/).length;
    const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (!title || trimmedTitle.length === 0) { // Check if the title is empty or only contains spaces
        newErrors.title = "Title is required and cannot be empty or only spaces";
    } else if (!isNaN(title) || /^-\d+$/.test(title)) {
        newErrors.title = "Title should not contain numeric or a negative integer";
    } else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
        newErrors.title = "Title must contain at least 2 letters";
    } else if (specialCharacterPattern.test(title)) {
        newErrors.title = "Title should not contain special characters";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const validateForm2 = () => {
    let title = updateTitle?.title;
    const newErrors = {};
    const trimmedTitle = title ? title.trim() : '';
    const wordCount = trimmedTitle.split(/\s+/).length;
    const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if(!title || trimmedTitle.length === 0) { // Check if the title is empty or only contains spaces
      newErrors.title = "Title is required and cannot be empty or only spaces";
    } else if (!isNaN(title) || /^-\d+$/.test(title)) {
      newErrors.title = "Title should not contain numeric or a negative integer";
    } else if (trimmedTitle.length < 2) {
      newErrors.title = "Title must contain at least 2 letters";
    } else if (specialCharacterPattern.test(title)) {
      newErrors.title = "Title should not contain special characters";
    }
    setErrors2(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };
  
  const handleRowsPerPageChange = (rowsPerPage) => {
    setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };
  
  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return FilteredData?.slice(startIndex, endIndex);
  };

  useEffect(() => {
    getAssetType();
  }, []);

  useEffect(() => {
    const result = assetType.filter((item) => {
      return item.title?.toLowerCase().match(searchText.toLocaleLowerCase());
    });
    setFilteredData(result);
  }, [searchText]);

  return (
    <CContainer>
      <CRow>

        {/* <----------------- Add Asset Type Form ---------------------> */}
        <CCol className='col-md-4 col-lg-4'>
          <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
            <CCardHeader>
              <CCardTitle className="fs-6 fw-semibold pt-1">
                ADD ASSET TYPE
              </CCardTitle>
            </CCardHeader>
            <CForm onSubmit={addAssetType}>
              <CCardBody>
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="Title"
                    required
                    className="form-control"
                    name="title"
                    value={title}
                    onChange={handleChange}
                  />
                  {errors.title && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.title}</div>}
                </div>
              </CCardBody>
              <CCardFooter>
                <PrimaryButton title="ADD ASSET TYPE" 
                isLoading={isLoading}
                />
              </CCardFooter>
            </CForm>
          </CCard>
        </CCol>

        {/* <----------------- Listing of Asset Types ------------------> */}
        <CCol className='col-md-8 col-lg-8'>
          <DataTableComponent
            title="ASSET TYPE LIST"
            columns={updatedColumns}
            // data={FilteredData}
            data={getPaginatedData()} // Use the paginated data here
            searchText={searchText}
            onChange={handleSearchChange}
            striped
            itemsPerPage={10}
            pagination
            totalRows={FilteredData?.length}
            paginationTotalRows={FilteredData?.length} // Total number of items
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationServer={false} // Set to false for client-side pagination
          />
        </CCol>
      </CRow>

      {/* <----------------- Edit Asset Type Modal ---------------------> */}
      {openEditModal ? (
        <CModal
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT ASSET TYPE
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={editAssetTypeSubmit}>
            <CModalBody>
              <CFormLabel className="fw-semibold">
                Title <span className="text-danger">*</span>
              </CFormLabel>
              <div className="mb-3">
                <CFormInput
                  type="text"
                  placeholder="Title"
                  className="form-control"
                  name="title"
                  value={updateTitle.title}
                  required
                  onChange={handleEditChange}
                />
                { errors2.title && <div className='text-danger' style={{ fontSize: '12px'}}>{errors2.title}</div> }
              </div>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {/* <------------------- Delete Asset Type Modal ---------------------> */}
      {openDeleteModal ? (
        <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteAssetType} isLoading={isDeleteBtnLoading} title="DELETE ASSET TYPE" />
      ) : null}

    </CContainer>
  )
}

export default AssetType