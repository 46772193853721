import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CImage,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { DataTableComponent, PrimaryButton } from "../../components";
import { FaPlus, FaTrash, FaPencilAlt, FaEye, FaMinus } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';

const Outlets = () => {
  const accessToken = localStorage.getItem("authToken");
  const [inputData, setInputData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
    latitude: "",
    longitude: "",
    isEventOutlet: false,
    openingTime: '',
    closingTime: '',
    gstin: '',
    platForms: [{ name: "", webLink: "" }]
  });
  const [outletImage, setOutletImage] = useState(null);
  const [isUpdateImageBtnLoading, setIsUpdateImageBtnLoading] = useState(false);
  const [editedOutletImage, setEditedOutletImage] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [categoryId, setCategoryId] = useState("");
  const [outletDetails, setOutletDetails] = useState({});
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  });
  const [outletId, setOutletId] = useState("");

  const [editValidationErrors, setEditValidationErrors] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [addNewOpenModal, setAddNewOpenModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [seletedImage, setSelectedImage] = useState(null);
  const [outletImageId, setOutletImageId] = useState('');
  const [openUpdateImageModal, setOpenUpdateImageModal] = useState(false);
  const [searchData, setSearchData] = useState({
    city: "",
    pincode: ""
  });

  const [center, setCenter] = useState({
    name: "",
    lat: 17.9104,
    lng: 77.5199,
  });

  const containerStyle = {
    width: '80%',
    height: '350px',
    margin: '20px auto'
  };

  const updateCenter = (newLat, newLng) => {
    if (typeof newLat === 'number' && typeof newLng === 'number') {
      setCenter({
        lat: newLat,
        lng: newLng,
      });
    } else {
      console.error('Invalid latitude or longitude:', newLat, newLng);
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyATjJP7Zx7lNzU-dRY9dLNTY29TfUHXPJo'
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [center.lat, center.lng])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const columns = [
    {
      name: "Sr.No",
      selector: (row, index) => index + 1,
      cell: (row) => (
        <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>
      ),
      width: "10%",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="fs-7">{row.name ? row.name : "--------"}</div>
      ),
      width: "15%",
    },
    {
      name: "CONTACT",
      selector: (row) => row.email,
      cell: (row) => (
        <div style={{ cursor: 'pointer' }}>
          <span
            className="fs-7 text-primary"
            style={{ textDecoration: "none" }}
          >
            {row.email ? row.email : "--------"}
          </span>{" "}
          <br />
          <span className="fs-7 text-danger" style={{ textDecoration: "none" }}>
            {row.phone ? row.phone : "--------"}
          </span>
        </div>
      ),
      width: "20%",
    },
    {
      name: "CITY",
      selector: (row) => row.city,
      cell: (row) => (
        <div>
          <span
            className="fs-7"
            style={{ textDecoration: "none" }}
          >
            {row.city ? row.city.toUpperCase() : "--------"}
          </span>{" "}
          <br />
          <span className="fs-7 text-info" style={{ textDecoration: "none" }}>
            {row.pincode ? row.pincode : "--------"}
          </span>
        </div>
      ),
    },
    {
      name: "STATE & COUNTRY",
      selector: (row) => row.state,
      cell: (row) => (
        <div>
          <span className="fs-7">
            {row.state ? row.state.toUpperCase() : "--------"}
          </span>
          &nbsp;
          <span className="fs-7">
            &amp;
          </span>
          &nbsp;
          <span className="fs-7">
            {row.country ? row.country.toUpperCase() : "--------"}
          </span>
        </div>
      ),
      width: "18%",
    },
    {
      name: "OUTLET STATUS",
      selector: (row) => row.status,
      cell: (row) => (
        <div className="fs-7 fw-semibold" style={{ color: row?.status === "ACTIVE" ? "green" : row?.status === "INACTIVE" ? "red" : "orange" }}>{row.status ? row.status : "--------"}</div>
      ),
    },
    {
      name: "ACTIONS",
      // right: "true",
      algin: "center",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center">
          <a
            href="#"
            className="text-warning me-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleOpenViewModal(row)}
          >
            <FaEye /> View
          </a>{" "} |
          <a
            href="#"
            className="text-primary me-2 ms-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleEditModal(row)}
          >
            <FaPencilAlt /> Edit
          </a>{" "}
          |
          <a
            href="#"
            className="text-danger ms-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleDeleteModal(row)}
          >
            <FaTrash /> Delete
          </a>
        </div>
      ),
      width: "20%",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleAddNewOpenModal = () => {
    setAddNewOpenModal(true);
  };

  const handleImageChange = (e) => {
    const input = document.getElementById("imageInput");

    const file = input?.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const maxWidth = 1410;
          const maxHeight = 1100;

          if (width === maxWidth && height === maxHeight) {
            setOutletImage(file);
          } else {
            alert(
              "Image dimensions are not valid. Please choose an image with dimensions equal to " +
              maxWidth +
              "x" +
              maxHeight
            );
            input.value = "";
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleEditImageChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;
  
        image.onload = () => {
          const width = image.width;
          const height = image.height;
          const maxWidth = 1410;
          const maxHeight = 1100;
  
          if (width === maxWidth && height === maxHeight) {
            // Set the selected file to editedOutletImage if dimensions are correct
            setEditedOutletImage(file);
          } else {
            alert(
              `Invalid image dimensions. Please upload an image with dimensions ${maxWidth}x${maxHeight}.`
            );
            e.target.value = ""; // Clear the file input if dimensions are incorrect
          }
        };
      };
  
      reader.readAsDataURL(file);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    if (index !== undefined) {
      setInputData((prevState) => {
        const updatedPlatforms = [...prevState.platForms];
        updatedPlatforms[index] = { ...updatedPlatforms[index], [name]: value };
        return { ...prevState, platForms: updatedPlatforms };
      });
    } else {
      setInputData((prevState) => ({
        ...prevState,
        [name]: value,
        ...(name === "pincode" && value === "" ? { city: "", state: "", country: "" } : {}),
      }));
    }
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleAddItem = () => {
    setInputData((prevData) => ({
      ...prevData,
      platForms: [
        ...prevData.platForms,
        { name: "", webLink: "" },
      ],
    }));
  };

  const handleRemoveItem = (index) => {
    setInputData((prevData) => {
      const updatedItems = [...prevData.platForms];
      updatedItems.splice(index, 1);
      return {
        ...prevData,
        platForms: updatedItems,
      };
    });
  };

  const addOutlet = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", inputData.name);
      formData.append("phone", inputData.phone);
      formData.append("email", inputData.email);
      formData.append("address", inputData.address);
      formData.append("pincode", inputData.pincode);
      formData.append("city", inputData.city);
      formData.append("state", inputData.state);
      formData.append("country", inputData.country);
      formData.append("outletImage", outletImage);
      formData.append("coordinates[latitude]", inputData.latitude);
      formData.append("coordinates[longitude]", inputData.longitude);
      formData.append("gstin", inputData.gstin);

      formData.append("openingTime", inputData.openingTime || null);
      formData.append("closingTime", inputData.closingTime || null);
      formData.append("isEventOutlet", inputData.isEventOutlet || false);

      inputData.platForms.forEach((platform, index) => {
        // formData.append(`platForms[${index}][name]`, platform.name);
        // formData.append(`platForms[${index}][webLink]`, platform.webLink);
        if (platform.name && platform.webLink) {
          formData.append(`platForms[${index}][name]`, platform.name);
          formData.append(`platForms[${index}][webLink]`, platform.webLink);
        }
      });

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post("outlet", formData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getOutlets();

        e.target.reset();
        setInputData({
          name: "",
          email: "",
          phone: "",
          address: "",
          pincode: "",
          city: "",
          state: "",
          country: "",
          latitude: "",
          longitude: "",
          status: "",
          platForms: [{ name: "", webLink: "" }],
        });
        setAddNewOpenModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        response?.data?.data?.outlets.forEach((item, index) => {
          item.serialNumber = index + 1;
        });

        setOutlets(response?.data?.data?.outlets);
        setFilteredData(response?.data?.data?.outlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleIsOutletClosedChange = (e) => {
    setEditedData((prev) => ({ ...prev, isOutletClosed: e.target.checked }))
  }

  const handleIsEventOutletChange = (e) => {
    setInputData((prev) => ({ ...prev, isEventOutlet: e.target.checked }))
  }

  const handleEditIsEventOutletChange = (e) => {
    setEditedData((prev) => ({ ...prev, isEventOutlet: e.target.checked }))
  }

  const handleAddEditItem = () => {
    setEditedData((prevData) => ({
      ...prevData,
      platForms: [
        ...prevData.platForms,
        { name: "", webLink: "" },
      ],
    }));
  };

  const handleRemoveEditItem = (index) => {
    setEditedData((prevData) => {
      const updatedItems = [...prevData.platForms];
      updatedItems.splice(index, 1);
      return {
        ...prevData,
        platForms: updatedItems,
      };
    });
  };

  const handleEditModal = async (data) => {
    setEditedData({
      _id: data?._id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      pincode: data.pincode,
      city: data.city,
      state: data.state,
      gstin: data.gstin,
      country: data.country,
      latitude: data?.coordinates?.latitude,
      longitude: data?.coordinates?.longitude,
      openingTime: data?.openingTime,
      closingTime: data?.closingTime,
      status: data?.status,
      isEventOutlet: data?.isEventOutlet,
      isOutletClosed: data?.isOutletClosed,
      platForms: Array.isArray(data.platForms) ? data.platForms : [],
    });
    setOutletId(data._id);
    setOpenEditModal(true);
  };

  const handleEditChange = (e, platformIndex = null) => {
    const { name, value } = e.target;

    // Handle platform fields
    if (platformIndex !== null) {
      // Update platform name or webLink
      const updatedPlatforms = [...editedData.platForms];

      // Check if the platform exists at the given index
      if (!updatedPlatforms[platformIndex]) {
        updatedPlatforms[platformIndex] = { name: '', webLink: '' }; // Initialize if it doesn't exist
      }

      if (name === "platformName") {
        updatedPlatforms[platformIndex].name = value;
      } else if (name === "platformWeblink") {
        updatedPlatforms[platformIndex].webLink = value;
      }

      setEditedData((prevState) => ({
        ...prevState,
        platForms: updatedPlatforms,
      }));
    } else {
      // Handle regular fields
      setEditedData((prevState) => ({
        ...prevState,
        [name]: value,
        ...(name === "pincode" && value === "" ? { city: "", state: "", country: "" } : {}),
      }));
    }

    // Reset validation errors for the field
    setEditValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("_id", editedData._id);
      formData.append("name", editedData.name);
      formData.append("phone", editedData.phone);
      formData.append("email", editedData.email);
      formData.append("address", editedData.address);
      formData.append("pincode", editedData.pincode);
      formData.append("city", editedData.city);
      formData.append("state", editedData.state);
      formData.append("country", editedData.country);
      formData.append("coordinates[latitude]", editedData.latitude);
      formData.append("coordinates[longitude]", editedData.longitude);
      formData.append("isOutletClosed", editedData.isOutletClosed);
      formData.append("status", editedData?.status);
      formData.append("isEventOutlet", editedData?.isEventOutlet);
      formData.append("gstin", editedData?.gstin);
      formData.append("openingTime", editedData?.openingTime || null);
      formData.append("closingTime", editedData?.closingTime || null);

      if (editedData?.platForms.length > 0) {
        editedData.platForms.forEach((platform, index) => {
          // Check if both fields (name and webLink) are not empty or just whitespace
          if (platform.name.trim() !== '' && platform.webLink.trim() !== '') {
            formData.append(`platForms[${index}][name]`, platform.name);
            formData.append(`platForms[${index}][webLink]`, platform.webLink);

            // Append _id if it exists
            if (platform._id) {
              formData.append(`platForms[${index}][_id]`, platform._id);
            }
          }
        });
      }

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("outlet", formData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getOutlets();
        setOpenEditModal(false);
        getSingleOutletDetails(outletId);
        setOpenViewModal(true);
        setOutletId("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setCategoryId(data?._id);
  };

  const handleDeleteCategory = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: categoryId,
      };
      const response = await axios.delete(`outlet`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getOutlets();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const getSingleOutletDetails = async (outletId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`outlet?_id=${outletId}`, { headers });

      if (response?.data?.status === true) {
        setOutletDetails(response?.data?.data?.outlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getPostalCodeDetails = async () => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${inputData.pincode}`
      );

      if (response?.data[0]?.Status === "Success") {
        const postOffice = response?.data[0]?.PostOffice[0];
        setInputData((prevData) => ({
          ...prevData,
          city: postOffice?.Division || "",
          state: postOffice?.State || "",
          country: postOffice?.Country || "",
        }));
      } else if (response?.data[0]?.Status === "Error") {
        toast.error(`${response?.data[0]?.Message} or Invalid Pincode.`, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setInputData((prevData) => ({
          ...prevData,
          city: "",
          state: "",
          country: "",
        }));
      }
    } catch (error) {
      if (error) {
        let errorMessage = error.message;

        if (errorMessage === "Network Error") {
          toast.error("Invalid Pincode.", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      }

    }
  };

  const getEditPostalCodeDetails = async () => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${editedData.pincode}`
      );

      if (response?.data[0]?.Status === "Success") {
        const postOffice = response?.data[0]?.PostOffice[0];
        setEditedData((prevData) => ({
          ...prevData,
          city: postOffice?.Division || "",
          state: postOffice?.State || "",
          country: postOffice?.Country || "",
        }));
      } else if (response?.data[0]?.Status === "Error") {
        toast.error(`${response?.data[0]?.Message} or Invalid Pincode.`, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setEditedData((prevData) => ({
          ...prevData,
          city: "",
          state: "",
          country: "",
        }));
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  };

  const handleOpenImageModal = (data) => {
    setOpenImageModal(true);
    setSelectedImage(data.outletImage);
  };

  const handleOpenImageChangeModal = (id) => {
    setOpenImageModal(false)
    setOpenUpdateImageModal(true);
    setOutletImageId(id);
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setSearchData((prev) => ({ ...prev, [name]: value }))
  }

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.get(`outlet/getOutletCityPincode?city=${searchData?.city}&pincode=${searchData?.pincode}`, { headers });
      if (response?.data?.status === true) {
        const totalRecords = response?.data?.data?.totalOutletRecords;
        setFilteredData(totalRecords)
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  }

  const handleOpenViewModal = (data) => {
    const newLat = parseFloat(data?.coordinates?.latitude);
    const newLng = parseFloat(data?.coordinates?.longitude);
    if (!isNaN(newLat) && !isNaN(newLng)) {
      updateCenter(newLat, newLng);
      setCenter((prev) => ({ ...prev, name: data.name }))
      setOpenViewModal(true);
      getSingleOutletDetails(data?._id);
    } else {
      console.error('Invalid coordinates:', data?.coordinates);
    }
  }

  const formatTime = (time) => {
    if (!time) return '';

    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    minutes = minutes.padStart(2, '0'); // Ensure minutes are always two digits

    return `${hours}:${minutes} ${ampm}`;
  };

  const disableShowBtn = !searchData?.city?.trim() || !searchData?.pincode.trim()

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };
  
  const handleRowsPerPageChange = (rowsPerPage) => {
    setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };
  
  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return filteredData?.slice(startIndex, endIndex);
  };

  const handleUpdateImage = async(e) => {
    e.preventDefault();
    setIsUpdateImageBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`
      }
      const formData = new FormData();
      formData.append('outletImage', editedOutletImage);
      formData.append('_id', outletImageId);
      const response = await axios.put("outlet", formData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getOutlets();
        setOpenUpdateImageModal(false);
      }
      
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsUpdateImageBtnLoading(false);
    }
  }
  
  useEffect(() => {
    getOutlets();
  }, []);

  useEffect(() => {
    const result = outlets.filter((item) => {
      return (
        item.name.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.email.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.phone.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.address.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.pincode.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.city.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.state.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.country.toLowerCase().match(searchText.toLocaleLowerCase())
      );
    });
    setFilteredData(result);
  }, [searchText]);

  useEffect(() => {
    if (!searchData?.city || !searchData?.pincode) {
      getOutlets()
    }
  }, [searchData?.city, searchData?.pincode])

  return (
    <CContainer>
      <div>
        <p>Locate Nearby Outlets</p>
        <div className="d-flex justify-content-between">
          <div>
            <CForm onSubmit={handleFilterSubmit}>
              <CRow className="d-flex align-items-center">
                <CCol md="4">
                  {" "}
                  <div className="mb-3">
                    <CFormInput
                      type="text"
                      placeholder="City"
                      className="form-control"
                      name="city"
                      value={searchData.city}
                      onChange={handleFilterChange}
                    />
                  </div>
                </CCol>
                <CCol md="4">
                  {" "}
                  <div className="mb-3">
                    <CFormInput
                      type="text"
                      placeholder="Pincode"
                      className="form-control"
                      name="pincode"
                      value={searchData.pincode}
                      onChange={handleFilterChange}
                    />
                  </div>
                </CCol>
                <CCol md="4">
                  {" "}
                  <div className="mb-3">
                    <PrimaryButton title="SHOW" style={{ width: '100%' }} isDisable={disableShowBtn} />
                  </div>
                </CCol>
              </CRow>
            </CForm>

          </div>
          <div className="mb-3 text-end">
            <button
              className="btn btn-warning"
              style={{ borderRadius: "20px" }}
              onClick={handleAddNewOpenModal}
            >
              ADD NEW OUTLET <FaPlus style={{ marginTop: "-4px" }} size={15} />
            </button>
          </div>
        </div>
      </div>
      <div>
        <DataTableComponent
          columns={updatedColumns}
          title="OUTLETS LIST"
          // data={filteredData}
          data={getPaginatedData()} // Use the paginated data here
          searchText={searchText}
          onChange={handleSearchChange}
          striped
          itemsPerPage={10}
          pagination
          totalRows={filteredData?.length}
          paginationTotalRows={filteredData?.length} // Total number of items
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationServer={false} // Set to false for client-side pagination
        />
      </div>

      {openEditModal ? (
        <CModal
          size="xl"
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader style={{ backgroundColor: "#F5F5F5" }}>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT OUTLET
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={editedData?.name}
                      onChange={handleEditChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.name}
                    </div>
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Email <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      name="email"
                      value={editedData?.email}
                      onChange={handleEditChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.email}
                    </div>
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Phone <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Phone"
                      className="form-control no-arrows"
                      name="phone"
                      value={editedData?.phone}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Latitude <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Latitude"
                      className="form-control no-arrows"
                      name="latitude"
                      value={editedData?.latitude}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Longitude <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Longitude"
                      className="form-control no-arrows"
                      name="longitude"
                      value={editedData?.longitude}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol md="3" lg="3">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Opening Time
                    </CFormLabel>
                    <CFormInput
                      type="time"
                      placeholder="Opening Time"
                      className="form-control"
                      name="openingTime"
                      value={editedData?.openingTime}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol md="3" lg="3">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Closing Time
                    </CFormLabel>
                    <CFormInput
                      type="time"
                      placeholder="Closing Time"
                      className="form-control"
                      name="closingTime"
                      value={editedData?.closingTime}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Full Address <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormTextarea
                      rows={2}
                      type="text"
                      placeholder="Address"
                      className="form-control"
                      name="address"
                      value={editedData?.address}
                      onChange={handleEditChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.address}
                    </div>
                  </div>
                </CCol>
                
                <CCol md="3" lg="3">
                  <CFormLabel className="fw-semibold">
                      Outlet Status <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect className='w-100' id="status" name="status" value={editedData?.status} onChange={handleEditChange}>
                      <option value="" disabled >Select Outlet Status</option>
                      <option value="ACTIVE" >ACTIVE</option>
                      <option value="INACTIVE" >INACTIVE</option>
                      <option value="COMING_SOON" >COMING_SOON</option>
                  </CFormSelect>
                </CCol>
                <CCol md="3" lg="3">
                  <CFormLabel className="fw-semibold">
                    Is Outlet Closed? <span className="text-danger">*</span>
                  </CFormLabel>
                  <div className="d-flex">
                    <CFormSwitch
                      // label=" Is Outlet Closed?"
                      size="xl"
                      style={{ width: '80px' }}
                      id="formSwitchCheckDefault"
                      onChange={handleIsOutletClosedChange}
                      checked={editedData?.isOutletClosed}
                    />
                  </div>
                </CCol>
              </CRow>

              <CRow className="mb-3">
                <CCol md="6" lg="6">
                  <CFormLabel className="fw-semibold">GSTIN</CFormLabel>
                  <CFormInput
                    className="form-control"
                    type="text"
                    placeholder="GSTIN"
                    name="gstin"
                    onChange={handleEditChange}
                    value={editedData?.gstin}
                  />
                </CCol>
                <CCol md="3" lg="3">
                  <CFormLabel className="fw-semibold">
                    Is It Event Outlet? <span className="text-danger">*</span>
                  </CFormLabel>
                  <div className="d-flex">
                    <CFormSwitch
                      // label=" Is Event Closed?"
                      size="xl"
                      style={{ width: '80px' }}
                      id="formSwitchCheckDefault"
                      onChange={handleEditIsEventOutletChange}
                      checked={editedData?.isEventOutlet}
                    />
                  </div>
                </CCol>
                <CCol md="3" lg="3">
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Pincode <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Pincode"
                      className="form-control no-arrows"
                      name="pincode"
                      value={editedData?.pincode}
                      onChange={handleEditChange}
                      onBlur={getEditPostalCodeDetails}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.pincode}
                    </div>
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol  md="4" lg="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      City <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="City"
                      className="form-control"
                      name="city"
                      value={editedData?.city}
                      onChange={handleEditChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.city}
                    </div>
                  </div>
                </CCol>
                <CCol  md="4" lg="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      State <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="State"
                      className="form-control"
                      name="state"
                      value={editedData?.state}
                      onChange={handleEditChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.state}
                    </div>
                  </div>
                </CCol>
                <CCol  md="4" lg="4">
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Country <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Country"
                      className="form-control"
                      name="country"
                      value={editedData.country}
                      onChange={handleEditChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.country}
                    </div>
                  </div>
                </CCol>
              </CRow>
              <>
                <h6>DELIVERY PLATFORMS</h6>
                <hr />
              </>
              {(Array.isArray(editedData?.platForms) && editedData?.platForms.length > 0 ? editedData.platForms : [{ name: '', webLink: '' }]).map((platform, index) => (
                <CRow key={index}>
                  <CCol md="5">
                    <div className="mb-3">
                      <CFormLabel className="fw-semibold">Name</CFormLabel>
                      <CFormInput
                        type="text"
                        placeholder="Platform Name"
                        className="form-control"
                        name="platformName"
                        value={platform.name}
                        onChange={(e) => handleEditChange(e, index)}
                      />
                    </div>
                  </CCol>
                  <CCol md="5">
                    <div className="mb-3">
                      <CFormLabel className="fw-semibold">Weblink</CFormLabel>
                      <CFormInput
                        type="text"
                        placeholder="Weblink"
                        className="form-control"
                        name="platformWeblink"
                        value={platform.webLink}
                        onChange={(e) => handleEditChange(e, index)}
                      />
                    </div>
                  </CCol>
                  <CCol>
                    {editedData?.platForms?.length === 1 ? (
                      <>
                        <CButton
                          onClick={() => handleAddEditItem()}
                          color="primary"
                          style={{ borderRadius: "18px" }}
                        >
                          <FaPlus />
                        </CButton>
                        <CButton
                          color="primary"
                          onClick={() => handleRemoveEditItem(index)}
                          style={{ borderRadius: "18px", marginLeft: '4px' }}
                        >
                          <FaMinus />
                        </CButton>
                      </>
                    ) : (
                      <>
                        {index === editedData?.platForms?.length - 1 && (
                          <CButton
                            color="primary"
                            style={{ borderRadius: "18px" }}
                            onClick={() => handleAddEditItem()}
                          >
                            <FaPlus />
                          </CButton>
                        )}
                        <CButton
                          color="primary"
                          style={{ borderRadius: "18px", marginRight: '4px' }}
                          onClick={() => handleRemoveEditItem(index)}
                        >
                          <FaMinus />
                        </CButton>
                      </>
                    )}
                  </CCol>
                </CRow>
              ))}


            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader style={{ backgroundColor: "#F5F5F5" }}>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE OUTLET
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteCategory}>
            <CModalBody>
              Are you sure you want to delete this outlet ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenDeleteModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                CLOSE
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openViewModal ? (
        <CModal
          size="xl"
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              VIEW OUTLET DETAILS
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow>
              <CCol>
                <div style={{ width: '100%', height: '400px' }} >
                  <CImage src={outletDetails?.outletImage} alt="outlet-img" className="w-100 h-100" />
                  <CButton className="mt-3" style={{ display: 'flex', justifySelf: 'center' }} onClick={() => handleOpenImageChangeModal(outletDetails?._id)}>Change Image</CButton>
                </div>
              </CCol>
              <CCol> <CTable striped bordered>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">Name</CTableDataCell>
                    <CTableDataCell>{outletDetails?.name}</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">Email</CTableDataCell>
                    <CTableDataCell>{outletDetails?.email}</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">Phone</CTableDataCell>
                    <CTableDataCell>{outletDetails?.phone}</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">
                      Address
                    </CTableDataCell>
                    <CTableDataCell>{outletDetails?.address}</CTableDataCell>
                  </CTableRow>
                  { outletDetails.gstin && outletDetails.gstin === null ? (
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">
                        GSTIN
                      </CTableDataCell>
                      <CTableDataCell></CTableDataCell>
                  </CTableRow>
                  ) : null }
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">City</CTableDataCell>
                    <CTableDataCell>{outletDetails?.city}</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">
                      Pincode
                    </CTableDataCell>
                    <CTableDataCell>{outletDetails?.pincode}</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">State</CTableDataCell>
                    <CTableDataCell>{outletDetails?.state} </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">
                      Country
                    </CTableDataCell>
                    <CTableDataCell>{outletDetails?.country} </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">
                      Status
                    </CTableDataCell>
                    <CTableDataCell
                      className={`${outletDetails?.status === "INACTIVE"
                        ? "text-danger"
                        : "text-success"
                        }`}
                        style={{ fontWeight: '500' }}
                    >
                      {outletDetails?.status}{" "}
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">
                      Opening Time
                    </CTableDataCell>
                    <CTableDataCell>
                      {formatTime(outletDetails?.openingTime)}{" "}
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">
                      Closing Time
                    </CTableDataCell>
                    <CTableDataCell>
                      {formatTime(outletDetails?.closingTime)}{" "}
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="fw-semibold">
                      Is Outlet Closed
                    </CTableDataCell>
                    <CTableDataCell className={`${outletDetails?.isOutletClosed === true
                      ? "text-danger"
                      : "text-success"
                      }`}>
                      {outletDetails?.isOutletClosed === true ? 'CLOSED' : 'OPEN'}{" "}
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="fw-semibold">
                      Outlet Type
                    </CTableDataCell>
                    <CTableDataCell className="fw-semibold" style={{ color: 'brown' }}>
                      {outletDetails?.isEventOutlet === true ? "Event Outlet" : "Regular Outlet"}{" "}
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable></CCol>
            </CRow>

            {
              outletDetails?.platForms && outletDetails?.platForms.length > 0 && <CTable striped bordered style={{ width: '80%', margin: 'auto' }}>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>Platform Name</CTableHeaderCell>
                    <CTableHeaderCell>Platform Weblink</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {
                    outletDetails?.platForms && outletDetails?.platForms.length > 0 &&
                    outletDetails?.platForms.map((item) =>
                      <CTableRow>
                        <CTableDataCell>{item?.name}</CTableDataCell>
                        <CTableDataCell>{item?.webLink}</CTableDataCell>
                      </CTableRow>
                    )
                  }

                </CTableBody>
              </CTable>
            }

            {
              isLoaded ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  <MarkerF position={center} title={center.name} />
                </GoogleMap>
              ) : <></>
            }
          </CModalBody>
          <CModalFooter>
            <CButton
              style={{ borderRadius: "18px" }}
              className="btn btn-primary"
              onClick={() => setOpenViewModal(false)}
            >
              CLOSE
            </CButton>
          </CModalFooter>
        </CModal>
      ) : null}

      {addNewOpenModal ? (
        <CModal
          size="xl"
          alignment="center"
          visible={addNewOpenModal}
          onClose={() => setAddNewOpenModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>ADD OUTLET</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={addOutlet}>
            <CModalBody>
              <CRow>
                <CCol md="6" lg="4">
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={inputData.name}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol md="6" lg="4">
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Email <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      name="email"
                      value={inputData.email}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol md="5" lg="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Mobile <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      minLength={10}
                      type="number"
                      placeholder="Mobile"
                      className="form-control no-arrows"
                      name="phone"
                      value={inputData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>

                <CCol md="6" lg="6">
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      GSTIN 
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="GstIn"
                      className="form-control"
                      name="gstin"
                      value={inputData.gstin}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>

                <CCol className="col-md-4" lg="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Choose Outlet Image <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      id="imageInput"
                      accept="image/*"
                      type="file"
                      onChange={handleImageChange}
                      className="form-control"
                    />
                    <span className="text-success" style={{ fontSize: '12px' }}>Choose an image with dimensions equal to 1410x1100</span>
                  </div>
                </CCol>

                <CCol md="2" lg="2">
                  <CFormLabel className="fw-semibold">
                    Is It Event Outlet? <span className="text-danger">*</span>
                  </CFormLabel>
                  <div className="d-flex">
                    <CFormSwitch
                      // label=" Is Event Closed?"
                      size="xl"
                      style={{ width: '80px' }}
                      id="formSwitchCheckDefault"
                      onChange={handleIsEventOutletChange}
                      checked={inputData?.isEventOutlet}
                    />
                  </div>
                </CCol>

                <CCol md="6" lg="6">
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Latitude <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Latitude"
                      className="form-control no-arrows"
                      name="latitude"
                      value={inputData.latitude}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol md="6" lg="6">
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Longitude <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Longitude"
                      className="form-control no-arrows"
                      name="longitude"
                      value={inputData.longitude}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>

                <CCol md="6" lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Address <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormTextarea
                      rows={2}
                      type="text"
                      placeholder="Address"
                      className="form-control"
                      name="address"
                      value={inputData.address}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>

                <CCol md="3" lg="3">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Opening Time
                    </CFormLabel>
                    <CFormInput
                      type="time"
                      placeholder="Opening Time"
                      className="form-control"
                      name="openingTime"
                      value={inputData.openingTime}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol md="3" lg="3">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Closing Time
                    </CFormLabel>
                    <CFormInput
                      type="time"
                      placeholder="Closing Time"
                      className="form-control"
                      name="closingTime"
                      value={inputData.closingTime}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol md="6" lg="3">
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Pincode <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      minLength={6}
                      type="number"
                      placeholder="Pincode"
                      className="form-control no-arrows"
                      name="pincode"
                      value={inputData.pincode}
                      onChange={handleChange}
                      onBlur={getPostalCodeDetails}
                    />
                  </div>
                </CCol>
                <CCol md="6" lg="3">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      City <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="City"
                      className="form-control"
                      name="city"
                      value={inputData.city}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol md="6" lg="3">
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      State <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="State"
                      className="form-control"
                      name="state"
                      value={inputData.state}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol md="6" lg="3">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Country <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Country"
                      className="form-control"
                      name="country"
                      value={inputData.country}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
              </CRow>
              <h6>ADD DELIVERY PLATFORMS</h6>
              <hr />
              <CRow>
                {inputData.platForms &&
                  inputData.platForms.length > 0 &&
                  inputData.platForms.map((item, index) => (
                    <div key={index} className="mt-2">
                      <CRow>
                        <CCol>
                          <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                              Name
                            </CFormLabel>
                            <CFormInput
                              type="text"
                              id={`platForms-${index}`}
                              placeholder="Platform Name"
                              className="form-control no-arrows"
                              name="name"
                              value={item.name}
                              onChange={(e) => handleChange(e, index)}
                            />
                            {/* <div className="text-danger">
                              {validationErrors.addonValues[index]?.mrp}
                            </div> */}
                          </div>
                        </CCol>
                        <CCol>
                          <div className="mb-3">
                            <CFormLabel className="fw-semibold">
                              Weblink
                            </CFormLabel>
                            <CFormInput
                              type="text"
                              id={`platForms-${index}`}
                              placeholder="Weblink"
                              className="form-control no-arrows"
                              name="webLink"
                              value={item.webLink}
                              onChange={(e) => handleChange(e, index)}
                            />
                            {/* <div className="text-danger">
                              {validationErrors.addonValues[index]?.offerPrice}
                            </div> */}
                          </div>
                        </CCol>
                        <CCol className="col-md-1">
                          <div className="d-flex justify-content-end mt-2">
                            {index === inputData.platForms.length - 1 && (
                              <div>
                                <CButton
                                  size="sm"
                                  onClick={handleAddItem}
                                  color="primary"
                                  style={{
                                    marginRight: "3px",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <FaPlus />
                                </CButton>
                              </div>
                            )}

                            {inputData.platForms.length > 1 && (
                              <div>
                                <CButton
                                  size="sm"
                                  onClick={() => handleRemoveItem(index)}
                                  color="primary"
                                  style={{ borderRadius: "18px" }}
                                >
                                  <FaMinus />
                                </CButton>
                              </div>
                            )}
                          </div>
                        </CCol>
                      </CRow>
                    </div>
                  ))}
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton title="ADD OUTLET" isLoading={isLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openImageModal ? (
        <CModal
          alignment="center"
          visible={openImageModal}
          onClose={() => setOpenImageModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              OUTLET IMAGE PREVIEW
            </CModalTitle>
          </CModalHeader>
          <CForm>
            <CModalBody>
              <div>
                {seletedImage ? (
                  <div className="d-flex justify-content-center">
                    <CImage
                      src={seletedImage}
                      alt="outlet-image"
                      style={{ width: "200px", height: "200px" }}
                    />
                  </div>
                ) : null}
              </div>
              <div>
                <CButton onClick={handleOpenImageChangeModal}>Change Image</CButton>
              </div>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                style={{ borderRadius: "18px" }}
                title="SAVE CHANGES"
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openUpdateImageModal ? (
        <CModal
          alignment="center"
          visible={openUpdateImageModal}
          onClose={() => setOpenUpdateImageModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE IMAGE
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleUpdateImage}>
            <CModalBody>
              <div className="mb-3">
                <CFormLabel>
                  Choose File <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="file"
                  onChange={handleEditImageChange}
                  className="form-control"
                  id="formFile"
                />
                <span className="text-success" style={{ fontSize: '12px' }}>Choose an image with dimensions equal to 1410x1100</span>
              </div>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                style={{ borderRadius: "18px" }}
                title="SAVE CHANGES"
                isLoading={isUpdateImageBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </CContainer>
  );
};

export default Outlets;
